import React from "react";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Fade from "@material-ui/core/Fade";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import {
  USER_SUBCLIENT,
  USER_MASTER,
  USER_PROOFREADER,
  USER_WRITER,
  USER_CLIENT,
} from "../../common/consts";
import { BlackTooltip } from "../UITooltips/UITooltip";

const displayRole = (role) => {
  switch (role) {
    case USER_SUBCLIENT:
      return "Admin";
    // fallback
    case USER_CLIENT:
      return "Admin";
    case USER_MASTER:
      return "Master";
    case USER_PROOFREADER:
      return "Proofreader";
    case USER_WRITER:
      return "Writer";
    default:
      return "";
  }
};

const displayStatus = (status) => {
  if (status) {
    return "Active";
  } else {
    return "Pending";
  }
};

const MemberRow = ({ data, editRole, removeUser }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDeleteUser = () => {
    const current = data.id ? { id: data.id } : { email: data.email };
    removeUser(current);
  };

  return (
    <div className="members-list-row">
      <div>{data.email}</div>
      <div>{displayRole(data.role)}</div>
      <div>
        <div className="invoice-status">
          {displayStatus(data.status)}
          {!data.status && (
            <WatchLaterIcon
              style={{ color: "#6F767E", width: "16px", marginLeft: "7px" }}
            />
          )}
        </div>
      </div>
      <BlackTooltip
        arrow
        placement="top"
        title={
          data.projectNumber ? data.projectNames.join(", ") : "Not Assigned"
        }
      >
        <div className="members-pill">{data.projectNumber || "N/A"}</div>
      </BlackTooltip>

      <Button onClick={handleClick}>
        <MoreHorizIcon onClick={handleClick} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {data.id && (
          <MenuItem onClick={() => editRole(data)}>Edit Role</MenuItem>
        )}
        <MenuItem onClick={onDeleteUser}>Remove</MenuItem>
      </Menu>
    </div>
  );
};

export default MemberRow;
