import React from "react";

import "./UserCredits.css";
import { BlackTooltip } from "../UITooltips/UITooltip";

const UserCredits = ({ credits }) => {
  const perc = (credits.used * 100) / credits.limit;

  const creditInfo = [
    `Used: ${credits.used} credits`,
    `Left: ${credits.left} credits`,
    `Limit: ${!credits.limit ? 'unlimited' : credits.limit} credits`,
    `Reset in: ${credits.reset.toFixed(0)} day${credits.reset > 1 ? `s` : ``}`,
  ];
  const tooltipMessage = creditInfo.join("\n");

  return (
    <BlackTooltip
      arrow
      placement="left"
      title={<div style={{ whiteSpace: "pre-line" }}>{tooltipMessage}</div>}
    >
      <div className="user-credits">
        <div className="user-credits-info">{`Credits: ${credits.used} / ${credits.limit}`}</div>
        <div className="user-credits-bar">
          <div
            style={{
              width: `${perc}%`,
              backgroundColor: "#6A5FCC",
              height: "6px",
              borderTopLeftRadius: "3px",
              borderTopRightRadius: "3px",
              borderBottomLeftRadius: "3px",
              borderBottomRightRadius: "3px",
            }}
          ></div>
        </div>
      </div>
    </BlackTooltip>
  );
};

export default UserCredits;
