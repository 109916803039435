import React from "react";
import Button from "../../Shared/Button";

import "./PopupContentWrapper.css";

const PopupContentWrapper = ({
  width = "640px",
  height = "400px",
  title,
  children,
  closePopup,
  showCloseBtn,
}) => {
  return (
    <div
      style={{ width, height }}
      // use received props as height and width height 9 results in dropdown
      className="popup-content-wrapper"
    >
      {closePopup && (
        <div className="submit-modal-control">
          <div className="close-pop-button" onClick={closePopup}>
            &#10005;
          </div>
        </div>
      )}
      <p className="popup-title">{title}</p>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {children}
      </div>
      {showCloseBtn && (
        <div className="submit-modal-controls">
          <Button type="button" fullSize onClick={closePopup}>
            Close
          </Button>
        </div>
      )}
    </div>
  );
};

export default PopupContentWrapper;
