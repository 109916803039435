import React from "react";
import RadarChartSkeleton from "./RadarChartSkeleton";
import Typed from "react-typed";

import "./RadarChartPlaceholder.css";

const strings = [
  "Analyzing Google Search Result Page...",
  "Building the content brief...",
  "On-Page crawling competitors...",
  "Building the content brief...",
  "Analyzing keywords related to this topic...",
  "Building the content brief...",
];

const RadarCharPlaceholder = () => {
  return (
    <div className="radar-chart-placeholder">
      <RadarChartSkeleton />
      <div className="radar-chart-placeholder-messages">
        <div className="radar-chart-placeholder-typed">
          <Typed
            strings={strings}
            typeSpeed={20}
            backSpeed={0}
            backDelay={2500}
          />
        </div>
      </div>
    </div>
  );
};

export default RadarCharPlaceholder;
