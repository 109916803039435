import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import HomeContent from "../Components/HomeContent";
import { isDemoApplication } from "../reducers/applicationMode";
import { useSelector } from "react-redux";

const Home = () => {
  const isDemoApp = useSelector(isDemoApplication);
  const [isUserAuth] = useState(!!localStorage.getItem("userToken"));

  return isUserAuth || isDemoApp ? <HomeContent /> : <Redirect to="/login" />;
};

export default Home;
