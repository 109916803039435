import React, { useState, useEffect } from "react";
import { userHasPermission } from "./dashboardComponentHelpers";
import { USER_PROOFREADER, USER_WRITER } from "../common/consts";
import TagSelectComponent from "./TagSelectComponent/TagSelectComponent";

const DynamicOptionsSelectPill = ({
  rows,
  updateProperty,
  data,
  defaultSelected,
  onSelectChange,
  currentUserType,
  action,
}) => {
  const [defSelectedValue, setDefSelectedValue] = useState();

  useEffect(() => {
    setDefSelectedValue(defaultSelected);
  }, [defaultSelected]);

  const updateRowWithSelectedOption = (currentValue, previousValue) => {
    const currentSelectedValue = currentValue ? currentValue.value : null;
    const previousSelectedValue = previousValue ? previousValue.value : null;
    // build new updated row
    const updatedRow = rows.map((row) => ({
      ...row,
      [updateProperty]: currentSelectedValue,
      [`prev${updateProperty}`]: previousSelectedValue,
    }));
    // send updated row as array to parent
    onSelectChange(updatedRow, action, updateProperty);
  };

  return (
    <>
      <TagSelectComponent
        handleOptionChange={(currentValue, previousValue) =>
          updateRowWithSelectedOption(currentValue, previousValue)
        }
        data={data}
        preselectedValue={defSelectedValue}
        readOnlyDisplay={
          userHasPermission(currentUserType, USER_PROOFREADER) ||
          userHasPermission(currentUserType, USER_WRITER)
        }
      />
    </>
  );
};

export default DynamicOptionsSelectPill;
