import axios from "axios";
import { BASE_URL } from "../common/consts";

const baseEndpoint = "api/billing";
const invoices = "list-invoice";
const getInfo = "get-information";
const editInfo = "edit-information";

// get a list of all all invoices
export const getInvoiceList = (token) =>
  axios
    .get(`${BASE_URL}/${baseEndpoint}/${invoices}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// get company details
export const getCompanyDetails = (token) =>
  axios
    .get(`${BASE_URL}/${baseEndpoint}/${getInfo}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// save company details
export const saveCompanyDetails = (token, payload) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${editInfo}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));
