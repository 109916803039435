import axios from "axios";
import { BASE_URL } from "../common/consts";

const topics = "api/topics/step1";
const topicsDetails = "api/topics/step2new";
const categoryTopic = "api/topics/category";
const usedDifferent = "api/keywords/different";
const keywordRemoved = "api/keywords/unused";

export const getTopicData = (topic, token) =>
  axios
    .post(`${BASE_URL}/${topics}`, topic, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

export const getTopicCoverage = (topic, token) =>
  axios
    .post(`${BASE_URL}/${topicsDetails}`, topic, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

export const getCategoryTopicCoverage = (topic, token) =>
  axios
    .post(`${BASE_URL}/${categoryTopic}`, topic, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

export const setWordAsDifferent = (differentKeyword, token) =>
  axios
    .post(`${BASE_URL}/${usedDifferent}`, differentKeyword, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

export const setWordAsRemoved = (removedKeyword, token) =>
  axios
    .post(`${BASE_URL}/${keywordRemoved}`, removedKeyword, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));
