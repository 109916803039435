import React from "react";
import { Slider } from "@material-ui/core";

import "./ProjectBriefSliders.css";

const defaultSliderValue = 3;

const ProjectBriefSliders = ({
  onUpdateSlider,
  isDisabled,
  defaultValue1,
  defaultValue2,
  defaultValue3,
}) => {
  const updateSliderValue = (newValue, sliderName) => {
    onUpdateSlider(sliderName, newValue);
  };

  const renderSlider = (
    startText,
    endText,
    sliderName,
    isDisabled,
    defaultValue = defaultSliderValue
  ) => {
    return (
      <div className={"slider-input"}>
        <div
          className={
            isDisabled ? "slider-text-start__disabled" : "slider-text-start"
          }
        >
          {startText}
        </div>
        <div className="slider-wrapper">
          <Slider
            disabled={isDisabled}
            size="aaa"
            aria-label="slider"
            defaultValue={defaultValue}
            onChangeCommitted={(_, newValue) =>
              updateSliderValue(newValue, sliderName)
            }
            valueLabelDisplay="auto"
            step={1}
            // marks
            min={1}
            max={5}
          />
        </div>
        <div
          className={
            isDisabled ? "slider-text-end__disabled" : "slider-text-end"
          }
        >
          {endText}
        </div>
      </div>
    );
  };

  const slider1Disabled = isDisabled && !defaultValue1;
  const slider2Disabled = isDisabled && !defaultValue2;
  const slider3Disabled = isDisabled && !defaultValue3;

  return (
    <div className={isDisabled ? "sliders-area__disabled" : "sliders-area"}>
      <div className={isDisabled ? "sliders-label__disabled" : "sliders-label"}>
        Content Styles
      </div>
      {!slider1Disabled &&
        renderSlider(
          "B2B",
          "General",
          "contentStyleSlider1",
          isDisabled,
          defaultValue1
        )}
      {!slider2Disabled &&
        renderSlider(
          "Formal",
          "Informal",
          "contentStyleSlider2",
          isDisabled,
          defaultValue2
        )}
      {!slider3Disabled &&
        renderSlider(
          "Technical",
          "Narrative",
          "contentStyleSlider3",
          isDisabled,
          defaultValue3
        )}
    </div>
  );
};

export default ProjectBriefSliders;
