import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import Login from "./Pages/Login";
import Register from "./Pages/Register";
import Home from "./Pages/Home";
import Template from "./Shared/Template";
import ResetPassword from "./Pages/ResetPassword";
import Eshop from "./Pages/Eshop";
import Native from "./Pages/Native";
import Dasboard from "./Pages/Dasboard";
import ProjectBrief from "./Pages/ProjectBrief";
import ProjectsDashboardPage from "./Pages/ProjectsDashboard";
import DesktopOnly from "./Components/DesktopOnly";
import { isDemoApplication } from "./reducers/applicationMode";
import {
  DEFAULT_ARTICLE_ID,
  LOGIN_PATH,
  REGISTER_PATH,
  RESET_PASS_PATH,
  HOME_PATH,
  DEMO_APP_PATH,
  ESHOP_PATH,
  DASHBOARD_PATH,
  NATIVE_PATH,
  CLIENT_BRIEF_PATH,
  CLIENTS_DASHBOARD_PATH,
  CHANGE_PASS_PATH,
  PERFORMANCE_DASHBOARD_PATH,
  PROJECTS_PERFORMANCE_DASHBOARD_PATH,
  MULTI_STEP_REGISTER,
  SEARCH_RESULTS_PATH,
} from "./common/consts";
import { useScreenSize } from "./utils/useWindowSize";
import useCacheBuster from "./utils/useCacheBuster";
import {
  apiErrorMessage,
  isApiError,
  setApiError,
  setApiErrorMessage,
} from "./reducers/apiErrors";
import ChangePassowrd from "./Pages/ChangePassword";
import PerformanceDashboardPage from "./Pages/PerformanceDashboard";
import ProjectsPerformanceDashboardPage from "./Pages/ProjectsPerformanceDashboard";
import MultistepRegister from "./Pages/MultistepRegister";
import SearchResultsPage from "./Pages/SearchResults";
import { QueryClient, QueryClientProvider } from "react-query";

const refreshCacheAndReload = () => {
  console.log("Clearing cache and hard reloading...");
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then(function (names) {
      for (let name of names) caches.delete(name);
    });
  }
  // delete browser cache and hard reload
  if (!window.location.hash) {
    window.location = window.location + "#loaded";
    window.location.reload(true);
  }
};

const App = () => {
  const isDemoApp = useSelector(isDemoApplication);
  const { forceRefresh } = useCacheBuster();
  const error = useSelector(isApiError);
  const dispatch = useDispatch();
  const errorMessage = useSelector(apiErrorMessage);
  const [snackOpen, setSnackOpen] = useState(false);

  // build a redirect url based on what domain the application is loaded on
  const redirectUrl = isDemoApp
    ? `${DEMO_APP_PATH}/${DEFAULT_ARTICLE_ID}`
    : `${LOGIN_PATH}`;

  /* if application is in demo mode we need to change the url to this format: `/demo/article/1`*/
  const currentHomePath = isDemoApp
    ? `${DEMO_APP_PATH}/${DEFAULT_ARTICLE_ID}`
    : `${HOME_PATH}/:id`;

  const { screenWidth } = useScreenSize();

  useEffect(() => {
    if (forceRefresh) {
      refreshCacheAndReload();
    }
  }, [forceRefresh]);

  useEffect(() => {
    if (error) {
      setSnackOpen(true);
    }
  }, [error]);

  const onCloseSnack = () => {
    setSnackOpen(false);
    dispatch(setApiError(null));
    dispatch(setApiErrorMessage(null));
  };

  const client = new QueryClient();

  return (
    <>
      {screenWidth > 768 ? (
        <>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={snackOpen}
            onClose={onCloseSnack}
          >
            <Alert
              onClose={onCloseSnack}
              severity="error"
              sx={{ width: "100%" }}
            >
              {errorMessage}
            </Alert>
          </Snackbar>
          <QueryClientProvider client={client}>
            <Router>
              <Switch>
                <Route path={LOGIN_PATH} exact>
                  <Template>
                    <Login />
                  </Template>
                </Route>
                <Route path={REGISTER_PATH} exact>
                  <Template>
                    <Register />
                  </Template>
                </Route>
                <Route path={MULTI_STEP_REGISTER} exact>
                  <Template>
                    <MultistepRegister />
                  </Template>
                </Route>
                <Route path={RESET_PASS_PATH} exact>
                  <Template>
                    <ResetPassword />
                  </Template>
                </Route>
                <Route path={CHANGE_PASS_PATH} exact>
                  <Template>
                    <ChangePassowrd />
                  </Template>
                </Route>
                <Route path={currentHomePath}>
                  <Template>
                    <Home />
                  </Template>
                </Route>
                <Route path={DASHBOARD_PATH}>
                  <Template>
                    <Dasboard />
                  </Template>
                </Route>

                <Route path={`${CLIENT_BRIEF_PATH}/:id`}>
                  <Template>
                    <ProjectBrief />
                  </Template>
                </Route>
                <Route path={SEARCH_RESULTS_PATH}>
                  <Template>
                    <SearchResultsPage />
                  </Template>
                </Route>
                <Route path={CLIENTS_DASHBOARD_PATH}>
                  <Template>
                    <ProjectsDashboardPage />
                  </Template>
                </Route>
                <Route path={`${PERFORMANCE_DASHBOARD_PATH}/:clientId`}>
                  <Template>
                    <PerformanceDashboardPage />
                  </Template>
                </Route>
                <Route path={PROJECTS_PERFORMANCE_DASHBOARD_PATH}>
                  <Template>
                    <ProjectsPerformanceDashboardPage />
                  </Template>
                </Route>
                <Route path={`${ESHOP_PATH}/:id`}>
                  <Template>
                    <Eshop />
                  </Template>
                </Route>
                <Route path={`${NATIVE_PATH}/:id`}>
                  <Template>
                    <Native />
                  </Template>
                </Route>
                <Redirect to={redirectUrl} />
              </Switch>
            </Router>
          </QueryClientProvider>
        </>
      ) : (
        <DesktopOnly />
      )}
    </>
  );
};

export default App;
