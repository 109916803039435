import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import SidebarRight from "../Components/SidebarRight";
import CategoryInputs from "../Components/CategoryInputs";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { DEFAULT_ESHOP_ID } from "../common/consts";
import InfoTips from "../Components/InfoTips/InfoTips";
import { USER_CLIENT, USER_PROOFREADER } from "../common/consts";
import { getCurrentArticle } from "../reducers/articles";

import "./CategoryMode.css";
import { activeUser } from "../reducers/user";
import TipTapCategoryEditor from "../Components/TipTapEditor/TipTapCategoryEditor";
import EditorMenuBottom from "../Components/TipTapEditor/EditorMenuBottom";
import { getEditorHTMLData } from "../reducers/textEditor";

const CategoryMode = () => {
  const { id: articleId } = useParams();
  const user = useSelector(activeUser);

  const data = useSelector(getEditorHTMLData);

  const currentArticle = useSelector(getCurrentArticle);
  const [userType, setCurrentUserType] = useState(null);
  const [article, setArticle] = useState(null);

  useEffect(() => {
    if (user) {
      setCurrentUserType(user.role[0]);
    }
  }, [user]);

  useEffect(() => {
    if (currentArticle) {
      setArticle(currentArticle);
    }
  }, [currentArticle]);

  const showInfoTips =
    userType &&
    userType !== USER_CLIENT &&
    userType &&
    userType !== USER_PROOFREADER;

  return (
    <>
      {/* bottom-header is a fake progress bar, for design purpose only  */}
      <div className="bottom-header"></div>
      <div className="homepage-content">
        <div className="category-editor-wrapper">
          {articleId !== DEFAULT_ESHOP_ID && (
            <div className="category-editor-content">
              <div className="category-top-info">
                <div className="meta-input-area">
                  <CategoryInputs eshopId={articleId} />
                </div>
              </div>

              <div className="category-editor">
                <div className="category-editor-head">
                  <h5 className="category-editor-title">Category text</h5>
                  <CopyToClipboard text={data}>
                    <img
                      className="category-editor-copy"
                      src="/images/_copy-clipboard.svg"
                      alt="copy"
                    />
                  </CopyToClipboard>
                </div>
                {showInfoTips && <InfoTips />}
                {article && <TipTapCategoryEditor />}
                {article && <EditorMenuBottom />}
              </div>
            </div>
          )}
        </div>

        <SidebarRight />
      </div>
    </>
  );
};

export default CategoryMode;
