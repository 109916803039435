import React from "react";

import "./CustomBarChart.css";
import { BlackTooltip } from "../../UITooltips/UITooltip";

const CustomBarChart = ({ values }) => {
  const limit = values && Math.max(...values.map((bar) => bar.value));
  return (
    <div className="bar-chart-wrapper">
      {values &&
        values.map((bar) => {
          const percentage = bar.value / limit;
          const barHeight = Math.round(percentage * 100);

          return (
            <div className="bar-chart-item" key={bar.id}>
              <BlackTooltip
                arrow
                placement="top"
                title={`Searches ${new Intl.NumberFormat("en-IN").format(
                  bar.value
                )}`}
              >
                <div
                  style={{
                    background: `${bar.fill}`,
                    display: "block",
                    width: "29px",
                    height: `${barHeight}px`,
                  }}
                ></div>
              </BlackTooltip>
              <div className="bar-name">{bar.name}</div>
            </div>
          );
        })}
    </div>
  );
};

export default CustomBarChart;
