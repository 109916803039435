import { getUserToken } from "../utils/userStatus";
import {
  saveAllComments,
  getAllComments,
  getUsersOnArticle,
} from "../api/comments";

export const saveComments = async (articleId, data) => {
  const token = getUserToken();

  const commentData = { id: articleId, comments: data };

  await saveAllComments(commentData, token);
};

export const getCommentsFromServer = async (articleId) => {
  const token = getUserToken();

  const allComments = await getAllComments({ id: articleId }, token);

  return allComments;
};

export const getUsersForArticle = async (articleId) => {
  const token = getUserToken();

  const allUsers = await getUsersOnArticle({ id: articleId }, token);

  return allUsers;
};
