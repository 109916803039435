export const parseAiOutline = (data) => {
  const mapped = Object.entries(data).map(([key, value]) => {
    return {
      title: key,
      subtitles: value,
    };
  });

  return generateHTML(mapped);
};

const generateHTML = (data) => {
  return data.reduce((html, item) => {
    html += `<p></p><h2>${item.title}</h2><p></p>`;
    html += item.subtitles.reduce((subHtml, subtitle) => {
      subHtml += `<h3><em>${subtitle}</em></h3>`;
      return subHtml;
    }, "");
    return html;
  }, "");
};

export const parseAiFAQ = (data) => {
  return data.reduce((html, item) => {
    html += `<p><em>${item}</em></p>`;
    return html;
  }, "");
};

export const parseKeywords = (data) => {
  const mapped = Object.entries(data).map(([key, value]) => {
    return {
      keyword: key,
      searchVol: value,
    };
  });

  return mapped;
};
