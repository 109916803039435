import axios from "axios";
import { BASE_URL } from "../common/consts";

const userProfile = "api/user-profile";
const login = "api/login";
const register = "api/register";
const industries = "api/industries";
const reset = "api/forgot-password";
const changePass = "api/reset";
const multistepRegister = "api/register-clients";

const credits = "api/credit/limit";

// user profile
export const getUserProfile = (token) =>
  axios
    .get(`${BASE_URL}/${userProfile}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// login
export const loginRequest = (user) =>
  axios
    .post(`${BASE_URL}/${login}`, user)
    .then(({ data: { token } }) => token)
    .catch((error) => Promise.reject(error.response));

// register
export const registerRequest = (user) =>
  axios
    .post(`${BASE_URL}/${register}`, user)
    .then(({ status }) => status)
    .catch((error) => Promise.reject(error.response));

// multistep register
export const multistepRegisterRequest = (multistepPayload) =>
  axios
    .post(`${BASE_URL}/${multistepRegister}`, multistepPayload)
    .then(({ status }) => status)
    .catch((error) => Promise.reject(error.response));

// we need industries for registration
export const getIndustries = () =>
  axios
    .get(`${BASE_URL}/${industries}`)
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error));

// reset password
export const resetPassRequest = (data) =>
  axios
    .post(`${BASE_URL}/${reset}`, data)
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// change password
export const changePassRequest = (data) =>
  axios
    .post(`${BASE_URL}/${changePass}`, data)
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// user credits
export const getUserCredits = (token) =>
  axios
    .get(`${BASE_URL}/${credits}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));
