import axios from "axios";
import { BASE_URL } from "../common/consts";

const baseEndpoint = "api/projects";
const allUsers = "get-users";
const assignedUsers = "get-project-users";
const assign = "manage-users";
const membersList = "api/pending-invites";
const inviteUsers = "invite-users";
const editRole = "api/edit-role";
const remove = "remove-invite";

// get all users
export const getAllUsers = (token) =>
  axios
    .get(`${BASE_URL}/${baseEndpoint}/${allUsers}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// get assigned users
export const getAssignedUsers = (token, id) =>
  axios
    .post(
      `${BASE_URL}/${baseEndpoint}/${assignedUsers}`,
      { id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

export const assignProjectMembers = (token, payload) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${assign}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// get all members list displayed in the table
export const getMembersList = (token) =>
  axios
    .get(`${BASE_URL}/${membersList}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// invite users to projects
export const inviteUsersToProjects = (token, payload) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${inviteUsers}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// edit roles
export const editMemberRole = (token, payload) =>
  axios
    .post(`${BASE_URL}/${editRole}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// remove user
export const removeUser = (token, payload) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${remove}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));
