import React from "react";
import RegisterForm from "./RegisterForm";

import "./RegisterComponent.css";

const RegisterComponent = ({ industriesOptions }) => {
  return (
    <div className="register-component">
      <div className="register-left-content">
        <img
          src="/images/_registration.png"
          width="210px"
          height="auto"
          alt="Registration"
        />
        <div className="register-copy-area">
          <p className="register-title-copy">Almost there!</p>
          <p className="register-subcopy">
            In just a few moments you will be able to create high performing
            content.
          </p>
        </div>
      </div>
      <div className="register-divider"></div>
      <div className="register-right-content">
        <div className="register-app-logo">
          <img
            src="/images/logo-contero.svg"
            alt="logo"
            width="200px"
            height="65px"
          />
        </div>
        <RegisterForm />
      </div>
    </div>
  );
};

export default RegisterComponent;
