import React, { useReducer, useEffect, useState } from "react";
import { validate } from "../utils/validators";

import "./Input.css";

const inputReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid:
          !action.noValidation && validate(action.val, action.validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: !action.noValidation && true,
      };
    default: {
      return state;
    }
  }
};

const Input = (props) => {
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: props.initialValue || "",
    isTouched: props.initialValue ? true : false,
    isValid: props.initialValue ? true : false,
    noValidation: props.noValidation,
    disabled: props.disabled,
  });

  const [placeHolderText, setPlaceholderText] = useState(props.placeholder);

  const { id, onInput, collapsed, isReset } = props;
  const { value, isValid } = inputState;

  useEffect(() => {
    onInput(id, value, isValid);
  }, [id, value, isValid, onInput]);

  useEffect(() => {
    if (isReset) {
      dispatch({
        type: "CHANGE",
        val: "",
        validators: props.validators,
        noValidation: props.noValidation,
      });
    }
  }, [isReset]);

  const changeHandler = (event) => {
    dispatch({
      type: "CHANGE",
      val: event.target.value,
      validators: props.validators,
      noValidation: props.noValidation,
    });
  };

  const touchHandler = () => {
    if (props.noValidation) {
      setPlaceholderText(props.placeholder);
    }
    dispatch({
      type: "TOUCH",
      noValidation: props.noValidation,
    });
  };

  const focusHandler = () => {
    setPlaceholderText("");
  };

  const element =
    props.element === "input" ? (
      <input
        id={props.id}
        type={props.type}
        placeholder={placeHolderText}
        onChange={changeHandler}
        onBlur={touchHandler}
        value={inputState.value}
        ref={props.refChild}
        disabled={props.disabled}
      />
    ) : (
      <textarea
        id={props.id}
        placeholder={placeHolderText}
        rows={props.rows || 3}
        maxLength={props.maxLength}
        onChange={changeHandler}
        onBlur={touchHandler}
        onFocus={focusHandler}
        value={inputState.value}
      />
    );

  return (
    <div
      className={`form-control ${
        !inputState.isValid && inputState.isTouched && "form-control--invalid"
      } ${collapsed && "form-control--collapsed"}`}
    >
      <label htmlFor={props.id}>{props.label}</label>
      {element}
      {!inputState.isValid && inputState.isTouched && <p>{props.errorText}</p>}
    </div>
  );
};

export default Input;
