import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import ProjectsDashboard from "../Components/ProjectsDashboard/ProjectsDashboard";

const ProjectsDashboardPage = () => {
  const [isUserAuth] = useState(!!localStorage.getItem("userToken"));

  return isUserAuth ? <ProjectsDashboard /> : <Redirect to="/login" />;
};

export default ProjectsDashboardPage;
