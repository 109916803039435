import React, { useEffect, useState } from "react";

import CustomMultiSelect from "./CustomMultiSelect";

import "./CustomColumnFilter.css";

export const SELECT_ALL_ID = -1;

const firstOption = {
  id: SELECT_ALL_ID,
  name: "All",
};

const CustomColumnFilter = ({
  currentData,
  onFilter,
  currentColumn,
  initialValue,
  isClear,
}) => {
  const [columnData, setColumnData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  useEffect(() => {
    const added = currentData && [firstOption, ...currentData];

    setColumnData(added);
  }, [currentData]);

  useEffect(() => {
    buildFilterOptions();
  }, [selectedData]);

  useEffect(() => {
    getInitialValue();
  }, [initialValue, columnData]);

  useEffect(() => {
    if (isClear) {
      handleClearOptions();
      buildFilterOptions();
    }
  }, [isClear]);

  const getInitialValue = () => {
    if (initialValue && columnData.length > 0) {
      if (initialValue.some((val) => val === SELECT_ALL_ID)) {
        setSelectedData(columnData);
        if (initialValue.length > 1) {
          // get unselected elements from the list
          const toBeAdded = columnData.filter(
            (o1) => !initialValue.some((o2) => o1.id === o2)
          );

          setSelectedData([firstOption, ...toBeAdded]);
        }
      } else {
        const result = columnData.filter((o1) =>
          initialValue.some((o2) => o1.id === o2)
        );

        setSelectedData(result);
      }
    } else {
      setSelectedData([]);
    }
  };

  const handleToggleOption = (selectedOptions) =>
    setSelectedData(selectedOptions);

  const handleClearOptions = () => setSelectedData([]);

  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      setSelectedData(columnData);
    } else {
      handleClearOptions();
    }
  };

  const isAllSelected =
    selectedData && selectedData.some((option) => option.id === SELECT_ALL_ID);
  const allItemsChecked =
    selectedData && selectedData.length === columnData.length;

  const buildFilterOptions = () => {
    const filter = {
      [currentColumn]: getSelectedIds(),
    };
    onFilter(filter);
  };

  const getSelectedIds = () => {
    if (selectedData && selectedData.length > 0 && allItemsChecked) {
      // return only id -1 - which means all items are selected
      return [SELECT_ALL_ID];
    } else if (
      selectedData &&
      selectedData.length > 0 &&
      isAllSelected &&
      !allItemsChecked
    ) {
      // get unselected elements from the list
      const unselected = columnData.filter(
        (o1) => !selectedData.some((o2) => o1.id === o2.id)
      );

      // send id -1 which means all, and the unselected ids - to be ignored by backend
      const onlyIds = [SELECT_ALL_ID, ...unselected.map(({ id }) => id)];

      return onlyIds;
    } else if (selectedData && selectedData.length > 0) {
      // return whatever is selected
      return selectedData.map(({ id }) => id);
    }
  };

  return (
    <>
      {columnData && (
        <>
          <CustomMultiSelect
            selectedData={selectedData}
            columnData={columnData}
            currentColumn={currentColumn}
            onToggleOption={handleToggleOption}
            onClearOptions={handleClearOptions}
            onSelectAll={handleSelectAll}
          />
        </>
      )}
    </>
  );
};

export default CustomColumnFilter;
