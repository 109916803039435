import React from "react";
import Button from "../../Shared/Button";
import TitleWithIcon from "../ProjectBriefComponents/TitleWithIcon";
import { Thumbs2Icon, EyesIcon } from "../ProjectBriefComponents/Icons";

import "./PlagiarismContent.css";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import SourceItem from "./SourceItem";
import Scrollbars from "react-custom-scrollbars";

const titleStyle = {
  color: "#384259",
  fontWeight: "normal",
  fontFamily: "Graphik",
  fontSize: "22px",
  lineHeight: "50px",
  marginLeft: "10px",
};

const PlagiarismContent = ({ onRedirect, onClose, result, asInfo }) => {
  const scorePlag = result.plagiarismScore || 0;
  const scoreUnique = 100 - result.plagiarismScore;

  const title = result.plagiarism
    ? "Similarity treshold reached"
    : "Your content is unique";
  const currentIcon = result.plagiarism ? (
    <EyesIcon width={25} height={25} />
  ) : (
    <Thumbs2Icon width={25} height={25} />
  );

  const subtitle = result.plagiarism
    ? "Please rephrase the article or contact us."
    : "You will be redirected to dashboard.";

  const handleAction = () => {
    if (!asInfo) {
      if (result.plagiarism) {
        onClose();
      } else {
        onRedirect();
      }
    } else {
      onClose();
    }
  };

  const buttonMessage = !asInfo ? (result.plagiarism ? "Ok" : "Go") : "Close";

  return (
    <>
      <TitleWithIcon
        name={title}
        icon={currentIcon}
        customFont={titleStyle}
        iconLeft
      />
      <p className="plagiarism-subtitle">{subtitle}</p>
      <div className="plagiarism-mid-wrapper">
        <div className="plagiarism-left">
          <div className="circle-box">
            <CircularProgressbarWithChildren
              value={100 - result.plagiarismScore}
              strokeWidth="12"
              styles={buildStyles({
                rotation: 0.25,
                strokeLinecap: "butt",
                pathTransitionDuration: 0.5,
                pathColor: "#ADE8C9",
                trailColor: "#E898A2",
              })}
            ></CircularProgressbarWithChildren>
          </div>
          <div className="circle-info">
            <div className="plag-info">
              <div className="plag-score">{`${scorePlag}%`}</div>
              <div className="plag-text">Plagiarized Content</div>
            </div>
            <div className="unique-info">
              <div className="unique-score">{`${scoreUnique}% `}</div>
              <div className="unique-text">Unique Content</div>
            </div>
          </div>
        </div>
        {result.plagiarism && (
          <div className="plagiarism-right">
            <div className="vertical-line"></div>
            <div className="plagiarism-sources">
              <Scrollbars>
                {result.plagiarismSources.map((source, index) => {
                  const homePath = source.link.split("/")[2];
                  const clean = homePath.split("www.")[1];

                  return (
                    <SourceItem
                      key={`${source.link}-${index}`}
                      name={clean || homePath}
                      link={source.link}
                      percent={source.percent}
                    />
                  );
                })}
              </Scrollbars>
            </div>
          </div>
        )}
      </div>
      <div className="submit-modal-controls">
        <Button type="button" fullSize onClick={handleAction}>
          {buttonMessage}
        </Button>
      </div>
    </>
  );
};

export default PlagiarismContent;
