import React from "react";

import "./DashboardTopBar.css";

const DashboardTopBar = ({ children }) => {
  return (
    <div className={`dashboard-topbar-full`}>
      <div className="performancebar-title">
        <span className="title-tag-top-bar"></span>
      </div>
      <div className="topbar-children-content">{children}</div>
    </div>
  );
};

export default DashboardTopBar;
