import React from "react";
import { useSelector } from "react-redux";
import { getWordCount } from "../reducers/counters";
import {
  geTopicCoveragePerc,
  getMaxTopicCoverage,
  getCurrentTopicCoverage,
} from "../reducers/topicCoverage";

import "./EshopProgressStatus.css";

const maxWords = 300;

const EshopProgressStatus = () => {
  const wordCount = useSelector(getWordCount);
  const topicCoveragePerc = useSelector(geTopicCoveragePerc);
  const maxTopicCoverage = useSelector(getMaxTopicCoverage);
  const currentTopicCoverage = useSelector(getCurrentTopicCoverage);

  const wordsPerc = (wordCount * 100) / maxWords;
  const wordCountBarWidth = wordsPerc <= 100 ? wordsPerc : 100;

  const wordCountColor = wordsPerc < 100 ? "#E898A2" : "#ADE8C9";
  const topicCoverageColor =
    topicCoveragePerc < 30
      ? "#E898A2"
      : topicCoveragePerc > 70
      ? "#ADE8C9"
      : "#EECD72";

  return (
    <div className="progress-info">
      <div className="wordcount-progress">
        <div className="progress-title">Wordcount</div>
        <div className="progress-value">
          <span style={{ color: wordCountColor }}>{wordCount}</span> /{" "}
          {maxWords}
        </div>
        <div className="eshop-progress-bar">
          <div
            style={{
              width: `${wordCountBarWidth}%`,
              backgroundColor: wordCountColor,
              height: "15px",
              borderTopLeftRadius: "20px",
              borderTopRightRadius: "20px",
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
              transition: "all 1s",
            }}
          ></div>
        </div>
      </div>
      <div className="keywords-progress">
        <div className="progress-title">Keywords Usage</div>
        <div className="progress-value">
          <span style={{ color: topicCoverageColor }}>
            {currentTopicCoverage}
          </span>{" "}
          / {maxTopicCoverage}
        </div>
        <div className="eshop-progress-bar">
          <div
            style={{
              width: `${topicCoveragePerc}%`,
              backgroundColor: topicCoverageColor,
              borderTopLeftRadius: "20px",
              borderTopRightRadius: "20px",
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
              height: "15px",
              borderTopRadius: "20px",
              transition: "all 1s",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default EshopProgressStatus;
