import React from "react";
import Scrollbars from "react-custom-scrollbars";
import {
  addInviteUsers,
  removeInvitedUser,
} from "../../services/project-members";

import RegisterTitle from "../MultistepRegister/RegisterTitle";
import AddUsersForm from "./AddUsersForm";
import MemberRow from "./MemberRow";

import "./MembersComponent.css";

const MembersComponent = ({ projects, allMembers, onRefetch, editRole }) => {
  const onUpdateTeam = async (team) => {
    const payload = {
      team,
    };
    const result = await addInviteUsers(payload);
    if (result) {
      onRefetch();
    }
  };

  const onRemoveUser = async (user) => {
    const result = await removeInvitedUser(user);
    if (result) {
      onRefetch();
    }
  };
  if (!allMembers) return null;

  return (
    <>
      <div className="members-component">
        <div className="members-left">
          {/* <Scrollbars> */}
          <div className="members-table">
            <div className="members-list-head">
              <div>Email</div>
              <div>Role</div>
              <div>Status</div>
              <div>Projects</div>
              <div></div>
            </div>
            {allMembers.length > 0 &&
              allMembers.map((member) => (
                <MemberRow
                  key={member.email}
                  data={member}
                  editRole={editRole}
                  removeUser={onRemoveUser}
                />
              ))}
          </div>
          {/* </Scrollbars> */}
        </div>
        <div className="vertical-divider"></div>
        <div className="members-right">
          <div className="members-right-side-form">
            <RegisterTitle title="Add users" />
            <div className="members-register-desc">
              Content writers can write content for the projects they are
              assigned on while administrator accounts have unrestricted access
              to projects and dashboards
            </div>
            <AddUsersForm onUpdateTeamList={onUpdateTeam} projects={projects} />
          </div>
        </div>
      </div>
    </>
  );
};

export default MembersComponent;
