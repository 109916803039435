import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Tags from "./Tags";
import KeywordsToggleSwitch from "./KeywordsToggleSwitch";
import TopicCoverageSkeletonPlaceholder from "./TopicCoverageSkeletonPlaceholder";
import SearchKeywords from "./SearchKeywords";
import EshopProgressStatus from "./EshopProgressStatus";

import { getTopicCoverageData, hasLowTopics } from "../reducers/topicCoverage";

import "./TopicCoverage.css";

const TopicCoverage = ({ isTopicLoading, showContent, isArticleMode }) => {
  const processedKeywords = useSelector(getTopicCoverageData);
  const displayKeywordsToggle = useSelector(hasLowTopics);
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [allKeywords, setAllKeywords] = useState(processedKeywords);

  useEffect(() => {
    setAllKeywords(processedKeywords);
  }, [processedKeywords]);

  const filterKeywords = (filter) => {
    setSearchKeyword(filter);
  };

  useEffect(() => {
    const filteredKeywords = processedKeywords.filter(({ keyword }) =>
      keyword?.includes(searchKeyword)
    );
    setAllKeywords(filteredKeywords);
  }, [processedKeywords, searchKeyword]);

  return (
    <div
      className={`${
        isArticleMode && showContent
          ? "side-topic-coverage"
          : "side-topic-coverage-category"
      }`}
    >
      {!isArticleMode && <EshopProgressStatus />}
      {isArticleMode && <div className="side-topic-divider"></div>}
      {showContent && (
        <div className="side-topic-info">
          <h3>Topic Coverage</h3>
          {isArticleMode && (
            <p>
              All keywords from below must be used in the body of the article.
              Some of them are marked to also be used in headings or in the
              title. If you notice that a keyword is not relevant you can press
              "x" on hover to remove it from the list and avoid using it.
            </p>
          )}
        </div>
      )}
      {isArticleMode && showContent && displayKeywordsToggle && (
        <KeywordsToggleSwitch />
      )}
      {isTopicLoading && (
        <TopicCoverageSkeletonPlaceholder isArticle={isArticleMode} />
      )}

      {!isTopicLoading && showContent && (
        <>
          <SearchKeywords onFilter={filterKeywords} />
          <Tags activeKeywords={allKeywords} />
        </>
      )}
    </div>
  );
};

export default TopicCoverage;
