import axios from "axios";
import { BASE_URL } from "../common/consts";

const baseEndpoint = "api/articles";
const save = "comments";
const get = "get-comments";
const users = "get-article-users";
// save all comments
export const saveAllComments = (comments, token) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${save}`, comments, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

export const getAllComments = (comments, token) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${get}`, comments, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

export const getUsersOnArticle = (articleId, token) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${users}`, articleId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));
