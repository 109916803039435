import React, { Fragment, useState, useEffect } from "react";
import Badge from "@material-ui/core/Badge";

import "./TabsMenu.css";
import CustomSvgIcon from "./TipTapEditor/CustomSvgIcon";

const TabsMenu = ({
  onChange,
  active,
  hideGuidelinesTab,
  isMaster,
  showBriefTab,
}) => {
  const [showBullet, setShowBullet] = useState(true);

  useEffect(() => {
    if (active === 3) {
      setShowBullet(false);
    }
  }, [active]);

  // if type is NATIVE we don't show guidelines tab, so we make the default selected tab 1 (competition)
  useEffect(() => {
    if (hideGuidelinesTab) {
      onChange(1);
    }
  }, [hideGuidelinesTab]);

  const toggleIconWithBadge = () => {
    if (showBullet && !isMaster) {
      return (
        <Badge color="secondary" overlap="rectangular" variant="dot">
          <img
            src="/images/_icon-brief.svg"
            width="12px"
            height="13px"
            alt="info"
          />
        </Badge>
      );
    } else {
      return (
        <img
          src="/images/_icon-brief.svg"
          width="12px"
          height="13px"
          alt="info"
        />
      );
    }
  };

  return (
    <Fragment>
      <div className="tabs-menu-header">
        <ul className="tabs-menu">
          {/* we hide guidelines tab if the article type is native (have this check in the parent) */}
          {!hideGuidelinesTab && (
            <li
              onClick={() => onChange(0)}
              className={active === 0 ? "active" : ""}
            >
              <div className="menu-icon">
                <span className="guides">
                  <img
                    src="/images/_brief-icon.svg"
                    width="15px"
                    height="15px"
                    alt="info"
                  />
                </span>
                <p>Guidelines</p>
              </div>
            </li>
          )}

          <li
            onClick={() => onChange(4)}
            className={active === 4 ? "active" : ""}
            style={{ position: 'relative' }}
          >
            <span className='beta-flag'>BETA</span>
            <div className="menu-icon">

              <span className="guides">
                <img
                  src="/images/_ai-icon.svg"
                  width="15px"
                  height="15px"
                  alt="info"
                />
                {/* <CustomSvgIcon icon="list-ordered" /> */}
              </span>
              <p>AI </p>
            </div>
          </li>

          <li
            onClick={() => onChange(1)}
            className={active === 1 ? "active" : ""}
          >
            <div className="menu-icon">
              <span className="competition">
                <img
                  src="/images/_competition-icon.svg"
                  width="14px"
                  height="14px"
                  alt="info"
                />
              </span>

              <p>Competition</p>
            </div>
          </li>
          {/* <li
            onClick={() => onChange(2)}
            className={active === 2 ? "active" : ""}
          >
            <div className="menu-icon">
              <span className="feedback">
                <img
                  src="/images/_feedback-icon.svg"
                  width="12px"
                  height="12px"
                  alt="info"
                />
              </span>

              <p>Feedback</p>
            </div>
          </li> */}
          {showBriefTab && (
            <li
              onClick={() => onChange(3)}
              className={active === 3 ? "active" : ""}
            >
              <div className="menu-icon">
                <span className="brief">{toggleIconWithBadge()}</span>
                <p>Brief</p>
              </div>
            </li>
          )}
        </ul>
      </div>
    </Fragment>
  );
};

export default TabsMenu;
