import React from "react";
import "./TopicSerpContent.css";

export default function TopicSerpContent({ rowData, gridSize }) {
  return (
    <div
      className="topic-serp-content-row"
      style={{
        display: "grid",
        gridTemplateColumns: gridSize,
      }}
    >
      <div className="serp-rank">{rowData?.rank}</div>
      <div className="serp-competitor">
        <img
          className="serp-comp-image"
          src={rowData?.image}
          alt="logo"
          width="40px"
          height="auto"
        />
        {rowData?.domain}
      </div>
      <div className="serp-keywords-wrapper">
        <div className="serp-keywords">{rowData?.keywords}</div>
      </div>
    </div>
  );
}
