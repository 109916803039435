import axios from "axios";
import { BASE_URL } from "../common/consts";

const baseApi = "api/articles";

export const sendFeedbackByUser = (token, eventType, patchObject) =>
  axios
    .post(`${BASE_URL}/${baseApi}/${eventType}`, patchObject, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));
