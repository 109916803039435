import React from "react";

const KeywordItem = ({ activeKeywords }) => {
  return (
    <>
      {activeKeywords.map(({ keyword, searchVol }) => {
        return (
          <div key={keyword} className={`custom-tag`}>
            <div className="dot-and-label">
              <span className={`custom-tag-dot `}></span>
              <div>
                <p className="keyword">{keyword}</p>
                <p className="monthly-searches">search volume {searchVol}</p>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default KeywordItem;
