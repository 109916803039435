import React, { useCallback, useState } from "react";
import "./PerformanceDashboardList.css";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { intToString } from "../../utils/formatNumbersToString";
import { useHistory } from "react-router-dom";
import { HOME_PATH } from "../../common/consts";
import {
  EditIcon,
  LinkIcon,
  TrendArrowDown,
  TrendArrowUp,
} from "../../UI_utils/SVGIcons";
import { restrictCharactersNumber } from "../../utils/characterLimitString";
import { BlackTooltip } from "../UITooltips/UITooltip";

const PerformanceCard = ({ data, onUpdateSelected }) => {
  const [activeId, setActiveId] = useState(null);
  const history = useHistory();

  const updateSelectedCard = (event) => {
    onUpdateSelected(data.id, event.target.checked);
  };

  const isHover = useCallback(() => {
    setActiveId(data.id);
  }, [data.id]);

  const isHighlight = activeId === data.id;

  const displayScoreBg = (color) => {
    const bgColor =
      data.score < 20
        ? color
        : data.score > 70
        ? "rgba(84, 214, 44, 0.16)"
        : "rgba(255, 193, 7, 0.16)";

    return bgColor;
  };

  const displayScoreTxt = (color) => {
    const txtColor =
      data.score < 20
        ? color
        : data.score > 70
        ? "rgb(34, 154, 22)"
        : "rgb(183, 129, 3)";

    return txtColor;
  };

  // red
  //   color: rgb(183, 33, 54);
  // background-color: rgba(255, 72, 66, 0.16);

  //   green
  // color: rgb(34, 154, 22);
  // background-color: rgba(84, 214, 44, 0.16);

  //   orange
  //   color: rgb(183, 129, 3);
  // background-color: rgba(255, 193, 7, 0.16);

  // background: displayScoreBg("#ffc0c7"),
  // color: displayScoreTxt("#e16d7a"),

  return (
    <>
      <div
        className="performanceCard"
        onMouseOver={isHover}
        onMouseLeave={() => setActiveId(null)}
      >
        <div className="cardCheck">
          <Checkbox
            checked={data.selected}
            onChange={updateSelectedCard}
            color="default"
          />
        </div>
        <div className="cardImage">
          <div
            className="imageWrapper"
            onMouseOver={() => console.log("hover ", data.title)}
          >
            <img
              src={data.image || "/images/no-image.svg"}
              width={80}
              height={80}
              alt="article"
            />
          </div>
        </div>
        <div className="articleInfo">
          <div className="infoDate">{data.date}</div>
          <div
            className="infoTitle"
            onClick={() => history.push(`${HOME_PATH}/${data.id}`)}
          >
            {restrictCharactersNumber(data.title, 68)}
          </div>
        </div>
        <div className="healthInfo">
          <BlackTooltip
            arrow
            placement="top"
            title="The percentage of organic visits for the selected timeframe compared to the total number of visits this topic can obtain."
          >
            <div className="circleWrapper">
              <CircularProgressbarWithChildren
                value={data.health}
                strokeWidth="7"
                styles={buildStyles({
                  rotation: 0,
                  strokeLinecap: "round",
                  pathTransitionDuration: 0.5,
                  pathColor: "#6870f6",
                  trailColor: isHighlight ? "white" : "#F4F4F4",
                })}
              >
                <div className="healthScore">
                  {data.health ? `${data.health}%` : "N/A"}
                </div>
              </CircularProgressbarWithChildren>
            </div>
          </BlackTooltip>
        </div>
        <div className="scoreInfo">
          <BlackTooltip
            arrow
            placement="top"
            title="The Contero score metric takes into account the percentage of article brief guidelines implementation by the writer."
          >
            <div className="scoreValue">
              <div
                className="infoTag"
                style={{
                  background: displayScoreBg("rgba(255, 72, 66, 0.16)"),
                  color: displayScoreTxt("rgb(183, 33, 54)"),
                }}
              >
                {data.score}
              </div>
            </div>
          </BlackTooltip>
        </div>
        <div className="visitsInfo">
          <BlackTooltip
            arrow
            placement="top"
            title="The number of organic visits for the selected timeframe."
          >
            <div className="centerLabelsOffset">
              <div className="visitsTrend">
                {data.visitsTrend.status === "ascending" ? (
                  <TrendArrowUp />
                ) : (
                  <TrendArrowDown />
                )}

                <div
                  className="visitsTrendValue"
                  style={{
                    color:
                      data.visitsTrend.status === "ascending"
                        ? "#83BF6E"
                        : "#FF6A55",
                  }}
                >{`${data.visitsTrend.value}%`}</div>
              </div>
              <div
                className="infoTag"
                style={{
                  background: `${isHighlight ? "white" : "#EFEFEF"}`,
                  color: "#1A1D1F",
                }}
              >
                {intToString(data.visits)}
              </div>
            </div>
          </BlackTooltip>
        </div>
        <div className="keywordsInfo">
          <BlackTooltip
            arrow
            placement="top"
            title="This article is ranking for this number of keywords in the selected timeframe."
          >
            <div className="centerLabelsOffset">
              <div className="visitsTrend">
                <div
                  className="visitsTrendValue"
                  style={{
                    color:
                      data.keywordsTrend.status === "ascending"
                        ? "#83BF6E"
                        : "#FF6A55",
                  }}
                >{`${data.keywordsTrend.status === "ascending" ? "+" : "-"} ${
                  data.keywordsTrend.value
                }`}</div>
              </div>
              <div
                className="infoTag"
                style={{
                  background: `${isHighlight ? "white" : "#EFEFEF"}`,
                  color: "#1A1D1F",
                }}
              >
                {data.keywords}
              </div>
            </div>
          </BlackTooltip>
        </div>
        <div className="opportunityInfo">
          <BlackTooltip
            arrow
            placement="top"
            title="The total number of visits that this topic can obtain."
          >
            <div
              className="infoTag"
              style={{
                background: `${isHighlight ? "white" : "#EFEFEF"}`,
                color: "#1A1D1F",
              }}
            >
              {intToString(data.trafficOpportunity.total)}
            </div>
          </BlackTooltip>
        </div>

        <div className="rankInfo">
          <BlackTooltip
            arrow
            placement="top"
            title="The average rank for all the keywords this article is ranking for."
          >
            <div className="centerLabelsOffset">
              <div className="visitsTrend">
                <div
                  className="visitsTrendValue"
                  style={{
                    color:
                      data.averageRankTrend.status === "ascending"
                        ? "#FF6A55"
                        : "#83BF6E",
                  }}
                >{`${
                  data.averageRankTrend.status === "ascending" ? "+" : "-"
                } ${data.averageRankTrend.value}`}</div>
              </div>
              <div
                className="infoTag"
                style={{
                  background: `${isHighlight ? "white" : "#EFEFEF"}`,
                  color: "#1A1D1F",
                }}
              >
                {data.averageRank}
              </div>
            </div>
          </BlackTooltip>
        </div>
        <div className="ratingInfo">
          <BlackTooltip
            arrow
            placement="top"
            title="The article rating manually set by the proofreader based on grammar, spelling, punctuation and formatting guidelines."
          >
            <div
              className="infoTag"
              style={{
                background: `${isHighlight ? "white" : "#EFEFEF"}`,
                color: "#1A1D1F",
              }}
            >
              {data.rating ? data.rating : "N/A"}
            </div>
          </BlackTooltip>
        </div>
        <div className="writerInfo">
          <BlackTooltip arrow placement="top" title={data.writerFull}>
            <div
              className="writerTag"
              style={{ background: `${isHighlight ? "white" : "#EFEFEF"}` }}
            >
              {data.writer}
            </div>
          </BlackTooltip>
        </div>
        <div className="menuItems">
          <div className="menuIcons">
            <div
              className="iconWrapper"
              style={{ background: `${isHighlight ? "white" : "#EFEFEF"}` }}
              onClick={() => history.push(`${HOME_PATH}/${data.id}`)}
            >
              <EditIcon />
            </div>
            <div
              className="iconWrapper"
              style={{ background: `${isHighlight ? "white" : "#EFEFEF"}` }}
              onClick={() => window.open(data.url, "_blank")}
            >
              <LinkIcon />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PerformanceCard;
