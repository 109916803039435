import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import CreatableSelect from "react-select/creatable";

const components = {
  DropdownIndicator: null,
};
const createOption = (label) => ({
  label,
  value: label,
});

const CustomAsyncSelect = ({
  placeholder,
  onStringSearch,
  customStyles,
  initialValue,
}) => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const searchString = queryParams.get("search");

  const showOption = () => {
    if (searchString || initialValue) {
      return createOption(searchString || initialValue);
    } else {
      return [];
    }
  };

  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState(showOption());

  const handleChange = (value) => {
    setValue(value);
    requestSearch();
  };

  const handleInputChange = (inputValue) => {
    setInputValue(inputValue);
  };

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setValue(createOption(inputValue));
        setInputValue("");
        event.preventDefault();
        requestSearch();
        break;
      default: {
        return null;
      }
    }
  };

  const requestSearch = () => {
    // set search string in the parent, this will trigger a fetch with string search

    onStringSearch(inputValue);
  };

  return (
    <CreatableSelect
      components={components}
      inputValue={inputValue}
      isClearable
      isMulti
      menuIsOpen={false}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      className="react-select-container"
      classNamePrefix="react-select"
      value={value}
      styles={customStyles}
    />
  );
};

export default CustomAsyncSelect;
