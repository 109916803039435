import React, { useState } from "react";

import Select from "react-select";
import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
  USER_PROOFREADER,
  USER_SUBCLIENT,
  USER_WRITER,
} from "../../common/consts";
import { editUsersRole } from "../../services/project-members";

const roles = [
  {
    value: USER_SUBCLIENT,
    label: "Admin",
  },
  {
    value: USER_WRITER,
    label: "Writer",
  },
  {
    value: USER_PROOFREADER,
    label: "Proofreader",
  },
];

const colourStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: "white",
    borderRadius: "10px",
    borderColor: state.isFocused ? "#dbdbdb" : "#EFEFEF",
    borderWidth: "2px",
    boxShadow: 0,
    color: "red",
    "&:hover": {
      borderColor: "#dbdbdb",
    },
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      fontWeight: 600,
      fontSize: "14px",
      borderRadius: "4px",

      "&:hover": {
        color: isSelected ? "#6C5DD3" : "#6C5DD3",
        background: "red",
      },

      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "white"
        : isFocused
        ? "white"
        : undefined,
      color: "#6F767E",
    };
  },
  menu: (styles) => ({
    ...styles,
    borderRadius: "10px",
    marginTop: 3,
    borderColor: "#EFEFEF",
    borderWidth: "2px",
    boxShadow: "0 0 0 2px #dbdbdb",
  }),
  input: (styles) => ({
    ...styles,
    width: "120px",
    innerHeight: "40px",
  }),
  placeholder: (styles) => ({ ...styles, color: "red" }),
  singleValue: (styles) => ({
    ...styles,
    color: "#6F767E",
    fontWeight: 600,
    fontSize: "14px",
  }),
};

const useButtonStyles = makeStyles(() =>
  createStyles({
    button: {
      marginTop: 20,
      borderRadius: "6px",
      textTransform: "none",
      backgroundColor: "#6870f6",
      width: "100%",
      minHeight: "20px",
      fontWeight: "bold",
      color: "white",
      "&:hover": {
        backgroundColor: "#7e86f7",
      },
    },
  })
);

const EditMemberRole = ({ defaultSelected, onRefetch, onClose }) => {
  const [memberWithNewRole, setMemberWithNewRole] = useState();
  const classes = useButtonStyles();

  const preselectedValue = roles.find(
    (role) => role.value === defaultSelected.role
  );

  const onChangeSelect = (role) => {
    const newRole = {
      ...defaultSelected,
      role: role.value,
    };

    setMemberWithNewRole(newRole);
  };

  const onSubmitSelected = async () => {
    console.log(memberWithNewRole);
    const payload = {
      id: memberWithNewRole.id,
      role: memberWithNewRole.role,
    };
    try {
      const result = await editUsersRole(payload);
      if (result) {
        onRefetch();
        onClose();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <Select
        defaultValue={[preselectedValue]}
        isMulti={false}
        name="members"
        options={roles}
        className="members-select-container"
        classNamePrefix="members-select"
        onChange={onChangeSelect}
        styles={colourStyles}
      />
      <Button
        aria-controls="simple-menu"
        onClick={onSubmitSelected}
        className={classes.button}
      >
        Submit
      </Button>
    </div>
  );
};

export default EditMemberRole;
