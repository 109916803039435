import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { validate } from "../../utils/validators";
import { VALIDATOR_EMAIL } from "../../utils/validators";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

import "./StepThreeRowInputs.css";
import FormErrorMessage from "../FormErrorMessage";
import { BlackTooltip } from "../UITooltips/UITooltip";
import {
  USER_PROOFREADER,
  USER_SUBCLIENT,
  USER_WRITER,
} from "../../common/consts";

const StepThreeRowInputs = ({
  onInputUserData,
  showDeleteUser,
  onRemoveRow,
  userId,
}) => {
  const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },
    button: {
      display: "block",
      marginTop: theme.spacing(2),
    },
    formControl: {
      minWidth: 120,
      display: "flex",
    },
    inputFont: {
      fontSize: "0.9rem",
    },
  }));
  const classes = useStyles();

  const [role, setRole] = useState("");
  const [user, setUser] = useState("");
  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleChange = (event) => {
    setRole(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const onInputChange = (event) => {
    setUser(event.target.value);
    const isValid = validate(event.target.value, [VALIDATOR_EMAIL()]);
    setIsError(!isValid);
  };

  useEffect(() => {
    if (user !== "" && role !== "") {
      const data = {
        email: user,
        role,
      };
      onInputUserData(data);
    }
  }, [role, user]);

  return (
    <div>
      <div className="step3-inputs-row">
        <div className="email-input-step3">
          <Input
            id="input-with-icon"
            placeholder="Add user email"
            onChange={onInputChange}
            required={role !== ""}
            startAdornment={
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            }
            className={classes.inputFont}
          />
          {isError && (
            <FormErrorMessage>Please enter a valid email.</FormErrorMessage>
          )}
        </div>
        <FormControl className={classes.formControl}>
          <InputLabel>Role</InputLabel>
          <Select
            labelId="role"
            id="role"
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={role}
            onChange={handleChange}
            className={classes.inputFont}
          >
            <MenuItem className={classes.inputFont} value={USER_SUBCLIENT}>
              Admin
            </MenuItem>
            <MenuItem className={classes.inputFont} value={USER_WRITER}>
              Writer
            </MenuItem>
            <MenuItem className={classes.inputFont} value={USER_PROOFREADER}>
              Proofreader
            </MenuItem>
          </Select>
        </FormControl>
        {showDeleteUser && (
          <div className="remove-users" onClick={() => onRemoveRow(userId)}>
            <BlackTooltip arrow placement="top" title="Remove this user">
              <RemoveCircleIcon style={{ color: "#ec5032" }} />
            </BlackTooltip>
          </div>
        )}
      </div>
    </div>
  );
};

export default StepThreeRowInputs;
