import React, { useState } from "react";
import { requestIndustries } from "../services/industries";
import RegisterComponent from "../Components/RegisterComponent";
import useMount from "../utils/useMount";

import "./Register.css";

const Register = () => {
  const [industries, setIndustries] = useState([]);
  useMount(() => {
    getIndustries();
  });

  const getIndustries = async () => {
    const industriesOptions = await requestIndustries();
    setIndustries(industriesOptions);
  };

  return (
    <div className="register-page">
      <RegisterComponent industriesOptions={industries} />
    </div>
  );
};

export default Register;
