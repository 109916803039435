import React, { useState } from "react";
import "./KeywordCluster.css";

const KeywordTag = ({ extraKeys }) => {
  return (
    <div className="extra-key-tag">
      <div className="key-desc">{extraKeys.key}</div>
      <div className="key-search">{`${extraKeys.searches}K SEARCH VOLUME`}</div>
    </div>
  );
};

const KeywordCluster = ({
  gridSize,
  rowData: {
    topic,
    keyword,
    totalKeywords,
    searchVol,
    trafficPerc,
    extraKeywords,
  },
}) => {
  const [show, setShow] = useState(false);
  const showKeywords = () => {
    setShow(!show);
  };
  // on click on the show keywords button, the extra keywords should be displayed in the table, as in the design
  return (
    <>
      <div
        className="topic-clusters"
        style={{ display: "grid", gridTemplateColumns: gridSize }}
      >
        <div className="clusters-keyword">{keyword}</div>
        <div className="clusters-keyword-number">
          <div className="cluster-values">
            <span>{totalKeywords}</span>
            <span className="cluster-desc">KEYWORDS</span>
          </div>
        </div>
        <div className="clusters-keyword-number">
          <div className="cluster-values">
            <span>{`${searchVol}K`}</span>
            <span className="cluster-desc">VOLUME</span>
          </div>
        </div>

        <div className="clusters-keyword-number">
          <div className="cluster-values">
            <span>{`${trafficPerc}%`}</span>
            <span className="cluster-desc">TRAFFIC SHARE</span>
          </div>
        </div>

        <div className="clusters-control">
          <button className="toggle-extra-keywords" onClick={showKeywords}>{`${
            show ? "Hide" : "Show"
          } Keywords`}</button>
        </div>
      </div>
      {show && (
        <div className="toggled-keywords">
          {extraKeywords.map((keys) => (
            <KeywordTag key={keys.key} extraKeys={keys} />
          ))}
        </div>
      )}
    </>
  );
};

export default KeywordCluster;
