import React, { useEffect, useState } from "react";
import { Slider } from "@material-ui/core";
import PlagiarismTag from "../Plagiarism/PlagiarismTag";

import "./RangeSlider.css";

const RangeSlider = ({ initialValue, onFilter, currentColumn, isClear }) => {
  const [rangeValue, setRangeValue] = useState(initialValue);

  useEffect(() => {
    if (isClear) {
      setRangeValue([10, 30]);
      const filter = {
        [currentColumn]: undefined,
      };
      onFilter(filter);
    }
  }, [isClear]);

  const handleChange = (_, newValue) => {
    setRangeValue(newValue);
    buildFilterOptions();
  };

  const buildFilterOptions = () => {
    const filter = {
      [currentColumn]: rangeValue,
    };
    onFilter(filter);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <div className="range-labels">
        <div className="label-wrapper">
          <p className="label-text">Min:</p>
          <p style={{fontSize:"bold"}}>{rangeValue[0]}</p>
        </div>
        <div className="label-wrapper">
          <p className="label-text">Max:</p>
          <p style={{fontSize:"bold"}}>{rangeValue[1]}</p>
        </div>
      </div>
      <div className="range-slider" style={{width: "100%"}}>
        <Slider
          getAriaLabel={() => "Similarity range"}
          value={rangeValue}
          onChange={handleChange}
          valueLabelDisplay="off"
          step={1}
          min={1}
          max={100}
        />
      </div>
    </div>
  );
};

export default RangeSlider;
