import React from "react";
import { BlackTooltip } from "../UITooltips/UITooltip";
import "./DynamicPill.css";

const DynamicPill = ({ data }) => {
  const noScore = data && data.score === null;
  const displayScoreTxt = (color) => {
    if (noScore) {
      return "#75819b";
    }
    const txtColor =
      data && data.score < 50
        ? color
        : data && data.score > 85
        ? "#7FBA7A"
        : "#F7D080";

    return txtColor;
  };

  return (
    <div className="dynamic-pill">
      <div className="value" style={{ color: displayScoreTxt("#EE7D57") }}>
        <BlackTooltip
          arrow
          placement="top"
          title="The article score provided by the content editor"
        >
          <div>{data && (noScore ? "N/A" : data.score)}</div>
        </BlackTooltip>
        {data && data.articleRating && (
          <BlackTooltip
            arrow
            placement="top"
            title="The rating of the article provided by the proofreader"
          >
            <div className="article-rating">{data.articleRating}</div>
          </BlackTooltip>
        )}
      </div>
    </div>
  );
};

export default DynamicPill;
