import { getSearchResults } from "../api/manage-search-results";
import { transformData } from "./topic-results-helpers";

export const fetchTopicResults = async (queryParams) => {
  console.log("fetching data with ", queryParams.queryKey[1]);

  const result = await getSearchResults();
  const data = transformData(result);

  return data;
};
