import React from "react";
import DashboardComponent from "../Components/ManagementDashboard/DashboardComponent";
import Header from "../Components/Header";
import SidebarLeft from "../Components/SidebarLeft";
import Scrollbars from "react-custom-scrollbars";

const Dashboard = () => {
  return (
    <div className="homepage">
      <SidebarLeft />
      <div className="homepage-wrapper">
        <Header />
        <div className="bottom-header"></div>
        <Scrollbars>
          <DashboardComponent />
        </Scrollbars>
      </div>
    </div>
  );
};

export default Dashboard;
