import { IN_TIME, DEADLINE_CLOSE, DEADLINE_PASSED } from "../common/consts";
import {
  differenceInHours,
  differenceInCalendarDays,
  differenceInCalendarISOWeekYears,
  getHours,
  getMinutes,
  getSeconds,
  getMonth,
  getYear,
  format,
  addDays,
} from "date-fns";

/* getting time from a date Object, used to display the last autosave information in editor  */
export const getTimeFromDate = (dateObject) => {
  const newDate = new Date(dateObject);
  const hours = String(getHours(newDate)).padStart(2, "0");
  const minutes = String(getMinutes(newDate)).padStart(2, "0");
  const seconds = String(getSeconds(newDate)).padStart(2, "0");

  const time = `${hours}:${minutes}:${seconds}`;

  return time;
};

export const getDeadlineInDays = (dateObj) => {
  const diffs = differenceInCalendarDays(new Date(dateObj), new Date());
  return diffs;
};

export const getDeadlineInYears = (dateObj) => {
  const diffs = differenceInCalendarISOWeekYears(new Date(dateObj), new Date());
  return diffs;
};

// compare dates with date-fns
export const compareDates = (deadline) => {
  const difference = differenceInHours(new Date(deadline), new Date());

  if (difference < 0) {
    return DEADLINE_PASSED;
  }

  if (difference <= 24) {
    return DEADLINE_CLOSE;
  }

  return IN_TIME;
};

/* format for Safari - __ -
we need to transform the date when receiving it from server because Safari doesn't work well with this format MM-dd-yyyy */
export const formatDateForStore = (date) => new Date(date.replace(/-/g, "/"));

/* transform the date back into shape accepted by server  */
export const formatDateForServer = (dateObject) =>
  format(new Date(dateObject), "MM-dd-yyyy");

export const addDaysToCurrentDate = (days) => {
  const futureDate = addDays(new Date(), days);

  return futureDate;
};

export const getFirstDayOfNextMonth = () => {
  const date = new Date();
  const nextDate = new Date(getYear(date), getMonth(date) + 1, 1);
  const formatted = format(nextDate, "MMM-dd-yyyy");

  return formatted;
};
