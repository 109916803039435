import React from "react";

import "./HeadingsList.css";

const HeadingsList = ({ title, headings }) => {
  return (
    <div className="headings-group">
      {headings && headings.length > 0 && (
        <div className="headings-list">
          <img
            src="/images/_headings-icon.svg"
            alt="headings"
            width="16px"
            height="16px"
          />
          <p className="headings-title">{title}</p>
        </div>
      )}
      {headings &&
        headings.length > 0 &&
        headings.map((name, index) => (
          <p className="heading-text" key={index}>{`${name}`}</p>
        ))}
    </div>
  );
};

export default HeadingsList;
