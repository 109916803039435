import React, { useCallback, useReducer } from "react";
import Input from "../../Shared/Input";
import Button from "../../Shared/Button";
import Select from "react-select";
import {
  DEADLINE,
  WRITER,
  PROJECT,
  INDUSTRY,
  TYPE_NATIVE,
  USER_WRITER,
} from "../../common/consts";

import { addDaysToCurrentDate } from "../../utils/dateUtility";

import { formReducer } from "../../utils/formReducer";
import { VALIDATOR_MINLENGTH } from "../../utils/validators";
import { createNewEntry } from "../../services/dashboard";

import "./CreateNewEntryForm.css";
import Spacer from "../Utils/Spacer";
import Scrollbars from "react-custom-scrollbars";
import DateDisplayWrapper from "../DateDisplayWrapper/DateDisplayWrapper";
import { setApiError } from "../../reducers/apiErrors";
import { useDispatch } from "react-redux";

const findDefaultValue = (data, currentId) => {
  if (currentId) {
    const current = data.find((element) => element.id === currentId);

    return {
      value: current.id,
      label: current.name,
    };
  } else {
    return null;
  }
};

const convertOptionsData = (array) => {
  const formatted =
    array &&
    array.map((option) => {
      return {
        value: option.id,
        label: option.name,
      };
    });

  return formatted;
};

const CreateNewNativeForm = ({
  writers,
  projects,
  industries,
  editRowEntry,
  onClose,
  onCreated,
  currentUserType,
}) => {
  const isEditMode = editRowEntry.length > 0;
  const currentEntry = isEditMode ? editRowEntry[0] : {};

  const restrictInputs = isEditMode && currentUserType === USER_WRITER;

  const initialValues = {
    topic: currentEntry.topic,
    writer: currentEntry.writer,
    project: currentEntry.project,
    industry: currentEntry.industry,
    deadline: currentEntry.deadline,
    info: currentEntry.info,
    anchor1: currentEntry.anchor1,
    anchor2: currentEntry.anchor2,
    publisherUrl: currentEntry.publisherUrl,
    url1: currentEntry.url1,
    url2: currentEntry.url2,
  };
  const stateDispatch = useDispatch();
  const [formState, dispatch] = useReducer(formReducer, {
    inputs: {
      topic: {
        value: "",
        isValid: false,
      },
      writer: {
        value: "",
        isValid: true,
      },
      project: {
        value: "",
        isValid: true,
      },
      industry: {
        value: "",
        isValid: true,
      },
      deadline: {
        value: addDaysToCurrentDate(2),
        isValid: true,
      },
      anchor1: {
        value: "",
        isValid: true,
      },
      anchor2: {
        value: "",
        isValid: true,
      },
      url1: {
        value: "",
        isValid: true,
      },
      url2: {
        value: "",
        isValid: true,
      },
      publisherUrl: {
        value: "",
        isValid: true,
      },
      info: {
        value: "",
        isValid: true,
      },
    },
    isValid: false,
  });

  const handleSelectChange = (id, values) => {
    dispatch({
      type: "INPUT_CHANGE",
      value: values.value || values,
      isValid: true,
      inputId: id,
    });
  };

  const inputHandler = useCallback((id, value, isValid) => {
    dispatch({
      type: "INPUT_CHANGE",
      value: value,
      isValid: isValid,
      inputId: id,
    });
  }, []);

  const saveFormInputs = async (event) => {
    event.preventDefault();

    const normalPayload = isEditMode
      ? { ...formState.inputs, id: currentEntry.id, type: TYPE_NATIVE }
      : { ...formState.inputs, type: TYPE_NATIVE };

    const writerPayload = {
      topic: formState.inputs.topic,
      id: currentEntry.id,
      type: TYPE_NATIVE,
      writer: { value: currentEntry.writer },
      project: { value: currentEntry.project },
      industry: { value: currentEntry.industry },
      deadline: { value: currentEntry.deadline },
      info: { value: currentEntry.info },
      anchor1: { value: currentEntry.anchor1 },
      anchor2: { value: currentEntry.anchor2 },
      publisherUrl: { value: currentEntry.publisherUrl },
      url1: { value: currentEntry.url1 },
      url2: { value: currentEntry.url2 },
    };

    const payload = restrictInputs ? writerPayload : normalPayload;
    try {
      const entry = await createNewEntry(payload, isEditMode);
      if (entry.id || (isEditMode && entry.includes(currentEntry.id))) {
        // callback to update entire table with the new article created
        onCreated();
        // close popup if we have an id from server response, or if the response
        // contains the id, in case of edit mode
        onClose();
        // we don't prefetch data for native type, so just close the popup
      }
    } catch (error) {
      stateDispatch(setApiError(error));
    }
  };

  return (
    <Scrollbars>
      <div className="form-wrapper">
        <form className={"new-entry-form"} onSubmit={saveFormInputs}>
          <Input
            id="topic"
            element="input"
            type="text"
            label="Topic"
            validators={[VALIDATOR_MINLENGTH(3)]}
            errorText="Please enter a topic longer than 3 characters."
            // noValidation
            onInput={inputHandler}
            initialValue={isEditMode ? initialValues.topic : ""}
          />
          {!restrictInputs && (
            <>
              <div className={"select-inputs"}>
                <div className={"left-column native"}>
                  <Select
                    options={writers ? convertOptionsData(writers) : []}
                    placeholder="Select writer"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    onChange={(values) => handleSelectChange(WRITER, values)}
                    defaultValue={
                      isEditMode
                        ? findDefaultValue(writers, initialValues.writer)
                        : null
                    }
                  />
                  <Select
                    options={industries ? convertOptionsData(industries) : []}
                    placeholder="Select industry"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    onChange={(values) => handleSelectChange(INDUSTRY, values)}
                    defaultValue={
                      isEditMode
                        ? findDefaultValue(industries, initialValues.industry)
                        : null
                    }
                  />
                  <Input
                    id="anchor1"
                    element="input"
                    type="text"
                    placeholder="Anchor 1"
                    validators={[]}
                    onInput={inputHandler}
                    initialValue={isEditMode ? initialValues.anchor1 : ""}
                    noValidation
                  />
                  <Spacer size={15} />
                  <Input
                    id="anchor2"
                    element="input"
                    type="text"
                    placeholder="Anchor 2"
                    validators={[]}
                    onInput={inputHandler}
                    initialValue={isEditMode ? initialValues.anchor2 : ""}
                    noValidation
                  />
                </div>
                <div className={"right-column native"}>
                  <div className={"date-wrapper"}>
                    <div className={"date-input-container"}>
                      <p className={"date-input-label"}>Set deadline</p>
                      <DateDisplayWrapper
                        handleDateChange={(date, property) =>
                          handleSelectChange(property, date)
                        }
                        savedDate={
                          isEditMode
                            ? initialValues.deadline
                            : addDaysToCurrentDate(2) // set deadline default for 2 days from currentDate
                        }
                        updateProperty={DEADLINE}
                        isCreateArticle={true}
                      />
                    </div>
                  </div>
                  <Select
                    options={projects ? convertOptionsData(projects) : []}
                    placeholder="Select project"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    onChange={(values) => handleSelectChange(PROJECT, values)}
                    defaultValue={
                      isEditMode
                        ? findDefaultValue(projects, initialValues.project)
                        : null
                    }
                  />
                  <Input
                    id="url1"
                    element="input"
                    type="text"
                    placeholder="URL 1"
                    validators={[]}
                    onInput={inputHandler}
                    initialValue={isEditMode ? initialValues.url1 : ""}
                    noValidation
                  />
                  <Spacer size={15} />
                  <Input
                    id="url2"
                    element="input"
                    type="text"
                    placeholder="URL 2"
                    validators={[]}
                    onInput={inputHandler}
                    initialValue={isEditMode ? initialValues.url2 : ""}
                    noValidation
                  />
                </div>
              </div>
              <Input
                id="publisherUrl"
                element="input"
                type="text"
                label="Publisher URL"
                validators={[]}
                onInput={inputHandler}
                initialValue={isEditMode ? initialValues.publisherUrl : ""}
                noValidation
              />
              <div className="additional-info-input">
                <Input
                  id="info"
                  type="text"
                  label="Article Brief"
                  rows={8}
                  validators={[]}
                  onInput={inputHandler}
                  initialValue={isEditMode ? initialValues.info : ""}
                  noValidation
                />
              </div>
            </>
          )}

          <Button
            type="submit"
            fullSize
            disabled={!formState.inputs.topic.isValid}
          >
            Submit
          </Button>
          {/* <Button type="submit" fullSize>
        Submit
      </Button> */}
        </form>
      </div>
    </Scrollbars>
  );
};

export default CreateNewNativeForm;
