import React from "react";

import Header from "../Header";
import SidebarLeft from "../SidebarLeft";
import Scrollbars from "react-custom-scrollbars";
import SearchResultsDasboardList from "./SearchResultsDasboardList";

const SearchResultsDashboard = () => {
  return (
    <div className="homepage">
      <SidebarLeft />
      <div className="homepage-wrapper">
        <Header />
        <div className="bottom-header"></div>
        <Scrollbars>
          <SearchResultsDasboardList />
        </Scrollbars>
      </div>
    </div>
  );
};

export default SearchResultsDashboard;
