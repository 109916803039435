import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { lowTopicsFlag, toggleTopics } from "../reducers/topicCoverage";
import CustomTooltip from "./CustomTooltip";

import "./KeywordsToggleSwitch.css";

const CustomToggle = () => {
  const [selected, setSelected] = useState(useSelector(lowTopicsFlag));
  const dispatch = useDispatch();

  const switchToggle = (e) => {
    dispatch(toggleTopics(e.target.checked));
    setSelected(e.target.checked);
  };

  return (
    <div className="keywords-toggle">
      <div className="high-relevance">
        <p className={`switch-label ${!selected && "switch-label-active"}`}>
          High Relevance
        </p>
        <CustomTooltip
          posTop="-140px"
          posLeft="-105px"
          text="These keywords are very important to the selected topic. We strongly recommend using these keywords in the article. They are also part of the Topic Coverage score at the top of the radar chart."
        />
      </div>
      <label className="toggle">
        <input type="checkbox" checked={selected} onChange={switchToggle} />
        <span className="slider round"></span>
      </label>
      <div className="low-relevance">
        <p className={`switch-label ${selected && "switch-label-active"}`}>
          Low Relevance
        </p>
        <CustomTooltip
          posTop="-154px"
          posLeft="-198px"
          text="We have identified these keywords as being associated with the selected topic and they might add context to your article. Feel free to use them but they are not mandatory and they are not being used in the Topic Coverage score."
        />
      </div>
    </div>
  );
};

export default CustomToggle;
