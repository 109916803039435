import axios from "axios";
import { BASE_URL } from "../common/consts";

const mockedResults = "/response.json";

// search results dummy response
export const getSearchResults = (token) =>
  axios
    .get(`${mockedResults}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));
