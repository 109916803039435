import axios from "axios";
import { BASE_URL } from "../common/consts";

const baseEndpoint = "api/projects";

const createBrief = "post-brief";
const getBrief = "get-brief";
const projectsDashboard = "list";
const newProject = "new";
const deleteProject = "delete";

export const createProjectBrief = (payload, token) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${createBrief}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

export const getProjectBrief = (id, token) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${getBrief}`, id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

export const getDashboardProjects = (token, payload) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${projectsDashboard}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

export const onAddNewProject = (token, payload) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${newProject}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

export const onDeleteProject = (token, payload) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${deleteProject}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));
