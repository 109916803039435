import React from "react";

import "./CustomMessage.css";

const CustomMessage = ({ text, posTop, posLeft, icon, hideIcon }) => {

  return (
    <div className="custom-message">
      {text && (
        <>
          <div className="message-box" style={{ top: posTop, left: posLeft }}>
            <p className="message-text">{text}</p>
          </div>
          <span className="message-arrow"></span>
        </>
      )}
    </div>
  );
};

export default CustomMessage;
