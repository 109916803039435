import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import "./TopicCoverageHeadSkeleton.css";

const TopicCoverageHeadSkeleton = ({ isArticle }) => {
  return (
    <div className="topic-head-skeleton">
      {!isArticle ? (
        <SkeletonTheme color="#f4f4f3" highlightColor="#f4f4f5">
          <div className="topic-category-head-top">
            <Skeleton width={150} height={20} />
            <Skeleton width={140} height={18} />
          </div>
        </SkeletonTheme>
      ) : (
        <SkeletonTheme color="#f4f4f3" highlightColor="#f4f4f5">
          <div className="topic-article-head-top">
            <Skeleton width={150} height={20} />
            <div className="topic-article-text">
              <Skeleton width={`100%`} height={5} />
              <Skeleton width={`100%`} height={5} />
              <Skeleton width={`100%`} height={5} />
              <Skeleton width={`60%`} height={5} />
            </div>
            <div className="topic-article-switch-area">
              <Skeleton width={150} height={20} />
              <Skeleton width={50} height={20} />
              <Skeleton width={150} height={20} />
            </div>
          </div>
        </SkeletonTheme>
      )}
    </div>
  );
};

export default TopicCoverageHeadSkeleton;
