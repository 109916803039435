import { Tooltip } from "@material-ui/core";
import React from "react";

import "./PlagiarismTag.css";

const PlagiarismTag = ({ value, onOpen }) => {
  const bgColor = value > 30 ? "#f0e3e0" : "#e7f1e3";
  const textColor = value > 30 ? "#a97060" : "#7da46c";

  return (
    <>
      {onOpen ? (
        <Tooltip
          arrow
          placement="top"
          title={"Last plagiarism check score. Click for new analysis."}
        >
          <div
            onClick={onOpen}
            className="plagirism-tag"
            style={{ background: bgColor, color: textColor }}
          >
            {value ? `${value}%` : `0%`}
          </div>
        </Tooltip>
      ) : (
        <div
          className="plagirism-tag"
          style={{ background: bgColor, color: textColor }}
        >
          {value ? `${value}%` : `0%`}
        </div>
      )}
    </>
  );
};

export default PlagiarismTag;
