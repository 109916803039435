import React from "react";
import Button from "../../Shared/Button";

import "./SingleArticleDelete.css";

const SingleArticleDelete = ({
  currentRows,
  onClose,
  onConfirm,
  bulkAction,
}) => {
  const confirmHandler = () => {
    onConfirm(currentRows);
    onClose();
  };

  return (
    <>
      {bulkAction && (
        <p className="confirm-all-titles">
          Are you sure you want to delete all articles?
        </p>
      )}
      {/* display article name only if it's not bulk action, otherwise we will 
      get a very long list in the popup */}
      {!bulkAction && currentRows.length > 0 && (
        <p className="delete-article-name">{currentRows[0].topic}</p>
      )}
      <div className="delete-confirmation-controls">
        <Button type="button" passive onClick={confirmHandler}>
          Yes
        </Button>
        <Button type="button" passive onClick={onClose}>
          No
        </Button>
      </div>
    </>
  );
};

export default SingleArticleDelete;
