import React from "react";
import HeadingsList from "./HeadingsList";
import CustomTooltip from "./CustomTooltip";

import "./HeadingsSummary.css";

const HeadingsSummary = ({ data: { h2, h3 } }) => {
  return (
    <div className="headings-summary">
      <div className="heading-summary-title">
        <img src="/images/_heading-summary-icon.svg" alt="headings" />
        <p className="title-text">Competitors' Headings Summary</p>
        <CustomTooltip
          posTop="-155px"
          posLeft="-106px"
          text="We have compiled a list of relevant H2 and H3 tags from top 10 ranking competitors that can be used as guidance for your article structure. You can individually analyze each competitor's article structure above."
        />
      </div>
      <div className="summary-list">
        <HeadingsList title="HEADINGS 2" headings={h2} />
        <HeadingsList title="HEADINGS 3" headings={h3} />
      </div>
    </div>
  );
};

export default HeadingsSummary;
