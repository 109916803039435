import React, { useCallback, useReducer, useState } from "react";
import Input from "../../Shared/Input";
import Button from "../../Shared/Button";
import Select, { components } from "react-select";
import {
  DEADLINE,
  WRITER,
  PROJECT,
  TYPE_DESCRIPTION,
} from "../../common/consts";

import { addDaysToCurrentDate } from "../../utils/dateUtility";

import { formReducer } from "../../utils/formReducer";
import { VALIDATOR_MINLENGTH } from "../../utils/validators";
import { createNewEntry, prefetchData } from "../../services/dashboard";

import "./CreateNewDescriptionForm.css";
import Scrollbars from "react-custom-scrollbars";
import DateDisplayWrapper from "../DateDisplayWrapper/DateDisplayWrapper";
import { setApiError } from "../../reducers/apiErrors";
import { useDispatch, useSelector } from "react-redux";
import { flagSelector, countries } from "../SearchTopicComponent";
import { getFeatureFlags } from "../../reducers/featureFlags";

const findDefaultValue = (data, currentId) => {
  if (currentId) {
    const current = data.find((element) => element.id === currentId);

    return {
      value: current.id,
      label: current.name,
    };
  } else {
    return null;
  }
};

const convertOptionsData = (array) => {
  const formatted =
    array &&
    array.map((option) => {
      return {
        value: option.id,
        label: option.name,
      };
    });

  return formatted;
};

const CreateNewDescriptionForm = ({
  writers,
  projects,
  editRowEntry,
  onClose,
  onCreated,
}) => {
  const isEditMode = editRowEntry.length > 0;
  const currentEntry = isEditMode ? editRowEntry[0] : {};

  const stateDispatch = useDispatch();

  const initialValues = {
    topic: currentEntry.topic,
    writer: currentEntry.writer,
    project: currentEntry.project,
    industry: currentEntry.industry,
    deadline: currentEntry.deadline,
    brief: currentEntry.briefUrl,
    info: currentEntry.info,
    topicUrl: currentEntry.topicUrl,
  };
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const { showTopicLanguages } = useSelector(getFeatureFlags);
  const [formState, dispatch] = useReducer(formReducer, {
    inputs: {
      topicUrl: {
        value: "",
        isValid: false,
      },
      topic: {
        value: "",
        isValid: false,
      },
      deadline: {
        value: addDaysToCurrentDate(2),
        isValid: true,
      },
      writer: {
        value: "",
        isValid: true,
      },
      project: {
        value: "",
        isValid: true,
      },
      // brief: {
      //   value: "",
      //   isValid: true,
      // },
      info: {
        value: "",
        isValid: true,
      },
    },
    isValid: false,
  });

  const handleSelectChange = (id, values) => {
    dispatch({
      type: "INPUT_CHANGE",
      value: values.value || values,
      isValid: true,
      inputId: id,
    });
  };

  const inputHandler = useCallback((id, value, isValid) => {
    dispatch({
      type: "INPUT_CHANGE",
      value: value,
      isValid: isValid,
      inputId: id,
    });
  }, []);

  const saveFormInputs = async (event) => {
    event.preventDefault();

    const payload = isEditMode
      ? { ...formState.inputs, id: currentEntry.id }
      : { ...formState.inputs, type: 1 };

    try {
      const entry = await createNewEntry(payload, isEditMode);

      if (entry.id || (isEditMode && entry.includes(currentEntry.id))) {
        // callback to update entire table with the new article created
        onCreated();
        // close popup if we have an id from server response, or if the response
        // contains the id, in case of edit mode
        onClose();
        // prefetch data for the created topic

        await prefetchData(
          formState.inputs.topic.value,
          selectedCountry.value,
          TYPE_DESCRIPTION
        );
      }
    } catch (error) {
      stateDispatch(setApiError(error));
    }
  };

  const shouldDisableSubmit = () => {
    const isTopicValid =
      formState.inputs.topic !== "" && formState.inputs.topic.isValid;

    const isUrlValid =
      formState.inputs.topicUrl !== "" && formState.inputs.topicUrl.isValid;
    const isButtonDisabled = !isTopicValid && !isUrlValid;

    return isButtonDisabled;
  };

  const Option = (props) => (
    <components.Option {...props} className="country-option">
      <div className="country-option-select">
        <span className="country-option-icon">{props.data.icon}</span>
        <span>{props.data.label}</span>
      </div>
    </components.Option>
  );

  const handleChange = (value) => {
    setSelectedCountry(value);
  };

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      {selectedCountry.icon}
    </components.SingleValue>
  );
  return (
    <Scrollbars>
      <div className="form-wrapper">
        <form className={"new-description-form"} onSubmit={saveFormInputs}>
          <div className="topic-and-deadline">
            <Input
              id="topic"
              element="input"
              type="text"
              label="Topic"
              validators={[VALIDATOR_MINLENGTH(3)]}
              errorText="Please enter a topic longer than 3 characters."
              onInput={inputHandler}
              initialValue={isEditMode ? initialValues.topic : ""}
              // noValidation={formState.inputs.topicUrl.isValid}
            />
            {showTopicLanguages && (
              <div style={{ position: "absolute", top: 23, right: 120 }}>
                <Select
                  value={selectedCountry}
                  options={countries}
                  onChange={handleChange}
                  components={{
                    Option,
                    SingleValue,
                  }}
                  styles={flagSelector}
                />
              </div>
            )}

            <div className={"desc-date-wrapper"}>
              <div className={"desc-date-input-container"}>
                <p className={"desc-date-input-label"}>Set deadline</p>
                <DateDisplayWrapper
                  handleDateChange={(date, property) =>
                    handleSelectChange(property, date)
                  }
                  savedDate={
                    isEditMode
                      ? initialValues.deadline
                      : addDaysToCurrentDate(2)
                  }
                  updateProperty={DEADLINE}
                  isCreateArticle={true}
                />
              </div>
            </div>
          </div>
          <Input
            id="topicUrl"
            element="input"
            type="text"
            label="Category URL"
            validators={[VALIDATOR_MINLENGTH(5)]}
            errorText="Please enter a topic url."
            onInput={inputHandler}
            initialValue={isEditMode ? initialValues.topicUrl : ""}
            noValidation
          />

          <div className={"select-inputs"}>
            <div className={"left-column"}>
              <Select
                options={writers ? convertOptionsData(writers) : []}
                placeholder="Select writer"
                className="react-select-container"
                classNamePrefix="react-select"
                onChange={(values) => handleSelectChange(WRITER, values)}
                defaultValue={
                  isEditMode
                    ? findDefaultValue(writers, initialValues.writer)
                    : null
                }
              />
            </div>
            <div className={"right-column"}>
              <Select
                options={projects ? convertOptionsData(projects) : []}
                placeholder="Select project"
                className="react-select-container"
                classNamePrefix="react-select"
                onChange={(values) => handleSelectChange(PROJECT, values)}
                defaultValue={
                  isEditMode
                    ? findDefaultValue(projects, initialValues.project)
                    : null
                }
              />
            </div>
          </div>
          {/* <Input
        id="brief"
        element="input"
        type="text"
        label="Brief URL"
        validators={[]}
        onInput={inputHandler}
        initialValue={isEditMode ? initialValues.brief : ""}
        noValidation
      /> */}
          <div className="additional-info-input">
            <Input
              id="info"
              type="text"
              label="Article Brief"
              rows={6}
              validators={[]}
              onInput={inputHandler}
              initialValue={isEditMode ? initialValues.info : ""}
              noValidation
            />
          </div>

          <Button type="submit" fullSize disabled={shouldDisableSubmit()}>
            Submit
          </Button>
        </form>
      </div>
    </Scrollbars>
  );
};

export default CreateNewDescriptionForm;
