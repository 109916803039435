import React from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";

import Select from "react-select";
import {
  PerformanceDownIcon,
  PerformanceUpIcon,
} from "../../UI_utils/SVGIcons";
import TextWithInfoIcon from "../PerformanceStatsSmallCard/TextWithInfoIcon";
import "./PerformanceStatsBigCard.css";

const dataOptions = [
  { value: 7, label: "Last 7 Days" },
  { value: 28, label: "Last 28 Days" },
];

const colourStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: "white",
    borderRadius: "10px",
    borderColor: state.isFocused ? "#dbdbdb" : "#EFEFEF",
    borderWidth: "2px",
    boxShadow: 0,
    color: "red",
    "&:hover": {
      borderColor: "#dbdbdb",
    },
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      fontWeight: 600,
      fontSize: "12px",
      borderRadius: "4px",

      "&:hover": {
        color: isSelected ? "#6C5DD3" : "#6C5DD3",
      },

      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "white"
        : isFocused
        ? "white"
        : undefined,
      color: "#6F767E",
      // color: isDisabled
      //   ? "#ccc"
      //   : isSelected
      //   ? "#6F767E"
      //     ? "#6F767E"
      //     : "#6F767E"
      //   : "#6F767E",
    };
  },
  menu: (styles) => ({
    ...styles,
    borderRadius: "10px",
    marginTop: 3,
    borderColor: "#EFEFEF",
    borderWidth: "2px",
    boxShadow: "0 0 0 2px #dbdbdb",
  }),
  input: (styles) => ({
    ...styles,
    width: "120px",
    innerHeight: "40px",
  }),
  placeholder: (styles) => ({ ...styles, color: "red" }),
  singleValue: (styles) => ({
    ...styles,
    color: "#6F767E",
    fontWeight: 600,
    fontSize: "12px",
  }),
};

const PerformanceStatsBigCard = ({
  title,
  health,
  opportunity,
  publishedArticles,
  onSelectDateRange,
}) => {
  const onChangeInterval = (value) => {
    onSelectDateRange(value);
  };

  console.log({ health });
  return (
    <div className="performance-stats-big">
      <div className="stats-big-top">
        <div>
          <div className="big-stats-title">Content Performance</div>
          <div className="big-stats-project">{title}</div>
        </div>
        <div className="select-period">
          <Select
            defaultValue={dataOptions[1]}
            options={dataOptions}
            styles={colourStyles}
            onChange={onChangeInterval}
          />
        </div>
      </div>
      <div className="stats-split-3">
        <div className="content-illustration">
          <img
            src="/images/illustration-scope.png"
            width="360px"
            height="auto"
            alt="Stats"
          />
        </div>
        <div className="opportunity-stats">
          <div className="opportunity-block">
            <div className="opportunity-value">
              {new Intl.NumberFormat("en-IN").format(publishedArticles)}
            </div>
            <div className="info-text-wrapper">
              <TextWithInfoIcon
                title="Published articles"
                tooltipText="The number of articles written for this client that have been published and matched with a live URL in Contero"
              />
            </div>

            <div className="opportunity-value">
              {new Intl.NumberFormat("en-IN").format(opportunity)}
            </div>
            <div className="info-text-wrapper">
              <TextWithInfoIcon
                title="Total traffic opportunity"
                tooltipText="This is an estimate of how much traffic you can get based on the search volume of the keywords targeted by the published articles"
              />
            </div>
          </div>
        </div>
        <div className="health-wrapper">
          <div className="health-stats">
            <CircularProgressbarWithChildren
              value={health && health.new}
              strokeWidth="12"
              styles={buildStyles({
                rotation: 0,
                strokeLinecap: "rect",
                pathTransitionDuration: 0.5,
                pathColor: "#7fba7a",
                trailColor: "#f1f1f1",
              })}
            >
              <div className="health-stats-value">
                {health ? `${health.new}%` : "N/A"}
                <div className="health-stats-info">overall health</div>
              </div>
            </CircularProgressbarWithChildren>
          </div>
          <div className="health-comparison">
            <div className="chart-bottom-info">
              {health && !health.ascending && (
                <div className="perf-icon-down-wrapper">
                  <PerformanceDownIcon />
                </div>
              )}
              {health && health.ascending && (
                <div className="perf-icon-up-wrapper">
                  <PerformanceUpIcon />
                </div>
              )}
              <div className="perc-value">{health && health.value}</div>
              <TextWithInfoIcon
                title={health && health.desc}
                tooltipText="How the current health compares to the previous timeframe"
                fontSize={14}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerformanceStatsBigCard;
