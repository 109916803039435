import React from "react";

import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import { SELECT_ALL_ID } from "./CustomColumnFilter";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CustomMultiSelect = ({
  selectedData,
  columnData,
  currentColumn,
  onSelectAll,
  onToggleOption,
  onClearOptions,

  defaultValues,
}) => {
  const updateSelectedCheckboxes = (event, selectedOptions, reason) => {
    const allInOptions = selectedOptions.some(
      (val) => val.id === SELECT_ALL_ID
    );
    const allInState =
      selectedData && selectedData.some((val) => val.id === SELECT_ALL_ID);

    if (reason === "select-option" || reason === "remove-option") {
      if (!allInOptions && !allInState) {
        onToggleOption && onToggleOption(selectedOptions);
      } else if (allInOptions && allInState) {
        onToggleOption && onToggleOption(selectedOptions);
      } else if (allInState) {
        onSelectAll(false);
      } else if (allInOptions) {
        onSelectAll(true);
      }
    } else if (reason === "clear") {
      onClearOptions && onClearOptions();
    }
  };

  return (
    <Autocomplete
      multiple
      id="checkboxes-tags"
      limitTags={3}
      options={columnData}
      disableCloseOnSelect={true}
      getOptionLabel={(option) => option.name}
      value={selectedData}
      onChange={updateSelectedCheckboxes}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            key={option.id}
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8, color: "#6870F6" }}
            checked={selected}
          />
          {option.name}
        </React.Fragment>
      )}
      style={{ width: 500 }}
      renderInput={(params) => {
        const { InputProps, ...restParams } = params;
        const { startAdornment, ...restInputProps } = InputProps;
        return (
          <TextField
            {...restParams}
            // label="Filter"
            placeholder={`Filter ${currentColumn}`}
            InputProps={{
              ...restInputProps,
              startAdornment: (
                <div
                  style={{
                    maxHeight: "60px",
                    overflowY: "auto",
                    display: "flex",
                    maxWidth: "290px",
                    flexWrap: "wrap",
                  }}
                >
                  {startAdornment}
                </div>
              ),
            }}
          />
        );
      }}
    />
  );
};

export default CustomMultiSelect;
