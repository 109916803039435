import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { compareDates } from "../utils/dateUtility";
import "react-datepicker/dist/react-datepicker.css";

import "./CustomDatePicker.css";

const CustomDatePicker = ({
  savedDate,
  isDeadline,
  handleDateChange,
  isPopUp,
  onClose,
  readOnlyDisplay,
  currentRows,
  updateProperty,
  isCreateArticle,
  inline,
}) => {
  const [startDate, setStartDate] = useState(
    new Date(savedDate) // MM-DD-YYYY
  );
  const [deadlineStatus, setDeadlineStatus] = useState();

  useEffect(() => {
    if (isDeadline) {
      const status = compareDates(startDate);
      setDeadlineStatus(status);
    }
  }, [isDeadline, startDate]);

  const setChosenDate = (currentChosenDate) => {
    // don't update date here, will be updated from parent local state
    const updatedRow = isCreateArticle
      ? currentChosenDate
      : currentRows.map((row) => ({
          ...row,
          [updateProperty]: currentChosenDate,
        }));
    if (isCreateArticle) {
      setStartDate(currentChosenDate);
    }

    handleDateChange(updatedRow, updateProperty);
    if (isPopUp) {
      onClose();
    }
  };

  return (
    <div
      className={`${
        isDeadline ? `date-picker-${deadlineStatus}` : "date-picker-created"
      }`}
    >
      <DatePicker
        closeOnScroll={true}
        selected={startDate}
        onChange={(date) => setChosenDate(date)}
        dateFormat="dd/MM/yyyy"
        popperPlacement={"auto"}
        inline={isPopUp || inline}
        readOnly={readOnlyDisplay}
        calendarClassName="rasta-stripes"
        minDate={isDeadline && new Date()}
        openToDate={new Date()}
      />
    </div>
  );
};

export default CustomDatePicker;
