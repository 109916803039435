import { createSlice } from "@reduxjs/toolkit";

export const apiErrors = createSlice({
  name: "apierrors",
  initialState: {
    apiError: null,
    apiErrorInfo: null,
  },
  reducers: {
    setApiError: (state, { payload }) => {
      return {
        ...state,
        apiError: payload,
      };
    },

    setApiErrorMessage: (state, { payload }) => {
      return {
        ...state,
        apiErrorInfo: payload,
      };
    },
  },
});

// actions
export const { setApiError } = apiErrors.actions;
export const { setApiErrorMessage } = apiErrors.actions;

// selectors
export const isApiError = ({ apiErrors: { apiError } }) => apiError;
export const apiErrorMessage = ({ apiErrors: { apiErrorInfo } }) =>
  apiErrorInfo;

export default apiErrors.reducer;
