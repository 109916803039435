import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import "./ProjectsBriefDashboard.css";

import { getDefaultValue } from "../dashboardComponentHelpers";

import DynamicOptionsSelect from "../DynamicOptionsSelect";
import { INDUSTRY } from "../../common/consts";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Fade from "@material-ui/core/Fade";

import BriefCompletionBar from "./BriefCompletionBar";

const ProjectBriefCard = ({
  data,
  onUpdateSelected,
  industries,
  currentUserType,
  handleOptionChange,
  onOpenProject,
  onDeleteProject,
  onManageMembers,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const updateSelectedCard = (event) => {
    onUpdateSelected(data.id, event.target.checked);
  };

  const editSelected = () => {
    onOpenProject(data.id, data.complete);
  };

  return (
    <>
      <div className="projectBriefCard">
        <div className="contentCheck">
          <Checkbox
            checked={!!data.selected}
            onChange={updateSelectedCard}
            color="default"
          />
        </div>
        <div className="contentProject" onClick={() => onOpenProject(data.id)}>
          {data.name}
        </div>

        <div className="contentIndustry1">
          {industries && (
            <DynamicOptionsSelect
              isReadOnly={true}
              isPopup={false}
              rows={[data]}
              updateProperty={INDUSTRY}
              action="industry"
              data={industries}
              currentUserType={currentUserType}
              defaultSelected={getDefaultValue(
                data.primaryIndustry,
                industries
              )}
              onSelectChange={handleOptionChange}
            />
          )}
        </div>
        <div className="contentIndustry2">
          <div className="team-pill" onClick={() => onManageMembers(data.id)}>
            {data.team}
          </div>
        </div>
        <div className="contentCompletion">
          <BriefCompletionBar completion={data.complete} />
        </div>
        <div className="contentAction">
          <Button onClick={handleClick}>
            <MoreHorizIcon onClick={handleClick} />
          </Button>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem onClick={editSelected}>Edit</MenuItem>
            <MenuItem onClick={() => onManageMembers(data.id)}>
              Manage members
            </MenuItem>
            <MenuItem onClick={() => onDeleteProject(data.id)}>Delete</MenuItem>
          </Menu>
        </div>
      </div>
    </>
  );
};

export default ProjectBriefCard;
