import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import NativeContent from "../Components/NativeContent";

const Native = () => {
  const [isUserAuth] = useState(!!localStorage.getItem("userToken"));

  return isUserAuth ? <NativeContent /> : <Redirect to="/login" />;
};

export default Native;
