import {
  getDashPerformanceList,
  sendGoogleCode,
  getProjectsDashPerformanceList,
  impressionsStats,
  visitsStats,
  opportunityStats,
  healthStats,
  allPublished,
} from "../api/performance";
import { getUserToken } from "../utils/userStatus";

export const sendGoogleAuthToken = async (googleCode) => {
  const token = getUserToken();

  try {
    const result = await sendGoogleCode(token, googleCode);
    return result;
  } catch ({ data }) {
    return {
      ...data,
      error: true,
    };
  }
};

export const getPerformanceList = async (
  page,
  rowsPerPage,
  period,
  projectId
) => {
  const token = getUserToken();
  const payload = {
    page,
    limit: rowsPerPage,
    project: Number(projectId),
    search: null,
    filters: null,
    period,
    sort: null,
  };

  const data = await getDashPerformanceList(token, payload);

  const updatedList =
    data && data.rows.map((item) => ({ ...item, selected: false }));
  const result = {
    rows: updatedList,
    total: data.total,
  };

  return result;
};

export const getProjectsPerformanceList = async (page, rowsPerPage, period) => {
  const token = getUserToken();
  const payload = {
    page,
    limit: rowsPerPage,
    search: null,
    filters: null,
    period,
    sort: null,
  };

  const data = await getProjectsDashPerformanceList(token, payload);

  const updatedList =
    data && data.rows.map((item) => ({ ...item, selected: false }));

  const result = {
    rows: updatedList,
    total: data.total,
  };

  return result;
};

export const getAllImpresions = async (id, period) => {
  const token = getUserToken();
  const payload = {
    project: id,
    period,
  };

  const data = await impressionsStats(token, payload);

  const trending = ((data.new - data.old) / data.old) * 100;
  const result = {
    ...data,
    value: `${trending.toFixed(2)}%`,
    ascending: trending > 0,
    desc: `than last ${period === "w" ? "week" : "month"}`,
  };

  return result;
};

export const getAllVisits = async (id, period) => {
  const token = getUserToken();
  const payload = {
    project: id,
    period,
  };

  const data = await visitsStats(token, payload);

  const trending = ((data.new - data.old) / data.old) * 100;

  const result = {
    ...data,
    value: `${trending.toFixed(2)}%`,
    ascending: trending > 0,
    desc: `than last ${period === "w" ? "week" : "month"}`,
  };

  return result;
};

export const getAllOpportunity = async (id, period) => {
  const token = getUserToken();
  const payload = {
    project: id,
    period,
  };

  const data = await opportunityStats(token, payload);

  return data;
};

export const getAllHealth = async (id, period) => {
  const token = getUserToken();
  const payload = {
    project: id,
    period,
  };

  const data = await healthStats(token, payload);
  const trending = ((data.new - data.old) / data.old) * 100;

  const result = {
    ...data,
    value: `${trending.toFixed(2)}%`,
    ascending: trending > 0,
    desc: `than last ${period === "w" ? "week" : "month"}`,
  };

  return result;
};

export const getAllPublished = async (id) => {
  const token = getUserToken();
  const payload = {
    project: id,
  };

  const data = await allPublished(token, payload);

  return data;
};
