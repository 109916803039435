import React from "react";
import { SmallInfoIcon } from "../../UI_utils/SVGIcons";
import { BlackTooltip } from "../UITooltips/UITooltip";

import "./TextWithInfoIcon.css";

const TextWithInfoIcon = ({
  title,
  tooltipText,
  fontSize,
  font,
  color,
  weight,
}) => {
  return (
    <div className="parent-wrapper">
      <div
        className="text-title"
        style={{
          fontSize: `${fontSize}px`,
          color,
          fontWeight: weight,
          fontFamily: font,
        }}
      >
        {title}
      </div>
      <BlackTooltip arrow placement="top" title={tooltipText}>
        <div className="info-icon">
          <SmallInfoIcon />
        </div>
      </BlackTooltip>
    </div>
  );
};

export default TextWithInfoIcon;
