import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
  getMemberUsers,
  getMemberUsersAssigned,
  requestAssignedMembers,
} from "../../services/project-members";

import "./ManageProjectMembers.css";

const useButtonStyles = makeStyles(() =>
  createStyles({
    button: {
      marginTop: 20,
      borderRadius: "6px",
      textTransform: "none",
      backgroundColor: "#6870f6",
      width: "100%",
      minHeight: "20px",
      fontWeight: "bold",
      color: "white",
      "&:hover": {
        backgroundColor: "#7e86f7",
      },
    },
  })
);

const ManageProjectMembers = ({ projectId, onUsersAssigned }) => {
  const [membersList, setMembersList] = useState(null);
  const [assignedMembers, setAssignedMembers] = useState(null);
  const [selectedMembers, setSelectedMembers] = useState(null);

  const classes = useButtonStyles();

  useEffect(() => {
    getAllMembers(projectId);
  }, []);

  const getAllMembers = async (id) => {
    const members = await getMemberUsers();
    const assigned = await getMemberUsersAssigned(id);

    if (!members.error && !assigned.error) {
      // put the assigned members in `selectedMembers` state by default
      setSelectedMembers(assigned);
      const allMembers = [...members, ...assigned];
      const unique = allMembers.reduce(
        (acc, current) =>
          !acc.some((item) => item.id === current.id) ? [...acc, current] : acc,
        []
      );

      setMembersList(unique);
    }
    setAssignedMembers(assigned || []);
  };

  const onChangeSelect = (members) => {
    setSelectedMembers(members);
    const unique = membersList.reduce(
      (acc, current) =>
        !acc.some((item) => item.id === current.id) ? [...acc, current] : acc,
      []
    );

    setMembersList(unique);
  };

  const onSubmitSelected = async () => {
    const payload = {
      ids: [projectId],
      team: selectedMembers ? selectedMembers.map((member) => member.id) : [],
    };

    const assigned = await requestAssignedMembers(payload);
    if (assigned) {
      onUsersAssigned();
    }
  };

  if (!assignedMembers) return null;

  return (
    <div>
      <Select
        defaultValue={assignedMembers}
        isMulti
        name="members"
        options={membersList}
        className="members-select-container"
        classNamePrefix="members-select"
        onChange={onChangeSelect}
      />
      <Button
        aria-controls="simple-menu"
        onClick={onSubmitSelected}
        className={classes.button}
      >
        Submit
      </Button>
    </div>
  );
};

export default ManageProjectMembers;
