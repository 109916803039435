import { useDispatch } from "react-redux";
import { loginRequest, registerRequest } from "../api/user";
import { setApiError, setApiErrorMessage } from "../reducers/apiErrors";
import { multistepRegisterReq } from "../services/user";

const transformForPost = (data) => {
  const {
    firstname: { value: firstname },
    lastname: { value: lastname },
    email: { value: email },
    password: { value: password },
    registrationCode: { value: registrationCode },
  } = data;

  const user = {
    firstname,
    lastname,
    email,
    password,
    registrationCode,
  };

  return user;
};

const useUserHook = () => {
  const dispatch = useDispatch();
  const loginRequestHook = async (values) => {
    const {
      email: { value: email },
      password: { value: password },
    } = values;

    const user = {
      username: email,
      password,
    };

    try {
      const userToken = await loginRequest(user);

      return userToken;
    } catch (error) {
      dispatch(setApiError(true));
      dispatch(setApiErrorMessage(error.data.message));
    }
  };

  const registerRequestHook = async (values) => {
    const user = transformForPost(values);
    try {
      const userStatus = await registerRequest(user);
      return userStatus;
    } catch (error) {
      dispatch(setApiError(true));
      dispatch(setApiErrorMessage(error.data.message));
    }
  };

  const registerMultistepRequestHook = async (values) => {
    try {
      const userStatus = await multistepRegisterReq(values);
      return userStatus;
    } catch (error) {
      dispatch(setApiError(true));
      dispatch(setApiErrorMessage(error.data.message));
    }
  };
  return {
    loginRequestHook,
    registerRequestHook,
    registerMultistepRequestHook,
  };
};

export default useUserHook;
