import {
  assignProjectMembers,
  editMemberRole,
  getAllUsers,
  getAssignedUsers,
  getMembersList,
  inviteUsersToProjects,
  removeUser,
} from "../api/project-members";
import { getUserToken } from "../utils/userStatus";

export const getMemberUsers = async () => {
  const token = getUserToken();

  try {
    const result = await getAllUsers(token);
    const custom = result.map((member) => ({
      ...member,
      value: member.name,
      label: member.name,
      assigned: false,
    }));
    return custom;
  } catch ({ data }) {
    return {
      ...data,
      error: true,
    };
  }
};

export const getMemberUsersAssigned = async (id) => {
  const token = getUserToken();

  try {
    const result = await getAssignedUsers(token, id);
    const custom = result.map((member) => ({
      ...member,
      value: member.name,
      label: member.name,
      assigned: true,
    }));
    return custom;
  } catch ({ data }) {
    return {
      ...data,
      error: true,
    };
  }
};

export const requestAssignedMembers = async (payload) => {
  const token = getUserToken();
  try {
    const result = await assignProjectMembers(token, payload);

    return result;
  } catch ({ data }) {
    return {
      ...data,
      error: true,
    };
  }
};

// this displays all members in the members tab table
export const getAllMembersList = async () => {
  const token = getUserToken();
  try {
    const result = await getMembersList(token);

    return result;
  } catch ({ data }) {
    return {
      ...data,
      error: true,
    };
  }
};

// invite users
export const addInviteUsers = async (payload) => {
  const token = getUserToken();
  try {
    const result = await inviteUsersToProjects(token, payload);
    return result;
  } catch ({ data }) {
    return {
      ...data,
      error: true,
    };
  }
};

// edit users role
export const editUsersRole = async (payload) => {
  const token = getUserToken();
  try {
    const result = await editMemberRole(token, payload);
    console.log(result);
    return result;
  } catch ({ data }) {
    return {
      ...data,
      error: true,
    };
  }
};

// remove user
export const removeInvitedUser = async (payload) => {
  const token = getUserToken();
  try {
    const result = await removeUser(token, payload);
    console.log(result);
    return result;
  } catch ({ data }) {
    return {
      ...data,
      error: true,
    };
  }
};
