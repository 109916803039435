import React from "react";
import CustomSvgIcon from "../../TipTapEditor/CustomSvgIcon";
import "./ResultsChartSummary.css";

const ResultsChartSummary = () => {
  return (
    <div className="results-wrapper">
      <div className="summary-text">
        <span>42%</span>
        <p>ORGANIC CLICKS</p>
      </div>
      <div className="summary-info-box">
        <CustomSvgIcon icon="image-fill" />
        <span>460</span>
      </div>
      <div className="summary-info-box">
        <CustomSvgIcon icon="movie-line" />
        <span>311</span>
      </div>
      <div className="summary-info-box">
        <CustomSvgIcon icon="shopping-cart-2-line" />
        <span>124</span>
      </div>
      <div className="summary-info-box">
        <CustomSvgIcon icon="shopping-cart-2-line" />
        <span>1</span>
      </div>
    </div>
  );
};

export default ResultsChartSummary;
