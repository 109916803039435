import React from "react";
import SkeletonCardCategory from "./SkeletonCardCategory";
import TopicCoverageHeadSkeleton from './TopicCoverageHeadSkeleton';

const TopicCoverageSkeletonPlaceholder = ({ isArticle }) => {
  return (
    <>
      <TopicCoverageHeadSkeleton isArticle={isArticle} />
      <SkeletonCardCategory firstItem />
      <SkeletonCardCategory />
      <SkeletonCardCategory firstItem={isArticle}/>
      <SkeletonCardCategory />
      <SkeletonCardCategory />
      <SkeletonCardCategory />
      <SkeletonCardCategory />
      <SkeletonCardCategory />
    </>
  );
};

export default TopicCoverageSkeletonPlaceholder;
