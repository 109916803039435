import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import PerformanceDashboard from "../Components/PerformanceDashboard/PerformanceDashboard";

const PerformanceDashboardPage = () => {
  const [isUserAuth] = useState(!!localStorage.getItem("userToken"));

  return isUserAuth ? <PerformanceDashboard /> : <Redirect to="/login" />;
};

export default PerformanceDashboardPage;
