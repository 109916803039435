import { Tooltip, withStyles } from "@material-ui/core";

export const BlackTooltip = withStyles({
  tooltip: {
    color: "white",
    fontWeight: "bold",
    backgroundColor: "black",
  },
  arrow: {
    "&:before": {
      color: "black",
    },
  },
})(Tooltip);

export const WhiteTooltip = withStyles({
  tooltip: {
    color: "#384359",
    fontSize: "14px",
    backgroundColor: "white",
    boxShadow: "1.3px 0.3px 15px rgba(0, 0, 0, 0.2)",
  },
  arrow: {
    "&:before": {
      color: "white",
      boxShadow: "1.3px 0.3px 15px rgba(0, 0, 0, 0.2)",
    },
  },
})(Tooltip);
