import { createSlice } from "@reduxjs/toolkit";
import {
  getDashboard,
  getDashboardEndpoints,
  setNewDate,
  setNewOption,
  deleteArticleRow,
  getProjectsDashboard,
  requestWriters,
  requestProofreaders,
} from "../services/dashboard";
import { setApiError } from "./apiErrors";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    allDashboardData: null,
    totalRows: null,
    industries: null,
    projects: null,
    userProjects: null,
    feedbacks: null,
    statuses: null,
    writers: null,
    proofreaders: null,
    noDataResults: false,
    projectsDashboardData: null,
  },
  reducers: {
    setDashboardData: (state, { payload }) => {
      return {
        ...state,
        allDashboardData: payload,
      };
    },

    setTotalRows: (state, { payload }) => {
      return {
        ...state,
        totalRows: payload,
      };
    },

    setIndustries: (state, { payload }) => {
      return {
        ...state,
        industries: payload,
      };
    },

    setProjects: (state, { payload }) => {
      return {
        ...state,
        projects: payload,
      };
    },
    setUserProjects: (state, { payload }) => {
      return {
        ...state,
        userProjects: payload,
      };
    },

    setFeedbacks: (state, { payload }) => {
      return {
        ...state,
        feedbacks: payload,
      };
    },

    setStatuses: (state, { payload }) => {
      return {
        ...state,
        statuses: payload,
      };
    },

    setWriters: (state, { payload }) => {
      return {
        ...state,
        writers: payload,
      };
    },

    setProofreaders: (state, { payload }) => {
      return {
        ...state,
        proofreaders: payload,
      };
    },

    setNoDataResults: (state, { payload }) => {
      return {
        ...state,
        noDataResults: payload,
      };
    },

    setProjectsDashboardData: (state, { payload }) => {
      return {
        ...state,
        projectsDashboardData: payload,
      };
    },
  },
});

export const { setDashboardData } = dashboardSlice.actions;
export const { setTotalRows } = dashboardSlice.actions;
export const { setIndustries } = dashboardSlice.actions;
export const { setProjects } = dashboardSlice.actions;
export const { setUserProjects } = dashboardSlice.actions;
export const { setFeedbacks } = dashboardSlice.actions;
export const { setStatuses } = dashboardSlice.actions;
export const { setWriters } = dashboardSlice.actions;
export const { setProofreaders } = dashboardSlice.actions;
export const { setNoDataResults } = dashboardSlice.actions;
export const { setProjectsDashboardData } = dashboardSlice.actions;

// get all dashboard data on init
export const initDashboardData = (token) => async (dispatch) => {
  try {
    const proofreaders = await requestProofreaders(token);

    const transformedProofs =
      proofreaders &&
      proofreaders.map((item) => ({
        ...item,
        initials: item.initials.split(" ").join(""),
      }));

    dispatch(setProofreaders(transformedProofs));
  } catch (error) {
    if (error.data.data !== "No users was found!") dispatch(setApiError(error));
    dispatch(setProofreaders([]));
  }

  try {
    const { industries, projects, feedbacks, statuses, userProjects } =
      await getDashboardEndpoints(token);

    dispatch(setIndustries(industries));
    dispatch(setProjects(projects));
    dispatch(setUserProjects(userProjects));
    dispatch(setFeedbacks(feedbacks));
    dispatch(setStatuses(statuses));
  } catch (error) {
    if (error.data.data !== "No users was found!") dispatch(setApiError(error));
  }

  try {
    const writers = await requestWriters(token);

    const transformedWriters =
      writers &&
      writers.map((item) => ({
        ...item,
        initials: item.initials.split(" ").join(""),
      }));
    dispatch(setWriters(transformedWriters));
  } catch (error) {
    // if (error.data.data !== "No users was found!") dispatch(setApiError(error));
  }
};

// projects dashboard data
export const getProjectsDashboardData =
  (currentPage, rowsPerPage, searchValue, filters, sortedBy) =>
  async (dispatch) => {
    try {
      const { rows, total } = await getProjectsDashboard(
        currentPage,
        rowsPerPage,
        searchValue,
        filters,
        sortedBy
      );

      dispatch(setProjectsDashboardData(rows));
      dispatch(setTotalRows(total));
    } catch (error) {
      dispatch(setApiError(error));
    }
  };

// articles dashboard data
export const getDashboardData =
  (currentPage, rowsPerPage, searchValue, filters, sortedBy) =>
  async (dispatch) => {
    try {
      const { rows, total } = await getDashboard(
        currentPage,
        rowsPerPage,
        searchValue,
        filters,
        sortedBy
      );

      const noResults = rows && rows.some((row) => row.empty);

      dispatch(setNoDataResults(noResults));

      dispatch(setDashboardData(rows));
      dispatch(setTotalRows(total));
    } catch (error) {
      dispatch(setApiError(error));
    }
  };

/**DATA PATCHES **/
// updates from date picker
export const updateCreateOrDeadline =
  (updateType, updatedRows) => async (dispatch) => {
    try {
      const updated = await setNewDate(updateType, updatedRows);
      return updated;
    } catch (error) {
      dispatch(setApiError(error));
    }
  };

// updates from dropdown menu
export const updateDropdownOptions =
  (eventType, updatedRows, updatedProperty) => async (dispatch) => {
    try {
      const updateData = await setNewOption(
        eventType,
        updatedRows,
        updatedProperty
      );

      return updateData;
    } catch (error) {
      dispatch(setApiError(error));
    }
  };

// delete row from dashboard
export const removeArticleRow = (ids) => async (dispatch) => {
  try {
    const confirmation = await deleteArticleRow(ids);
    return confirmation;
  } catch (error) {
    dispatch(setApiError(error));
  }
};

/* selectors */
export const dashboardData = ({ dashboard: { allDashboardData } }) =>
  allDashboardData;
export const dashboardProjectsData = ({
  dashboard: { projectsDashboardData },
}) => projectsDashboardData;
export const dashboardTotalRows = ({ dashboard: { totalRows } }) => totalRows;
export const dashboardNoDataResults = ({ dashboard: { noDataResults } }) =>
  noDataResults;
export const dashboardWriters = ({ dashboard: { writers } }) => writers;
export const dashboardProofreaders = ({ dashboard: { proofreaders } }) =>
  proofreaders;
export const dashboardProjects = ({ dashboard: { projects } }) => projects;
export const dashboardUserProjects = ({ dashboard: { userProjects } }) =>
  userProjects;
export const dashboardFeedback = ({ dashboard: { feedbacks } }) => feedbacks;
export const dashboardStatuses = ({ dashboard: { statuses } }) => statuses;
export const dashboardIndustries = ({ dashboard: { industries } }) =>
  industries;

export default dashboardSlice.reducer;
