import React from "react";
import { BlackTooltip } from "../UITooltips/UITooltip";
import "./CustomBars.css";

const CustomBars = ({ data, title }) => {
  const limit = data && Math.max(data.new, data.old);
  const percCurrent = data && (data.new * 100) / limit;
  const percOld = data && (data.old * 100) / limit;

  const period = data && data.desc.includes("month") ? "month" : "week";

  return (
    <div className="bars-wrapper">
      <BlackTooltip
        arrow
        placement="top"
        title={`This ${period}: ${
          data && new Intl.NumberFormat("en-IN").format(data.new)
        } ${title.toLowerCase()}`}
      >
        <div
          className="single-bar"
          style={{ height: `${percCurrent}%`, background: "#6C5DD3" }}
        ></div>
      </BlackTooltip>
      <BlackTooltip
        arrow
        placement="top"
        title={`Previous ${period}: ${
          data && new Intl.NumberFormat("en-IN").format(data.old)
        } ${title.toLowerCase()}`}
      >
        <div
          className="single-bar"
          style={{ height: `${percOld}%`, background: "#A0D7E7" }}
        ></div>
      </BlackTooltip>
    </div>
  );
};

export default CustomBars;
