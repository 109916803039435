import { getUserToken } from "../utils/userStatus";

import { createProjectBrief, getProjectBrief } from "../api/projects";

export const sendProjectBriefForm = async (payload) => {
  const token = getUserToken();

  const result = await createProjectBrief(payload, token);
  return result;
};

export const getProjectBriefData = async (id) => {
  const token = getUserToken();

  try {
    const result = await getProjectBrief({ id }, token);
    return result;
  } catch ({ data }) {
    return {
      ...data,
      error: true,
    };
  }
};
