import React from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Button, Menu, MenuItem } from "@material-ui/core";
import CustomFileUploader from "./CustomFileUploader";

const CreateOptionsMenu = ({ onSelect, onImportCSV }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    setAnchorEl(null);

    if (e.currentTarget.id) {
      onSelect(e.currentTarget.id);
    }
  };

  const useButtonStyles = makeStyles(() =>
    createStyles({
      button: {
        margin: 2,
        borderRadius: "10px",
        textTransform: "none",
        backgroundColor: "#6870f6",
        minWidth: "90px",
        minHeight: "20px",
        fontWeight: "bold",
        color: "white",
        "&:hover": {
          backgroundColor: "#7e86f7",
        },
      },
    })
  );

  const classes = useButtonStyles();

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.button}
      >
        + Create
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem id="article" onClick={handleClose}>
          Article
        </MenuItem>
        <MenuItem id="description" onClick={handleClose}>
          Description
        </MenuItem>
        <MenuItem id="native" onClick={handleClose}>
          Native
        </MenuItem>
        <MenuItem id="uploader" onClick={handleClose}>
          <CustomFileUploader title="Bulk CSV Import" onSuccess={onImportCSV} />
        </MenuItem>
      </Menu>
    </>
  );
};

export default CreateOptionsMenu;
