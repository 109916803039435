export const validateStep = (step, inputs) => {
  const { firstName, lastName, email, websiteUrl, password } = inputs;
  let isValid = false;
  switch (step) {
    case 0:
      isValid =
        firstName.isValid &&
        lastName.isValid &&
        email.isValid &&
        websiteUrl.isValid &&
        password.isValid;
      return isValid;

    default:
      return false;
  }
};
