export const getShuffledArray = (data) => {
  // remove first element
  const [, ...withoutFirst] = data;
  // shuffle remaining elements
  const shuffledWithoutFirst = getShuffledArr(withoutFirst);
  // remove last 2 elements from shuffled array
  const [, , ...onlyLastElements] = shuffledWithoutFirst;
  // add back first element to always be first
  const newShuffledArray = [data[0], ...onlyLastElements];
  return newShuffledArray;
};

const getShuffledArr = (arr) => {
  if (arr.length === 1) {
    return arr;
  }
  const rand = Math.floor(Math.random() * arr.length);
  return [arr[rand], ...getShuffledArr(arr.filter((_, i) => i !== rand))];
};
