import React, { useEffect } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import CharacterCount from "@tiptap/extension-character-count";
import Image from "@tiptap/extension-image";
import TextAlign from "@tiptap/extension-text-align";
import { debounce } from "lodash";
import "./TipTapCategoryEditor.css";
import {
  saveEditorDataToStore,
  setEditorInstance,
} from "../../reducers/textEditor";
import {
  setHighTopicCoverage,
  setLowTopicCoverage,
  setTopicCoverageData,
  updateMatchingWords,
} from "../../reducers/topicCoverage";
import { useDispatch, useSelector } from "react-redux";
import { setWordCount } from "../../reducers/counters";
import { DEFAULT_ARTICLE_ID } from "../../common/consts";
import {
  getCurrentArticle,
  getCurrentArticleId,
  saveArticle,
  setArticle,
  setArticleId,
  setArticlesCollection,
} from "../../reducers/articles";
import { isDemoApplication } from "../../reducers/applicationMode";

import useUnmount from "../../utils/useUnmount";
import { useApplicationType } from "../../utils/useApplicationType";
import EditorMenuTop from "./EditorMenuTop";
import { loadingStep2Data } from "../../reducers/searchTopic";

const parseEditorData = debounce((dispatch, data) => {
  // console.log("SAVING DATA DEBOUNCED ", data);
  dispatch(saveEditorDataToStore(data));
}, 500);

const trackTopicCoverage = debounce((dispatch) => {
  dispatch(updateMatchingWords());
}, 600);

const sendDataToServer = debounce((dispatch, articleType) => {
  // console.log("SAVING TO SERVER", articleType);
  dispatch(saveArticle(articleType));
}, 5000);

const TipTapCategoryEditor = () => {
  const dispatch = useDispatch();
  const isDemoApp = useSelector(isDemoApplication);
  const currentArticleId = useSelector(getCurrentArticleId);

  const currentArticle = useSelector(getCurrentArticle);
  const applicationType = useApplicationType();
  const isTopicLoading = useSelector(loadingStep2Data);

  useEffect(() => {
    if (currentArticle && editor) {
      editor.commands.setContent(
        currentArticle.content === "Article content"
          ? "<h1>Insert title here..."
          : currentArticle.content
      );
      trackDataDetails(editor);
    }
  }, [currentArticle]);

  useUnmount(() => {
    dispatch(setArticle(null));
    dispatch(setArticleId(null));
    dispatch(setArticlesCollection([]));
    dispatch(setEditorInstance(null));
    dispatch(setTopicCoverageData([]));
    dispatch(setHighTopicCoverage([]));
    dispatch(setLowTopicCoverage([]));
    if (editor) {
      editor.destroy();
    }
  });

  const trackDataDetails = (editor) => {
    const data = editor.getHTML();
    // console.log({ data });
    const wordCount = editor.storage.characterCount.words();
    dispatch(setWordCount(wordCount));
    // parsed all data - debounced
    parseEditorData(dispatch, data);
    // track words from the content editor - debounced
    trackTopicCoverage(dispatch);
  };

  const editor = useEditor({
    extensions: [
      StarterKit,
      CharacterCount,
      Image,
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
    ],
    // content: currentArticle.content,
    onCreate: ({ editor }) => {
      // setting editor content with current article content
      editor.commands.setContent(
        currentArticle.content === "Article content"
          ? "<h1>Insert title here...</h1>"
          : currentArticle.content
      );
      trackDataDetails(editor);
      // set editor instance globally so it can be accessed throught the app
      dispatch(setEditorInstance(editor));
    },
    onUpdate: ({ editor }) => {
      trackDataDetails(editor);
      // send data to API, autosave debounced
      if (!isDemoApp && currentArticleId !== DEFAULT_ARTICLE_ID) {
        sendDataToServer(dispatch, applicationType);
      }
    },
  });

  if (!editor) {
    return null;
  }
  return (
    <div className="description-editor-wrapper">
      <EditorMenuTop editor={editor} />
      {!isTopicLoading && <EditorContent editor={editor} />}
    </div>
  );
};

export default TipTapCategoryEditor;
