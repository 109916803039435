export const currentTopicDensity = (topics, topicCoverage) => {
  // get from topicCoverage only the results relevant for topic density

  const relevantResults = topicCoverage.filter((result) => {
    const found = topics.find((topic) => topic === result.keyword);
    return found;
  });

  const totalDensity =
    relevantResults &&
    relevantResults.reduce((acc, curr) => acc + curr.repeat, 0);

  return totalDensity;
};
