import React from "react";

import "./ResetComponent.css";
import ChangePassForm from "./ChangePassForm";

const ChangePassComponent = () => {
  return (
    <div className="reset-component">
      <div className="reset-left-content">
        <img
          src="/images/_reset.png"
          width="360px"
          height="228px"
          alt="Forgot Password"
        />
        <div className="reset-copy-area">
          <p>No biggie. Let's resolve this quickly.</p>
          <p className="reset-subcopy">
            All you need to do is tell us your e-mail and we'll help you out.
          </p>
        </div>
      </div>
      <div className="login-divider"></div>
      <div className="reset-right-content">
        <div className="reset-app-logo">
          <img
            src="/images/logo-contero.svg"
            alt="logo"
            width="200px"
            height="65px"
          />
        </div>
        <ChangePassForm />
      </div>
    </div>
  );
};

export default ChangePassComponent;
