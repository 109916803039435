import React from "react";
import CustomSvgIcon from "./CustomSvgIcon";

import "./MenuItemTop.css";

const MenuItemTop = ({
  icon,
  title,
  action,
  isActive = null,
  isComponent,
  Component,
}) => {
  if (isComponent) {
    // if we defined a custom component we just render it here
    return Component;
  } else {
    return (
      <button
        className={`menu-item${isActive && isActive() ? " is-active" : ""}`}
        onClick={action}
        title={title}
      >
        <CustomSvgIcon icon={icon} />
      </button>
    );
  }
};

export default MenuItemTop;
