import React, { useState } from "react";
import AddUsersRowInputs from "./AddUsersRowInputs";
import Scrollbars from "react-custom-scrollbars";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Button from "../../Shared/Button";
import "./AddUsersForm.css";

const AddUsersForm = ({ onUpdateTeamList, projects }) => {
  const [userId, setUserId] = useState(0);
  const [teamUsers, setTeamUsers] = useState([{ id: userId }]);
  const [teamList, setTeamList] = useState([]);

  const insertNewRow = () => {
    const nextUserId = userId + 1;
    setUserId(nextUserId);
    updateTeamMembers(nextUserId);
  };

  const updateTeamMembers = (nextUserId) => {
    const newUser = {
      id: nextUserId,
    };
    const updatedUsers = [...teamUsers, newUser];
    setTeamUsers(updatedUsers);
  };

  const onInputNewUser = (user) => {
    const team = [...teamList, user];
    setTeamList(team);
  };

  const onAddUsers = () => {
    onUpdateTeamList(teamList);
  };

  const removeRow = (passedId) => {
    const filteredList = teamUsers.filter((user) => user.id !== passedId);

    setTeamUsers(filteredList);
  };

  const showDeleteUser = teamUsers.length > 1;

  return (
    <div className="add-users-wrapper">
      <div>
        <Scrollbars
          style={{
            height: 50 * teamUsers.length,
            maxHeight: 250,
            marginBottom: 15,
          }}
        >
          {teamUsers.map((userRow) => (
            <div className="add-user-row-form" key={userRow.id}>
              <AddUsersRowInputs
                onInputUserData={onInputNewUser}
                projects={projects}
                showDeleteUser={showDeleteUser}
                onRemoveRow={removeRow}
                userId={userRow.id}
              />
            </div>
          ))}
        </Scrollbars>

        <span className="add-new-user-btn" onClick={insertNewRow}>
          <AddCircleIcon
            style={{ color: "#6F767E", width: "16px", marginRight: "7px" }}
          />
          <div className="add-new-user-text">Invite another</div>
        </span>
      </div>
      <div>
        <Button
          type="submit"
          fullSize
          onClick={onAddUsers}
          disabled={teamList.length === 0}
        >
          Invite Users
        </Button>
      </div>
    </div>
  );
};

export default AddUsersForm;
