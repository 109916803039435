import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CompetitionItem from "./CompetitionItem";
import CustomTooltip from "./CustomTooltip";
import {
  getCompetitionData,
  loadingCompetitionData,
  isFetchCompetitionError,
} from "../reducers/searchTopic";
import { getMaxImages } from "../reducers/images";
import HeadingsSummary from "./HeadingsSummary";
import { getApplicationMode } from "../reducers/applicationMode";
import CompetitionSkeletonPlaceholder from "./CompetitionSkeletonPlaceholder";
import FormErrorMessage from "./FormErrorMessage";

import "./Competition.css";

const Competition = () => {
  const [competitionData, setCompetitionData] = useState();
  const competition = useSelector(getCompetitionData);
  const maxImages = useSelector(getMaxImages);
  const appMode = useSelector(getApplicationMode);
  const isLoadingCompetition = useSelector(loadingCompetitionData);
  const competitionError = useSelector(isFetchCompetitionError);

  useEffect(() => {
    setCompetitionData(competition);
  }, [competition]);

  const isArticle = appMode === "article";
  const showContent = competitionData && !isLoadingCompetition;

  return (
    <div className="competition">
      {showContent && !competitionError && (
        <div className="competiton-head">
          <h3>Competition Analysis</h3>
          <p className="competition-sub">
            An overview of top 10 articles ranking in Google for this topic
          </p>
        </div>
      )}
      {showContent && isArticle && (
        <div className="top-numbers">
          <div className="competition-numbers info-words">
            {(competitionData && competitionData.avgWords.toLocaleString()) ||
              0}
            <div className="competition-numbers-info">
              <div className="competition-numbers-label">Avg. wordcount</div>
              <CustomTooltip
                posTop="-111px"
                posLeft="-112px"
                text="This is an average wordcount for articles ranking in top 10 Google results. It should help you estimate how long your article should be in order to rank."
              />
            </div>
          </div>
          <div className="competition-numbers info-images">
            {maxImages.toLocaleString()}
            <div className="competition-numbers-info">
              <div className="competition-numbers-label">Avg. images</div>
              <CustomTooltip
                posTop="-126px"
                posLeft="-140px"
                text="This is the average number of images placed inline in articles ranking in top 10 Google results. Articles related to interior design or travelling tend to be image-rich for example."
              />
            </div>
          </div>
        </div>
      )}

      {isLoadingCompetition && (
        <CompetitionSkeletonPlaceholder isArticle={isArticle} />
      )}

      {competitionError ? (
        <FormErrorMessage>
          Competition Data is not available currently
        </FormErrorMessage>
      ) : (
        <>
          {competitionData &&
            competitionData.articles &&
            competitionData.articles.map(({ id, ...rest }) => (
              <CompetitionItem key={id} {...rest} />
            ))}

          {competitionData && competitionData.allHtags && (
            <HeadingsSummary data={competitionData.allHtags} />
          )}
        </>
      )}
    </div>
  );
};

export default Competition;
