import React from 'react';
import LoginComponent from '../Components/LoginComponent';

import './Login.css';

const Login = () => {
  return (
    <div className='login-page'>
      <LoginComponent />
    </div>
  );
};

export default Login;