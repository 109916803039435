import React, { useCallback, useEffect, useReducer } from "react";
import Input from "../../Shared/Input";
import { formReducer } from "../../utils/formReducer";

import "./CustomSearchTopicFilter.css";

const CustomSearchTopicFilter = ({
  placeholder,
  onFilter,
  currentColumn,
  initialValue,
  isClear,
}) => {
  const [formState, dispatch] = useReducer(formReducer, {
    inputs: {
      topicFilter: {
        value: "",
        isValid: false,
      },
    },
    isValid: false,
  });

  const inputHandler = useCallback((id, value, isValid) => {
    dispatch({
      type: "INPUT_CHANGE",
      value: value,
      isValid: isValid,
      inputId: id,
    });
  }, []);

  useEffect(() => {
    const inputValue = formState.inputs.topicFilter.value;

    const filter = {
      [currentColumn]: inputValue,
    };
    onFilter(filter);
  }, [formState.inputs.topicFilter.value]);

  useEffect(() => {
    if (isClear) {
      const filter = {
        [currentColumn]: "",
      };
      onFilter(filter);
    }
  }, [isClear]);

  return (
    <div className="head-topic-filter">
      <div className="input-search-topic">
        <Input
          id="topicFilter"
          element="input"
          type="text"
          placeholder={placeholder}
          onInput={inputHandler}
          noValidation
          initialValue={initialValue}
          isReset={isClear}
        />
      </div>
    </div>
  );
};

export default CustomSearchTopicFilter;
