import React, { useCallback, useReducer } from "react";
import Input from "../../Shared/Input";
import Button from "../../Shared/Button";

import { formReducer } from "../../utils/formReducer";
import { VALIDATOR_MINLENGTH } from "../../utils/validators";

const AddNewProject = ({ onAddNewProject }) => {
  const [formState, dispatch] = useReducer(formReducer, {
    inputs: {
      project: {
        value: "",
        isValid: false,
      },
    },
  });

  const inputHandler = useCallback((id, value, isValid) => {
    dispatch({
      type: "INPUT_CHANGE",
      value: value,
      isValid: isValid,
      inputId: id,
    });
  }, []);

  const saveTopicInput = (event) => {
    event.preventDefault();
    onAddNewProject(formState.inputs.project.value);
  };

  return (
    <form className={"rename-topic-form"} onSubmit={saveTopicInput}>
      <Input
        id="project"
        element="input"
        type="text"
        label="Project Name"
        validators={[VALIDATOR_MINLENGTH(2)]}
        errorText="Please enter a text longer than 2 characters."
        onInput={inputHandler}
      />

      <Button type="submit" fullSize disabled={!formState.isValid}>
        Create Project
      </Button>
    </form>
  );
};

export default AddNewProject;
