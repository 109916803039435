import React from "react";
import Rating from "react-rating";
import { getStarsNumber } from "../../utils/getDecimals";
import { StarIconEmpty, StarIconFilled } from "../Rating/Icons";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { useState } from "react";
import "./UserRating.css";

const UserRating = ({ currentUser }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const logOutHandler = () => {
    localStorage.removeItem("userToken");
    window.location.replace("./login");
  };

  if (!currentUser) {
    return null;
  }

  return (
    <div className="rating-info">
      <div className="user-data">
        <div className="rating-username">{`Hello, ${currentUser.firstname}!`}</div>
        <div className="rating-stars">
          {currentUser.rating && (
            <Rating
              readonly={true}
              initialRating={getStarsNumber(currentUser.rating)}
              emptySymbol={
                <StarIconEmpty width={14} height={14} fill="#d9e1e9" />
              }
              fullSymbol={
                <StarIconFilled width={14} height={14} fill="#f6cb00" />
              }
            />
          )}
        </div>
      </div>
      <div className="user-image">
        <Button onClick={(e) => handleClick(e)}>
          <img
            src="/images/user-avatar.png"
            width="43px"
            height="43px"
            alt="avatar"
          />
        </Button>
        <Menu
          className="user-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={logOutHandler}>Logout</MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default UserRating;
