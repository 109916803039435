import React, { useState } from "react";

const CountDownTimer = ({ minSecs }) => {
  const { minutes = 0, seconds = 60 } = minSecs;
  const [[mins, secs], setTime] = useState([minutes, seconds]);
  const [stopInterval, setStopInterval] = useState(false);

  const tick = () => {
    if (mins === 0 && secs === 0) reset();
    else if (mins === 0 && secs === 0) {
      setTime([mins - 1, 59]);
    } else if (secs === 0) {
      setTime([mins - 1, 59]);
    } else {
      setTime([mins, secs - 1]);
    }
  };

  const reset = () => {
    setStopInterval(true);
  };

  React.useEffect(() => {
    if (!stopInterval) {
      const timerId = setInterval(() => tick(), 1000);
      return () => clearInterval(timerId);
    }
  });

  return (
    <div>
      <p className="close-tips-timer">{`${mins
        .toString()
        .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`}</p>
    </div>
  );
};

export default CountDownTimer;
