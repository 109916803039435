import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useHistory, useLocation } from "react-router-dom";
import Popup from "reactjs-popup";
import Select from "react-select";
import CreateNewEntryForm from "../PopupContent/CreateNewEntryForm";
import CustomAsyncSelect from "../CustomAsyncSelect";
import RenameTopic from "../PopupContent/RenameTopic";
import TopicNameCell from "../TopicNameCell";
import { setArticleId } from "../../reducers/articles";
import Tooltip from "@material-ui/core/Tooltip";

import "./ManagementDashboard.css";

import {
  getDashboardData,
  dashboardData,
  dashboardTotalRows,
  dashboardIndustries,
  dashboardFeedback,
  dashboardStatuses,
  dashboardProjects,
  dashboardWriters,
  dashboardProofreaders,
  updateCreateOrDeadline,
  updateDropdownOptions,
  removeArticleRow,
  dashboardNoDataResults,
} from "../../reducers/dashboard";
import { toggleApplicationMode } from "../../reducers/applicationMode";
import { setTopicCoverageData } from "../../reducers/topicCoverage";

import CustomDatePicker from "../CustomDatePicker";
import CustomMenu from "../CustomMenu";
import {
  customMenuOptions,
  dymanicMenuOptions,
  getDefaultValue,
  updateLocalStateWithOusideValues,
  userHasPermission,
  updateTableInLocalState,
  getTableForExport,
  getNextUrl,
  displayEntryType,
  customSortedTable,
} from "../dashboardComponentHelpers";
import {
  TOPIC,
  CREATED,
  DEADLINE,
  WRITER,
  PROJECT,
  INDUSTRY,
  PROOFREADER,
  STATUS,
  WRITER_ASSIGN,
  WRITER_UNASSIGN,
  WRITER_SWITCH,
  PROOFREADER_ASSIGN,
  PROOFREADER_UNASSIGN,
  PROOFREADER_SWITCH,
  DEADLINE_CHANGE,
  CREATION_CHANGE,
  ARTICLE_DELETE,
  ARTICLE_DELETE_BULK,
  CREATE_DASHBOARD_ENTRY,
  CREATE_DASHBOARD_DESCRIPTION_ENTRY,
  RENAME_TOPIC,
  EDIT_ENTRY,
  USER_WRITER,
  USER_MASTER,
  USER_PROOFREADER,
  STATUS_CHANGE,
  DEFAULT_ARTICLE_ID,
  CREATE_DASHBOARD_NATIVE_ENTRY,
  TYPE_DESCRIPTION,
  TYPE_NATIVE,
  CSV_TEMPLATE_DOWNLOAD,
  USER_CLIENT,
  EDIT_LIVE_URL,
  DASH_MANAGEMENT,
} from "../../common/consts";

import { activeUser } from "../../reducers/user";
import {
  resetEditorData,
  setActiveSidebarTab,
  // setEditorData,
} from "../../reducers/textEditor";

import SingleArticleDelete from "../PopupContent/SingleArticleDelete";
import DashboardPopup from "../PopupContent/DashboardPopup";
import PopupContentWrapper from "../PopupContent/PopupContentWrapper";
import DynamicOptionsSelect from "../DynamicOptionsSelect";
import "./DashboardComponent.css";
import CreateNewDescriptionForm from "../PopupContent/CreateNewDescriptionForm";
import CustomCSVUploader from "../CustomFileUploader";
import CreateNewNativeForm from "../PopupContent/CreateNewNativeForm";
import CreateOptionsMenu from "../CreateOptionsMenu";
import { setKeywordsSearch, setTopicData } from "../../reducers/searchTopic";
import PlagiarismTag from "../Plagiarism/PlagiarismTag";
import DateDisplayWrapper from "../DateDisplayWrapper/DateDisplayWrapper";
import PerformanceHeaderBar from "../PerformanceDashboard/PerformanceHeaderBar";
import { Checkbox, TablePagination } from "@material-ui/core";
import ManagementCard from "./ManagementCard";
import { LogoutIcon } from "../../UI_utils/SVGIcons";
import TextWithInfoIcon from "../PerformanceStatsSmallCard/TextWithInfoIcon";
import FilterPanel from "../FiltersPanel/FilterPanel";
import CustomColumnFilter from "../TableHeadFilter/CustomColumnFilter";
import CustomSearchTopicFilter from "../TableHeadFilter/CustomSearchTopicFilter";
import RangeSlider from "../RangeSlider/RangeSlider";

const DasboardComponent = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const urlSearch = queryParams.get("search");

  const dispatch = useDispatch();
  const history = useHistory();
  const dashboard = useSelector(dashboardData);
  const projects = useSelector(dashboardProjects);
  const feedbacks = useSelector(dashboardFeedback);
  const statuses = useSelector(dashboardStatuses);
  const industries = useSelector(dashboardIndustries);
  const writers = useSelector(dashboardWriters);
  const proofreaders = useSelector(dashboardProofreaders);
  const totalRows = useSelector(dashboardTotalRows);
  const currentUser = useSelector(activeUser);

  const noResults = useSelector(dashboardNoDataResults);

  const [tableData, setTableData] = useState([]);
  const [tableHeaderData, setTableHeaderData] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1); // this is old, needs to be removed
  const [currentPage, setCurrentPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentUserType, setCurrentUserType] = useState("");
  const [totalRowsDisplay, setTotalRowsDisplay] = useState(totalRows);
  const [selectAll, setSelectAll] = useState(false);

  // filters
  const [currentFilters, setCurrentFilters] = useState(null);
  const [searchString, setSearchString] = useState(urlSearch);
  const [hasAppliedFilters, setHasAppliedFilters] = useState(false);
  const [removeAllFilters, setRemoveAllFilters] = useState(false);

  const [sortedBy, setSortedBy] = useState(null);

  // popup
  const [showModal, setShowModal] = useState(false);
  const [modalAction, setModalAction] = useState();

  const [currentRowArray, setCurrentRowArray] = useState([]);
  const [tableIsUpdated, setTableIsUpdated] = useState(false);

  // filters control
  const [openFilterPanel, setOpenFilterPanel] = useState(false);

  // clean up all article related data when loading this page
  useEffect(() => {
    dispatch(setArticleId(null));
    dispatch(setTopicData(null));
    //   // reset topic coverage data when coming from eshop
    dispatch(setTopicCoverageData([]));
    //   // reset editor data to empty
    // dispatch(resetEditorData(true));
    dispatch(setKeywordsSearch(null));
  }, []);

  const closeModalHandler = () => {
    // reset selected rows when modal is closing
    setSelectedRows([]);
    setShowModal(false);
  };
  const openModalHandler = () => setShowModal(true);

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const setDropdownBulkActions = (value) => {
    switch (value.label) {
      case WRITER_ASSIGN:
        openModalHandler();
        setModalAction(WRITER_ASSIGN);
        break;
      case WRITER_UNASSIGN:
        // build previous and current value before unassign
        const unassignWriterBulk = selectedRows.map((row) => {
          const newRow = {
            ...row,
            prevwriter: row.writer,
            writer: null,
          };
          return newRow;
        });

        handleOptionChange(unassignWriterBulk, "users", WRITER);

        break;
      case PROOFREADER_ASSIGN:
        openModalHandler();
        setModalAction(PROOFREADER_ASSIGN);
        break;
      case PROOFREADER_UNASSIGN:
        // build previous and current value before unassign
        const unassignProofBulk = selectedRows.map((row) => {
          const newRow = {
            ...row,
            prevproofreader: row.proofreader,
            proofreader: null,
          };
          return newRow;
        });

        handleOptionChange(unassignProofBulk, "users", PROOFREADER);
        break;
      case DEADLINE_CHANGE:
        openModalHandler();
        setCurrentRowArray(selectedRows);
        setModalAction(DEADLINE_CHANGE);
        break;
      case CREATION_CHANGE:
        openModalHandler();
        setCurrentRowArray(selectedRows);
        setModalAction(CREATION_CHANGE);
        break;
      case STATUS_CHANGE:
        openModalHandler();
        setCurrentRowArray(selectedRows);
        setModalAction(STATUS_CHANGE);
        break;
      case ARTICLE_DELETE:
        openModalHandler();
        setCurrentRowArray(selectedRows);
        setModalAction(ARTICLE_DELETE_BULK);
        break;
      default: {
        return null;
      }
    }
  };

  useEffect(() => {
    dispatch(
      getDashboardData(
        currentPage,
        rowsPerPage,
        searchString,
        currentFilters,
        sortedBy
      )
    );
  }, [
    currentPage,
    rowsPerPage,
    tableIsUpdated,
    searchString,
    currentFilters,
    sortedBy,
  ]);

  useEffect(() => {
    // sort data here
    const sorted = customSortedTable(dashboard);
    setTableData(sorted);
    setTableIsUpdated(false);
  }, [dashboard]);

  useEffect(() => {
    if (currentUser) {
      setCurrentUserType(currentUser.role[0]);
    }
  }, [currentUser]);

  const handleOpenArticle = ({ id, type }) => {
    // we just need to navigate to the id, the article will be loaded from url
    getNextUrl(type);
    const navigationURL = `${getNextUrl(type)}/${id}`;
    history.push(navigationURL);
    // reset article id
    dispatch(setArticleId(null));
    // reset topic coverage data when coming from eshop
    dispatch(setTopicCoverageData([]));
    // reset editor data to empty
    // dispatch(resetEditorData(true));
    dispatch(setKeywordsSearch(null));
    dispatch(setActiveSidebarTab(0));
  };

  const handleOptionChange = async (
    updatedRows,
    actionType,
    updatedProperty
  ) => {
    const checkStatus = updatedRows[0].status;
    // if status is `published` open modal with url input
    if (checkStatus === 7) {
      setSelectedRows(updatedRows);
      openModalHandler();
      setModalAction(EDIT_LIVE_URL);
    }
    // send new data to backend
    const response = await dispatch(
      updateDropdownOptions(actionType, updatedRows, updatedProperty)
    );

    if (response) {
      const isUpdated = updatedRows.every(
        ({ id }) => !response.error && response.includes(id)
      );

      // update local state
      if (isUpdated) {
        const updatedTable = updateTableInLocalState(tableData, updatedRows);
        const sorted = customSortedTable(updatedTable);
        setTableData(sorted);
      }
      // refresh table when updateProperty is writer and proofreader
      // we do this to correctly display the article status after updating 'writer' and 'proofreader'
      if (updatedProperty === "writer" || updatedProperty === "proofreader") {
        setTableIsUpdated(true);
      }
    }
  };

  // dispatch row action from 3 dots menu
  const dispatchRowAction = (row, action) => {
    setSelectedRows([row]);
    switch (action.label) {
      case WRITER_ASSIGN:
        openModalHandler();
        setModalAction(WRITER_ASSIGN);
        break;
      case WRITER_SWITCH:
        openModalHandler();
        setModalAction(WRITER_ASSIGN);
        break;
      case WRITER_UNASSIGN:
        const unassignWriterTemplate = {
          ...row,
          prevwriter: row.writer,
          writer: null,
        };
        handleOptionChange([unassignWriterTemplate], "users", WRITER);
        break;
      case PROOFREADER_ASSIGN:
        openModalHandler();
        setModalAction(PROOFREADER_ASSIGN);
        break;
      case PROOFREADER_SWITCH:
        openModalHandler();
        setModalAction(PROOFREADER_ASSIGN);
        break;
      case PROOFREADER_UNASSIGN:
        const unassignProofTemplate = {
          ...row,
          prevproofreader: row.proofreader,
          proofreader: null,
        };
        handleOptionChange([unassignProofTemplate], "users", PROOFREADER);
        break;
      case ARTICLE_DELETE:
        openModalHandler();
        setModalAction(ARTICLE_DELETE);
        break;
      case DEADLINE_CHANGE:
        openModalHandler();
        setCurrentRowArray([row]);
        setModalAction(DEADLINE_CHANGE);
        break;
      case CREATION_CHANGE:
        openModalHandler();
        setCurrentRowArray([row]);
        setModalAction(CREATION_CHANGE);
        break;
      case RENAME_TOPIC:
        openModalHandler();
        setModalAction(RENAME_TOPIC);
        break;
      case EDIT_LIVE_URL:
        openModalHandler();
        setModalAction(EDIT_LIVE_URL);
        break;
      case EDIT_ENTRY:
        openModalHandler();
        if (row.type === TYPE_DESCRIPTION) {
          setModalAction(CREATE_DASHBOARD_DESCRIPTION_ENTRY);
        } else if (row.type === TYPE_NATIVE) {
          setModalAction(CREATE_DASHBOARD_NATIVE_ENTRY);
        } else {
          setModalAction(CREATE_DASHBOARD_ENTRY);
        }
        break;
      default: {
        return null;
      }
    }
  };

  const handleDeleteArticle = async (rowsId) => {
    const response = await dispatch(removeArticleRow(rowsId));
    if (response) {
      const isDeleted = rowsId.every(({ id }) => response.includes(id));

      if (isDeleted) {
        // update table after deleted article
        setTableIsUpdated(true);
      }
    }
  };

  // set new date for `Created` or `Deadline`
  const changeDateHandler = async (updatedRows, updatedProperty) => {
    // patch server with new object
    const response = await dispatch(
      updateCreateOrDeadline(updatedProperty, updatedRows)
    );
    if (response) {
      const isUpdated = updatedRows.every(
        ({ id }) => !response.error && response.includes(id)
      );
      if (isUpdated) {
        const updatedTable = updateTableInLocalState(tableData, updatedRows);
        const sorted = customSortedTable(updatedTable);
        setTableData(sorted);
      }
    }
  };

  useEffect(() => {
    setTotalRowsDisplay(totalRows);
    if (!totalRows) {
      setLoading(true);
    } else {
      setLoading(false);
    }
    if (totalRows === 0) {
      setLoading(false);
    }
  }, [totalRows]);

  const handleRowsPerPage = (rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const createNewDashboardEntry = (type) => {
    if (type === "uploader") {
      // return in this case, the uploaded will do the work
      return;
    }

    // if the user is WRITER we redirect to main page article and open popup for inserting topic
    // also we need to reset the topic coverage, editor data and article id
    if (userHasPermission(currentUserType, USER_WRITER)) {
      if (type === "description") {
        dispatch(toggleApplicationMode("category"));
        history.push(`/eshop/${DEFAULT_ARTICLE_ID}`);
      } else {
        dispatch(toggleApplicationMode(type));
        history.push(`/${type}/${DEFAULT_ARTICLE_ID}`);
      }
      // reset article id to default only if we are coming dashboard

      // reset article id
      dispatch(setArticleId(null));
      // reset topic coverage data when coming from eshop
      dispatch(setTopicCoverageData([]));
      // reset editor data to empty
      // dispatch(resetEditorData(true));
    } else {
      openModalHandler();
      if (type === "article") {
        setModalAction(CREATE_DASHBOARD_ENTRY);
      } else if (type === "native") {
        setModalAction(CREATE_DASHBOARD_NATIVE_ENTRY);
      } else {
        setModalAction(CREATE_DASHBOARD_DESCRIPTION_ENTRY);
      }
    }
  };

  // rename topic handler
  const handleRenameTopic = (newValue) => {
    const updatedTable = updateLocalStateWithOusideValues(
      tableData,
      [newValue], // we need to send this as an array
      TOPIC,
      newValue
    );

    const sorted = customSortedTable(updatedTable);

    setTableData(sorted);
  };

  const menuSingleOption =
    userHasPermission(currentUserType, USER_PROOFREADER) ||
    userHasPermission(currentUserType, USER_WRITER);

  const disabledCheckboxes = (row) => {
    if (
      currentUserType === USER_PROOFREADER ||
      (currentUserType === USER_WRITER && row.master)
    ) {
      return true;
    }

    return false;
  };

  const onExportCSV = () => {
    let csv = getTableForExport(
      tableData,
      projects,
      feedbacks,
      statuses,
      industries,
      writers,
      proofreaders
    );
    const link = document.createElement("a");
    // let csv = convertArrayOfObjectsToCSV(tableForExport);
    if (csv === null) return;
    const filename = "exported.csv";
    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }
    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  };

  const onRemoveAllFilters = () => {
    setRemoveAllFilters(true);
  };

  const onFiltersRemoved = () => {
    setRemoveAllFilters(false);
  };

  const columnFilterData = {
    projects,
    statuses,
    industries,
    writers,
    proofreaders,
  };

  const applyColumnFilters = (data) => {
    setCurrentFilters(data);
    handlePageChange(0);
  };

  const applySearchFilter = (data) => {
    setSearchString(data);
    handlePageChange(0);
  };

  const label = { inputProps: { "aria-label": "Checkbox" } };
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const selectAllCards = () => {
    setSelectAll(!selectAll);
  };

  const updateSelected = (id, value) => {
    const updateListById =
      tableData &&
      tableData.map((item) =>
        item.id === id ? { ...item, selected: value } : { ...item }
      );
    const rowsSelected = updateListById.filter(({ selected }) => selected);

    setSelectedRows(rowsSelected);
    setTableData(updateListById);
  };

  useEffect(() => {
    const updateListWithSelected =
      tableData &&
      tableData.map((item) => ({
        ...item,
        selected: selectAll,
      }));

    if (selectAll) {
      setSelectedRows(updateListWithSelected);
    } else {
      setSelectedRows([]);
    }

    setTableData(updateListWithSelected);
  }, [selectAll]);

  const logOutHandler = () => {
    localStorage.removeItem("userToken");
    window.location.replace("./login");
  };

  const types = [
    { id: 0, name: "Article" },
    { id: 1, name: "Description" },
    { id: 2, name: "Native" },
  ];

  const filterElements = [
    {
      header: "Project",
      type: "select",
      data: projects,
    },
    {
      header: "Topic",
      type: "search",
    },
    {
      header: "Status",
      type: "select",
      data: statuses,
    },
    {
      header: "Writer",
      type: "select",
      data: writers,
    },
    {
      header: "Proofreader",
      type: "select",
      data: proofreaders,
    },
    {
      header: "Created",
      type: "picker",
    },
    {
      header: "Deadline",
      type: "picker",
    },
    {
      header: "Type",
      type: "select",
      data: types,
    },
    // {
    //   header: "Contero Score",
    //   type: "range",
    // },
  ];

  return (
    <>
      <FilterPanel
        isOpen={openFilterPanel}
        onClose={() => setOpenFilterPanel(false)}
        elements={filterElements}
        onAddedFilters={(filters) => applyColumnFilters(filters)}
        initialFilters={currentFilters}
      />
      <Popup
        open={showModal}
        closeOnDocumentClick={false}
        onClose={closeModalHandler}
      >
        <DashboardPopup>
          {modalAction === ARTICLE_DELETE && (
            <PopupContentWrapper
              title="Are you sure you want to delete this article?"
              width="540px"
              height="220px"
              closePopup={closeModalHandler}
              showCloseBtn={false}
            >
              <SingleArticleDelete
                currentRows={selectedRows}
                onClose={closeModalHandler}
                onConfirm={handleDeleteArticle}
                bulkAction={false}
              />
            </PopupContentWrapper>
          )}

          {modalAction === ARTICLE_DELETE_BULK && (
            <PopupContentWrapper
              title="You selected multiple rows"
              width="540px"
              height="220px"
              closePopup={closeModalHandler}
              showCloseBtn={false}
            >
              <SingleArticleDelete
                currentRows={currentRowArray}
                onClose={closeModalHandler}
                onConfirm={handleDeleteArticle}
                bulkAction={true}
              />
            </PopupContentWrapper>
          )}
          {modalAction === DEADLINE_CHANGE && (
            <PopupContentWrapper
              title="Change deadline"
              width="450px"
              height="380px"
              closePopup={closeModalHandler}
              showCloseBtn={true}
            >
              <CustomDatePicker
                currentRows={selectedRows}
                updateProperty={DEADLINE}
                handleDateChange={(updatedRows, updatedProperty) =>
                  changeDateHandler(updatedRows, updatedProperty)
                }
                savedDate={null}
                isDeadline
                isPopUp
                onClose={closeModalHandler}
              />
            </PopupContentWrapper>
          )}
          {modalAction === CREATION_CHANGE && (
            <PopupContentWrapper
              title="Change creation date"
              width="450px"
              height="380px"
              closePopup={closeModalHandler}
              showCloseBtn={true}
            >
              <CustomDatePicker
                currentRows={selectedRows}
                updateProperty={CREATED}
                handleDateChange={(updatedRows, updatedProperty) =>
                  changeDateHandler(updatedRows, updatedProperty)
                }
                savedDate={null}
                isPopUp
                onClose={closeModalHandler}
              />
            </PopupContentWrapper>
          )}
          {modalAction === WRITER_ASSIGN && (
            <PopupContentWrapper
              title="Assign writer"
              width="370px"
              height="200px"
              closePopup={closeModalHandler}
              showCloseBtn={true}
            >
              <DynamicOptionsSelect
                isPopup={true}
                rows={selectedRows}
                updateProperty={WRITER}
                action="users"
                data={writers}
                currentUserType={currentUserType}
                defaultSelected={null}
                onSelectChange={handleOptionChange}
              />
            </PopupContentWrapper>
          )}
          {modalAction === PROOFREADER_ASSIGN && (
            <PopupContentWrapper
              title="Assign proofreader"
              width="370px"
              height="200px"
              closePopup={closeModalHandler}
              showCloseBtn={true}
            >
              <DynamicOptionsSelect
                isPopup={true}
                rows={selectedRows}
                updateProperty={PROOFREADER}
                action="users"
                data={proofreaders}
                currentUserType={currentUserType}
                defaultSelected={null}
                onSelectChange={handleOptionChange}
              />
            </PopupContentWrapper>
          )}
          {/* popup for create new article  */}
          {modalAction === CREATE_DASHBOARD_ENTRY && (
            <PopupContentWrapper
              title={selectedRows.length > 0 ? "Edit article" : "New article"}
              width="820px"
              height="700px"
              closePopup={closeModalHandler}
              showCloseBtn={false}
            >
              <CreateNewEntryForm
                writers={writers}
                projects={projects}
                industries={industries}
                editRowEntry={selectedRows}
                onClose={closeModalHandler}
                onCreated={() => setTableIsUpdated(true)}
              />
            </PopupContentWrapper>
          )}
          {/* popup for create new native  */}
          {modalAction === CREATE_DASHBOARD_NATIVE_ENTRY && (
            <PopupContentWrapper
              title={selectedRows.length > 0 ? "Edit native" : "New native"}
              width="820px"
              height={currentUserType === USER_WRITER ? "220px" : "800px"}
              closePopup={closeModalHandler}
              showCloseBtn={false}
            >
              <CreateNewNativeForm
                writers={writers}
                projects={projects}
                industries={industries}
                editRowEntry={selectedRows}
                onClose={closeModalHandler}
                onCreated={() => setTableIsUpdated(true)}
                currentUserType={currentUserType}
              />
            </PopupContentWrapper>
          )}
          {/* popup for create new description  */}
          {modalAction === CREATE_DASHBOARD_DESCRIPTION_ENTRY && (
            <PopupContentWrapper
              title={
                selectedRows.length > 0 ? "Edit description" : "New description"
              }
              width="820px"
              height="700px"
              closePopup={closeModalHandler}
              showCloseBtn={false}
            >
              <CreateNewDescriptionForm
                writers={writers}
                projects={projects}
                industries={industries}
                editRowEntry={selectedRows}
                onClose={closeModalHandler}
                onCreated={() => setTableIsUpdated(true)}
              />
            </PopupContentWrapper>
          )}
          {(modalAction === RENAME_TOPIC || modalAction === EDIT_LIVE_URL) && (
            <PopupContentWrapper
              title={`${
                modalAction === RENAME_TOPIC ? `Rename topic` : `Edit live URL`
              }`}
              width="540px"
              height="270px"
              closePopup={closeModalHandler}
            >
              <RenameTopic
                onClose={closeModalHandler}
                currentRow={selectedRows}
                onRename={handleRenameTopic}
                modalAction={modalAction}
                onUpdateData={() => setTableIsUpdated(true)}
              />
            </PopupContentWrapper>
          )}

          {modalAction === STATUS_CHANGE && (
            <PopupContentWrapper
              title="Change status"
              width="370px"
              height="200px"
              closePopup={closeModalHandler}
              showCloseBtn={true}
            >
              <DynamicOptionsSelect
                isPopup={true}
                rows={selectedRows}
                updateProperty={STATUS}
                action="status"
                data={statuses}
                currentUserType={currentUserType}
                defaultSelected={null}
                onSelectChange={handleOptionChange}
              />
            </PopupContentWrapper>
          )}
        </DashboardPopup>
      </Popup>
      <div className="dashboard">
        <div className="table-wrapper">
          {/* <div className={"dashboard-topbar"}>
            {currentUserType !== USER_PROOFREADER && (
              <div className={"topbar-left"}>
                <div
                  className={`${
                    menuSingleOption ? `dropdown-menu-single` : `dropdown-menu`
                  }`}
                >
                  <Select
                    options={customMenuOptions(currentUserType)}
                    placeholder="Bulk actions"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    onChange={setDropdownBulkActions}
                    value={null}
                  />
                </div>
                <CreateOptionsMenu
                  onSelect={(type) => createNewDashboardEntry(type)}
                />
              </div>
            )}
            <div className={"topbar-right"}>
              {hasAppliedFilters && (
                <div
                  className={"clear-filters-button"}
                  onClick={onRemoveAllFilters}
                >
                  <span className="clear-filters-text">Clear Filters</span>
                  <img
                    src="/images/_remove-icon.svg"
                    width="14px"
                    height="14px"
                    alt="remove"
                  />
                </div>
              )}

              <CustomAsyncSelect
                placeholder="Search..."
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                onStringSearch={(value) => applySearchFilter(value)}
              />
              <CustomCSVUploader onSuccess={() => setTableIsUpdated(true)} />
              <Tooltip
                arrow
                placement="right-start"
                title="Import CSV template file"
              >
                <img
                  style={{ cursor: "pointer " }}
                  src="/images/_download-template.png"
                  width="18px"
                  height="18px"
                  alt="download"
                  onClick={() => window.location.replace(CSV_TEMPLATE_DOWNLOAD)}
                />
              </Tooltip>
            </div>
          </div> */}

          {!loading && (
            <>
              <div className="managementDashboard">
                <PerformanceHeaderBar
                  showLogin={false}
                  title="Management"
                  dashboardType={DASH_MANAGEMENT}
                  currentUserType={currentUserType}
                  setDropdownBulkActions={setDropdownBulkActions}
                  createNewDashboardEntry={createNewDashboardEntry}
                  onStringSearch={(value) => applySearchFilter(value)}
                  onImportCSV={() =>
                    console.log("success importing csv") ||
                    setTableIsUpdated(true)
                  }
                  onExportCSV={onExportCSV}
                  showFilterBtn={true}
                  onOpenFilterPanel={() => setOpenFilterPanel(true)}
                  currentFilters={currentFilters}
                />
                <div className="manag-mainList">
                  <div className="manag-listHead">
                    <div className="titleSelectCard">
                      <Checkbox
                        {...label}
                        checked={selectAll}
                        onChange={selectAllCards}
                        color="default"
                      />
                    </div>
                    <div className="titleType">Type</div>
                    <div className="titleTopic">Topic</div>
                    <div className="titleWriter">
                      <TextWithInfoIcon
                        title="Writer"
                        tooltipText="Full name of writer and writer's aggregated rating"
                        fontSize={13}
                        weight={600}
                        color="#6F767E"
                      />
                    </div>
                    <div className="titleCreated">
                      <p className="text-title-created">Created</p>
                    </div>
                    <div className="titleDeadline">Deadline</div>
                    <div className="titleScore">
                      <TextWithInfoIcon
                        title="Contero Score"
                        tooltipText="The black pill represents the rating of the article provided by the proofreader. The colored number represents the contero score provided by the contero editor"
                        fontSize={13}
                        weight={600}
                        color="#6F767E"
                      />
                    </div>
                    <div className="titleProof">
                      <TextWithInfoIcon
                        title="Proofreader"
                        tooltipText="Full name of proofreader"
                        fontSize={13}
                        weight={600}
                        color="#6F767E"
                      />
                    </div>
                    <div className="titleProject">Project</div>
                    <div className="titleStatus">Status</div>
                  </div>
                </div>
                {tableData.map(
                  (article) =>
                    !noResults && (
                      <ManagementCard
                        key={article.id}
                        rowData={article}
                        handleClickAction={handleOpenArticle}
                        writers={writers}
                        projects={projects}
                        industries={industries}
                        proofreaders={proofreaders}
                        statuses={statuses}
                        currentUserType={currentUserType}
                        handleOptionChange={handleOptionChange}
                        changeDateHandler={changeDateHandler}
                        handleRowAction={dispatchRowAction}
                        onUpdateSelected={updateSelected}
                      />
                    )
                )}
                {totalRowsDisplay !== 0 && (
                  <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    count={totalRowsDisplay || 0}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                )}
              </div>
              {/* <DashboardTable
                onUploadCsv={onExportCSV}
                showUploadIcon={true}
                tableData={tableData}
                tableHeaderData={tableHeaderData}
                changePage={handlePageChange}
                changeRowsPerPage={handleRowsPerPage}
                totalRows={totalRowsDisplay}
                onBulkActions={handleRowSelected}
                selectedRows={selectedRows}
                disabledSelectableRows={disabledCheckboxes}
                selectableRows={
                  currentUserType !== USER_PROOFREADER && !noResults // hide checkboxes when we have no results
                }
                columnFilterData={columnFilterData}
                noResults={noResults}
                hasFilters={(value) => setHasAppliedFilters(value)}
                removeAllFilters={removeAllFilters}
                onFiltersRemoved={onFiltersRemoved}
                //
                onAddedFilters={(filters) => applyColumnFilters(filters)}
                onSorted={(sortedObj) => setSortedBy(sortedObj)}
              /> */}
            </>
          )}
        </div>
        {!loading && (
          <div className="bottom-info">
            <div className="copyright-info">
              &#169; Contero. All rights reserved.
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DasboardComponent;
