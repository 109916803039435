import React, { useState } from "react";
import HeadingsInArticle from "./HeadingsInArticle";

import "./CompetitionItem.css";

const CompetitionItem = ({
  position,
  domain,
  url,
  words = 0,
  keywords = 0,
  visits = 0,
  title,
  description,
  htags,
}) => {
  const [expanded, setExpanded] = useState(false);

  const getCircleColor = (position) => {
    if (position > 8) {
      return "circle-position red";
    }

    if (position > 4) {
      return "circle-position orange";
    }

    if (position > 1) {
      return "circle-position yellow";
    }

    return "circle-position green";
  };

  const showExpandedButton = !expanded && htags !== "N/A" && htags.length > 0;

  return (
    <div className="competition-item">
      <div className="competition-body">
        <div className="item-position">
          <span className={getCircleColor(position)}></span>
          <div className="number-position">{position}</div>
        </div>
        <div className="item-content">
          <div className="item-top">
            <a href={url} target="blank">
              <div className="item-top-stats">
                {domain}{" "}
                <img
                  src="/images/_external.svg"
                  alt="open"
                  width="14px"
                  height="14px"
                />
              </div>
            </a>
            {words !== 0 && (
              <div className="item-top-stats">
                {words.toLocaleString() || ""} words{" "}
              </div>
            )}
            {keywords !== 0 && (
              <div className="item-top-stats">
                {keywords.toLocaleString()} keywords
              </div>
            )}
            {visits !== 0 && (
              <div className="item-top-stats">
                {visits.toLocaleString()} visits
              </div>
            )}
          </div>
          <div className="competition-title">{title}</div>
          <div className="competition-desc">{description}</div>
        </div>
      </div>
      <div className="headings-info">
        {showExpandedButton && (
          <div className="expand-headings" onClick={() => setExpanded(true)}>
            <img
              src="/images/show_icon.svg"
              alt="expand"
              width="15px"
              height="15px"
            />
            <p className="expand-headings-text">SHOW OUTLINE</p>
          </div>
        )}
        {expanded && (
          <>
            <HeadingsInArticle headings={htags} />
            <div className="hide-headings" onClick={() => setExpanded(false)}>
              <img
                src="/images/hide_icon.svg"
                alt="collapse"
                width="15px"
                height="15px"
              />
              <p className="hide-headings-text">HIDE OUTLINE</p>
            </div>
          </>
        )}
      </div>
      <div className="competition-item-divider"></div>
    </div>
  );
};

export default CompetitionItem;
