import {
  getCompanyDetails,
  getInvoiceList,
  saveCompanyDetails,
} from "../api/billing-invoices";
import { getUserToken } from "../utils/userStatus";

export const getInvoices = async () => {
  const token = getUserToken();
  const data = await getInvoiceList(token);

  return data;
};

export const getCompanyDetailsReq = async () => {
  const token = getUserToken();
  const data = await getCompanyDetails(token);

  return data;
};

export const saveCompanyDetailsReq = async (payload) => {
  const token = getUserToken();

  const data = await saveCompanyDetails(token, payload);

  return data;
};
