import React, {
  useReducer,
  useEffect,
  useCallback,
  useState,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Input from "../Shared/Input";
import Button from "../Shared/Button";
import { PROJECT, TYPE_DESCRIPTION } from "../common/consts";

import { VALIDATOR_MINLENGTH } from "../utils/validators";
import { formReducer } from "../utils/formReducer";
import {
  searchTopic,
  searchTopicForCategory,
  getCompetitionOnCategory,
} from "../reducers/searchTopic";
import Select, { components } from "react-select";

import { resetEditorData } from "../reducers/textEditor";

import { onCreateNewArticle, requestArticleById } from "../reducers/articles";

import "./SearchTopicComponent.css";
import { convertOptionsData } from "./PopupContent/CreateNewEntryForm";
import { dashboardUserProjects } from "../reducers/dashboard";
import { FlagGB, FlagRO, FlagUS } from "../UI_utils/FlagIcons";
import { getFeatureFlags } from "../reducers/featureFlags";

const articleTitle = "What do you want to write about today?";
const categoryTitle = "What category do you want to optimize?";

const displayTitle = (isArticle) => {
  const title = isArticle ? articleTitle : categoryTitle;

  return <p className="popup-title-search">{title}</p>;
};

export const countries = [
  { value: "RO", label: "RO", icon: <FlagRO width={20} /> },
  { value: "GB", label: "GB", icon: <FlagGB width={20} /> },
  { value: "US", label: "US", icon: <FlagUS width={20} /> },
];

const displayContent = (isArticle) => {
  if (isArticle) {
    return (
      <>
        <p>
          Broad Topics (Eg. "ulei de cocos") are the ones that enable you to
          reach the widest audience possible, letting you drive a larger volume
          of traffic to your website.
        </p>
        <p>
          Even though niched subtopics (eg. "beneficii ulei cocos pentru par")
          have a lower search volume than broad keywords but they are usually
          less competitive, which makes them easier to rank for.
        </p>
      </>
    );
  } else {
    return (
      <>
        <p>
          Recomandam inserarea pentru fiecare categorie a denumirii categoriei
          plus a 2-3 derivate cel putin la singular, articulat, plural, samd in
          combinatie cu expresiile oferite.
        </p>
        <p>
          Accesati intotdeauna inainte de a redacta descrierea, categoria de
          produse si uitati-va la gama de produse pentru a va face o imagine ce
          tip de produse sunt comercializate si a reflecta acest lucru in cadrul
          descrierii.
        </p>
        <p>
          Putem sa folosim cuvinte de legatura intre cuvintele din cadrul
          expresiilor astfel incat exprimarile din descriere sa sune natural.
        </p>
      </>
    );
  }
};

export const flagSelector = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: "transparent",
    border: "none",
    boxShadow: 0,
    color: "red",
    "&:hover": {
      borderColor: "#dbdbdb",
    },
  }),
  option: (styles, { isSelected }) => {
    return {
      ...styles,
      fontWeight: 600,
      fontSize: "14px",
      borderRadius: "4px",

      "&:hover": {
        color: isSelected ? "#6C5DD3" : "#6C5DD3",
      },
      backgroundColor: "white",
      color: "#6F767E",
    };
  },
  menu: (styles) => ({
    ...styles,
    borderRadius: "5px",
    marginTop: 3,
    borderColor: "#EFEFEF",
    borderWidth: "1px",
    boxShadow: "0 0 0 2px #dbdbdb",
  }),
  input: (styles) => ({
    ...styles,
    width: "30px",
  }),
  placeholder: (styles) => ({ ...styles, color: "red" }),
  singleValue: (base) => ({
    ...base,
    display: "flex",
    alignItems: "center",
  }),
};

const SearchTopicComponent = ({ nexPage, onClose, appMode }) => {
  const dispatchTopic = useDispatch();
  const { showTopicLanguages } = useSelector(getFeatureFlags);
  const history = useHistory();
  const projects = useSelector(dashboardUserProjects);
  const isArticle = appMode === "article" || appMode === "native";
  const searchTopicInput = useRef(null);
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const [formState, dispatch] = useReducer(formReducer, {
    inputs: {
      topic: {
        value: "",
        isValid: false,
      },
      project: {
        value: "",
        isValid: false,
      },
      topicUrl: {
        value: "",
        isValid: false,
      },
    },
    isValid: false,
  });

  const handleSelectChange = (id, values) => {
    dispatch({
      type: "INPUT_CHANGE",
      value: values.value || values,
      isValid: true,
      inputId: id,
    });
  };

  useEffect(() => {
    // focus the search field input on mount
    searchTopicInput.current.focus();
  }, []);

  const inputHandler = useCallback((id, value, isValid) => {
    dispatch({
      type: "INPUT_CHANGE",
      value: value,
      isValid: isValid,
      inputId: id,
    });
  }, []);

  const searchSubmitHandler = async (event) => {
    event.preventDefault();
    const {
      inputs: {
        topic: { value },
        project: { value: projectId },
      },
    } = formState;

    if (!isArticle) {
      dispatchTopic(searchTopicForCategory(value, selectedCountry.value));
      dispatchTopic(getCompetitionOnCategory(value, selectedCountry.value));

      try {
        const articleId = await dispatchTopic(
          onCreateNewArticle(TYPE_DESCRIPTION)
        );

        dispatchTopic(requestArticleById(articleId));

        history.push(`/eshop/${articleId}`);
      } catch (error) {
        console.log({ error });
      }
      // close modal and clean the editor data when searching for a new category topic
      onClose();
      dispatchTopic(resetEditorData(true));
    } else {
      dispatchTopic(searchTopic(value, projectId, selectedCountry.value));
    }
    // go to next page in modal
    nexPage();
  };

  const Option = (props) => (
    <components.Option {...props} className="country-option">
      <div className="country-option-select">
        <span className="country-option-icon">{props.data.icon}</span>
        <span>{props.data.label}</span>
      </div>
    </components.Option>
  );

  const handleChange = (value) => {
    setSelectedCountry(value);
  };

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      {selectedCountry.icon}
    </components.SingleValue>
  );

  return (
    <div className="search-component">
      <div className="search-topic">
        {displayTitle(isArticle)}
        <form className="search-topic-form" onSubmit={searchSubmitHandler}>
          <div style={{ position: "relative" }}>
            <Input
              // send ref as prop to child component
              refChild={searchTopicInput}
              id="topic"
              element="input"
              type="text"
              placeholder={`${
                isArticle ? "Enter topic here" : "Enter category name here"
              }`}
              validators={[VALIDATOR_MINLENGTH(3)]}
              errorText="Topic must have at least 3 characters"
              onInput={inputHandler}
            />
            {showTopicLanguages && (
              <div style={{ position: "absolute", top: 0, right: 0 }}>
                <Select
                  value={selectedCountry}
                  options={countries}
                  onChange={handleChange}
                  components={{
                    Option,
                    SingleValue,
                  }}
                  styles={flagSelector}
                />
              </div>
            )}
          </div>

          <Select
            options={convertOptionsData(projects)}
            placeholder="Select project"
            className="react-select-container"
            classNamePrefix="react-select required"
            onChange={(values) => handleSelectChange(PROJECT, values)}
          />

          {/* {!isArticle && (
            <Input
              // input for topic url, only used in article
              id="topicUrl"
              element="input"
              type="text"
              placeholder="Enter category URL here"
              validators={[VALIDATOR_MINLENGTH(5)]}
              errorText="Please enter a topic url."
              onInput={inputHandler}
              noValidation
            />
          )} */}
          <Button
            type="submit"
            fullSize
            disabled={
              !formState.inputs.topic.isValid ||
              !formState.inputs.project.isValid
            }
          >
            {`${isArticle ? "Search topic data" : "Let's go"}`}
          </Button>
        </form>
        <div className="search-hints">{displayContent(isArticle)}</div>
      </div>
      <img
        src="/images/_search-topic.png"
        alt="Search"
        width="314px"
        height="443px"
      />
    </div>
  );
};

export default SearchTopicComponent;
