import React from 'react';
import ResetComponent from '../Components/ResetComponent';

import './ResetPassword.css';

const ResetPassword = () => {
  return (
    <div className='reset-pass-page'>
      <ResetComponent />
    </div>
  );
};

export default ResetPassword;