import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CustomAsyncSelect from "../CustomAsyncSelect";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

import {
  getProjectsDashboardData,
  dashboardProjectsData,
  dashboardTotalRows,
  dashboardIndustries,
  dashboardFeedback,
  dashboardStatuses,
  dashboardProjects,
  dashboardWriters,
  dashboardProofreaders,
  updateDropdownOptions,
  dashboardNoDataResults,
  dashboardUserProjects,
} from "../../reducers/dashboard";

import {
  updateTableInLocalState,
  customSortedTable,
} from "../dashboardComponentHelpers";
import {
  USER_WRITER,
  USER_PROOFREADER,
  CLIENT_BRIEF_PATH,
  ADD_NEW_PROJECT,
  MANAGE_PROJECT_MEMBERS,
  EDIT_MEMBER_ROLE,
  USER_MASTER,
  USER_CLIENT,
} from "../../common/consts";

import { activeUser } from "../../reducers/user";

import "./ProjectsDashboardComponent.css";
import "./ProjectsBriefDashboard.css";

import PerformanceHeaderBar from "../PerformanceDashboard/PerformanceHeaderBar";
import { Checkbox, TablePagination } from "@material-ui/core";

import ProjectBriefCard from "./ProjectBriefCard";
import { setArticleId } from "../../reducers/articles";
import { setKeywordsSearch, setTopicData } from "../../reducers/searchTopic";
import { setTopicCoverageData } from "../../reducers/topicCoverage";
import { resetEditorData, setEditorData } from "../../reducers/textEditor";
import { LogoutIcon } from "../../UI_utils/SVGIcons";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import DashboardTopBar from "../DashboardsTopBar/DashboardTopBar";
import BillingComponent from "./BillingComponent";
import MembersComponent from "./MembersComponent";
import Popup from "reactjs-popup";
import DashboardPopup from "../PopupContent/DashboardPopup";
import PopupContentWrapper from "../PopupContent/PopupContentWrapper";
import Options from "../Options";
import { addNewProjectReq, removeProjectReq } from "../../services/dashboard";
import AddNewProject from "./AddNewProject";
import { identity } from "lodash";
import ManageProjectMembers from "./ManageProjectMembers";
import { getAllMembersList } from "../../services/project-members";
import {
  getCompanyDetailsReq,
  getInvoices,
} from "../../services/invoice-billing";
import EditMemberRole from "./EditMemberRole";

const ProjectsDasboardComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const dashboard = useSelector(dashboardProjectsData);
  const projects = useSelector(dashboardProjects);
  const userProjects = useSelector(dashboardUserProjects);
  const feedbacks = useSelector(dashboardFeedback);
  const statuses = useSelector(dashboardStatuses);
  const industries = useSelector(dashboardIndustries);
  const writers = useSelector(dashboardWriters);
  const proofreaders = useSelector(dashboardProofreaders);
  const totalRows = useSelector(dashboardTotalRows);
  const currentUser = useSelector(activeUser);

  const noResults = useSelector(dashboardNoDataResults);

  const [tableData, setTableData] = useState([]);
  const [tableHeaderData, setTableHeaderData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentUserType, setCurrentUserType] = useState("");
  const [totalRowsDisplay, setTotalRowsDisplay] = useState(totalRows);
  const [selectAll, setSelectAll] = useState(false);

  // filters
  const [currentFilters, setCurrentFilters] = useState(null);
  const [searchString, setSearchString] = useState(null);
  const [hasAppliedFilters, setHasAppliedFilters] = useState(false);
  const [removeAllFilters, setRemoveAllFilters] = useState(false);
  const [modalAction, setModalAction] = useState();
  const [refetchTable, setRefetchTable] = useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const [selectedProjectId, setSelectedProjectId] = useState(null);

  const [sortedBy, setSortedBy] = useState(null);

  const [allMembers, setAllMembers] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [defaultValues, setDefaultValues] = useState();
  const [editMember, setEditMember] = useState();

  // clean up all article related data when loading this page
  useEffect(() => {
    dispatch(setArticleId(null));
    dispatch(setTopicData(null));
    //   // reset topic coverage data when coming from eshop
    dispatch(setTopicCoverageData([]));
    //   // reset editor data to empty
    // dispatch(resetEditorData(true));
    dispatch(setKeywordsSearch(null));
  }, []);

  // popup
  const [showModal, setShowModal] = useState(false);
  const [, setTableIsUpdated] = useState(false);

  const closeModalHandler = () => {
    // reset selected rows when modal is closing
    setSelectedRows([]);
    setShowModal(false);
  };
  // const openModalHandler = () => setShowModal(true);

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };

  useEffect(() => {
    dispatch(
      getProjectsDashboardData(
        currentPage,
        rowsPerPage,
        searchString,
        currentFilters,
        sortedBy
      )
    );
  }, [
    currentPage,
    rowsPerPage,
    searchString,
    currentFilters,
    sortedBy,
    refetchTable,
  ]);

  useEffect(() => {
    getMembersList();
    fetchInvoiceData();
    fetchCompanyData();
  }, []);

  const getMembersList = async () => {
    const members = await getAllMembersList();
    setAllMembers(members);
  };

  const fetchInvoiceData = async () => {
    const data = await getInvoices();
    if (data) {
      setInvoiceData(data);
    }
  };

  const fetchCompanyData = async () => {
    const data = await getCompanyDetailsReq();

    if (data) {
      setDefaultValues(data);
    }
  };

  useEffect(() => {
    if (dashboard) {
      setTableData(dashboard);
      setTableIsUpdated(false);
    }
  }, [dashboard]);

  useEffect(() => {
    if (currentUser) {
      setCurrentUserType(currentUser.role[0]);
    }
  }, [currentUser]);

  const editProjectBrief = (id, completed) => {
    const isNew = completed === 0;

    history.push({
      pathname: `${CLIENT_BRIEF_PATH}/${id}`,
      search: null,
      state: { new: isNew },
    });
  };

  const deleteProjectBrief = async (id) => {
    const result = await removeProjectReq(id);
    if (result) {
      closeModalHandler();
      setRefetchTable(true);
    }

    setRefetchTable(false);
  };

  const onCreateNewProject = () => {
    setShowModal(true);
    setModalAction(ADD_NEW_PROJECT);
  };

  const handleOptionChange = async (
    updatedRows,
    actionType,
    updatedProperty
  ) => {
    // send new data to backend
    const response = await dispatch(
      updateDropdownOptions(actionType, updatedRows, updatedProperty)
    );

    const isUpdated = updatedRows.every(
      ({ id }) => !response.error && response.includes(id)
    );
    // update local state
    if (isUpdated) {
      const updatedTable = updateTableInLocalState(tableData, updatedRows);
      const sorted = customSortedTable(updatedTable);
      setTableData(sorted);
    }
    if (showModal) {
      // close modal if it's open from bulk action
      closeModalHandler();
    }
  };

  useEffect(() => {
    setTotalRowsDisplay(totalRows);
    if (!totalRows) {
      setLoading(true);
    } else {
      setLoading(false);
    }
    if (totalRows === 0) {
      setLoading(false);
    }
  }, [totalRows]);

  const handleRowsPerPage = (rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const disabledCheckboxes = (row) => {
    if (
      currentUserType === USER_PROOFREADER ||
      (currentUserType === USER_WRITER && row.master)
    ) {
      return true;
    }

    return false;
  };

  const onRemoveAllFilters = () => {
    setRemoveAllFilters(true);
  };

  const onFiltersRemoved = () => {
    setRemoveAllFilters(false);
  };

  const columnFilterData = {
    projects,
    statuses,
    industries,
    writers,
    proofreaders,
  };

  const label = { inputProps: { "aria-label": "Checkbox" } };
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const selectAllCards = () => {
    setSelectAll(!selectAll);
  };

  const updateSelected = (id, value) => {
    const updateListById =
      tableData &&
      tableData.map((item) =>
        item.id === id ? { ...item, selected: value } : { ...item }
      );

    setTableData(updateListById);
  };

  useEffect(() => {
    const updateListWithSelected =
      tableData &&
      tableData.map((item) => ({
        ...item,
        selected: selectAll,
      }));
    setTableData(updateListWithSelected);
  }, [selectAll]);

  const logOutHandler = () => {
    localStorage.removeItem("userToken");
    window.location.replace("./login");
  };

  const handleTabChange = (event, newValue) => {
    console.log({ newValue });
    setTabValue(newValue);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  };

  const useButtonStyles = makeStyles(() =>
    createStyles({
      button: {
        margin: 2,
        borderRadius: "10px",
        textTransform: "none",
        backgroundColor: "#6870f6",
        minWidth: "90px",
        minHeight: "20px",
        fontWeight: "bold",
        color: "white",
        "&:hover": {
          backgroundColor: "#7e86f7",
        },
      },
    })
  );

  const classes = useButtonStyles();

  const addNewProject = async (currentValue) => {
    const result = await addNewProjectReq(currentValue);
    if (result) {
      closeModalHandler();
      setRefetchTable(true);
    }

    setRefetchTable(false);
  };

  const manageProjectMembers = (id) => {
    setSelectedProjectId(id);
    setShowModal(true);
    setModalAction(MANAGE_PROJECT_MEMBERS);
  };

  const onEditMemberRole = (selectedMember) => {
    setEditMember(selectedMember);
    setShowModal(true);
    setModalAction(EDIT_MEMBER_ROLE);
  };

  return (
    <>
      <Popup
        open={showModal}
        closeOnDocumentClick={false}
        onClose={closeModalHandler}
      >
        <DashboardPopup>
          {modalAction === ADD_NEW_PROJECT && (
            <PopupContentWrapper
              title="Add new project"
              width="540px"
              height="220px"
              closePopup={closeModalHandler}
              showCloseBtn={false}
            >
              <AddNewProject onAddNewProject={addNewProject} />
            </PopupContentWrapper>
          )}
          {modalAction === MANAGE_PROJECT_MEMBERS && (
            <PopupContentWrapper
              title="Manage members"
              width="540px"
              height="220px"
              closePopup={closeModalHandler}
              showCloseBtn={false}
            >
              <ManageProjectMembers
                projectId={selectedProjectId}
                onUsersAssigned={() => closeModalHandler()}
              />
            </PopupContentWrapper>
          )}
          {modalAction === EDIT_MEMBER_ROLE && (
            <PopupContentWrapper
              title="Edit member role"
              width="540px"
              height="220px"
              closePopup={closeModalHandler}
              showCloseBtn={false}
            >
              <EditMemberRole
                defaultSelected={editMember}
                onRefetch={getMembersList}
                onClose={closeModalHandler}
              />
            </PopupContentWrapper>
          )}
        </DashboardPopup>
      </Popup>
      {/* <div>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Item One" />
          <Tab label="Item Two" />
          <Tab label="Item Three" />
        </Tabs>
      </div>
      <TabPanel value={value} index={0}>
        <div className="brief-mainList">
          <div className="brief-listHead">
            <div className="titleSelectCard">
              <Checkbox
                {...label}
                checked={selectAll}
                onChange={selectAllCards}
                color="default"
              />
            </div>
            <div className="titleProject">Project</div>
            <div className="titleUrl">URL</div>
            <div className="titleIndustry1">Main Industry</div>
            <div className="titleIndustry2">Secondary Industry</div>
            <div className="titleCompletion">Brief Completion</div>
            <div className="titleAction">Action</div>
          </div>
        </div>
        {tableData.map((brief) => (
          <ProjectBriefCard
            key={brief.id}
            data={brief}
            industries={industries}
            onOpenProject={editProjectBrief}
            handleOptionChange={handleOptionChange}
            onUpdateSelected={updateSelected}
          />
        ))}
        {totalRowsDisplay && (
          <TablePagination
            component="div"
            rowsPerPageOptions={[5, 10, 15, 20]}
            count={totalRowsDisplay}
            page={currentPage}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel> */}
      {/* stuff from here  */}
      <div className="dashboard">
        <div className="table-wrapper">
          {/* <div className={"projects-dashboard-topbar"}>
            <div className={"topbar-right"}>
              {hasAppliedFilters && (
                <div
                  className={"clear-filters-button"}
                  onClick={onRemoveAllFilters}
                >
                  <span className="clear-filters-text">Clear Filters</span>
                  <img
                    src="/images/_remove-icon.svg"
                    width="14px"
                    height="14px"
                    alt="remove"
                  />
                </div>
              )}
            </div>
          </div> */}

          {!loading && (
            <>
              <div className="briefDashboard">
                <DashboardTopBar>
                  <Tabs value={tabValue} onChange={handleTabChange}>
                    <Tab label="Projects" />
                    <Tab label="Team Members" />
                    <Tab label="Billing & Invoices" />
                  </Tabs>
                  <div className="topbar-right">
                    {/* display add new btn only for projects tab  */}
                    {tabValue === 0 && (
                      <Button
                        aria-controls="simple-menu"
                        onClick={onCreateNewProject}
                        className={classes.button}
                      >
                        + Add New
                      </Button>
                    )}
                    {tabValue === 0 && (
                      <div className="header-search-bar">
                        <CustomAsyncSelect
                          placeholder="Search..."
                          onStringSearch={(value) => setSearchString(value)}
                        />
                      </div>
                    )}
                  </div>
                </DashboardTopBar>
                <TabPanel value={tabValue} index={0}>
                  <div className="brief-mainList">
                    <div className="brief-listHead">
                      <div className="titleSelectCard">
                        <Checkbox
                          {...label}
                          checked={selectAll}
                          onChange={selectAllCards}
                          color="default"
                        />
                      </div>
                      <div className="titleProject">Project</div>
                      <div className="titleIndustry1">Main Industry</div>
                      <div className="titleIndustry2">Team Members</div>
                      <div className="titleCompletion">Brief Completion</div>
                      <div className="titleAction">Action</div>
                    </div>
                  </div>
                  {tableData.map((brief) => (
                    <ProjectBriefCard
                      key={brief.id}
                      data={brief}
                      industries={industries}
                      onOpenProject={editProjectBrief}
                      onDeleteProject={deleteProjectBrief}
                      handleOptionChange={handleOptionChange}
                      onUpdateSelected={updateSelected}
                      onManageMembers={manageProjectMembers}
                    />
                  ))}
                  {totalRowsDisplay && (
                    <TablePagination
                      component="div"
                      rowsPerPageOptions={[5, 10, 15, 20]}
                      count={totalRowsDisplay}
                      page={currentPage}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  )}
                </TabPanel>
                {/* only master and client users are able to see these tabs */}
                {currentUserType === USER_MASTER ||
                  (currentUserType === USER_CLIENT && (
                    <>
                      <TabPanel value={tabValue} index={1}>
                        <MembersComponent
                          projects={userProjects}
                          allMembers={allMembers}
                          onRefetch={getMembersList}
                          editRole={onEditMemberRole}
                        />
                      </TabPanel>
                      <TabPanel value={tabValue} index={2}>
                        <BillingComponent
                          defaultValues={defaultValues}
                          invoiceData={invoiceData}
                        />
                      </TabPanel>
                    </>
                  ))}
              </div>
            </>
          )}
        </div>
        <div className="bottom-info">
          <div className="copyright-info">
            &#169; Contero. All rights reserved.
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectsDasboardComponent;
