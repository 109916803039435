import axios from "axios";
import { BASE_URL } from "../common/consts";

const baseEndpoint = "api/articles";
const aiEndpoint = "ask-ai";

export const getAIResponse = (payload, token) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${aiEndpoint}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));
