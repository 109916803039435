import { useEffect, useState } from "react";
import PackedVersion from "../../package.json";

const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

const useCacheBuster = () => {
  const [forceRefresh, setForceRefresh] = useState();

  useEffect(() => {
    getMetaDataVersion();
  }, []);

  const getMetaDataVersion = async () => {
    const result = await fetch("/metadata.json");

    const metaVersion = await result.json();

    const shouldForceRefresh = semverGreaterThan(
      metaVersion.version,
      PackedVersion.version
    );
    if (shouldForceRefresh) {
      setForceRefresh(true);
      console.log(
        `We have a new version - ${metaVersion.version}. Should force refresh`
      );
    } else {
      setForceRefresh(false);
      console.log(
        `You already have the latest version - ${metaVersion.version}. No cache refresh needed.`
      );
    }
  };
  return { forceRefresh: forceRefresh || false };
};

export default useCacheBuster;
