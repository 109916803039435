import React from "react";
import Input from "../../Shared/Input";
import { VALIDATOR_EMAIL, VALIDATOR_MINLENGTH } from "../../utils/validators";

import "./MultistepRegisterComponent.css";

const StepOneInputs = ({ onInputChange }) => {
  return (
    <>
      <div className="multistep-register-row">
        <div className="row-input">
          <Input
            id="firstName"
            element="input"
            type="text"
            label="Firstname"
            validators={[VALIDATOR_MINLENGTH(3)]}
            errorText="Must be longer than 3 characters."
            onInput={onInputChange}
            collapsed={true}
          />
        </div>
        <div className="row-input">
          <Input
            id="lastName"
            element="input"
            type="text"
            label="Lastname"
            validators={[VALIDATOR_MINLENGTH(3)]}
            errorText="Must be longer than 3 characters."
            onInput={onInputChange}
            collapsed={true}
          />
        </div>
      </div>
      <Input
        id="email"
        element="input"
        type="text"
        label="Email"
        validators={[VALIDATOR_EMAIL()]}
        errorText="Please enter a valid email."
        onInput={onInputChange}
      />
      <Input
        id="websiteUrl"
        element="input"
        type="text"
        label="Website"
        validators={[VALIDATOR_MINLENGTH(7)]}
        errorText="Must be longer than 7 characters."
        onInput={onInputChange}
      />
      <Input
        id="password"
        element="input"
        type="password"
        label="Password"
        validators={[VALIDATOR_MINLENGTH(5)]}
        errorText="Must be longer than 5 characters."
        onInput={onInputChange}
      />
    </>
  );
};

export default StepOneInputs;
