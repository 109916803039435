import React from "react";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";

import "./ResultsCompetitionBox.css";

const ResultsCompetitionBox = ({ data }) => {
  return (
    <>
      <Box className="competition-box">
        <div
          className="competition-box-image"
          style={{ border: `solid 3px ${data?.color_hex}` }}
        >
          <img src={data?.logo} alt="logo" width="40px" height="auto" />
        </div>
        <div className="competition-domain">{data?.name}</div>
        <Paper className="competition-detail-box" elevation={0}>
          <p className="detail-box-value">{`${data?.visibility}K`}</p>
          <p className="detail-box-desc">ESTIMATED VISITS</p>
        </Paper>
      </Box>
    </>
  );
};

export default ResultsCompetitionBox;
