import { createStyles, makeStyles } from "@material-ui/styles";
import React from "react";
import Select from "react-select";
import {
  CSV_TEMPLATE_DOWNLOAD,
  DASH_BRIEFS,
  DASH_MANAGEMENT,
  USER_PROOFREADER,
  USER_WRITER,
} from "../../common/consts";
import { DownloadSampleIcon, ExportIcon } from "../../UI_utils/SVGIcons";
import CreateOptionsMenu from "../CreateOptionsMenu";
import CustomAsyncSelect from "../CustomAsyncSelect";
import {
  customMenuOptions,
  userHasPermission,
} from "../dashboardComponentHelpers";
import GoogleLoginButton from "../GoogleAuth/GoogleLogin";
import { BlackTooltip } from "../UITooltips/UITooltip";

import "./PerformanceHeaderBar.css";

const dataOptions = [
  { value: 7, label: "Last 7 Days" },
  { value: 28, label: "Last 28 Days" },
];

const colourStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: "white",
    borderRadius: "10px",
    borderColor: state.isFocused ? "#dbdbdb" : "#EFEFEF",
    borderWidth: "2px",
    boxShadow: 0,
    color: "red",
    "&:hover": {
      borderColor: "#dbdbdb",
    },
  }),

  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      fontWeight: 600,
      fontSize: "14px",
      borderRadius: "4px",

      "&:hover": {
        color: isSelected ? "#6C5DD3" : "#6C5DD3",
      },

      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "white"
        : isFocused
        ? "white"
        : undefined,
      color: "#6F767E",
      // color: isDisabled
      //   ? "#ccc"
      //   : isSelected
      //   ? "#6F767E"
      //     ? "#6F767E"
      //     : "#6F767E"
      //   : "#6F767E",
    };
  },
  menu: (styles) => ({
    ...styles,
    borderRadius: "10px",
    marginTop: 3,
    borderColor: "#EFEFEF",
    borderWidth: "2px",
    boxShadow: "0 0 0 2px #dbdbdb",
  }),
  input: (styles) => ({
    ...styles,
    width: "120px",
    innerHeight: "40px",
  }),
  placeholder: (styles) => ({ ...styles, color: "red" }),
  singleValue: (styles) => ({
    ...styles,
    color: "#6F767E",
    fontWeight: 600,
    fontSize: "14px",
  }),
};

const PerformanceHeaderBar = ({
  title,
  onSelectDateRange,
  showLogin,
  dashboardType,
  currentUserType,
  setDropdownBulkActions,
  createNewDashboardEntry,
  onStringSearch,
  onImportCSV,
  onExportCSV,
  showFilterBtn,
  onOpenFilterPanel,
  currentFilters,
}) => {
  const onChangeInterval = (value) => {
    onSelectDateRange(value);
  };

  const menuSingleOption =
    userHasPermission(currentUserType, USER_PROOFREADER) ||
    userHasPermission(currentUserType, USER_WRITER);

  const useButtonStyles = makeStyles(() =>
    createStyles({
      button: {
        margin: 2,
        borderRadius: "10px",
        textTransform: "none",
        backgroundColor: "white",
        border: "2px solid #75819B",
        minWidth: "90px",
        minHeight: "20px",
        fontWeight: "bold",
        color: "#75819B",
        outline: "none",
        cursor: "pointer",
        "&:hover": {
          border: "2px solid #ccc",
          // backgroundColor: "#f4f4f4",
        },
      },
    })
  );

  const classes = useButtonStyles();

  const totalFilters =
    currentFilters && Object.values(currentFilters).map((item) => item).length;

  return (
    <div className={`performance-header`}>
      <div className="performance-left-side">
        <div className="performancebar-title">
          <span className="title-tag"></span>
          <div className="perf-title">{title}</div>
        </div>
        {dashboardType === DASH_MANAGEMENT && (
          <div className="header-list-bar">
            {currentUserType !== USER_PROOFREADER && (
              <div className={"topbar-left"}>
                <div
                  className={`${
                    menuSingleOption ? `dropdown-menu-single` : `dropdown-menu`
                  }`}
                >
                  <Select
                    options={customMenuOptions(currentUserType)}
                    placeholder="Bulk actions"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    onChange={setDropdownBulkActions}
                    value={null}
                  />
                </div>

                {showFilterBtn && (
                  <button
                    onClick={onOpenFilterPanel}
                    className={classes.button}
                  >
                    {`Filters ${totalFilters ? `(${totalFilters})` : ``}`}{" "}
                  </button>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      {(dashboardType === DASH_MANAGEMENT || dashboardType === DASH_BRIEFS) && (
        <div className="performance-right-side">
          <CreateOptionsMenu
            onSelect={(type) => createNewDashboardEntry(type)}
            onImportCSV={onImportCSV}
          />
          <div className="header-search-bar">
            <CustomAsyncSelect
              placeholder="Search..."
              onStringSearch={onStringSearch}
            />
          </div>
          {dashboardType === DASH_MANAGEMENT && (
            <>
              <BlackTooltip
                arrow
                placement="top"
                title={"Export dashboard data"}
              >
                <div className="performance-action-icon" onClick={onExportCSV}>
                  <ExportIcon />
                </div>
              </BlackTooltip>
              <BlackTooltip
                arrow
                placement="top"
                title={"Download CSV import file"}
              >
                <div
                  className="performance-action-icon"
                  onClick={() => window.location.replace(CSV_TEMPLATE_DOWNLOAD)}
                >
                  <DownloadSampleIcon />
                </div>
              </BlackTooltip>
            </>
          )}
        </div>
      )}

      {dashboardType !== DASH_MANAGEMENT && dashboardType !== DASH_BRIEFS && (
        <div className="interval-select">
          {showLogin && (
            <div className="search-console-btn">
              <GoogleLoginButton />
            </div>
          )}
          <Select
            defaultValue={dataOptions[1]}
            options={dataOptions}
            styles={colourStyles}
            onChange={onChangeInterval}
          />
        </div>
      )}
    </div>
  );
};

export default PerformanceHeaderBar;
