import { createSlice } from "@reduxjs/toolkit";

export const headingsOptimizationSlice = createSlice({
  name: "headingsoptimization",
  initialState: {
    allheadings: 0,
    usedHeadings: 0,
  },
  reducers: {
    setAllHeadings: (state, { payload }) => {
      return {
        ...state,
        allheadings: payload,
      };
    },

    setUsedHeadings: (state, { payload }) => {
      return {
        ...state,
        usedHeadings: payload,
      };
    },
  },
});

// actions
export const { setAllHeadings } = headingsOptimizationSlice.actions;
export const { setUsedHeadings } = headingsOptimizationSlice.actions;
export const { setHeadingsPerc } = headingsOptimizationSlice.actions;

export const setHeadingsForOptimization = (topicCoverage) => (dispatch) => {
  // get original topic coverage here
  // check if topicCoverage exists, otherwise we have an error
  if (topicCoverage) {
    const headings = topicCoverage.filter(({ heading }) => heading);

    // check if user has marked as junk some headers and remove it from the count
    const totalHeadingsAfterMarked = headings.filter(
      ({ ignoredByUser }) => !ignoredByUser
    );

    dispatch(setAllHeadings(totalHeadingsAfterMarked.length));
  }
};

// this action is dispatch in TextEditor component, onChange
export const checkHeadingsOptimization = () => (dispatch, getState) => {
  // get processed topic coverage here, so we can filter for usedInHeading
  // check the headings only if high topics are active
  const { activeTopicCoverage, showLowTopics } = getState().topiccoverage;

  // filter usedHeadings even if keyword has `use_in_title`
  if (!showLowTopics) {
    // take into account the headings which are markedByUser
    const usedHeadings = activeTopicCoverage.filter(
      ({ usedInHeading, markedByUser, heading }) =>
        usedInHeading || (markedByUser && heading)
    );

    // remove form the count marked as junk headings
    const headingsWithoutJunk = usedHeadings?.filter(
      ({ ignoredByUser }) => !ignoredByUser
    );

    dispatch(setUsedHeadings(headingsWithoutJunk.length));
  }
};

// selectors
export const getAllHeadings = ({ headingsoptimization: { allheadings } }) =>
  allheadings;

export const getUsedHeadings = ({ headingsoptimization: { usedHeadings } }) =>
  usedHeadings;

export const getHeadingsPerc = ({
  headingsoptimization: { usedHeadings, allheadings },
}) => (usedHeadings * 100) / allheadings || 0;

export default headingsOptimizationSlice.reducer;
