import React from "react";

import "./LoaderAnimation.css";

const LoaderAnimation = ({ content }) => {
  return (
    <div className="loader-animation">
      <img src="/images/_loading.svg" alt="loading" />
      <p className="loading-text">{content}</p>
    </div>
  );
};

export default LoaderAnimation;
