import { createSlice } from "@reduxjs/toolkit";
import { requestUserProfile, requestAllMasters } from "../services/user";
import { setApiError } from "./apiErrors";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    activeUser: null,
    masterUsers: null,
  },
  reducers: {
    setActiveUser: (state, { payload }) => {
      return {
        ...state,
        activeUser: payload,
      };
    },
    setMasterUsers: (state, { payload }) => {
      return {
        ...state,
        masterUsers: payload,
      };
    },
  },
});

export const { setActiveUser } = userSlice.actions;
export const { setMasterUsers } = userSlice.actions;

export const getUserProfile = (token) => async (dispatch) => {
  try {
    const user = await requestUserProfile(token);
    dispatch(setActiveUser(user));
  } catch (error) {
    dispatch(setApiError(error));
  }
};

export const getMasterUsers = (token) => async (dispatch) => {
  try {
    const masters = await requestAllMasters(token);

    dispatch(setMasterUsers(masters));
  } catch (error) {
    dispatch(setApiError(error));
  }
};

export const activeUser = ({ user: { activeUser } }) => activeUser;
export const allMasterUsers = ({ user: { masterUsers } }) => masterUsers;

export default userSlice.reducer;
