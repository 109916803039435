import React from "react";
import remixiconUrl from "./font-icons-remix/remixicon.symbol.svg";

const CustomSvgIcon = ({ icon }) => {
  return (
    <svg className="remix">
      <use xlinkHref={`${remixiconUrl}#ri-${icon}`} />
    </svg>
  );
};

export default CustomSvgIcon;
