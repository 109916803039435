import React, { useReducer, useCallback, useState, useEffect } from "react";

import Input from "../../Shared/Input";
import Button from "../../Shared/Button";
import { formReducer } from "../../utils/formReducer";
import { saveTechnicalFeedback } from "../../services/articles";
// temporary fetch
import { getProjectBriefData } from "../../services/projects";
import FormSuccessMessage from "../FormSuccessMessage";
import UsefulLinks from "./UsefulLinks";
import TitleWithDescription from "./TitleWithDescription";
import {
  AudienceIcon,
  AvoidIcon,
  CTAIcon,
  FbIcon,
  FormattingIcon,
  PictureIcon,
  ThumbsIcon,
  ToneOfVoiceIcon,
} from "../ProjectBriefComponents/Icons";
import ProjectBriefSliders from "../ProjectBriefComponents/ProjectBriefSliders";
import { dashboardProjects } from "../../reducers/dashboard";

import "./ArticleBriefData.css";
import { useSelector } from "react-redux";

const ArticleBriefData = ({ article }) => {
  const projects = useSelector(dashboardProjects);
  // const hasBrief = article && article.briefUrl;
  // const hasInfo = article && article.info;
  const hasFeedback = article && article.technicalCheck;
  const isReadOnly = true;

  const [feedbackUpdated, setFeedbackUpdated] = useState(false);
  const [dataBrief, setDataBrief] = useState(null);

  useEffect(() => {
    if (article && article.projectId) {
      fetchBriefData();
    }
  }, [article]);

  const fetchBriefData = async () => {
    try {
      const result = await getProjectBriefData(article.projectId);
      setDataBrief(result);
    } catch (error) {
      console.log({ error });
    }
  };

  const currentProject =
    projects && projects.find((project) => project.id === article.projectId);

  const [formState, dispatch] = useReducer(formReducer, {
    inputs: {
      technical: {
        value: "",
        isValid: false,
      },
    },
    isValid: false,
  });

  const inputHandler = useCallback((id, value, isValid) => {
    dispatch({
      type: "INPUT_CHANGE",
      value: value,
      isValid: isValid,
      inputId: id,
    });
  }, []);

  const saveFormInputs = async (event) => {
    event.preventDefault();

    const payload = {
      id: article.id,
      technicalCheck: formState.inputs.technical.value,
    };

    const result = await saveTechnicalFeedback(payload);

    if (result) {
      setFeedbackUpdated(true);
    }
  };

  const displayLinks = !!dataBrief || !!article;

  return (
    <div className="brief-content">
      {currentProject && (
        <div className="brief-project-name">
          <h3>{currentProject.name}</h3>
        </div>
      )}
      {displayLinks && (
        <div className="brief-section">
          <UsefulLinks dataBrief={dataBrief} article={article} />
        </div>
      )}
      <div className="brief-section">
        {article && article.info && (
          <TitleWithDescription name="Article Brief" desc={article.info} />
        )}
        {article && article.anchor1 && (
          <TitleWithDescription name="Anchor 1" desc={article.anchor1} />
        )}
        {article && article.url1 && (
          <TitleWithDescription name="Url 1" desc={article.url1} />
        )}
        {article && article.anchor2 && (
          <TitleWithDescription name="Anchor 2" desc={article.anchor2} />
        )}
        {article && article.url2 && (
          <TitleWithDescription name="Url 2" desc={article.url2} />
        )}
        {dataBrief && (
          <>
            {dataBrief.additionalInfo && (
              <TitleWithDescription
                name="Project Brief"
                desc={dataBrief.additionalInfo}
              />
            )}
            {article.technicalCheck && (
              <TitleWithDescription
                name="Technical input"
                desc={article && article.technicalCheck}
              />
            )}
            {dataBrief.thingsToConsider && (
              <TitleWithDescription
                name="Things to do"
                // icon={<ThumbsIcon />}
                color="#66ca95"
                desc={dataBrief.thingsToConsider}
              />
            )}
            {dataBrief.thingsToAvoid && (
              <TitleWithDescription
                name="Things to avoid"
                // icon={<AvoidIcon />}
                color="#ec5032"
                desc={dataBrief.thingsToAvoid}
              />
            )}

            {dataBrief.audience && (
              <TitleWithDescription
                name="Audience"
                // icon={<AudienceIcon />}
                desc={dataBrief.audience}
              />
            )}

            {dataBrief.contentStyleSlider1 &&
              dataBrief.contentStyleSlider2 &&
              dataBrief.contentStyleSlider3 && (
                <ProjectBriefSliders
                  isDisabled
                  defaultValue1={dataBrief.contentStyleSlider1}
                  defaultValue2={dataBrief.contentStyleSlider2}
                  defaultValue3={dataBrief.contentStyleSlider3}
                />
              )}

            {dataBrief.toneOfVoice && (
              <TitleWithDescription
                name="Tone of voice"
                // icon={<ToneOfVoiceIcon />}
                desc={dataBrief.toneOfVoice}
              />
            )}
            {dataBrief.formatting && (
              <TitleWithDescription
                name="Formatting"
                // icon={<FormattingIcon />}
                desc={dataBrief.formatting}
              />
            )}

            {dataBrief.sourcesAndImages && (
              <TitleWithDescription
                name="Sources & Images"
                // icon={<PictureIcon />}
                desc={dataBrief.sourcesAndImages}
              />
            )}

            {dataBrief.desiredCta && (
              <TitleWithDescription
                name="Desired CTA"
                // icon={<CTAIcon />}
                desc={dataBrief.desiredCta}
              />
            )}

            {dataBrief.facebookCopy && (
              <TitleWithDescription
                name="Facebook Copy"
                // icon={<FbIcon />}
                desc={dataBrief.facebookCopy}
              />
            )}
          </>
        )}
      </div>

      {/* <div>
        <p className="brief-titles">Additional Info:</p>
        <p className="brief-description">{article.info}</p>
      </div> */}

      {/* this view is for writer or proofreader */}
      {/* uncomment this when ready  */}
      {/* {hasFeedback && isReadOnly && (
        <div className="brief-tech-feedback">
          <p className="brief-titles">Technical input:</p>
          <p className="brief-description">
            {hasFeedback && article.technicalCheck}
          </p>
        </div>
      )} */}

      {/* {isReadOnly && (
        <div className="brief-tech-feedback">
          <p className="brief-titles">Technical input:</p>
          <p className="brief-description">
            {hasFeedback && article.technicalCheck}
          </p>
        </div>
        
      )} */}

      {/* this view is for master before setting the feedback  */}
      {!isReadOnly && (
        <div className="brief-tech-feedback">
          <p className="brief-titles">Technical input:</p>
          <form className="feedback-form" onSubmit={saveFormInputs}>
            <Input
              id="technical"
              type="text"
              rows={3}
              validators={[]}
              onInput={inputHandler}
              initialValue={article && article.technicalCheck}
              noValidation
            />
            {feedbackUpdated && (
              <FormSuccessMessage>Technical feedback saved.</FormSuccessMessage>
            )}

            <div className="update-feedback-btn">
              <Button type="submit" fullSize>
                {hasFeedback ? "Update Feedback" : "Add Feedback"}
              </Button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default ArticleBriefData;
