import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import "./SkeletonCardCategory.css";

const SkeletonCardCategory = ({ firstItem }) => {
  return (
    <SkeletonTheme color="#f4f4f3" highlightColor="#f4f4f5">
      <div className="skeleton-card-category">
        <div className="skeleton-card-circle">
          <Skeleton circle={true} height={30} width={30} />
        </div>
        <div className="skeleton-bar-section">
          <div className="skeleton-bar-bars">
            <Skeleton width={130} height={16} />
            <Skeleton width={150} height={12} />
          </div>
          {firstItem && (
            <div className="skeleton-title-bars">
              <Skeleton width={100} height={16} />
              <Skeleton width={100} height={16} />
            </div>
          )}
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default SkeletonCardCategory;
