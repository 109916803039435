import React, { useReducer, useCallback, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Input from "../Shared/Input";
import Button from "../Shared/Button";

import { VALIDATOR_MINLENGTH } from "../utils/validators";
import { formReducer } from "../utils/formReducer";
import { changePass } from "../services/user";
import FormErrorMessage from "../Components/FormErrorMessage";

import "./ResetForm.css";

const ChangePassForm = () => {
  const history = useHistory();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const [errorMessage, setErrorMessage] = useState(null);
  const [formState, dispatch] = useReducer(formReducer, {
    inputs: {
      password: {
        value: "",
        isValid: false,
      },
      confirmPassword: {
        value: "",
        isValid: false,
      },
    },
    isValid: false,
  });

  const inputHandler = useCallback((id, value, isValid) => {
    dispatch({
      type: "INPUT_CHANGE",
      value: value,
      isValid: isValid,
      inputId: id,
    });
  }, []);

  const resetSubmitHandler = async (event) => {
    event.preventDefault();
    console.log(formState.inputs);
    if (
      formState.inputs.password.value !== formState.inputs.confirmPassword.value
    ) {
      setErrorMessage("Passwords don't match!");
      return;
    }
    const token = queryParams.get("token");
    try {
      const changedPass = await changePass(formState.inputs, token);
      if (changedPass) {
        history.push("/login");
      }
    } catch (error) {
      setErrorMessage(error.data.data);
    }
  };

  return (
    <div className="reset">
      <form className="reset-form" onSubmit={resetSubmitHandler}>
        <Input
          id="password"
          element="input"
          type="password"
          label="New password"
          validators={[VALIDATOR_MINLENGTH(5)]}
          errorText="Please enter more than 5 characters."
          onInput={inputHandler}
        />
        <Input
          id="confirmPassword"
          element="input"
          type="password"
          label="Confirm new password"
          validators={[VALIDATOR_MINLENGTH(5)]}
          errorText="Please enter more than 5 characters."
          onInput={inputHandler}
        />
        {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
        <Button type="submit" fullSize disabled={!formState.isValid}>
          Change Password
        </Button>
      </form>
    </div>
  );
};

export default ChangePassForm;
