import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import TabsMenu from "./TabsMenu";
import Chart from "./Chart";
import TopicCoverage from "./TopicCoverage";
import ArticleBriefData from "./ArticleBriefData/ArticleBriefData";
import { Scrollbars } from "react-custom-scrollbars";
import Competition from "./Competition";
import { getApplicationMode } from "../reducers/applicationMode";
import RadarChartPlaceholder from "./RadarCharPlaceholder";
import { loadingStep2Data } from "../reducers/searchTopic";
import { getTopicCoverageData } from "../reducers/topicCoverage";
import { getCurrentArticle } from "../reducers/articles";
import {
  setActiveSidebarTab,
  getActiveSidebarTab,
  resetEditorData,
  getEditorInstance,
} from "../reducers/textEditor";
import {
  USER_MASTER,
  TYPE_NATIVE,
  TYPE_DESCRIPTION,
  HOME_PATH,
  DEFAULT_ARTICLE_ID,
} from "../common/consts";
import { activeUser } from "../reducers/user";

import "./SidebarRight.css";

import { useApplicationType } from "../utils/useApplicationType";

import Popup from "reactjs-popup";
import useArticleId from "../common/hooks/useArticleId";
import Button from "../Shared/Button";
import { useParams } from "react-router-dom";
import AISidebarContent from "./AIContent/AISidebarContent";

const SidebarRight = () => {
  const dispatch = useDispatch();

  const applicationType = useApplicationType();
  const urlParams = useParams();
  const appMode = useSelector(getApplicationMode);
  const isTopicLoading = useSelector(loadingStep2Data);
  const keywords = useSelector(getTopicCoverageData);
  const currentTab = useSelector(getActiveSidebarTab);
  const currentArticle = useSelector(getCurrentArticle);
  const { isDefaultArticleId } = useArticleId();
  const currentUser = useSelector(activeUser);
  const editorInstance = useSelector(getEditorInstance);
  const [activeMenu, setActiveMenu] = useState(currentTab);
  const [openPopup, setOpenPopup] = useState(false);
  const [localLoading, setLocalLoading] = useState(true);

  useEffect(() => {
    setActiveMenu(currentTab);
  }, [currentTab]);

  useEffect(() => {
    // addind a local state flag to avoid the popup to open every time the article changes
    if (!isTopicLoading) {
      setLocalLoading(false);
    }
  }, [isTopicLoading]);

  useEffect(() => {
    // only open the popup if the article id is not the default one
    if (
      applicationType !== TYPE_NATIVE &&
      urlParams.id !== DEFAULT_ARTICLE_ID
    ) {
      // check if both flags are false meaning content is loaded but the keywords are empty
      if (!localLoading && !isTopicLoading && keywords?.length === 0) {
        setOpenPopup(true);
      }
    }
  }, [localLoading, isTopicLoading, urlParams, keywords]);

  const showContent = !isTopicLoading;

  const articleHasBriefUrl = currentArticle && currentArticle.briefUrl;
  const articleHasInfo = currentArticle && currentArticle.info;
  const articleHasFeedback = currentArticle && currentArticle.technicalCheck;
  const articleHasProjectId = currentArticle && currentArticle.projectId;

  const currentUserType = currentUser && currentUser.role[0];

  const showBriefTab =
    articleHasBriefUrl ||
    articleHasInfo ||
    articleHasFeedback ||
    articleHasProjectId;

  const hideGuidelinesTab =
    currentArticle && currentArticle.type === TYPE_NATIVE;

  const showRadarChart = () => {
    if (showContent) {
      return <Chart />;
    }
    if (isTopicLoading) {
      return <RadarChartPlaceholder />;
    }
  };

  const onRedirect = () => {
    // redirect to the search topic with a refresh, clean all redux state
    if (editorInstance) {
      dispatch(resetEditorData(true));
    }
    window.location.replace(`${HOME_PATH}/${DEFAULT_ARTICLE_ID}`);
  };

  if (openPopup) {
    return (
      <Popup
        open={openPopup}
        onClose={() => setOpenPopup(false)}
        closeOnDocumentClick={false}
      >
        <div className="topic-result">
          <div className="topic-result-data">
            <div className="topic-numbers">
              <div className="numbers topic-searches"></div>
              <div className="numbers topic-visits"></div>
            </div>

            <div className="no-data-found">
              There is not enough data in the database to be able to create a
              content brief. Please try another topic.
            </div>

            <div className="topic-result-actions">
              <Button type="button" fullSize onClick={onRedirect}>
                Write another article
              </Button>
            </div>
          </div>

          <img
            className="topic-result-img"
            src="/images/_topic-result.png"
            alt="Search"
            width="314px"
            height="390px"
          />
        </div>
      </Popup>
    );
  }

  const activeTab = (val) => {
    dispatch(setActiveSidebarTab(val));
  };

  const displayRadarChart = applicationType !== TYPE_DESCRIPTION;

  return (
    <div className="sidebar-right">
      <TabsMenu
        active={activeMenu}
        showBriefTab={showBriefTab}
        hideGuidelinesTab={hideGuidelinesTab}
        isMaster={currentUserType === USER_MASTER}
        onChange={(val) => activeTab(val)}
      />
      {/* <div
        id="comments-sidebar"
        onClick={() => dispatch(setCommentAdded(true))}
        className={`sidebar-comments ${
          activeMenu === 2 && `sidebar-comments-active`
        }`}
      ></div> */}
      {activeMenu === 0 && (
        <Scrollbars>
          {displayRadarChart && showRadarChart()}
          <TopicCoverage
            appMode={appMode}
            isTopicLoading={isTopicLoading}
            showContent={showContent}
            isArticleMode={displayRadarChart}
          />
        </Scrollbars>
      )}
      {activeMenu === 1 && (
        <Scrollbars>
          <Competition />
        </Scrollbars>
      )}
      {activeMenu === 3 && (
        <Scrollbars>
          <ArticleBriefData
            article={currentArticle}
            currentUserType={currentUserType}
          />
        </Scrollbars>
      )}
      {activeMenu === 4 && (
        <Scrollbars>
          <AISidebarContent />
        </Scrollbars>
      )}
    </div>
  );
};

export default SidebarRight;
