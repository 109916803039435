import React, { useState } from 'react';
import { Redirect } from "react-router-dom";
import EshopContent from '../Components/EshopContent';

const Eshop = () => {
  const [isUserAuth] = useState(!!localStorage.getItem("userToken"));

  return  isUserAuth ? <EshopContent /> : <Redirect to="/login" />;
};

export default Eshop;