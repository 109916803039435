import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import "./TopicNameCell.css";

const TopicNameCell = ({ row, handleClickAction }) => {
  const handleOnClick = () => {
    handleClickAction(row);
  };

  const trimmed = row && row.topic.trim();
  const cleanTopic = trimmed.replace(/&nbsp;/g, "");

  return (
    <Tooltip arrow placement="left" title={`Id: ${row.id}`}>
      <div className={"topic-name"} onClick={handleOnClick}>
        {cleanTopic !== "" ? cleanTopic : "Selectat ulterior de scriitor"}
      </div>
    </Tooltip>
  );
};

export default TopicNameCell;
