import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import CustomMessage from "./CustomMessage";
import {
  getTopicCoverageData,
  setTopicCoverageData,
  setHighTopicCoverage,
  setLowTopicCoverage,
  updateMaxTopicCoveragePoints,
  updateCurrentTopicCoveragePoints,
  lowTopicsFlag,
} from "../reducers/topicCoverage";

import {
  getTitleOptimization,
  getH1Optimization,
} from "../reducers/titleOptimization";
import {
  checkHeadingsOptimization,
  setHeadingsForOptimization,
} from "../reducers/headingsOptimization";
import { getApplicationMode } from "../reducers/applicationMode";
import { filterUsedKeywords, markUsedKeywords } from "./tagsHelpers";
import { saveArticle } from "../reducers/articles";
import { keywordSetAsCompleted, keywordRemovedByUser } from "../services/topic";
import { TYPE_DESCRIPTION, TYPE_ARTICLE, TYPE_NATIVE } from "../common/consts";

import "./Tags.css";
import { BlackTooltip } from "./UITooltips/UITooltip";

const TitleInfo = () => (
  <div className="used-in-title">
    <img
      className="used-in-icon"
      src="/images/_icon-use-in-title.png"
      width="18px"
      height="18px"
      alt="icon"
    />
    <p className="used-in">use in title</p>
  </div>
);

const HeadingInfo = ({ isCategory }) => (
  <div className="used-in-headings">
    <img
      className="used-in-icon"
      src="/images/_icon-use-in-heading.png"
      width="18px"
      height="18px"
      alt="icon"
    />
    <p className="used-in">{`${isCategory ? "use in H1 tag" : "use in headings"
      }`}</p>
  </div>
);

const ArticleInfo = () => (
  <div className="used-in-paragraph">
    <img
      className="used-in-icon"
      src="/images/_icon-use-in-article.png"
      width="18px"
      height="15px"
      alt="icon"
    />
    <p className="used-in">use in article</p>
  </div>
);

const Tags = ({ activeKeywords }) => {
  const processedKeywords = useSelector(getTopicCoverageData);
  const isTitleOptimized = useSelector(getTitleOptimization);
  const isH1TagOptimized = useSelector(getH1Optimization);
  const showLowTopics = useSelector(lowTopicsFlag);
  const appMode = useSelector(getApplicationMode);

  const [activeTags, setActiveTags] = useState(activeKeywords);
  const [titleOptimization, setTitleOptimization] = useState(false);
  // `h1TagOptimization` is used only in category mode
  const [h1TagOptimization, setH1TagOptimization] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showCompletedTooltip, setShowCompletedTooltip] = useState(false);
  const [showRemove, setShowRemove] = useState({ id: null, show: false });
  const dispatch = useDispatch();
  const { id: articleId } = useParams();

  useEffect(() => {
    const usedKeywords = markUsedKeywords(
      activeKeywords,
      titleOptimization,
      h1TagOptimization
    );

    // filter used keywords and move them at the bottom of the list
    const filtered = filterUsedKeywords(usedKeywords);
    setActiveTags(filtered);

    // we only calculate topic coverage points for the high keywords
    if (!showLowTopics) {
      calculateCurrentTopicCoverage(usedKeywords);
    }
  }, [activeKeywords, titleOptimization]);

  useEffect(() => {
    // we only calculate topic coverage points for the high keywords
    if (!showLowTopics) {
      const activeWithoutIgnoredByUser = activeTags?.filter(
        ({ ignoredByUser }) => !ignoredByUser
      );
      calculateCurrentTopicCoverage(activeWithoutIgnoredByUser);
      calculateMaxTopicCoveragePoints(activeWithoutIgnoredByUser);
    }
  }, [activeTags, showLowTopics]);

  useEffect(() => {
    setTitleOptimization(isTitleOptimized);
  }, [isTitleOptimized]);

  useEffect(() => {
    setH1TagOptimization(isH1TagOptimized);
  }, [isH1TagOptimized]);

  const hasTags = activeTags && activeTags.length !== 0;

  // type 2 needs to be provided
  const onRemove = debounce((index, keyword) => {
    const articleType =
      appMode === "category"
        ? TYPE_DESCRIPTION
        : appMode === "native"
          ? TYPE_NATIVE
          : TYPE_ARTICLE;
    // when removing a keywords we use the entire keywords list because if there is a filter applied
    // we might end up with only a few keywords left (the filtered ones) in the state
    // const remainingTags = processedKeywords.filter(({ key }) => key !== index);
    const removedByUser = processedKeywords.reduce(
      (acc, { key, ignoredByUser, ...rest }) =>
        index === key
          ? [...acc, { key, ignoredByUser: true, ...rest }]
          : [...acc, { key, ignoredByUser, ...rest }],
      []
    );

    // saving active topic coverage
    dispatch(setTopicCoverageData(removedByUser));
    if (!showLowTopics) {
      // if currently we are on high topic coverage,
      // save the same data in high topic coverage, so we keep in in sync
      dispatch(setHighTopicCoverage(removedByUser));
    } else {
      // if currently we are on low topic coverage,
      // save the same data in low topic coverage, so we keep in in sync
      dispatch(setLowTopicCoverage(removedByUser));
    }
    // recalculate heading optimization
    dispatch(checkHeadingsOptimization());
    dispatch(setHeadingsForOptimization(removedByUser));
    // force save article here, so we can have the latest stats
    dispatch(saveArticle(articleType));
    // send removed keyword to backend
    keywordRemovedByUser(keyword, articleId);
  }, 1000);

  const onMarkAsUsed = debounce((index, keyword) => {
    const keywordsMarkedByUser = activeTags.reduce(
      (acc, { key, markedByUser, ...rest }) =>
        index === key
          ? [...acc, { key, markedByUser: true, ...rest }]
          : [...acc, { key, markedByUser, ...rest }],
      []
    );
    // save new array with flag markedByUser,
    // this will automatically update the list of keywords and
    // apply the correct css classes to display the keyword as `used`
    dispatch(setTopicCoverageData(keywordsMarkedByUser));
    if (!showLowTopics) {
      // if currently we are on high topic coverage,
      // save the same data in high topic coverage, so we keep in in sync
      dispatch(setHighTopicCoverage(keywordsMarkedByUser));
    } else {
      // if currently we are on low topic coverage,
      // save the same data in low topic coverage, so we keep in in sync
      dispatch(setLowTopicCoverage(keywordsMarkedByUser));
    }

    dispatch(checkHeadingsOptimization());
    const articleType =
      appMode === "category"
        ? TYPE_DESCRIPTION
        : appMode === "native"
          ? TYPE_NATIVE
          : TYPE_ARTICLE;
    dispatch(saveArticle(articleType));

    // send this keyword to backend
    keywordSetAsCompleted(keyword, articleId);
  }, 1000);

  const calculateCurrentTopicCoverage = debounce((totalTags) => {
    const currentTopicCoverage = totalTags.filter(
      ({ usedInHeading, usedInParagraph, usedInTitle, markedByUser }) =>
        usedInHeading || usedInParagraph || usedInTitle || markedByUser
    );
    dispatch(updateCurrentTopicCoveragePoints(currentTopicCoverage));
  }, 500);

  const calculateMaxTopicCoveragePoints = debounce((currentTags) => {
    dispatch(updateMaxTopicCoveragePoints(currentTags));
  }, 500);

  return (
    <Fragment>
      {hasTags &&
        activeTags.map(
          (
            {
              key,
              keyword,
              isMulti,
              usedInParagraph,
              usedInHeading,
              use_in_title,
              use_in_text,
              use_in_h1,
              repeat,
              titleAndHeading,
              titleAndH1,
              titleAndArticle,
              headingAndArticle,
              search_volume,
              usedComplete,
              markedByUser,
              ignoredByUser,
            },
            index
          ) => {
            const title = use_in_title;
            const h1Tag = use_in_h1;
            const displayUsage = use_in_text > 0;
            const displaySearchVolume = appMode === "category";
            const showKeywordInfo = !displaySearchVolume;
            const isUsedInTitle = title && titleOptimization;
            const isUsedInH1Tag = h1Tag && h1TagOptimization;
            const isUsedInArticle = usedInParagraph;
            const isUsedInHeading = usedInHeading;
            const repeatedTimes =
              titleOptimization && title ? repeat + 1 : repeat;

            const partiallyUsed =
              isUsedInTitle ||
              isUsedInHeading ||
              isUsedInArticle ||
              isUsedInH1Tag;

            const displayRightSideInfo =
              titleAndHeading ||
              titleAndArticle ||
              headingAndArticle ||
              titleAndH1;

            const getIdentifier = () => {
              let identifier = "";
              if (isMulti && titleAndHeading) {
                identifier = isUsedInTitle
                  ? "-title"
                  : isUsedInHeading
                    ? "-heading"
                    : "";
              }

              if (isMulti && titleAndArticle) {
                identifier = isUsedInTitle ? "-title" : "-paragraph";
              }

              if (isMulti && headingAndArticle) {
                identifier = isUsedInHeading ? "-heading" : "-paragraph";
              }

              if (isMulti && titleAndH1) {
                identifier = isUsedInTitle
                  ? "-title"
                  : isUsedInH1Tag
                    ? "-heading"
                    : "";
              }

              return identifier;
            };

            return (
              <div
                key={index}
                onMouseOver={() => setShowRemove({ id: key, show: true })}
                onMouseLeave={() => setShowRemove({ id: key, show: false })}
                className={`custom-tag ${partiallyUsed && `custom-tag-partially${getIdentifier()}`
                  } ${usedComplete && "custom-tag-used"} `}
              >
                <div className="dot-and-label">
                  <span
                    className={`custom-tag-dot ${partiallyUsed &&
                      `custom-tag-dot-partially${getIdentifier()}`
                      } ${usedComplete && "custom-tag-dot-used"} `}
                  ></span>
                  <div>
                    <p className="keyword">{keyword}</p>

                    {displayUsage && (
                      // // repeat value doesn't include counting in titles (h1),
                      // so if title is optimized, we add 1 to the current value
                      <BlackTooltip
                        arrow
                        placement="top"
                        title="This metric impacts the 'Topic Density' on the radar chart"
                      >
                        <p
                          className="keyword-usage"
                          style={{
                            color: repeatedTimes > 0 ? "#66ca95" : "#df94a1",
                          }}
                        >{`used ${repeatedTimes} / ${use_in_text} times`}</p>
                      </BlackTooltip>
                    )}
                    {showKeywordInfo && (
                      <p className="monthly-searches">{`monthly searches: ${search_volume !== 0
                        ? search_volume?.toLocaleString()
                        : `0-10`
                        }`}</p>
                    )}
                    {markedByUser && (
                      <span className="interactive-label used-keyword">
                        manually selected
                      </span>
                    )}

                    {ignoredByUser && (
                      <span className="interactive-label removed-keyword">
                        marked as junk
                      </span>
                    )}

                    {displaySearchVolume && (
                      <p className="keyword-search-volume">{`Search volume: ${search_volume?.toLocaleString()}`}</p>
                    )}
                  </div>
                </div>
                {displayRightSideInfo && (
                  <div className="right-side-info">
                    {titleAndHeading && (
                      <>
                        <TitleInfo />
                        <HeadingInfo />
                      </>
                    )}
                    {titleAndArticle && (
                      <>
                        <TitleInfo />
                        <ArticleInfo />
                      </>
                    )}
                    {headingAndArticle && !displaySearchVolume && (
                      <>
                        <HeadingInfo />
                        <ArticleInfo />
                      </>
                    )}
                    {titleAndH1 && (
                      <>
                        <TitleInfo />
                        <HeadingInfo isCategory={titleAndH1} />
                      </>
                    )}
                  </div>
                )}

                {showRemove.id === key && showRemove.show && (
                  <>
                    {!markedByUser && !ignoredByUser && !usedComplete && (
                      <>
                        <div
                          className="complete-tag"
                          onClick={() => onMarkAsUsed(key, keyword)}
                        >
                          <img
                            onMouseOver={() => setShowCompletedTooltip(true)}
                            onMouseOut={() => setShowCompletedTooltip(false)}
                            src="/images/_tick-icon.svg"
                            width="14px"
                            height="14px"
                            alt="mark complete"
                          />
                          {showCompletedTooltip && (
                            <CustomMessage
                              posTop="-100px"
                              posLeft="-200px"
                              text="Click here to mark this keyword as completed if you used it in the article in a slightly different form"
                            />
                          )}
                        </div>
                        <div
                          className="remove-tag"
                          onClick={() => onRemove(key, keyword)}
                        >
                          <img
                            onMouseOver={() => setShowTooltip(true)}
                            onMouseOut={() => setShowTooltip(false)}
                            src="/images/_remove-icon.svg"
                            width="14px"
                            height="14px"
                            alt="remove"
                          />
                          {showTooltip && (
                            <CustomMessage
                              posTop="-115px"
                              posLeft="-200px"
                              text="Click here to dismiss this keyword as a recommendation and exclude it from article score if it is not relevant."
                            />
                          )}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            );
          }
        )}
    </Fragment>
  );
};

export default Tags;
