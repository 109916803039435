import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import Popup from "reactjs-popup";
import { isDemoApplication } from "../../reducers/applicationMode";
import {
  checkAndUpdatePlagiarismScore,
  exportArticleToPdf,
  getArticleSavingStatus,
  getCurrentArticleId,
  getPlagiarismStatus,
  saveArticle,
} from "../../reducers/articles";
import { getWordCount } from "../../reducers/counters";
import { getEditorInstance } from "../../reducers/textEditor";
import {
  CopyCodeIcon,
  DownloadPdfIcon,
  SaveDraftIcon,
} from "../../UI_utils/SVGIcons";
import { useApplicationType } from "../../utils/useApplicationType";
import PlagiarismScreen from "../Plagiarism/PlagiarismScreen";
import PlagiarismTag from "../Plagiarism/PlagiarismTag";
import { BlackTooltip } from "../UITooltips/UITooltip";

import "./EditorMenuBottom.css";

const EditorMenuBottom = () => {
  const dispatch = useDispatch();
  const totalWords = useSelector(getWordCount);
  const isDemoApp = useSelector(isDemoApplication);
  const lastSaved = useSelector(getArticleSavingStatus);
  const applicationType = useApplicationType();
  const plagiarism = useSelector(getPlagiarismStatus);
  const currentArticleId = useSelector(getCurrentArticleId);
  const editor = useSelector(getEditorInstance);

  const [popupOpen, setPopupOpen] = useState(false);
  const [showPlagiarismScreen, setShowPlagiarismScreen] = useState(false);
  const [articlePlagiarism, setArticlePlagiarism] = useState(null);
  const [plagScore, setPlagScore] = useState(null);

  useEffect(() => {
    if (plagiarism) {
      setPlagScore(plagiarism.plagiarismScore);
    }
  }, [plagiarism]);

  const saveAsDraft = () => {
    dispatch(saveArticle(applicationType));
  };

  const displayNotification = () => {
    openPopup();
  };

  const exportArticle = () => {
    dispatch(exportArticleToPdf());
  };

  const openPlagiarism = async () => {
    setArticlePlagiarism(null);
    setShowPlagiarismScreen(true);

    if (!isDemoApp) {
      // save article first and wait for response
      const res = await dispatch(saveArticle(applicationType));
      // if article was saved do check for plagiarism
      if (res && res.id) {
        const response = await dispatch(
          checkAndUpdatePlagiarismScore(currentArticleId)
        );
        if (response) {
          setArticlePlagiarism(response);
        } else {
          onClosePlagiarism();
        }
      }
    }
  };

  const onClosePlagiarism = () => {
    setArticlePlagiarism(null);
    setShowPlagiarismScreen(false);
  };

  const openPopup = () => setPopupOpen(true);
  const closePopup = () => setPopupOpen(false);

  return (
    <>
      <Popup open={popupOpen} onClose={closePopup} closeOnDocumentClick={true}>
        <div className="notification-modal">
          <p className="notification-message">
            {"The sourcecode of the article has been copied to clipboard"}
          </p>
        </div>
      </Popup>
      <Popup
        open={showPlagiarismScreen}
        onClose={onClosePlagiarism}
        closeOnDocumentClick={false}
      >
        <PlagiarismScreen
          response={articlePlagiarism}
          onRephrase={onClosePlagiarism}
          asInfo={true}
        />
      </Popup>
      <div className="text-editor-footer">
        <div className="text-editor-footer-info">
          <>
            <p className="footer-text">Plagiarism:</p>
            <PlagiarismTag onOpen={openPlagiarism} value={plagScore || 0} />
          </>
          <p className="footer-text">Wordcount:</p> <span>{totalWords}</span>
          {lastSaved}
        </div>
        <div className="editor-footer-actions">
          <BlackTooltip arrow placement="top" title="Save article draft">
            <div className="footer-icon-wrapper" onClick={saveAsDraft}>
              <SaveDraftIcon />
            </div>
          </BlackTooltip>
          <BlackTooltip
            arrow
            placement="top"
            title="Download article HTML code"
          >
            <div>
              <CopyToClipboard
                text={editor?.getHTML()}
                onCopy={displayNotification}
              >
                <div className="footer-icon-wrapper">
                  <CopyCodeIcon />
                </div>
              </CopyToClipboard>
            </div>
          </BlackTooltip>
          <BlackTooltip arrow placement="top" title="Export article PDF">
            <div className="footer-icon-wrapper" onClick={exportArticle}>
              <DownloadPdfIcon />
            </div>
          </BlackTooltip>
        </div>
      </div>
    </>
  );
};

export default EditorMenuBottom;
