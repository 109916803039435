import React, { useReducer, useCallback } from "react";
import Input from "../Shared/Input";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { updateMatchingWords } from "../reducers/topicCoverage";
import {
  checkCategoryTitleOptimization,
  checkCategoryH1Optimization,
} from "../reducers/titleOptimization";
import { VALIDATOR_MAXLENGTH } from "../utils/validators";
import {
  updateMetaTitle,
  updateMetaDescription,
  updateH1Tag,
  getMetaDesc,
  getMetaTitle,
  getH1Tag,
  getIsDataLoaded,
} from "../reducers/categoryTextEditor";
import { removeSpecialChars } from "../utils/convertSpecialChars";

import CustomTooltip from "../Components/CustomTooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { formReducer } from "../utils/formReducer";
import { saveArticle } from "../reducers/articles";
import { saveCommentsToServer } from "../reducers/feedbackFlow";
import { TYPE_DESCRIPTION } from "../common/consts";

import "./CategoryInputs.css";

const storeData = debounce((dispatch, method, data) => {
  // convert all texts to lowercase and remove special chars
  const lowerCaseData = data.toLowerCase();
  const noSpecialCharsData = removeSpecialChars(lowerCaseData);
  dispatch(method(noSpecialCharsData));
}, 500);

const trackTopicCoverage = debounce((dispatch) => {
  dispatch(updateMatchingWords());
}, 600);

const trackH1 = debounce((dispatch) => {
  dispatch(checkCategoryH1Optimization());
}, 600);

const trackTitle = debounce((dispatch) => {
  dispatch(checkCategoryTitleOptimization());
}, 900);

const sendEditorDataToBackend = debounce((dispatch, eshopId) => {
  dispatch(saveArticle(TYPE_DESCRIPTION));
  dispatch(saveCommentsToServer(eshopId));
}, 15000);

const CategoryInputs = ({ eshopId }) => {
  const metaTitle = useSelector(getMetaTitle);
  const metaDesc = useSelector(getMetaDesc);
  const h1Tag = useSelector(getH1Tag);
  const isDataLoaded = useSelector(getIsDataLoaded);

  const dispatch = useDispatch();

  // const [initialValues, setInitialValues] = useState(null);
  const [, dispatchInputs] = useReducer(formReducer, {
    inputs: {
      metaTitle: {
        value: "",
        isValid: false,
      },
      h1Tag: {
        value: "",
        isValid: false,
      },
      metaDescription: {
        value: "",
        isValid: true,
      },
    },
    isValid: false,
  });

  const inputHandler = useCallback((id, value, isValid) => {
    dispatchInputs({
      type: "INPUT_CHANGE",
      value: value,
      isValid: isValid,
      inputId: id,
    });
    if (id === "metaTitle") {
      storeData(dispatch, updateMetaTitle, value);
      trackTitle(dispatch);
    }
    if (id === "metaDescription") {
      storeData(dispatch, updateMetaDescription, value);
    }
    if (id === "h1Tag") {
      storeData(dispatch, updateH1Tag, value);
      trackH1(dispatch);
    }
    trackTopicCoverage(dispatch);
    sendEditorDataToBackend(dispatch, eshopId);
  }, []);

  if (!isDataLoaded) {
    return null;
  }

  return (
    <div className="category-inputs">
      <div className="category-input-titles">
        <div className="meta-title-wrapper">
          <Input
            id="metaTitle"
            element="input"
            type="text"
            label="META Title"
            validators={[VALIDATOR_MAXLENGTH(65)]}
            errorText="Please use maximum 65 characters"
            onInput={inputHandler}
            initialValue={metaTitle}
          />
          <div className="meta-title-info">
            <CustomTooltip
              posTop="-82px"
              posLeft="-111px"
              text="Please try to keep the important elements or the entire title within the limit of 65 characters"
            />
            <CopyToClipboard text={metaTitle}>
              <img
                className="meta-title-copy"
                src="/images/_copy-clipboard.svg"
                alt="copy"
              />
            </CopyToClipboard>
          </div>
        </div>

        <div className="h1-tag-wrapper">
          <Input
            id="h1Tag"
            element="input"
            type="text"
            label="H1 Tag"
            validators={[VALIDATOR_MAXLENGTH(65)]}
            errorText="Please use maximum 65 characters"
            onInput={inputHandler}
            initialValue={h1Tag}
          />
          <div className="h1-tag-info">
            <CustomTooltip
              posTop="-97px"
              posLeft="-111px"
              text="The most important keyword in terms of search volume (how people search for this products the most) must be used here"
            />
          </div>
          <CopyToClipboard text={h1Tag}>
            <img
              className="h1-tag-copy"
              src="/images/_copy-clipboard.svg"
              alt="copy"
            />
          </CopyToClipboard>
        </div>
      </div>
      <div className="meta-desc-wrapper">
        <Input
          id="metaDescription"
          type="text"
          label="META Description"
          maxLength="150"
          rows={5}
          validators={[VALIDATOR_MAXLENGTH(155)]}
          errorText="Character limit is 155"
          onInput={inputHandler}
          initialValue={metaDesc}
        />
        <div className="meta-desc-info">
          <CustomTooltip
            posTop="-112px"
            posLeft="-111px"
            text="The META description is limited to 155 characters as Google will truncate it beyond this limit. Please use emojis, competitive advantages"
          />
        </div>
        <CopyToClipboard text={metaDesc}>
          <img
            className="meta-desc-copy"
            src="/images/_copy-clipboard.svg"
            alt="copy"
          />
        </CopyToClipboard>
      </div>
    </div>
  );
};

export default CategoryInputs;
