import React from "react";
import InfoIcon from "@material-ui/icons/Info";

import "./InfoTitle.css";

const InfoTitle = ({ title }) => {
  return (
    <div className="info-title-card">
      <div className="info-text-card">{title}</div>
      <InfoIcon style={{ color: "#6f767e" }} />
    </div>
  );
};

export default InfoTitle;
