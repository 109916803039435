import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ResultsChart from "./ResultsChart";
import Button from "../Shared/Button";
import { resetTopicData } from "../reducers/searchTopic";
import { resetEditorData } from "../reducers/textEditor";
import {
  onCreateNewArticle,
  setArticleId,
  setArticle,
  setArticlesCollection,
  requestArticleById,
} from "../reducers/articles";

import { TYPE_ARTICLE, TYPE_NATIVE } from "../common/consts";

import "./TopicResultComponent.css";

const TopicResultComponent = ({ data, onClose, nextPage, appMode }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleCancelClick = () => {
    // resetting topic values
    dispatch(resetTopicData());
    // reset modal page back to 0, so we can go back to previous screen
    nextPage();
  };

  const handleProceedWithArticle = async () => {
    // dispatch(requestTopicDetails(topic));
    // reset(clean) the editor when writting new article
    dispatch(resetEditorData(true));
    // reset existing articles data
    dispatch(setArticle(null));
    dispatch(setArticleId(null));
    dispatch(setArticlesCollection([]));
    const articleType = appMode === "article" ? TYPE_ARTICLE : TYPE_NATIVE;
    try {
      // this will send server request and get back an article id
      const articleId = await dispatch(onCreateNewArticle(articleType));
      if (articleId) {
        dispatch(requestArticleById(articleId));

        history.push(`/${appMode}/${articleId}`);
        onClose();
      }
    } catch (error) {
      // console.log({ error });
    }
  };

  return (
    <div className="topic-result">
      <div className="topic-result-data">
        <h2>Topic Metrics</h2>
        <div className="topic-seached">
          <p>{`"${data?.topic}"`}</p>
        </div>
        <div className="topic-numbers">
          <div className="numbers topic-searches">
            {data?.monthSearches?.toLocaleString()}
            <div className="numbers-info">
              <p>searches/month</p>
              <img src="/images/_info-icon.svg" alt="info" />
            </div>
          </div>
          <div className="numbers topic-visits">
            {data?.estimatedVisits?.toLocaleString()}
            <div className="numbers-info">
              <p>estimated visits</p>
              <img src="/images/_info-icon.svg" alt="info" />
            </div>
          </div>
        </div>
        {/* {data?.estimatedVisits ? (
          <div className="topic-chart">
            <p>When people search for this topic</p>
            <ResultsChart data={data?.monthlyValues} />
          </div>
        ) : (
          <div className="no-data-found">
            There is not enough data in the database to be able to create a
            content brief. Please try another topic.
          </div>
        )} */}
        <div className="topic-chart">
          <p>When people search for this topic</p>
          <ResultsChart data={data?.monthlyValues} />
        </div>
        <div className="topic-result-actions">
          <Button type="button" fullSize passive onClick={handleCancelClick}>
            Choose another topic
          </Button>
          <Button
            // disabled={!data?.estimatedVisits}
            type="button"
            fullSize
            onClick={handleProceedWithArticle}
          >
            Write article
          </Button>
        </div>
      </div>

      <img
        className="topic-result-img"
        src="/images/_topic-result.png"
        alt="Search"
        width="314px"
        height="390px"
      />
    </div>
  );
};

export default TopicResultComponent;
