import { getIndustries } from '../api/user';
export const requestIndustries = async () => {
  const industries = await getIndustries();
  const shapedIndustries = industries.reduce((acc, { name, id }) => [
    ...acc,
    {
      id,
      value: name.toLowerCase(),
      label: name,
    }
  ], []);

  return shapedIndustries;
}