import React, { useEffect, useState } from "react";
import CompletionBar from "../Components/CompletionBar";
import SidebarRight from "../Components/SidebarRight";
import InfoTips from "../Components/InfoTips/InfoTips";
import { useSelector } from "react-redux";
import { activeUser } from "../reducers/user";
import { USER_CLIENT, USER_PROOFREADER } from "../common/consts";
import { getCurrentArticle } from "../reducers/articles";
import { useEditorModeCheck } from "../utils/useEditorModeCheck";
import TipTapEditor from "../Components/TipTapEditor/TipTapEditor";
import EditorMenuBottom from "../Components/TipTapEditor/EditorMenuBottom";

const ArticleMode = () => {
  const user = useSelector(activeUser);
  const currentArticle = useSelector(getCurrentArticle);

  const isReadOnlyMode = useEditorModeCheck();

  const [userType, setCurrentUserType] = useState(null);
  const [article, setArticle] = useState(null);

  useEffect(() => {
    if (user) {
      setCurrentUserType(user.role[0]);
    }
  }, [user]);

  useEffect(() => {
    if (currentArticle) {
      setArticle(currentArticle);
    }
  }, [currentArticle]);

  return (
    <>
      <CompletionBar />
      <div className="homepage-content">
        <div className="homepage-editor">
          {/* {showInfoTips && <InfoTips />} */}
          {article && <TipTapEditor isReadOnly={false} />}
          {article && <EditorMenuBottom />}
        </div>
        <SidebarRight />
      </div>
    </>
  );
};

export default ArticleMode;
