import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import ProjectBriefContent from "../Components/ProjectBriefComponents/ProjectBriefContent";

const ProjectBrief = () => {
  const [isUserAuth] = useState(!!localStorage.getItem("userToken"));

  return isUserAuth ? <ProjectBriefContent /> : <Redirect to="/login" />;
};

export default ProjectBrief;
