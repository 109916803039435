import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import Popup from "reactjs-popup";
import { useDispatch, useSelector } from "react-redux";
import {
  exportArticleToPdf,
  setPlagiarismFromSubmit,
} from "../reducers/articles";
import { CopyToClipboard } from "react-copy-to-clipboard";

import Button from "../Shared/Button";
import PlagiarismScreen from "./Plagiarism/PlagiarismScreen";
// import { getAllComments } from "../reducers/feedbackFlow";
import {
  sendCurrentFeedback,
  saveCommentsToServer,
} from "../reducers/feedbackFlow";
import { saveArticle } from "../reducers/articles";
import {
  DASHBOARD_PATH,
  // TYPE_DESCRIPTION,
  USER_WRITER,
} from "../common/consts";
import { isDemoApplication } from "../reducers/applicationMode";
// import SubmitDescriptionWarning from "./SubmitDescriptionWarning";

import "./HeaderButtons.css";
import RatingComponent from "./Rating/RatingComponent";
import { useApplicationType } from "../utils/useApplicationType";
import { useEditorModeCheck } from "../utils/useEditorModeCheck";
import { Tooltip } from "@material-ui/core";
const HeaderButtons = ({
  buttonMessage,
  isArticle,
  currentArticle,
  currentUserType,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: articleId } = useParams();

  const isDemoApp = useSelector(isDemoApplication);
  // const isReadOnly = useEditorModeCheck();
  const isReadOnly = false;

  const applicationType = useApplicationType();

  const [message, setMessage] = useState("");
  const [popupInfo, setPopupInfo] = useState("");
  const [popupOpen, setPopupOpen] = useState(false);

  const [showPlagiarismScreen, setShowPlagiarismScreen] = useState(false);
  const [plagiarismResult, setPlagiarismResult] = useState(null);

  const openPopup = () => setPopupOpen(true);
  const closePopup = () => setPopupOpen(false);

  // const exportArticle = () => {
  //   dispatch(exportArticleToPdf());
  // };

  const displayNotification = () => {
    openPopup();
    setPopupInfo("copy");
    setMessage("The sourcecode of the article has been copied to clipboard");
  };

  const displayPagiarismScreen = async () => {
    setShowPlagiarismScreen(true);
    setPopupInfo("submit");

    if (!isDemoApp) {
      // save article first and wait for response
      const res = await dispatch(saveArticle(applicationType));
      // save comments no matter what
      dispatch(saveCommentsToServer(articleId));
      // if article was saved do check for plagiarism
      if (res && res.id) {
        const response = await dispatch(
          sendCurrentFeedback(articleId, buttonMessage.action)
        );

        if (response) {
          setPlagiarismResult(response);
          dispatch(setPlagiarismFromSubmit(response));
        }
      }
    }
  };

  const onClosePlagiarism = () => {
    setShowPlagiarismScreen(false);
    setPlagiarismResult(null);
  };

  const handleButtonAction = async () => {
    if (currentUserType === USER_WRITER) {
      displayPagiarismScreen();
    } else {
      openPopup();
      setPopupInfo("submit");
      if (!isDemoApp) {
        dispatch(saveArticle(applicationType));
        dispatch(saveCommentsToServer(articleId));
        const response = await dispatch(
          sendCurrentFeedback(articleId, buttonMessage.action)
        );
        if (response) {
          setPlagiarismResult(response);
        }
      }
    }
  };

  const confirmRedirect = () => {
    if (!isDemoApp) {
      history.push(DASHBOARD_PATH);
    } else {
      closePopup();
    }
  };

  // const saveAsDraft = () => {
  //   dispatch(saveArticle(applicationType));
  //   dispatch(saveCommentsToServer(articleId));
  // };

  const onRatingOver = () => {
    dispatch(sendCurrentFeedback(articleId, buttonMessage.action));
    dispatch(saveCommentsToServer(articleId));

    dispatch(saveArticle(applicationType));
  };

  const articleHasRating = currentArticle && currentArticle.hasRating;
  const showRatingPopup =
    (buttonMessage && buttonMessage.action === "no-feedback") ||
    (buttonMessage && buttonMessage.action === "submit-feedback");

  // const isArticleOrNative = currentArticle && currentArticle.type !== 1;

  return (
    <>
      <Popup
        open={showPlagiarismScreen}
        onClose={onClosePlagiarism}
        closeOnDocumentClick={false}
      >
        <PlagiarismScreen
          response={plagiarismResult}
          confirmRedirect={confirmRedirect}
          onRephrase={onClosePlagiarism}
        />
      </Popup>
      <Popup open={popupOpen} onClose={closePopup} closeOnDocumentClick={true}>
        {popupInfo !== "submit" ? (
          <div className="notification-modal">
            <p className="notification-message">{message}</p>
          </div>
        ) : (
          <div className="submit-modal">
            <div className="submit-modal-control">
              <div className="close-rating-button" onClick={closePopup}>
                &#10005;
              </div>
            </div>
            <>
              {!articleHasRating && showRatingPopup ? (
                <RatingComponent
                  articleId={articleId}
                  onClose={closePopup}
                  onRatingDone={onRatingOver}
                />
              ) : (
                <>
                  <p className="submit-modal-title">
                    Your article has been submitted successfully!
                  </p>
                  {!isDemoApp && (
                    <p>You will be redirected to your dashboard page</p>
                  )}
                  <div className="submit-modal-controls">
                    <Button type="button" fullSize onClick={confirmRedirect}>
                      Ok
                    </Button>
                  </div>
                </>
              )}
            </>
            {/* {isArticleOrNative ? (
              <>
                {!articleHasRating && showRatingPopup ? (
                  <RatingComponent
                    articleId={articleId}
                    onClose={closePopup}
                    onRatingDone={onRatingOver}
                  />
                ) : (
                  <>
                    <p className="submit-modal-title">
                      Your article has been submitted successfully!
                    </p>
                    {!isDemoApp && (
                      <p>You will be redirected to your dashboard page</p>
                    )}
                    <div className="submit-modal-controls">
                      <Button type="button" fullSize onClick={confirmRedirect}>
                        Ok
                      </Button>
                    </div>
                  </>
                )}
              </>
            ) : (
              <SubmitDescriptionWarning
                onCancel={closePopup}
                onConfirm={submitDescription}
              />
            )} */}
          </div>
        )}
      </Popup>
      <div className="header-buttons">
        {!isReadOnly ? (
          <>
            {/* <button className={"save-draft-button"} onClick={saveAsDraft}>
              Save draft
            </button> */}
            <button
              className={"user-action-button"}
              onClick={handleButtonAction}
            >
              {!isArticle
                ? "Submit"
                : (buttonMessage && buttonMessage.message) || "Submit"}
            </button>
          </>
        ) : (
          <div className="read-only-info">
            <p className="info-text">Read only mode </p>
            <Tooltip
              arrow
              placement="left"
              title={"After you submit an article you can't edit it anymore."}
            >
              <img src="/images/_info-icon.svg" alt="info" />
            </Tooltip>
          </div>
        )}
      </div>
    </>
  );
};

export default HeaderButtons;
