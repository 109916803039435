import { createSelector, createSlice } from "@reduxjs/toolkit";

export const countersSlice = createSlice({
  name: "counters",
  initialState: {
    totalWords: 0,
    totalCharacters: 0,
    maxWords: 10,
  },
  reducers: {
    setWordCount: (state, { payload }) => {
      return {
        ...state,
        totalWords: payload,
      };
    },

    setMaxWordCount: (state, { payload }) => {
      return {
        ...state,
        maxWords: payload,
      };
    },

    setCharactersCount: (state, { payload }) => {
      return {
        ...state,
        totalCharacters: payload,
      };
    },
  },
});

// actions
export const { setWordCount } = countersSlice.actions;
export const { setMaxWordCount } = countersSlice.actions;
export const { setCharactersCount } = countersSlice.actions;

// selectors

export const getWordCount = ({ counters: { totalWords } }) => totalWords;

export const getMaxWordCount = ({ counters: { maxWords } }) => maxWords;

export const getWordsPercentage = createSelector(
  [getWordCount, getMaxWordCount],
  (totalWords, maxWords) => (totalWords * 100) / maxWords
);

export const getCharactersCount = ({ counters: { totalCharacters } }) =>
  totalCharacters;

export default countersSlice.reducer;
