export const matchingKeywords = (keywords, editorData) => {
  const { headings, paragraphs } = editorData;
  const matchingWords = keywords.map((word) => {
    const lowerKey = word.keyword.toLowerCase();

    // checking to see if variants exists, (in eshop we don't have keyword variants)
    // if not, we send the normal keyword as an array to our helper method for match checking
    const keywordsToCheck =
      !word.variations || word.variations.length === 0
        ? [lowerKey]
        : word.variations;

    // transform in a single string for optimisation when checking total count of a keyword
    const allStrings = headings.concat(" ", paragraphs);

    if (isAnyWordMatching(allStrings, keywordsToCheck)) {
      // -> keyword has a match anywhere in the text
      if (
        isAnyWordMatching(headings, keywordsToCheck) &&
        isAnyWordMatching(paragraphs, keywordsToCheck)
      ) {
        // -> keyword has a match in headings and in paragraphs
        return {
          ...word,
          usedInParagraph: true,
          usedInHeading: true,
          repeat:
            // don't calculate repeat value if is not displayed
            word.use_in_text > 0
              ? getKeywordTotalCount(keywordsToCheck, allStrings)
              : 0,
        };
      }
      if (isAnyWordMatching(paragraphs, keywordsToCheck)) {
        // -> keyword has a match in paragraphs
        return {
          ...word,
          usedInParagraph: true,
          usedInHeading: false,
          repeat:
            // don't calculate repeat value if is not displayed
            word.use_in_text > 0
              ? getKeywordTotalCount(keywordsToCheck, allStrings)
              : 0,
        };
      }
      if (isAnyWordMatching(headings, keywordsToCheck)) {
        // -> keyword has a match in headings
        return {
          ...word,
          usedInParagraph: false,
          usedInHeading: true,
          repeat:
            // don't calculate repeat value if is not displayed
            word.use_in_text > 0
              ? getKeywordTotalCount(keywordsToCheck, allStrings)
              : 0,
        };
      }
    } else {
      // -> keyword has NO matches in article
      // reset any existing marks, in case the keyword was matching initially, but it was deleted or renamed
      return {
        ...word,
        usedInParagraph: false,
        usedInHeading: false,
        repeat: 0,
      };
    }

    return word;
  });

  return matchingWords;
};

export const topicCoverageMaxPoints = (data) => {
  const totalPoints = data && data.reduce((acc, curr) => acc + curr.points, 0);

  return totalPoints;
};

export const topicCoverageCurrentPoints = (data) => {
  const totalPoints =
    data &&
    data.reduce((acc, curr) => {
      // should this take the points if the keyword is markedByUser also?
      return curr.usedComplete || curr.markedByUser
        ? acc + curr.points
        : acc + curr.points - 1;
    }, 0);

  return totalPoints;
};

const isAnyWordMatching = (data, variations) => {
  const matchingWord = variations.some((keyword) => {
    const lowerKey = keyword.toLowerCase();

    return data.includes(lowerKey);
  });

  // const matchingKey = variations.find((keyword) => keyword === data);
  // console.log("MATCHING KEY: ", matchingKey);

  return matchingWord;
};

const getKeywordTotalCount = (keywords, data) => {
  return keywords.reduce((count, variant) => {
    const regex = new RegExp(`\\b${variant}\\b`, "gi");
    const matches = data.match(regex) || [];
    return count + matches.length;
  }, 0);
};
