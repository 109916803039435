import React from "react";

export const MouseIcon = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 20"
  >
    <g id="Page-1">
      <g id="Group">
        <path
          id="Rectangle-259-_Stroke_"
          d="M12,13V7c0-2.76-2.24-5-5-5S2,4.24,2,7v6c0,2.76,2.24,5,5,5s5-2.24,5-5ZM7,0C3.13,0,0,3.13,0,7v6c0,3.87,3.13,7,7,7s7-3.13,7-7V7C14,3.13,10.87,0,7,0Z"
          fill="#1a1d1f"
          fillRule="evenodd"
        />
        <path
          id="Vector-479-_Stroke_"
          d="M7,4c.55,0,1,.45,1,1v3c0,.55-.45,1-1,1s-1-.45-1-1v-3c0-.55,.45-1,1-1Z"
          fill="#1a1d1f"
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

export const CartIcon = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 11.38 10.5"
  >
    <g id="Page-1">
      <g id="UI-icon_cart_light">
        <path
          id="Path-_Stroke_"
          d="M0,.58C0,.26,.26,0,.58,0h.26c.8,0,1.5,.55,1.7,1.33l.11,.42h6.99c1.12,0,1.95,1.04,1.71,2.13l-.65,2.92c-.18,.8-.89,1.37-1.71,1.37H4.41c-.8,0-1.5-.55-1.7-1.33L1.41,1.61c-.06-.26-.3-.44-.57-.44h-.26c-.32,0-.58-.26-.58-.58ZM2.93,2.92l.91,3.64c.06,.26,.3,.44,.57,.44h4.57c.27,0,.51-.19,.57-.46l.65-2.92c.08-.36-.2-.71-.57-.71H2.93Z"
          fill="#6f767e"
          fillRule="evenodd"
        />
        <path
          id="Oval"
          d="M4.37,10.5c.48,0,.88-.39,.88-.88s-.39-.88-.88-.88-.88,.39-.88,.88,.39,.88,.88,.88Z"
          fill="#6f767e"
          fillRule="evenodd"
        />
        <path
          id="Oval-Copy"
          d="M9.04,10.5c.48,0,.88-.39,.88-.88s-.39-.88-.88-.88-.88,.39-.88,.88,.39,.88,.88,.88Z"
          fill="#6f767e"
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

export const StatisticsIcon = ({ width, height }) => (
  <svg
    id="Layer_1"
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 14"
  >
    <g id="Page-1">
      <g id="UI-icon_leaderboard_light">
        <path
          id="Union"
          d="M14,7V3c0-1.66-1.34-3-3-3h-2c-1.66,0-3,1.34-3,3v2H3c-1.66,0-3,1.34-3,3v5c0,.55,.45,1,1,1H19c.55,0,1-.45,1-1v-3c0-1.66-1.34-3-3-3h-3Zm-3-5h-2c-.55,0-1,.45-1,1V12h4V3c0-.55-.45-1-1-1Zm3,7v3h4v-2c0-.55-.45-1-1-1h-3ZM3,7h3v5H2v-4c0-.55,.45-1,1-1Z"
          fill="#1a1d1f"
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>
);
