import { createSlice } from "@reduxjs/toolkit";
import {
  createNewArticle,
  saveArticleData,
  getArticleById,
  saveStats,
  getStats,
  exportArticle,
  checkPlagiarismScore,
  checkPlagiarismAndUpdate,
} from "../services/articles";

import {
  setMetaDescription,
  setMetaTitle,
  setH1Tag,
  setDescriptionDataLoaded,
} from "./categoryTextEditor";

import {
  searchTopic,
  requestTopicDetails,
  searchTopicForCategory,
  setLoadingStep2,
} from "../reducers/searchTopic";

import {
  setTopicCoverageData,
  setTopicCoverage,
  setHighTopicCoverage,
  setLowTopicCoverage,
} from "./topicCoverage";

import { setHeadingsForOptimization } from "./headingsOptimization";
import { setTopicDensity, setMaxTopicDensity } from "./topicDensity";
import { setMaxImages } from "./images";
import { setMaxWordCount } from "./counters";
import {
  setKeywordsSearch,
  setTopicRules,
  getCompetitionOnTopic,
  setTopicData,
  setCompetitionData,
  getCompetitionOnCategory,
} from "./searchTopic";
import { setExpectedTitle, setTitleVariations } from "./titleOptimization";
import { getTimeFromDate } from "../utils/dateUtility";
import {
  sampleArticleStats,
  sampleArticle,
  sampleCompetition,
} from "../config/demoEditorData";

import { TYPE_ARTICLE, TYPE_NATIVE } from "../common/consts";
import { setApiError } from "./apiErrors";

export const articleSlice = createSlice({
  name: "articles",
  initialState: {
    articleId: null,
    articlesCollection: [],
    currentArticle: null,
    articleScore: 0,
    saveArticleStatus: "Not Saved",
    plagiarismStatus: null,
  },

  reducers: {
    setArticleId: (state, { payload }) => {
      return {
        ...state,
        articleId: payload,
      };
    },

    setArticle: (state, { payload }) => {
      return {
        ...state,
        currentArticle: payload,
      };
    },

    setArticlesCollection: (state, { payload }) => {
      return {
        ...state,
        articlesCollection: payload,
      };
    },

    setArticleScore: (state, { payload }) => {
      return {
        ...state,
        articleScore: payload,
      };
    },

    setSaveStatus: (state, { payload }) => {
      return {
        ...state,
        saveArticleStatus: payload,
      };
    },
    setPlagiarismStatus: (state, { payload }) => {
      return {
        ...state,
        plagiarismStatus: payload,
      };
    },
  },
});

// actions
export const { setArticleId } = articleSlice.actions;
export const { setArticle } = articleSlice.actions;
export const { setArticlesCollection } = articleSlice.actions;
export const { setArticleScore } = articleSlice.actions;
export const { setSaveStatus } = articleSlice.actions;
export const { setPlagiarismStatus } = articleSlice.actions;

// only displays plagiarism
export const plagiarismScore = (id) => async (dispatch) => {
  try {
    const plagiarism = await checkPlagiarismScore(id);
    dispatch(setPlagiarismStatus(plagiarism));
    return plagiarism;
  } catch (error) {
    dispatch(setApiError(error));
  }
};

export const setPlagiarismFromSubmit = (plagiarism) => (dispatch) => {
  dispatch(setPlagiarismStatus(plagiarism));
};

// actual check plagiarism
export const checkAndUpdatePlagiarismScore = (id) => async (dispatch) => {
  try {
    const plagiarism = await checkPlagiarismAndUpdate(id);
    dispatch(setPlagiarismStatus(plagiarism));
    return plagiarism;
  } catch (error) {
    dispatch(setApiError(error));
  }
};

export const onCreateNewArticle = (type) => async (dispatch, getState) => {
  const { searchKeywords, userProjectId } = getState().searchtopic;

  // add type when creating a new article
  try {
    const articleId = await createNewArticle(
      searchKeywords,
      type,
      userProjectId
    );

    dispatch(setArticleId(articleId));

    return articleId;
  } catch (error) {
    dispatch(setApiError(error));
  }
};

// provide type 2 when saving the article
export const saveArticle = (type) => async (dispatch, getState) => {
  // console.log("SAVING ARTICLE");
  dispatch(setSaveStatus("Autosaving..."));

  if (type === TYPE_NATIVE) {
    // shortcircuit when type is NATIVE, the functionality for that is different
    // we don't have stats for NATIVE type
    const article = await dispatch(saveNativeTypeArticle());
    return article;
  } else {
    const { articleData, stats } = dispatch(getDataByType(type));

    const articlePayload = {
      id: articleData.articleId,
      name: articleData.title,
      content: articleData.editorData,
    };

    //TODO: get this from stats instead
    const { metaTitle, metaDescription, h1Tag } = getState().categorytexteditor;

    const descriptionPayload = {
      id: articleData.articleId,
      name: articleData.title,
      content: articleData.editorData,
      meta_title: metaTitle,
      h1_tag: h1Tag,
      meta_description: metaDescription,
    };

    const payload = type === TYPE_ARTICLE ? articlePayload : descriptionPayload;

    if (articleData.articleId) {
      try {
        const resultArticle = await saveArticleData(payload);

        const saved = await saveStats(articleData.articleId, stats);
        // const parsedStats = JSON.parse(saved.stats);
        const newStats = saved.stats;
        const dateFromStats = newStats.dateObject;
        const saveTime = getTimeFromDate(dateFromStats);

        dispatch(setSaveStatus(`Last Autosave: ${saveTime}`));
        return resultArticle;
      } catch (error) {
        dispatch(setApiError(error));
      }
    }
  }
};

const saveNativeTypeArticle = () => async (dispatch, getState) => {
  const { articleId } = getState().articles;
  const {
    editorInstance,
    parsedEditor: { headings1: title },
  } = getState().texteditor;

  const editorData = editorInstance.getHTML();

  const trimmed = title.trim();
  const cleanTitle = trimmed.replace(/&nbsp;/g, "");

  const articleData = {
    articleId,
    title: cleanTitle || "Insert title here...",
    editorData: editorData,
  };
  const nativePayload = {
    id: articleData.articleId,
    name: articleData.title,
    content: articleData.editorData,
  };

  try {
    const result = await saveArticleData(nativePayload);

    if (result.id) {
      const today = new Date();
      const saveTime = getTimeFromDate(today);

      dispatch(setSaveStatus(`Last Autosave: ${saveTime}`));
      return result;
    }
  } catch (error) {
    dispatch(setApiError(error));
  }
};

const getDataByType = (type) => (dispatch, getState) => {
  if (type === TYPE_ARTICLE) {
    const {
      editorInstance,
      parsedEditor: { headings1: title },
    } = getState().texteditor;
    // extract editor data (html content) from the editor instance held in state
    const editorData = editorInstance.getHTML();
    const { articleId, articleScore } = getState().articles;
    const { maxImages } = getState().images;
    const { maxTopicDensity, topics } = getState().topicdensity;
    const { allheadings } = getState().headingsoptimization;
    const { maxWords } = getState().counters;
    const { lowTopics, highTopics } = getState().topiccoverage;
    const {
      searchKeywords,
      topicData: { estimatedVisits = 0 },
      topicRules,
    } = getState().searchtopic;
    const { expectedTitle, titleVariations } = getState().titleoptimization;

    const radarData = {
      images: maxImages,
      maxTopicDensity,
      headings: allheadings,
      wordcount: maxWords,
    };

    const topicCoverage = {
      high: highTopics,
      low: lowTopics,
    };

    const today = new Date();
    const stats = {
      keywordSearched: searchKeywords,
      expectedTitle,
      titleVariations,
      estimatedVisits,
      radarData,
      topicCoverage,
      topics,
      topicRules,
      articleScore,
      dateObject: today,
    };

    // remove whitespaces and &nbsp from title
    const trimmed = title.trim();
    const cleanTitle = trimmed.replace(/&nbsp;/g, "");

    const articleData = {
      articleId,
      title: cleanTitle || "Insert title here...",
      editorData,
    };

    return {
      articleData,
      stats,
    };
  } else {
    const { metaTitle, metaDescription, h1Tag } = getState().categorytexteditor;
    const { editorInstance } = getState().texteditor;
    // extract editor data (html content) from the editor instance held in state
    const categoryEditorData = editorInstance.getHTML();
    const { articleId } = getState().articles;
    const { activeTopicCoverage } = getState().topiccoverage;
    const { expectedTitle } = getState().titleoptimization;
    const { searchKeywords } = getState().searchtopic;

    // remove whitespaces and &nbsp from title
    const trimmed = metaTitle && metaTitle.trim();
    const cleanTitle = trimmed && trimmed.replace(/&nbsp;/g, "");

    const articleData = {
      articleId,
      title: cleanTitle || "Insert title here...",
      editorData: categoryEditorData,
    };

    const today = new Date();

    const descStats = {
      keywordSearched: searchKeywords,
      topicCoverage: activeTopicCoverage,
      dateObject: today,
      metaDescription,
      h1Tag,
      metaTitle: cleanTitle,
      expectedTitle,
    };

    return {
      articleData,
      stats: descStats,
    };
  }
};

export const requestArticleById = (id) => async (dispatch) => {
  dispatch(setLoadingStep2(true));
  // if fetch method was initialised with an id, we get the article from database
  // otherwise we load the sample article, meaning we are in demo mode
  try {
    const article = id ? await getArticleById(id) : sampleArticle;
    if (article.type === TYPE_NATIVE) {
      dispatch(setLoadingStep2(false));
      dispatch(getCompetitionOnTopic(article.name));
      // set estimated visits
      const topicData = {
        estimatedVisits: null,
      };
      dispatch(setTopicData(topicData));
    } else {
      dispatch(setupStatsByArticleType(article));
    }
    dispatch(setArticle(article));

    dispatch(setArticleId(article.id));
  } catch (error) {
    dispatch(setApiError(error));
  }
};

const setupStatsByArticleType = (article) => async (dispatch, getState) => {
  // if fetch method was initialised with an id, we get the article stats from database
  // otherwise we load the sample stats, meaning we are in demo mode
  const { topicLanguage } = getState().searchtopic;
  try {
    const { stats } = article.id
      ? await getStats(article.id)
      : sampleArticleStats;

    // set last autosave

    if (article.type === TYPE_ARTICLE) {
      // if we have stats we setup everything in place, otherwise we call for
      // step1 and step2 endpoint

      // console.log("GETTING STATS FOR ARTICLE ", stats);
      if (stats) {
        const dateFromStats = stats.dateObject;
        const saveTime = getTimeFromDate(dateFromStats);
        dispatch(setSaveStatus(`Last Autosave: ${saveTime}`));
        // set keyword search
        dispatch(setKeywordsSearch(stats.keywordSearched));

        // set topic coverage
        dispatch(setTopicCoverageData(stats.topicCoverage.high));
        dispatch(setHighTopicCoverage(stats.topicCoverage.high));
        dispatch(setLowTopicCoverage(stats.topicCoverage.low));

        // set headings
        dispatch(setHeadingsForOptimization(stats.topicCoverage.high));

        // set title
        dispatch(setExpectedTitle(stats.expectedTitle));
        dispatch(setTitleVariations(stats.titleVariations));

        // set topicDensity
        dispatch(setMaxTopicDensity(stats.radarData.maxTopicDensity));
        dispatch(setTopicDensity(stats.topics));

        // set images
        dispatch(setMaxImages(stats.radarData.images));

        // set wordcount
        dispatch(setMaxWordCount(stats.radarData.wordcount));

        // set estimated visits
        const topicData = {
          estimatedVisits: stats.estimatedVisits,
        };
        dispatch(setTopicData(topicData));

        // set topic rules
        dispatch(setTopicRules(stats.topicRules));

        // get competition data for this topic
        if (article.id) {
          dispatch(getCompetitionOnTopic(stats.keywordSearched));
          dispatch(setLoadingStep2(false));
        } else {
          dispatch(setCompetitionData(sampleCompetition));
        }
      } else {
        // call topic reducer function to request data
        dispatch(searchTopic(article.name));
        dispatch(requestTopicDetails(article.name, topicLanguage));
      }
    } else {
      if (stats) {
        const topicCoverage = !stats.topicCoverage.high
          ? stats.topicCoverage
          : stats.topicCoverage.high;
        dispatch(setKeywordsSearch(stats.keywordSearched));
        dispatch(setTopicCoverage(topicCoverage));
        dispatch(setExpectedTitle(stats.expectedTitle));
        dispatch(setMetaTitle(stats.metaTitle));
        dispatch(setH1Tag(stats.h1Tag));
        dispatch(setMetaDescription(stats.metaDescription));
        dispatch(getCompetitionOnCategory(stats.keywordSearched));
        dispatch(setLoadingStep2(false));
      } else {
        // call topic reducer function to request data
        dispatch(searchTopic(article.name));
        dispatch(searchTopicForCategory(article.name));
        dispatch(getCompetitionOnCategory(stats.keywordSearched));
      }
    }
    dispatch(setDescriptionDataLoaded(true));
  } catch (error) {
    // console.log({ error });
    // dispatch(setApiError(error));
    // console.log("GETTING ERROR AND on stats ", error);
    // if (error.status === 404) {
    //   if (article.type === TYPE_ARTICLE) {
    //     dispatch(searchTopic(article.name));
    //     dispatch(requestTopicDetails(article.name));
    //   } else {
    //     dispatch(searchTopic(article.name));
    //     dispatch(searchTopicForCategory(article.name));
    //   }
    // } else {
    //   dispatch(setApiError(error));
    // }
  }
};

export const exportArticleToPdf = () => async (dispatch, getState) => {
  const { articleId } = getState().articles;

  try {
    await exportArticle(articleId);
  } catch (error) {
    dispatch(setApiError(error));
  }
};

// selectors
export const allArticles = ({ articles: { articlesCollection } }) =>
  articlesCollection;

export const getCurrentArticle = ({ articles: { currentArticle } }) =>
  currentArticle;

export const getCurrentArticleId = ({ articles: { articleId } }) => articleId;

export const getArticleSavingStatus = ({ articles: { saveArticleStatus } }) =>
  saveArticleStatus;

export const getPlagiarismStatus = ({ articles: { plagiarismStatus } }) =>
  plagiarismStatus;

export default articleSlice.reducer;
