import React from "react";

import "./DashboardPopup.css";

const DashboardPopup = ({ width, height, children }) => {
  return (
    <div style={{ width, height }} className="dashboard-popup-wrapper">
      {children}
    </div>
  );
};

export default DashboardPopup;
