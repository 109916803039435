import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { BlackTooltip } from "../UITooltips/UITooltip";

import "./TagSelectComponent.css";

const unAssigned = {
  id: -111,
  name: "Not Assigned",
  rating: null,
  initials: "N/A",
  label: "n/a",
  value: -121,
};

const TagSelectComponent = ({
  data,
  handleOptionChange,
  preselectedValue,
  readOnlyDisplay,
}) => {
  const [selectedValue, setSelectedValue] = useState(unAssigned);
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState();

  useEffect(() => {
    if (preselectedValue) setSelectedValue(preselectedValue);
    else {
      setSelectedValue(unAssigned);
    }
  }, [preselectedValue]);

  useEffect(() => {
    const formattedOptions =
      data &&
      data.map((option) => {
        return {
          ...option,
          value: option.id,
          label: option.name,
          color: option.color,
        };
      });

    const noOption = {
      id: -234,
      value: "no-option-selected",
      label: "--",
      initials: "N/A",
      name: "--",
    };

    const newOptions = [noOption, ...formattedOptions];
    setOptions(newOptions);
  }, [data]);

  const handleChangedValue = (values) => {
    const currentValue = values.value !== "no-option-selected" ? values : null;
    setSelectedValue(currentValue || unAssigned);
    setIsOpen(false);
    // selectedValue from state will send the previous option selected
    // we need to know which user was unassigned (if that's the case)
    handleOptionChange(currentValue, selectedValue); // send this back to parent
  };

  return (
    <>
      <div className="tag-select-component">
        <div
          className="tag-selected"
          onClick={!readOnlyDisplay ? () => setIsOpen(!isOpen) : null}
        >
          <BlackTooltip
            arrow
            placement="top"
            title={selectedValue && selectedValue.name}
          >
            <div>{selectedValue && selectedValue.initials}</div>
          </BlackTooltip>
          {selectedValue && selectedValue.rating && (
            <BlackTooltip
              arrow
              placement="top"
              title="Writer's monthly aggregate rating based on the individual rating of articles"
            >
              <div className="selected-rating">{selectedValue.rating}</div>
            </BlackTooltip>
          )}
        </div>
        {isOpen && (
          <div className="tag-list">
            <Scrollbars>
              {options.map((item) => (
                <div
                  key={item.id}
                  className="tag-list-item"
                  onClick={() => handleChangedValue(item)}
                >
                  {item.name}
                </div>
              ))}
            </Scrollbars>
          </div>
        )}
      </div>
      {isOpen && (
        <div className="dropdown-closer" onClick={() => setIsOpen(false)}></div>
      )}
    </>
  );
};

export default TagSelectComponent;
