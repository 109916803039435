import React from "react";

import Header from "../Header";
import SidebarLeft from "../SidebarLeft";
import ProjectsPerformanceDashboardList from "./ProjectsPerformanceDashboardList";
import Scrollbars from "react-custom-scrollbars";

const ProjectsPerformanceDashboard = () => {
  return (
    <div className="homepage">
      <SidebarLeft />
      <div className="homepage-wrapper">
        <Header />
        <div className="bottom-header"></div>
        <Scrollbars>
          <ProjectsPerformanceDashboardList />
        </Scrollbars>
      </div>
    </div>
  );
};

export default ProjectsPerformanceDashboard;
