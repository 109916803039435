import React from "react";
import LoginForm from "../Components/LoginForm";

import "./LoginComponent.css";

const LoginComponent = () => {
  return (
    <div className="login-component">
      <div className="login-left-content">
        <img src="/images/_login.png" width="180px" height="auto" alt="Login" />
        <div>
          <p className="login-copy-area">Welcome back!</p>
          <p className="login-subcopy">
            You are seconds away from skyrocketing your <br />
            content marketing performance.
          </p>
        </div>
      </div>
      <div className="login-divider"></div>
      <div className="login-right-content">
        <div className="login-app-logo">
          <img
            src="/images/logo-contero.svg"
            alt="logo"
            width="200px"
            height="65px"
          />
        </div>
        <LoginForm />
      </div>
    </div>
  );
};

export default LoginComponent;
