import axios from "axios";
import { BASE_URL } from "../common/consts";

const baseApi = "api/performance";
const url = "google-code";
const list = "article-list";
const projects = "project-list";
const impressions = "all-impressions";
const visits = "all-traffic";
const opportunity = "all-opportunity";
const health = "all-health";
const publishedArticles = "all-articles";

export const sendGoogleCode = (token, googleToken) =>
  axios
    .post(
      `${BASE_URL}/${baseApi}/${url}`,
      { code: googleToken },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

export const getDashPerformanceList = (token, payload) =>
  axios
    .post(`${BASE_URL}/${baseApi}/${list}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

export const getProjectsDashPerformanceList = (token, payload) =>
  axios
    .post(`${BASE_URL}/${baseApi}/${projects}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

export const impressionsStats = (token, payload) =>
  axios
    .post(`${BASE_URL}/${baseApi}/${impressions}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

export const visitsStats = (token, payload) =>
  axios
    .post(`${BASE_URL}/${baseApi}/${visits}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

export const opportunityStats = (token, payload) =>
  axios
    .post(`${BASE_URL}/${baseApi}/${opportunity}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

export const healthStats = (token, payload) =>
  axios
    .post(`${BASE_URL}/${baseApi}/${health}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

export const allPublished = (token, payload) =>
  axios
    .post(`${BASE_URL}/${baseApi}/${publishedArticles}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));
