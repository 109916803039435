import React from "react";
import SidebarLeft from "../SidebarLeft";
import Header from "../Header";

import ProjectBriefForm from "./ProjectBriefForm";

import "./ProjectBriefContent.css";
import Scrollbars from "react-custom-scrollbars";

const ProjectBriefContent = () => {
  return (
    <div className="homepage">
      <SidebarLeft />
      <div className="homepage-wrapper">
        <Header />
        <div className="bottom-header"></div>
        <div className="project-brief-content">
          <Scrollbars>
            <ProjectBriefForm />
          </Scrollbars>
        </div>
      </div>
    </div>
  );
};

export default ProjectBriefContent;
