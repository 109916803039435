import React, { useEffect, useState } from "react";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import { gapi } from "gapi-script";
import { sendGoogleAuthToken } from "../../services/performance-dashboard";

import "./GoogleAuth.css";

const GoogleLoginButton = () => {
  const [showLogin, setShowLogin] = useState(true);

  const clientId =
    "647507267819-m3hnd6tqpfa3cgu6u4ebvri6o3digtn0.apps.googleusercontent.com";
  const onSuccess = (res) => {
    console.log("SUCESSFULLY logged in!");
    sendToBackend(res.code);
    setShowLogin(false);
  };

  const sendToBackend = async (code) => {
    const result = await sendGoogleAuthToken(code);
    console.log({ result });
  };

  const onFailure = (res) => {
    console.log("Ooops! ", res);
  };

  const initAll = () => {
    gapi.auth2.init({
      clientId: clientId,
      scope: "https://www.googleapis.com/auth/webmasters.readonly",
    });
  };

  useEffect(() => {
    gapi.load("client: auth2", initAll);
  });

  return (
    <div>
      {/* {showLogin && ( */}
      <GoogleLogin
        clientId={clientId}
        buttonText="Connect Search Console"
        onSuccess={onSuccess}
        onFailure={onFailure}
        accessType="offline"
        responseType="code"
        cookiePolicy={"single_host_origin"}
        isSignedIn={true}
        prompt="consent"
        className="custom-google-btn-style"
      />
      {/* )} */}
      {/* {showLogout && (
        <GoogleLogout
          clientId={clientId}
          buttonText="Logout"
          onLogoutSuccess={logoutSuccess}
          className="custom-google-btn-style"
        ></GoogleLogout>
      )} */}
    </div>
  );
};

export default GoogleLoginButton;
