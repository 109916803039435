import React from "react";

const TableGridGenerator = ({
  tableData, // data to be displayed in the table rows
  headers, // array of objects with name and style properties
  size, // string of grid-template-columns
  RowComponent, // component to be rendered in each row
  headerStyle, // style object for the entire header
  contentStyle, // style object for the entire row content
  tableStyle, // style object for the main table
  rowGap = 0, // vertical distance between rows
}) => {
  return (
    <div style={{ ...tableStyle }}>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: size,
          ...headerStyle,
        }}
      >
        {headers.map((header) => (
          <div key={header.name} style={header.style}>
            {header.name}
          </div>
        ))}
      </div>
      <div
        style={{
          ...contentStyle,
        }}
      >
        {tableData.map((data, index) => (
          <div key={data.id || index} style={{ margin: `${rowGap}px 0` }}>
            <RowComponent rowData={data} gridSize={size} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TableGridGenerator;
