import { getCompetition, getCategoryCompetition } from "../api/competition";
import { getUserToken } from "../utils/userStatus";

export const requestCompetition = async (topic, language) => {
  const search = {
    keyword: topic,
    lang: language,
  };

  const token = getUserToken();

  const { allHtags, competitionList, avgWords } = await getCompetition(
    search,
    token
  );

  const competitionData = {
    articles: competitionList,
    allHtags,
    avgWords,
  };

  return competitionData;
};

// competition for category
export const requestCategoryCompetition = async (topic, language) => {
  const search = {
    keyword: topic,
    lang: language,
  };

  const token = getUserToken();

  try {
    const { allHtags, competitionList, avgWords } =
      await getCategoryCompetition(search, token);

    const categoryCompetitionData = {
      articles: competitionList,
      allHtags,
      avgWords,
    };

    return {
      categoryCompetitionData,
      error: false,
    };
  } catch ({ data }) {
    return {
      ...data,
      error: true,
    };
  }
};
