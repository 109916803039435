import React from "react";
import { TrendArrowUp } from "../../../UI_utils/SVGIcons";
import { CartIcon } from "../Icons/DashboardIcons";
import InfoCard from "./InfoCard";
import Button from "../../../Shared/Button";

import "./TopicResearchContent.css";
import CustomSvgIcon from "../../TipTapEditor/CustomSvgIcon";
import ResultsChartSummary from "./ResultsChartSummary";
import CustomBarChart from "../CustomBarChart/CustomBarChart";

const TopicResearchContent = ({ data }) => {
  return (
    <div className="topic-research-card">
      <div className="cards-wrapper">
        <div className="info-cards">
          <InfoCard
            mainIcon={<CustomSvgIcon icon="mouse-line" />}
            spanColor="#b1e5fc"
            title="Traffic Opportunity"
            cardData={data.estimatedVisits}
          >
            <div className="card-content">
              <CartIcon width={14} height={14} />
              <div className="intent">{data.queryIntent}</div>
            </div>
          </InfoCard>

          <InfoCard
            mainIcon={<CustomSvgIcon icon="search-line" />}
            spanColor="#cabdff"
            title="Search Volume"
            cardData={data.monthSearches}
          >
            <div className="card-content">
              <TrendArrowUp />
              <div className="trend">37.8% YOY</div>
            </div>
          </InfoCard>
        </div>
        <div className="research-chart-data">
          <CustomBarChart values={data.monthlyValues} />
          <div className="results-chart-info">
            <ResultsChartSummary />
            <div className="research-submit">
              <Button fullSize>Write Article</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopicResearchContent;
