import { createSlice } from "@reduxjs/toolkit";
import {
  matchingKeywords,
  topicCoverageMaxPoints,
  topicCoverageCurrentPoints,
} from "../utils/topicCoverage";

/* in case no topic was searched, we set default data to whatever
we have in the local storage
this will be overriden when we search for a new topic
*/
// const topicsFromLocalStorage = JSON.parse(localStorage.getItem("topicCoverage"));

export const topicCoverageSlice = createSlice({
  name: "topiccoverage",
  initialState: {
    totalPoints: 0,
    currentPoints: 0,
    activeTopicCoverage: [], // this is what we currently display
    lowTopics: [],
    highTopics: [],
    showLowTopics: false,
  },
  reducers: {
    setTopicCoverage: (state, { payload }) => {
      return {
        ...state,
        activeTopicCoverage: payload,
      };
    },

    setHighTopicCoverage: (state, { payload }) => {
      return {
        ...state,
        highTopics: payload,
      };
    },

    setLowTopicCoverage: (state, { payload }) => {
      return {
        ...state,
        lowTopics: payload,
      };
    },

    setTopicCoverageMax: (state, { payload }) => {
      return {
        ...state,
        totalPoints: payload,
      };
    },

    setTopicCoverageCurrent: (state, { payload }) => {
      return {
        ...state,
        currentPoints: payload,
      };
    },

    switchTopics: (state, { payload }) => {
      return {
        ...state,
        activeTopicCoverage: payload,
      };
    },

    setTopicsFlag: (state, { payload }) => {
      return {
        ...state,
        showLowTopics: payload,
      };
    },
  },
});

// actions
export const { setTopicCoverage } = topicCoverageSlice.actions;
export const { setHighTopicCoverage } = topicCoverageSlice.actions;
export const { setLowTopicCoverage } = topicCoverageSlice.actions;

export const { setTopicCoverageMax } = topicCoverageSlice.actions;
export const { setTopicCoverageCurrent } = topicCoverageSlice.actions;

export const { switchTopics } = topicCoverageSlice.actions;
export const { setTopicsFlag } = topicCoverageSlice.actions;

export const setTopicCoverageData = (data) => (dispatch) => {
  dispatch(setTopicCoverage(data));
};

export const updateMatchingWords = () => (dispatch, getState) => {
  const { parsedEditor } = getState().texteditor;
  const { parsedMetaData } = getState().categorytexteditor;
  const { applicationMode } = getState().applicationmode;

  const isCategory = applicationMode === "category";
  const concatData = {
    headings: parsedEditor.headings.concat(" ", parsedMetaData.headings),
    headings1: parsedEditor.headings1.concat(" ", parsedMetaData.headings1),
    paragraphs: parsedEditor.paragraphs.concat(" ", parsedMetaData.paragraphs),
  };

  const parsedData = isCategory ? concatData : parsedEditor;

  const { activeTopicCoverage } = getState().topiccoverage;
  const matched = matchingKeywords(activeTopicCoverage, parsedData);

  dispatch(setTopicCoverage(matched));
};

export const updateMaxTopicCoveragePoints = (topicData) => (dispatch) => {
  const maxPoints = topicCoverageMaxPoints(topicData);
  dispatch(setTopicCoverageMax(maxPoints));
};

export const updateCurrentTopicCoveragePoints = (usedTopics) => (dispatch) => {
  const currentPoints = topicCoverageCurrentPoints(usedTopics);

  dispatch(setTopicCoverageCurrent(currentPoints));
};

export const toggleTopics = (displayLowTopics) => (dispatch, getState) => {
  const { lowTopics, highTopics, activeTopicCoverage } =
    getState().topiccoverage;
  if (displayLowTopics) {
    // first save everything into highTopics (user might have deleted some keywords)
    dispatch(setHighTopicCoverage(activeTopicCoverage));
    dispatch(switchTopics(lowTopics));
  } else {
    // same thing for lowTopics (user might have deleted some keywords)
    dispatch(setLowTopicCoverage(activeTopicCoverage));
    dispatch(switchTopics(highTopics));
  }
  dispatch(setTopicsFlag(displayLowTopics));
};

// selectors
export const getTopicCoverageData = ({
  topiccoverage: { activeTopicCoverage },
}) => activeTopicCoverage;

export const getMaxTopicCoverage = ({ topiccoverage: { totalPoints } }) =>
  totalPoints;

export const getCurrentTopicCoverage = ({ topiccoverage: { currentPoints } }) =>
  currentPoints;

export const geTopicCoveragePerc = ({
  topiccoverage: { currentPoints, totalPoints },
}) => (currentPoints * 100) / totalPoints || 0;

export const lowTopicsFlag = ({ topiccoverage: { showLowTopics } }) =>
  showLowTopics;

export const hasLowTopics = ({ topiccoverage: { lowTopics } }) =>
  lowTopics.length > 0;

export default topicCoverageSlice.reducer;
