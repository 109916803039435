import React from "react";
import {
  PerformanceDownIcon,
  PerformanceUpIcon,
} from "../../UI_utils/SVGIcons";
import CustomBars from "./CustomBars";

import "./PerformanceStatsSmallCard.css";
import TextWithInfoIcon from "./TextWithInfoIcon";

const PerformanceStatsSmallCard = ({
  icon,
  title,
  currentData,
  data,
  isView,
}) => {
  const period = data && data.desc.includes("month") ? "month" : "week";
  return (
    <div className="performance-stats-small">
      <div className="stats-small-wrapper">
        <div className="icon-with-title">
          <div className={!isView ? "icon-wrapper" : "icon-wrapper-view"}>
            {icon}
          </div>
          <TextWithInfoIcon
            title={title}
            tooltipText={title}
            fontSize={17}
            weight={500}
            color="#11142D"
          />
        </div>
        <div className="chart-info">
          <div className="current-value">
            {new Intl.NumberFormat("en-IN").format(currentData)}
          </div>
          <CustomBars data={data} title={title} />
        </div>
        <div className="chart-bottom-info">
          {data && !data.ascending && (
            <div className="perf-icon-down-wrapper">
              <PerformanceDownIcon />
            </div>
          )}
          {data && data.ascending && (
            <div className="perf-icon-up-wrapper">
              <PerformanceUpIcon />
            </div>
          )}
          <div className="perc-value">{data && data.value}</div>
          <TextWithInfoIcon
            title={data && data.desc}
            tooltipText={`The difference in percentage of this ${period}'s ${title.toLowerCase()} versus last month's ${title.toLowerCase()}`}
            fontSize={14}
          />
        </div>
      </div>
    </div>
  );
};

export default PerformanceStatsSmallCard;
