import React, { Fragment } from "react";
import { Radar, RadarChart, PolarGrid } from "recharts";
import { useSelector } from "react-redux";

import { geTopicCoveragePerc } from "../reducers/topicCoverage";
import { getTitleOptimization } from "../reducers/titleOptimization";
import {
  getTopicDensity,
  getMaxTopicDensity,
  getTopicDensityPerc,
} from "../reducers/topicDensity";
import {
  getAllHeadings,
  getUsedHeadings,
  getHeadingsPerc,
} from "../reducers/headingsOptimization";
import {
  getMaxImages,
  getCurrentImages,
  getImagesPerc,
} from "../reducers/images";
import {
  getWordCount,
  getMaxWordCount,
  getWordsPercentage,
} from "../reducers/counters";

import "./Chart.css";
import TextWithInfoIcon from "./PerformanceStatsSmallCard/TextWithInfoIcon";

const Chart = () => {
  const topicCoveragePercentage = useSelector(geTopicCoveragePerc);

  const titleOptimization = useSelector(getTitleOptimization);

  const currentTopicDensity = useSelector(getTopicDensity);
  const maxTopicDensity = useSelector(getMaxTopicDensity);
  const topicDensityPercentage = useSelector(getTopicDensityPerc);

  const currentHeadings = useSelector(getUsedHeadings);
  const maxHeadings = useSelector(getAllHeadings);
  const headingsPercentage = useSelector(getHeadingsPerc);

  const maxImages = useSelector(getMaxImages);
  const currentImages = useSelector(getCurrentImages);
  const imagePercentage = useSelector(getImagesPerc);

  const currentWordCount = useSelector(getWordCount);
  const maxWordCount = useSelector(getMaxWordCount);
  const wordsPercentage = useSelector(getWordsPercentage);

  /* if category percent goes higher than max limit, we set the value to 100
  and stop updating the graph - this means they reach their goal */
  const getCategoryValue = (categoryPercent) => {
    const perc = categoryPercent || 0;
    const value = perc <= 100 ? perc : 100;

    return value;
  };

  const data = [
    {
      subject: "this is topic coverage",
      A: getCategoryValue(topicCoveragePercentage) || 0,
    },
    {
      subject: "this is title optimization",
      A: titleOptimization ? 100 : 0,
    },

    {
      subject: "this is images",
      A: getCategoryValue(imagePercentage),
    },
    {
      subject: "this is topic density",
      A: getCategoryValue(topicDensityPercentage),
    },
    {
      subject: "this is wordcount ",
      A: getCategoryValue(wordsPercentage),
    },
    {
      subject: "this is headings optimization ",
      A: getCategoryValue(headingsPercentage),
    },
  ];

  return (
    <Fragment>
      <div className="chart-custom-labels">
        <div className="topic-coverage">
          <TextWithInfoIcon
            title="Topic coverage"
            tooltipText="The percentage of used keywords from the total number of keywords provided for this topic"
            fontSize={14}
            weight={600}
            color="#3A4257"
            font="Graphik"
          />
          <p className="chart-metric">{`${Math.round(
            topicCoveragePercentage
          )}%`}</p>
        </div>
        <div className="headings-optimization">
          <TextWithInfoIcon
            title="Headings"
            tooltipText="How many of the suggested headings you have used"
            fontSize={14}
            weight={600}
            color="#3A4257"
            font="Graphik"
          />
          <div>optimization</div>
          <p className="chart-metric">{`${currentHeadings}/${maxHeadings}`}</p>
        </div>
        <div className="chart-wordcount">
          <TextWithInfoIcon
            title="Wordcount"
            tooltipText="How long the article should be according to the user intent and competition"
            fontSize={14}
            weight={600}
            color="#3A4257"
            font="Graphik"
          />

          <p className="chart-metric">{`${new Intl.NumberFormat("en-IN").format(
            currentWordCount
          )}/${new Intl.NumberFormat("en-IN").format(maxWordCount)}`}</p>
        </div>
        <div className="topic-density">
          <TextWithInfoIcon
            title="Topic density"
            tooltipText="The frequency of the most important keyword and how many times you have used it compared to the guidelines"
            fontSize={14}
            weight={600}
            color="#3A4257"
            font="Graphik"
          />
          <p className="chart-metric">{`${currentTopicDensity}/${maxTopicDensity}`}</p>
        </div>
        <div className="chart-images">
          <TextWithInfoIcon
            title="Images"
            tooltipText="The number of images you should insert in the article. To prevent editor performance issues, the number of uploaded images is limited to 40."
            fontSize={14}
            weight={600}
            color="#3A4257"
            font="Graphik"
          />
          <p className="chart-metric">{`${currentImages}/${maxImages}`}</p>
        </div>
        <div className="title-optimization">
          <TextWithInfoIcon
            title="Title"
            tooltipText="Checks if you have used the suggested keyword in the title"
            fontSize={14}
            weight={600}
            color="#3A4257"
            font="Graphik"
          />
          <div>optimization</div>
          <p className="chart-metric">{`${
            titleOptimization ? `Yes` : `No`
          }`}</p>
        </div>
      </div>
      <div className="radar-chart">
        <RadarChart
          className="radar-chart"
          cx={190}
          cy={100}
          outerRadius={90}
          width={269}
          height={250}
          data={data}
          margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
        >
          <PolarGrid className="radar-chart" />
          <Radar
            name="suggestions"
            className="radar-chart"
            dataKey="A"
            stroke="#aee8ca"
            fill="#aee8ca"
            fillOpacity={0.9}
            isAnimationActive={false}
          ></Radar>
        </RadarChart>
      </div>
    </Fragment>
  );
};

export default Chart;
