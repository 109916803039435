/* base API path */

// const DEV_ENDPOINT = "https://ufc.dev.upswing.ro";
// const DEV_ENDPOINT = "https://api.dev.contero.io";
const DEV_ENDPOINT = "https://api.contero.seomonitor.com";
// const isProduction = process.env.NODE_ENV === "production";
// export const BASE_URL = isProduction ? PROD_ENDPOINT : DEV_ENDPOINT;
export const BASE_URL = DEV_ENDPOINT;

export const IMAGE_UPLOAD_PATH = "api/articles/upload-image";
// export const CSV_TEMPLATE_DOWNLOAD =
//   "https://api.contero.seomonitor.com/files/example.csv";
export const CSV_TEMPLATE_DOWNLOAD = "https://api.contero.seomonitor.com/files/example.csv";

/* error messages */
export const AUTH_ERR = "Your credentials have expired, please login again.";
export const GENERIC_ERR =
  "An error has occured during your last action. Please try again later or contact support.";

/* pages navigation path */
export const LOGIN_PATH = "/login";
export const REGISTER_PATH = "/register";
export const MULTI_STEP_REGISTER = "/multistep-register";
export const RESET_PASS_PATH = "/reset-password";
export const CHANGE_PASS_PATH = "/change-password";
export const HOME_PATH = "/article";
export const DEMO_APP_PATH = `/demo${HOME_PATH}`;
export const ESHOP_PATH = "/eshop";
export const NATIVE_PATH = "/native";
export const DASHBOARD_PATH = "/dashboard";
export const CLIENT_BRIEF_PATH = "/project-brief";
export const CLIENTS_DASHBOARD_PATH = "/projects-list";
export const PERFORMANCE_DASHBOARD_PATH = "/performance";
export const PROJECTS_PERFORMANCE_DASHBOARD_PATH = "/projects";
export const SEARCH_RESULTS_PATH = "/search-results";

/* other utilities consts */
export const DEFAULT_ARTICLE_ID = "1";
export const DEFAULT_ESHOP_ID = "1";
export const DEFAULT_NATIVE_ID = "1";
export const TYPE_ARTICLE = 0;
export const TYPE_DESCRIPTION = 1;
export const TYPE_NATIVE = 2;
export const NOTIFICATION_SERVER_ERROR =
  "Error retrieving data. Please try again later, or search for a different topic.";
export const OPEN_MODAL_INTERVAL = 500;
export const MAX_IMAGES_ALLOWED = 40;
export const IMG_PARTIALLY_ALLOWED = "NOT ALL IMAGES WERE UPLOADED";
export const IMG_NOT_ALLOWED = "YOU EXCEEDED THE MAXIMUM NUMBER OF IMAGES";

/* dashboard deadlines */
export const IN_TIME = "deadline-in-time";
export const DEADLINE_CLOSE = "deadline-close"; // one day until deadline
export const DEADLINE_PASSED = "deadline-passed"; // deadline today or passed
/* dashboard event types (endpoints)*/
export const ASSIGN = "asign";
export const UNASSIGN = "unasign";
/* dashboard properties (columns) - used to update the local state dynamically */
export const CREATED = "created";
export const DEADLINE = "deadline";
export const FEEDBACK = "feedback";
export const INDUSTRY = "industry";
export const PROJECT = "project";
export const PROOFREADER = "proofreader";
export const STATUS = "status";
export const TOPIC = "topic";
export const WRITER = "writer";
export const TYPE = "type";
export const SIMILARITY = "plagiarism";

/* dashboard menu actions */
export const WRITER_ASSIGN = "Assign writer";
export const WRITER_UNASSIGN = "Unassign writer";
export const WRITER_SWITCH = "Assign another writer";
export const PROOFREADER_ASSIGN = "Assign proofreader";
export const PROOFREADER_UNASSIGN = "Unassign proofreader";
export const PROOFREADER_SWITCH = "Assign another proofreader";
export const DEADLINE_CHANGE = "Change deadline";
export const CREATION_CHANGE = "Change creation date";
export const ARTICLE_DELETE = "Delete article";
export const ARTICLE_DELETE_BULK = "Delete bulk article";
export const CREATE_DASHBOARD_ENTRY = "create-new";
export const CREATE_DASHBOARD_DESCRIPTION_ENTRY = "create-new-description";
export const CREATE_DASHBOARD_NATIVE_ENTRY = "create-new-native";
export const RENAME_TOPIC = "Rename topic";
export const STATUS_CHANGE = "Change status";
export const EDIT_ENTRY = "Edit entry";
export const EDIT_LIVE_URL = "Edit live URL";

/* projects dashboard actions */
export const ADD_NEW_PROJECT = "Add new project";
export const MANAGE_PROJECT_MEMBERS = "Manage members";
export const EDIT_MEMBER_ROLE = "Edit member role";

// dashboard types
export const DASH_MANAGEMENT = "management";
export const DASH_PERFORMANCE = "performance";
export const DASH_BRIEFS = "brief";

/* user roles */
export const USER_MASTER = "ROLE_MASTER";
export const USER_PROOFREADER = "ROLE_PROOFREADER";
export const USER_WRITER = "ROLE_WRITER";
export const USER_CLIENT = "ROLE_CLIENT";
export const USER_SUBCLIENT = "ROLE_SUBCLIENT";

/* feedback button message and actions */
export const WRITER_SUBMIT = {
  action: "asign-to-proof",
  message: "Submit",
};

export const WRITER_FEEDBACK = {
  action: "asign-to-proof",
  message: "Submit",
};

export const PROOF_FEEDBACK = {
  action: "submit-feedback",
  message: "Submit",
};

export const PROOF_NO_FEEDBACK = {
  action: "no-feedback",
  message: "Submit",
};

export const ROUND2_NO_FEEDBACK = {
  action: "no-feedback",
  message: "Submit",
};

export const ROUND2_PROOF_FEEDBACK = {
  action: "submit-feedback",
  message: "Submit",
};

export const Queries = {
  SEARCH_RESULTS: "search-results",
  AI_RESULTS: "ask-ai",
};
