import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import ProjectsPerformanceDashboard from "../Components/ProjectsPerformanceDashboard/ProjectsPerformanceDashboard";

const ProjectsPerformanceDashboardPage = () => {
  const [isUserAuth] = useState(!!localStorage.getItem("userToken"));

  return isUserAuth ? (
    <ProjectsPerformanceDashboard />
  ) : (
    <Redirect to="/login" />
  );
};

export default ProjectsPerformanceDashboardPage;
