import { createSlice } from "@reduxjs/toolkit";
import { requestTopicSearch, getTopicDetails } from "../services/topic";
import { defaultTopicDetails } from "./defaultTopicResults";
import {
  requestCompetition,
  requestCategoryCompetition,
} from "../services/competition";
import {
  setTopicCoverageData,
  setHighTopicCoverage,
  setLowTopicCoverage,
} from "./topicCoverage";
import { setExpectedTitle, setTitleVariations } from "./titleOptimization";
import { setHeadingsForOptimization } from "./headingsOptimization";
import { setTopicDensity, setMaxTopicDensity } from "./topicDensity";
import { setMaxImages } from "./images";
import { setMaxWordCount } from "./counters";
import { getUserToken } from "../utils/userStatus";
import { saveArticle } from "../reducers/articles";
import { TYPE_ARTICLE, TYPE_DESCRIPTION, TYPE_NATIVE } from "../common/consts";
import { setDescriptionDataLoaded } from "../reducers/categoryTextEditor";
import { setApiError, setApiErrorMessage } from "./apiErrors";

export const searchTopicSlice = createSlice({
  name: "searchtopic",
  initialState: {
    topicData: null,
    topicDataLoading: null,
    topicStep2Loading: false,
    topicRules: defaultTopicDetails,
    searchKeywords: null,
    userProjectId: null,
    searchTopicError: null,
    competitionData: null,
    competitionDataLoading: null,
    fetchDataError: false,
    fetchCompetitionError: false,
    topicLanguage: null,
  },
  reducers: {
    setTopicData: (state, { payload }) => {
      return {
        ...state,
        topicData: payload && payload.code ? null : payload,
        searchTopicError: payload && payload.code,
      };
    },

    setKeywordsSearch: (state, { payload }) => {
      return {
        ...state,
        searchKeywords: payload,
      };
    },

    setUserProjectId: (state, { payload }) => {
      return {
        ...state,
        userProjectId: payload,
      };
    },

    setTopicLanguage: (state, { payload }) => {
      return {
        ...state,
        topicLanguage: payload,
      };
    },

    setLoading: (state, { payload }) => {
      return {
        ...state,
        topicDataLoading: payload,
      };
    },

    setLoadingStep2: (state, { payload }) => {
      return {
        ...state,
        topicStep2Loading: payload,
      };
    },

    setTopicRules: (state, { payload }) => {
      return {
        ...state,
        topicRules: payload,
      };
    },

    setCompetitionData: (state, { payload }) => {
      return {
        ...state,
        competitionData: payload,
      };
    },

    setLoadingCompetition: (state, { payload }) => {
      return {
        ...state,
        competitionDataLoading: payload,
      };
    },

    setFetchError: (state, { payload }) => {
      return {
        ...state,
        fetchDataError: payload,
      };
    },

    setFetchCompetitionError: (state, { payload }) => {
      return {
        ...state,
        fetchCompetitionError: payload,
      };
    },
  },
});

export const { setLoading } = searchTopicSlice.actions;
export const { setLoadingStep2 } = searchTopicSlice.actions;
export const { setTopicData } = searchTopicSlice.actions;
export const { setTopicRules } = searchTopicSlice.actions;
export const { setKeywordsSearch } = searchTopicSlice.actions;
export const { setUserProjectId } = searchTopicSlice.actions;
export const { setTopicLanguage } = searchTopicSlice.actions;
export const { setCompetitionData } = searchTopicSlice.actions;
export const { setLoadingCompetition } = searchTopicSlice.actions;
export const { setFetchError } = searchTopicSlice.actions;
export const { setFetchCompetitionError } = searchTopicSlice.actions;

export const searchTopic = (topic, projectId, language) => async (dispatch) => {
  const token = getUserToken();
  dispatch(setLoading(true));
  dispatch(setLoadingCompetition(true));
  // clear existing competition data, or it will be visible under skeleton
  dispatch(setCompetitionData(null));
  // we start the loading of step2 at the same time with step1
  // but this is just to keep the skeleton displayed from start
  // until we get actual data from step2 endpoint
  dispatch(setLoadingStep2(true));
  dispatch(setKeywordsSearch(topic));
  dispatch(setUserProjectId(projectId));
  dispatch(setTopicLanguage(language));

  try {
    const searchedTopicData = await requestTopicSearch(topic, language, token);

    const { topicData } = searchedTopicData;
    dispatch(setTopicData(topicData));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setApiError(error));
    dispatch(setLoading(false));
  }
};

export const requestTopicDetails =
  (topic, language) => async (dispatch, getState) => {
    const token = getUserToken();
    try {
      const topicDetails = await getTopicDetails(
        topic,
        language,
        TYPE_ARTICLE,
        token
      );

      const { applicationMode } = getState().applicationmode;

      const {
        topicCoverageHigh,
        topicCoverageLow,
        expectedTitle,
        topicDensityCount,
        maxValues,
        rules,
        error,
      } = topicDetails;

      if (error) {
        dispatch(setFetchError(error));
        dispatch(setLoadingStep2(false));
        dispatch(setLoadingCompetition(false));
      } else {
        dispatch(setFetchError(error));
        const titleVariations =
          topicDensityCount && topicDensityCount.map(({ keyword }) => keyword);
        /* first we set the default topic coverage which is high,
    then we send high and low topic coverage separate in order to
    have them into topic coverage state
  */
        // setting default topic coverage to its own slice
        // if high is empty, put the low in active instead of high
        if (topicCoverageHigh.length === 0) {
          dispatch(setTopicCoverageData(topicCoverageLow));
          // set high topic coverage
          dispatch(setHighTopicCoverage(topicCoverageLow));
          // set low topic coverage
          dispatch(setLowTopicCoverage(topicCoverageHigh));
        } else {
          dispatch(setTopicCoverageData(topicCoverageHigh));
          // set high topic coverage
          dispatch(setHighTopicCoverage(topicCoverageHigh));
          // set low topic coverage
          dispatch(setLowTopicCoverage(topicCoverageLow));
        }

        // setting expected title to its own slice
        dispatch(setExpectedTitle(expectedTitle));
        // setting title variations to its own slice
        dispatch(setTitleVariations(titleVariations));
        // setting headings to its own slice
        dispatch(setHeadingsForOptimization(topicCoverageHigh));
        // setting topic density to its own slice
        dispatch(setTopicDensity(topicDensityCount));
        // setting topic density to its own slice
        dispatch(setMaxTopicDensity(maxValues.topicDensity));
        // setting images to its own slice
        dispatch(setMaxImages(maxValues.maxImages));
        // setting words to its own slice
        dispatch(setMaxWordCount(maxValues.maxWords));
        dispatch(setTopicRules(rules));
        dispatch(setLoadingStep2(false));

        // get competition data
        dispatch(getCompetitionOnTopic(topic, language));

        // now when we have all the details for this article, we need to
        // enforce a save to make available all the stats

        // this can be article or native
        const currentType =
          applicationMode === "article" ? TYPE_ARTICLE : TYPE_NATIVE;
        dispatch(saveArticle(currentType)); // we know here the type is ARTICLE
      }
    } catch (error) {
      dispatch(setApiError(error));
    }
  };

export const getCompetitionOnTopic = (topic, language) => async (dispatch) => {
  try {
    dispatch(setLoadingCompetition(true));
    const competition = await requestCompetition(topic, language);
    dispatch(setCompetitionData(competition));
    dispatch(setLoadingCompetition(false));
  } catch (error) {
    dispatch(setApiError(error));
    dispatch(setApiErrorMessage("error getting competition"));
  }
};

// this method only applies when we are in application mode -> category
export const searchTopicForCategory = (value, language) => async (dispatch) => {
  const token = getUserToken();
  try {
    dispatch(setLoadingCompetition(true));
    dispatch(setLoadingStep2(true));
    dispatch(setKeywordsSearch(value));

    const { topicCoverageHigh, expectedTitle } = await getTopicDetails(
      value,
      language,
      TYPE_DESCRIPTION,
      token
    );

    dispatch(setTopicCoverageData(topicCoverageHigh));
    dispatch(setExpectedTitle(expectedTitle));
    dispatch(setLoadingStep2(false));
    dispatch(setDescriptionDataLoaded(true));

    dispatch(saveArticle(TYPE_DESCRIPTION));
  } catch (error) {
    dispatch(setApiError(error));
  }
};

export const getCompetitionOnCategory =
  (topic, language) => async (dispatch) => {
    try {
      dispatch(setLoadingCompetition(true));
      const categoryCompetition = await requestCategoryCompetition(
        topic,
        language
      );
      const { categoryCompetitionData } = categoryCompetition;

      dispatch(setFetchCompetitionError(false));
      dispatch(setCompetitionData(categoryCompetitionData));

      dispatch(setLoadingCompetition(false));
    } catch (error) {
      dispatch(setFetchCompetitionError(true));
      dispatch(setApiError(error));
    }
  };

export const resetTopicData = () => (dispatch) => {
  dispatch(setTopicData(null));

  dispatch(setLoading(false));
};

export const resetCompetitionData = () => (dispatch) => {
  dispatch(setCompetitionData(null));
};

// selectors
export const getCompetitionData = ({ searchtopic: { competitionData } }) =>
  competitionData;

export const loadingTopicData = ({ searchtopic: { topicDataLoading } }) =>
  topicDataLoading;

export const loadingStep2Data = ({ searchtopic: { topicStep2Loading } }) =>
  topicStep2Loading;
export const getTopicResults = ({ searchtopic: { topicData } }) => topicData;
export const getTopicRules = ({ searchtopic: { topicRules } }) => topicRules;

export const getSearchKeywords = ({ searchtopic: { searchKeywords } }) =>
  searchKeywords;

export const getUserProjectId = ({ searchtopic: { userProjectId } }) =>
  userProjectId;
export const isSearchError = ({ searchtopic: { searchTopicError } }) =>
  searchTopicError;

export const loadingCompetitionData = ({
  searchtopic: { competitionDataLoading },
}) => competitionDataLoading;

export const isFetchDataError = ({ searchtopic: { fetchDataError } }) =>
  fetchDataError;

export const isFetchCompetitionError = ({
  searchtopic: { fetchCompetitionError },
}) => fetchCompetitionError;

export default searchTopicSlice.reducer;
