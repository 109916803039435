import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getTopicRules } from "../reducers/searchTopic";
import { getCompletionOnCategory } from "../utils/categoryOnCompletion";
import { useWindowSize } from "../utils/useWindowSize";

import { geTopicCoveragePerc } from "../reducers/topicCoverage";
import { getImagesPerc } from "../reducers/images";
import { getWordsPercentage } from "../reducers/counters";
import { getTopicDensityPerc } from "../reducers/topicDensity";
import { getTitleOptimization } from "../reducers/titleOptimization";
import { getHeadingsPerc } from "../reducers/headingsOptimization";
import { setArticleScore } from "../reducers/articles";

import "./CompletionBar.css";

const CompletionBar = () => {
  const dispatch = useDispatch();
  const [completionWidth, setCompletionWidth] = useState(0);

  const topicRules = useSelector(getTopicRules);
  const { width } = useWindowSize();
  // topic coverage
  const topicCoveragePercent = useSelector(geTopicCoveragePerc);
  // images
  const imagePercentage = useSelector(getImagesPerc);
  // wordcount
  const wordsPercentage = useSelector(getWordsPercentage);
  // topic density
  const topicDensityPercentage = useSelector(getTopicDensityPerc);
  // title optimization
  const titleOptimization = useSelector(getTitleOptimization);
  // headings optimization
  const headingsPercentage = useSelector(getHeadingsPerc);

  useEffect(() => {
    setCompletionWidth(width);
  }, [width]);

  const calculateImages = () => {
    const imagesWeight = topicRules ? topicRules.images : 0;
    const imgDetailsObj = {
      categoryPerc: imagePercentage,
      weight: imagesWeight,
      completionWidth,
    };

    const imagesProgress = getCompletionOnCategory(imgDetailsObj);

    return imagesProgress;
  };

  const calculateWordCount = () => {
    const wordsWeight = topicRules ? topicRules.wordcount : 0;

    const wordsDetailsObj = {
      categoryPerc: wordsPercentage,
      weight: wordsWeight,
      completionWidth,
    };

    const wordcountProgress = getCompletionOnCategory(wordsDetailsObj);

    return wordcountProgress;
  };

  const calculateTopicDensity = () => {
    const topicDensityWeight = topicRules ? topicRules.topicDensity : 0;

    const topicDensityDetailsObj = {
      categoryPerc: topicDensityPercentage,
      weight: topicDensityWeight,
      completionWidth,
    };

    const topicDensityProgress = getCompletionOnCategory(
      topicDensityDetailsObj
    );

    return topicDensityProgress;
  };

  const calculateTopicCoverage = () => {
    const topicCoverageWeight = topicRules ? topicRules.topicCoverage : 0;
    const topicCoverageDetailsObj = {
      categoryPerc: topicCoveragePercent || 0,
      weight: topicCoverageWeight,
      completionWidth,
    };

    const topicCoverageProgress = getCompletionOnCategory(
      topicCoverageDetailsObj
    );

    return topicCoverageProgress;
  };

  const calculateTitleOptimization = () => {
    const titleOptimizationWeight = topicRules
      ? topicRules.titleOptimization
      : 0;
    const titleOptimizationDetailsObj = {
      categoryPerc: titleOptimization ? 100 : 0,
      weight: titleOptimizationWeight,
      completionWidth,
    };

    const titleOptimizationProgress = getCompletionOnCategory(
      titleOptimizationDetailsObj
    );

    return titleOptimizationProgress;
  };

  const calculateHeadingOptimization = () => {
    const headingOptimizationWeight = topicRules
      ? topicRules.headingsOptimization
      : 0;

    const headingsDetailsObj = {
      categoryPerc: headingsPercentage,
      weight: headingOptimizationWeight,
      completionWidth,
    };

    const headingsOptimizationProgress =
      getCompletionOnCategory(headingsDetailsObj);

    return headingsOptimizationProgress;
  };

  const progress =
    calculateImages() +
    calculateWordCount() +
    calculateTopicCoverage() +
    calculateTitleOptimization() +
    calculateHeadingOptimization() +
    calculateTopicDensity();

  const progressPerc = (progress * 100) / completionWidth || 0;

  // update progress percentage to articles store
  useEffect(() => {
    const roundedPerc = Math.round(progressPerc);
    dispatch(setArticleScore(roundedPerc));
  }, [progressPerc]);

  // console.log({ progressPerc });
  const handleProgressColor = (color) => {
    const progressColor =
      progressPerc < 50 ? color : progressPerc > 80 ? "#ADE8C9" : "#EECD72";

    return progressColor;
  };

  return (
    <div className="completion-bar">
      <div
        style={{
          width: `${progressPerc}%`,
          backgroundColor: `${handleProgressColor("#E898A2")}`,
          height: "6px",
          transition: "all 1s",
        }}
      ></div>
    </div>
  );
};

export default CompletionBar;
