import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import PlagiarismContent from "./PlagiarismContent";

import "./PlagiarismScreen.css";

const PlagiarismScreen = ({
  response,
  confirmRedirect,
  onRephrase,
  asInfo,
}) => {
  const showLoading = !response;

  return (
    <div className="plagiarism-screen">
      {showLoading && (
        <div className="plagiarism-progress">
          <LinearProgress />
          <p className="plagiarism-progress-info">
            Please wait while your content is being checked for plagiarism...
          </p>
        </div>
      )}
      {response && (
        <PlagiarismContent
          result={response}
          onRedirect={confirmRedirect}
          onClose={onRephrase}
          asInfo={asInfo}
        />
      )}
    </div>
  );
};

export default PlagiarismScreen;
