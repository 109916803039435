import React, { useReducer, useCallback, useState, useEffect } from "react";
import Input from "../../Shared/Input";
import { VALIDATOR_MINLENGTH } from "../../utils/validators";
import { formReducer } from "../../utils/formReducer";
import Button from "../../Shared/Button";
import LoaderAnimation from "../../Shared/LoaderAnimation";
import { saveCompanyDetailsReq } from "../../services/invoice-billing";

const StepTwoInputs = ({ defaultValues, onSubmitedData }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [initialValues, setInitialValues] = useState();
  const [formState, dispatch] = useReducer(formReducer, {
    inputs: {
      companyName: {
        value: "",
        isValid: false,
      },
      vatNumber: {
        value: "",
        isValid: false,
      },
      registrationNumber: {
        value: "",
        isValid: false,
      },
      address: {
        value: "",
        isValid: false,
      },
    },
  });

  const inputHandler = useCallback((id, value, isValid) => {
    dispatch({
      type: "INPUT_CHANGE",
      value: value,
      isValid: isValid,
      inputId: id,
    });
  }, []);

  const registerSubmitHandler = async (event) => {
    event.preventDefault();
    const {
      inputs: { companyName, vatNumber, registrationNumber, address },
    } = formState;

    const payload = {
      company: companyName.value,
      com_reg_no: registrationNumber.value,
      vat_id: vatNumber.value,
      address: address.value,
    };

    setIsSubmitting(true);
    const data = await saveCompanyDetailsReq(payload);
    if (data) {
      setIsSubmitting(false);
      onSubmitedData(true);
    }
  };

  useEffect(() => {
    setInitialValues(defaultValues);
  }, [defaultValues]);

  if (isSubmitting && defaultValues.company !== initialValues.company) {
    return <LoaderAnimation content="Submitting..." />;
  }

  return (
    <>
      {initialValues && (
        <form onSubmit={registerSubmitHandler}>
          <Input
            id="companyName"
            element="input"
            type="text"
            label="Company legal name"
            validators={[VALIDATOR_MINLENGTH(3)]}
            errorText="Must be longer than 3 characters."
            onInput={inputHandler}
            initialValue={initialValues.company}
          />
          <Input
            id="vatNumber"
            element="input"
            type="text"
            label="VAT Number"
            validators={[VALIDATOR_MINLENGTH(5)]}
            errorText="Must be longer than 5 characters."
            onInput={inputHandler}
            initialValue={initialValues.vat_id}
          />
          <Input
            id="registrationNumber"
            element="input"
            type="text"
            label="Registration Number"
            validators={[VALIDATOR_MINLENGTH(5)]}
            errorText="Must be longer than 5 characters."
            onInput={inputHandler}
            initialValue={initialValues.com_reg_no}
          />
          <Input
            id="address"
            type="text"
            rows={2}
            label="Address"
            validators={[VALIDATOR_MINLENGTH(5)]}
            errorText="Must be longer than 5 characters."
            onInput={inputHandler}
            initialValue={initialValues.address}
          />
          <Button type="submit" fullSize disabled={isSubmitting}>
            Save changes
          </Button>
        </form>
      )}
    </>
  );
};

export default StepTwoInputs;
