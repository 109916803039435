import React, { Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "../Components/Header";
import Modal from "../Shared/Modal";
import ModalContentSwitcher from "../Components/ModalContentSwitcher";
import SidebarLeft from "../Components/SidebarLeft";
import CategoryMode from "../ApplicationMode/CategoryMode";
import { OPEN_MODAL_INTERVAL, DEFAULT_ESHOP_ID } from "../common/consts";
import { getCurrentArticleId, requestArticleById } from "../reducers/articles";

import useMount from "../utils/useMount";

import "./HomeContent.css";

const EshopContent = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const articleId = useSelector(getCurrentArticleId);
  const [showModal, setShowModal] = useState(false);

  const closeModalHandler = () => setShowModal(false);
  const openModalHandler = () => setShowModal(true);

  const hideModal = id && id !== DEFAULT_ESHOP_ID;
  useMount(() => {
    if (!articleId && hideModal) {
      dispatch(requestArticleById(id));
    }

    const timer = setTimeout(() => {
      if (!hideModal) {
        setShowModal(true);
      }
    }, OPEN_MODAL_INTERVAL);
    return () => clearTimeout(timer);
  });

  return (
    <Fragment>
      <Modal
        show={showModal}
        onCancel={closeModalHandler}
        header={
          <div className="modal__close-btn" onClick={closeModalHandler}>
            &#10005;
          </div>
        }
        contentClass="search-topic__modal-content"
      >
        <ModalContentSwitcher onCancel={closeModalHandler} />
      </Modal>
      <div className="homepage">
        <SidebarLeft onCreateNew={openModalHandler} />
        <div className="homepage-wrapper">
          <Header />
          <CategoryMode />
        </div>
      </div>
    </Fragment>
  );
};

export default EshopContent;
