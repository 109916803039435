import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { AUTH_ERR, GENERIC_ERR } from "../common/consts";
import { setApiErrorMessage } from "../reducers/apiErrors";

const useGenericError = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const setGlobalError = (error) => {
    if (error.status === 401) {
      history.replace("../login");
      dispatch(setApiErrorMessage(AUTH_ERR));
    } else {
      dispatch(setApiErrorMessage(GENERIC_ERR));
    }
  };

  return { setGlobalError };
};

export default useGenericError;
