import React, { useEffect, useState } from "react";
import { getProjectsPerformanceList } from "../../services/performance-dashboard";
import Checkbox from "@material-ui/core/Checkbox";
import "./ProjectsPerformanceDashboardList.css";
import PerformanceHeaderBar from "../PerformanceDashboard/PerformanceHeaderBar";
import ProjectsPerformanceCard from "./ProjectsPerformanceCard";

import TablePagination from "@material-ui/core/TablePagination";
import { BlackTooltip } from "../UITooltips/UITooltip";
import { useDispatch } from "react-redux";
import { setArticleId } from "../../reducers/articles";
import { setKeywordsSearch, setTopicData } from "../../reducers/searchTopic";
import { setTopicCoverageData } from "../../reducers/topicCoverage";
import { resetEditorData, setEditorData } from "../../reducers/textEditor";
import { LogoutIcon } from "../../UI_utils/SVGIcons";
import TextWithInfoIcon from "../PerformanceStatsSmallCard/TextWithInfoIcon";

const ProjectsPerformanceDashboardList = () => {
  const dispatch = useDispatch();
  const [listData, setListData] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [period, setPeriod] = useState("m");
  const [page, setPage] = React.useState(0);
  const [totalRows, setTotalRows] = useState();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // clean up all article related data when loading this page
  useEffect(() => {
    dispatch(setArticleId(null));
    dispatch(setTopicData(null));
    //   // reset topic coverage data when coming from eshop
    dispatch(setTopicCoverageData([]));
    //   // reset editor data to empty
    // dispatch(resetEditorData(true));
    dispatch(setKeywordsSearch(null));
  }, []);

  useEffect(() => {
    fetchPerformanceData(page, rowsPerPage, period);
  }, [page, rowsPerPage, period]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const updateListWithSelected =
      listData &&
      listData.map((item) => ({
        ...item,
        selected: selectAll,
      }));
    setListData(updateListWithSelected);
  }, [selectAll]);

  const fetchPerformanceData = async (page, rowsPerPage, period) => {
    try {
      const result = await getProjectsPerformanceList(
        page,
        rowsPerPage,
        period
      );
      setListData(result.rows);
      setTotalRows(result.total);
    } catch (error) {
      console.log({ error });
    }
  };

  if (!listData) {
    return <div>Loading</div>;
  }

  const selectAllCards = () => {
    setSelectAll(!selectAll);
  };

  const updateSelected = (id, value) => {
    const updateListById =
      listData &&
      listData.map((item) =>
        item.id === id ? { ...item, selected: value } : { ...item }
      );

    setListData(updateListById);
  };

  const selectDateRange = (interval) => {
    if (interval.value === 7) {
      setPeriod("w");
    } else {
      setPeriod("m");
    }
  };

  const logOutHandler = () => {
    localStorage.removeItem("userToken");
    window.location.replace("./login");
  };

  const label = { inputProps: { "aria-label": "Checkbox" } };
  return (
    <div className="projectsPerformanceDashboard">
      <div className="projectsPerformanceListWrapper">
        <PerformanceHeaderBar
          showLogin={true}
          title="Performance"
          onSelectDateRange={selectDateRange}
        />
        <div className="mainList">
          <div className="listHead">
            <div className="selectCard">
              <Checkbox
                {...label}
                checked={selectAll}
                onChange={selectAllCards}
                color="default"
              />
            </div>
            <div className="selectAllTitle">Select All</div>

            <div className="articlesTitle">
              <TextWithInfoIcon
                title="Articles"
                tooltipText="The number of articles that have been published and matched with a live URL for a client."
                fontSize={13}
                weight={600}
                color="#6F767E"
              />
            </div>

            <div className="healthTitle">
              <TextWithInfoIcon
                title="Health"
                tooltipText="The percentage of organic visits for the selected timeframe compared to the total number of visits this topic can obtain."
                fontSize={13}
                weight={600}
                color="#6F767E"
              />
            </div>

            <div className="scoreTitle">
              <TextWithInfoIcon
                title="Score"
                tooltipText="The Contero score metric takes into account the percentage of article brief guidelines implementation by the writer."
                fontSize={13}
                weight={600}
                color="#6F767E"
              />
            </div>

            <div className="opportunityTitle">
              <TextWithInfoIcon
                title="Opportunity"
                tooltipText="The total number of visits that this topic can obtain."
                fontSize={13}
                weight={600}
                color="#6F767E"
              />
            </div>

            <div className="visitsTitle">
              <TextWithInfoIcon
                title="Visits"
                tooltipText="The total number of visits that this topic can obtain."
                fontSize={13}
                weight={600}
                color="#6F767E"
              />
            </div>

            {/* <div className="impressionsTitle">Impressions</div> */}
            <div className="rankTitle">
              <TextWithInfoIcon
                title="Google Search Console"
                tooltipText="Status of integration with Google Search Console."
                fontSize={13}
                weight={600}
                color="#6F767E"
              />
            </div>
          </div>
          <div className="listCard">
            {listData.map((article) => (
              <ProjectsPerformanceCard
                key={article.id}
                data={article}
                onUpdateSelected={updateSelected}
              />
            ))}
          </div>
          {totalRows && (
            <TablePagination
              component="div"
              rowsPerPageOptions={[5, 10, 15, 20]}
              count={totalRows}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </div>
      </div>
      <div className="bottom-info">
        <div className="copyright-info">
          &#169; Contero. All rights reserved.
        </div>
      </div>
    </div>
  );
};

export default ProjectsPerformanceDashboardList;
