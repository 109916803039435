import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";

import "./AddUsersRowInputs.css";
import { BlackTooltip } from "../UITooltips/UITooltip";
import { RemoveCircle } from "@material-ui/icons";
import {
  USER_PROOFREADER,
  USER_SUBCLIENT,
  USER_WRITER,
} from "../../common/consts";

const AddUsersRowInputs = ({
  onInputUserData,
  projects,
  showDeleteUser,
  onRemoveRow,
  userId,
}) => {
  const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },
    button: {
      display: "block",
      marginTop: theme.spacing(2),
    },
    formControl: {
      minWidth: 120,
      display: "flex",
    },
    root: {
      "&$checked": {
        color: "#6c5dd3",
      },
    },
    checked: {},
  }));
  const classes = useStyles();

  const [role, setRole] = useState("");
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedProjectsId, setSelectedProjectsId] = useState([]);
  const [user, setUser] = useState("");
  const [open, setOpen] = useState(false);
  const [openProject, setOpenProject] = useState(false);

  const handleChange = (event) => {
    setRole(event.target.value);
  };

  const handleProjectChange = (event) => {
    const selected = event.target.value;
    const ids = projects
      .filter((project) => selected.some((name) => project.name === name))
      .map((p) => p.id);
    setSelectedProjectsId(ids);
    setSelectedProjects(selected);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseProject = () => {
    setOpenProject(false);
  };

  const handleOpenProject = () => {
    setOpenProject(true);
  };

  const onInputChange = (event) => {
    setUser(event.target.value);
  };

  useEffect(() => {
    if (user !== "" && role !== "" && selectedProjects.length > 0) {
      const data = {
        email: user,
        role,
        id: selectedProjectsId,
      };

      onInputUserData(data);
    }
  }, [role, user, selectedProjects]);

  return (
    <div>
      <div className="step3-inputs-row">
        <div className="email-input-step3">
          <Input
            id="input-with-icon"
            placeholder="Add user email"
            onChange={onInputChange}
            required={role !== ""}
            startAdornment={
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            }
          />
          {/* {isError && (
            <FormErrorMessage>Please enter a valid email.</FormErrorMessage>
          )} */}
        </div>
        <FormControl className={classes.formControl} style={{ width: "190px" }}>
          <InputLabel>Project</InputLabel>
          <Select
            labelId="project"
            id="project"
            open={openProject}
            onClose={handleCloseProject}
            onOpen={handleOpenProject}
            value={selectedProjects}
            onChange={handleProjectChange}
            renderValue={(selected) => selected.join(", ")}
            multiple
          >
            {projects.map((project) => (
              <MenuItem key={project.id} value={project.name}>
                <Checkbox
                  // className={classes.customCheck}
                  classes={{
                    root: classes.root,
                    checked: classes.checked,
                  }}
                  checked={selectedProjects.indexOf(project.name) > -1}
                />
                {/* <Checkbox checked={true} /> */}
                <ListItemText primary={project.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel>Role</InputLabel>
          <Select
            labelId="role"
            id="role"
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={role}
            onChange={handleChange}
          >
            <MenuItem value={USER_SUBCLIENT}>Admin</MenuItem>
            <MenuItem value={USER_WRITER}>Writer</MenuItem>
            <MenuItem value={USER_PROOFREADER}>Proofreader</MenuItem>
          </Select>
        </FormControl>
        {showDeleteUser && (
          <div className="remove-users" onClick={() => onRemoveRow(userId)}>
            <BlackTooltip arrow placement="top" title="Remove this user">
              <RemoveCircle style={{ color: "#ec5032" }} />
            </BlackTooltip>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddUsersRowInputs;
