import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { activeUser } from "../../reducers/user";
import {
  getCompanyDetailsReq,
  getInvoices,
} from "../../services/invoice-billing";
import { getFirstDayOfNextMonth } from "../../utils/dateUtility";
import StepTwoInputs from "../MultistepRegister/StepTwoInputs";
import "./BillingComponent.css";
import InvoiceRow from "./InvoiceRow";

import RegisterTitle from "../MultistepRegister/RegisterTitle";

const description =
  "Please provide the following company data so we comply with local regulations and process future payments easier.";

const BillingComponent = ({ invoiceData, defaultValues }) => {
  const currentUser = useSelector(activeUser);

  const totalInvoices = invoiceData && invoiceData.length;

  // rendered when there are no invoices
  if (typeof invoiceData === "string") {
    return (
      <div className="billing-component">
        <div className="info-title">{invoiceData}</div>
      </div>
    );
  }

  return (
    <div className="billing-component">
      <div className="billing-left">
        <div className="invoice-table">
          <div className="invoices-list-head">
            <div>Amount</div>
            <div>Month</div>
            <div>Credits</div>
            <div>Status</div>
            <div>Date</div>
            <div></div>
          </div>
          {invoiceData.length > 0 &&
            invoiceData.map((invoice) => (
              <InvoiceRow key={invoice.id} data={invoice} />
            ))}
        </div>
        <div className="invoice-summary">{`${totalInvoices} results`}</div>
        <div className="account-data">
          <div className="info-content">
            <div className="info-title">Next Invoice</div>
            <div className="info-value">{`${getFirstDayOfNextMonth()}`}</div>
          </div>
          <div className="info-content">
            <div className="info-title">Invoice destination</div>
            <div className="info-value">{currentUser && currentUser.email}</div>
          </div>
        </div>
      </div>
      <div className="vertical-divider"></div>
      <div className="billing-right">
        <div className="right-side-form">
          <RegisterTitle title="Add company data" />
          <div className="register-desc">{description}</div>
          <StepTwoInputs
            defaultValues={defaultValues}
            // onSubmitedData={() => setIsDataSubmited(true)}
          />
        </div>
      </div>
    </div>
  );
};

export default BillingComponent;
