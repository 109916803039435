import React from "react";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Fade from "@material-ui/core/Fade";
import WatchLaterIcon from "@material-ui/icons/WatchLater";

const InvoiceRow = ({ data }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDownloadInvoice = () => {
    handleClose();
    window.open(`${data.downloadInvoice}`);
  };

  const onPayInvoice = () => {
    handleClose();
    window.open(`${data.payInvoice}`);
  };

  const formattedAmount = new Intl.NumberFormat("en-IN").format(data.amount);

  return (
    <div className="invoices-list-row">
      <div>{`${formattedAmount} RON`}</div>
      <div>{data.month}</div>
      <div>{data.credits}</div>
      <div>
        <div className="invoice-status">
          {data.status}
          {data.status !== "Paid" && (
            <WatchLaterIcon
              style={{ color: "#6F767E", width: "16px", marginLeft: "7px" }}
            />
          )}
        </div>
      </div>
      <div>{data.date}</div>
      <Button onClick={handleClick}>
        <MoreHorizIcon onClick={handleClick} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={onDownloadInvoice}>Download Invoice</MenuItem>
        {data.status !== "Paid" && (
          <MenuItem onClick={onPayInvoice}>Pay Invoice</MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default InvoiceRow;
