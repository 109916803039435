import React, { useCallback, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CustomColumnFilter from "../TableHeadFilter/CustomColumnFilter";
import CustomSearchTopicFilter from "../TableHeadFilter/CustomSearchTopicFilter";
import RangeSlider from "../RangeSlider/RangeSlider";
import CustomDateRangePicker from "../TableHeadFilter/CustomDateRangeFilter";
import { formatDateForStore } from "../../utils/dateUtility";
import Button from "../../Shared/Button";

import "./FilterPanel.css";
import { BlackTooltip } from "../UITooltips/UITooltip";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const useStyles = makeStyles(() => ({
  Accordion: {
    marginBottom: 10,
    paddingLeft: 18,
    paddingRight: 18,
  },

  accordionBody: {
    marginBottom: 20,
  },
}));

const FilterPanel = ({
  isOpen,
  onClose,
  elements,
  onAddedFilters,
  initialFilters,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState(null);
  const [hasFilters, setHasFilters] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [currentlyRemovedFilter, setCurrentlyRemoveFilter] = useState(null);

  useEffect(() => {
    if (appliedFilters) {
      const filtersOn = Object.values(appliedFilters).some((item) => item);
      setHasFilters(filtersOn);
    }
  }, [appliedFilters]);

  useEffect(() => {
    setAppliedFilters(initialFilters);
  }, [initialFilters]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const onFilterColumn = useCallback((filters) => {
    setAppliedFilters((prevState) => ({ ...prevState, ...filters }));
  }, []);

  const buildDatesInitialValues = (column) => {
    const values = initialFilters && initialFilters[column];

    if (!values) return null;

    const dateStart = values && formatDateForStore(values[0]);
    const dateEnd = values && formatDateForStore(values[1]);

    if (dateStart && dateEnd) {
      return [new Date(dateStart), new Date(dateEnd)];
    } else {
      return [];
    }
  };

  const onFiltersApply = () => {
    const cleanFilters = Object.keys(appliedFilters).reduce((acc, key) => {
      const _acc = acc;
      if (appliedFilters[key] !== undefined && appliedFilters[key] !== "")
        _acc[key] = appliedFilters[key];
      return _acc;
    }, {});

    onAddedFilters(cleanFilters);
    onClose();
  };

  const onRemoveAllFilters = () => {
    onAddedFilters(null);
    onClose();
  };

  const currentlyFiltered = (filterElement) => {
    return appliedFilters && appliedFilters[filterElement.header.toLowerCase()];
  };

  const removeCurrentFilter = (current) => {
    setIsReset(true);
    setCurrentlyRemoveFilter(current);
  };

  useEffect(() => {
    if (appliedFilters && currentlyRemovedFilter) {
      if (
        appliedFilters[currentlyRemovedFilter] === "" ||
        appliedFilters[currentlyRemovedFilter] === undefined
      ) {
        setIsReset(false);
      }
    }
  }, [appliedFilters, currentlyRemovedFilter]);

  const renderBody = (filterElement) => {
    switch (filterElement.type) {
      case "select":
        return (
          <CustomColumnFilter
            currentData={filterElement.data}
            currentColumn={filterElement.header.toLowerCase()}
            onFilter={(filters) => onFilterColumn(filters)}
            isClear={
              isReset &&
              currentlyRemovedFilter === filterElement.header.toLowerCase()
            }
            initialValue={
              initialFilters &&
              initialFilters[filterElement.header.toLowerCase()]
            }
          />
        );
      case "search": {
        return (
          <CustomSearchTopicFilter
            placeholder={"Filter topic"}
            currentColumn={filterElement.header.toLowerCase()}
            onFilter={(filters) => onFilterColumn(filters)}
            isClear={
              isReset &&
              currentlyRemovedFilter === filterElement.header.toLowerCase()
            }
            initialValue={
              initialFilters &&
              initialFilters[filterElement.header.toLowerCase()]
            }
          />
        );
      }
      case "range": {
        return (
          <RangeSlider
            currentColumn={filterElement.header.toLowerCase()}
            onFilter={(filters) => onFilterColumn(filters)}
            isClear={
              isReset &&
              currentlyRemovedFilter === filterElement.header.toLowerCase()
            }
            initialValue={
              initialFilters &&
              initialFilters[filterElement.header.toLowerCase()]
                ? initialFilters[filterElement.header.toLowerCase()]
                : [10, 30]
            }
          />
        );
      }
      case "picker": {
        return (
          <CustomDateRangePicker
            currentColumn={filterElement.header.toLowerCase()}
            onFilter={(filters) => onFilterColumn(filters)}
            isClear={
              isReset &&
              currentlyRemovedFilter === filterElement.header.toLowerCase()
            }
            initialValue={buildDatesInitialValues(
              filterElement.header.toLowerCase()
            )}
          />
        );
      }
      default:
        return null;
    }
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      style={{ with: "20px", padding: "30px" }}
    >
      <div
        style={{
          width: "450px",
          padding: "30px",
          height: "200%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          backgroundColor: "#f4f4f4",
        }}
      >
        <div className="filter-panel-top">
          <h3 className="search-filter-heading">Search Filter</h3>
          <div>
            {elements.map((filterElement) => (
              <div key={filterElement.header} className="accordion-element">
                <Accordion
                  className={classes.Accordion}
                  expanded={expanded === filterElement.header}
                  onChange={handleChange(filterElement.header)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <div className="accordion-header-details">
                      <div
                        className={`${
                          currentlyFiltered(filterElement)
                            ? `accordion-title-active`
                            : `accordion-title`
                        }`}
                      >
                        {filterElement.header}
                      </div>
                      {currentlyFiltered(filterElement) && (
                        <div
                          onClick={() =>
                            removeCurrentFilter(
                              filterElement.header.toLowerCase()
                            )
                          }
                        >
                          <BlackTooltip
                            arrow
                            placement="top"
                            title="Remove this filter"
                          >
                            <HighlightOffIcon
                              style={{
                                color: "#ec5032",
                                width: "18px",
                                marginLeft: "5px",
                                display: "flex",
                                alignItems: "center",
                                pointerEvents: "none",
                              }}
                            />
                          </BlackTooltip>
                        </div>
                      )}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionBody}>
                    {renderBody(filterElement)}
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <Button type="button" fullSize onClick={onFiltersApply}>
            Apply Filters
          </Button>
          <Button
            type="button"
            passive
            fullSize
            onClick={onRemoveAllFilters}
            disabled={!hasFilters}
          >
            Remove Filters
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default FilterPanel;
