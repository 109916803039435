export const transformData = (data) => {
  const date = new Date();
  const currentMonth = date.getMonth();

  const months = data.monthlyValues.reduce(
    (acc, curr) =>
      curr.id === currentMonth
        ? [...acc, { ...curr, fill: "#8a56ff", currentMonth: true }]
        : [...acc, { ...curr, fill: "#b6e4ca", currentMonth: false }],
    []
  );

  const shapedData = {
    ...data,
    monthlyValues: months,
  };

  return shapedData;
};
