import React from "react";

import "./RegisterTitle.css";

const RegisterTitle = ({ title }) => {
  return (
    <div className="register-title">
      <span className="register-tag"></span>
      <div className="register-title-text">{title}</div>
    </div>
  );
};

export default RegisterTitle;
