import React from "react";

import './RadarChartSkeleton.css';

const RadarChartSkeleton = () => {
  return (
    <div className="preloader loading">
      <span className="slice"></span>
      <span className="slice"></span>
      <span className="slice"></span>
      <span className="slice"></span>
      <span className="slice"></span>
      <span className="slice"></span>
    </div>
  );
};

export default RadarChartSkeleton;
