import React, { useEffect, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import "./ManagementDashboard.css";

import {
  displayEntryType,
  dymanicMenuOptions,
  getDefaultValue,
  getDefaultValuePill,
  userHasPermission,
} from "../dashboardComponentHelpers";
import TopicNameCell from "../TopicNameCell";
import DynamicOptionsSelect from "../DynamicOptionsSelect";
import {
  CREATED,
  DEADLINE,
  INDUSTRY,
  PROJECT,
  PROOFREADER,
  STATUS,
  TYPE_NATIVE,
  USER_CLIENT,
  USER_MASTER,
  USER_PROOFREADER,
  USER_WRITER,
  WRITER,
} from "../../common/consts";
import CustomDatePicker from "../CustomDatePicker";
import DateDisplayWrapper from "../DateDisplayWrapper/DateDisplayWrapper";
import CustomMenu2 from "../CustomMenu2";
import DynamicOptionsSelectPill from "../DynamicOptionsSelectPill";
import DynamicPill from "../DynamicPill/DynamicPill";
import HumanReadableDate from "../HumanReadableDate/HumanReadableDate";

const ManagementCard = ({
  rowData,
  onUpdateSelected,
  handleClickAction,
  writers,
  projects,
  industries,
  proofreaders,
  statuses,
  currentUserType,
  handleOptionChange,
  handleRowAction,
  changeDateHandler,
}) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    setData(rowData);
  }, [rowData]);

  const updateSelectedCard = (event) => {
    onUpdateSelected(data && data.id, event.target.checked);
  };

  const displayCustomMenu = () => {
    if (!data) return null;
    const userWriter = userHasPermission(currentUserType, USER_WRITER);
    const userMaster = userHasPermission(currentUserType, USER_MASTER);
    const userClient = userHasPermission(currentUserType, USER_CLIENT);
    const writerAndNative = userWriter && data && data.type === TYPE_NATIVE;
    const writerIsAuthor = data && !data.master;
    // if current user is WRITER and article was created by master hide the menu
    if (writerAndNative) {
      return (
        <CustomMenu2
          row={data}
          menuOptions={[{ value: 11, label: "Edit entry" }]}
          handleRowAction={handleRowAction}
        />
      );
    }
    if (userMaster || userClient || (userWriter && writerIsAuthor)) {
      return (
        <CustomMenu2
          row={data}
          menuOptions={dymanicMenuOptions(data, currentUserType)}
          handleRowAction={handleRowAction}
        />
      );
    }
  };

  if (!data) return null;

  const selectedInParent = getDefaultValuePill(data.writer, writers);

  return (
    <>
      <div className="managementCard">
        <div className="contentCheck">
          <Checkbox
            checked={!!data.selected}
            onChange={updateSelectedCard}
            color="default"
          />
        </div>
        <div className="contentType">
          <div
            style={{
              color: "#75819b",
              fontFamily: "Inter UI",
              fontWeight: "500",
            }}
          >
            {displayEntryType(data.type)}
          </div>
        </div>
        <div className="contentTopic">
          {!data.empty && (
            <TopicNameCell row={data} handleClickAction={handleClickAction} />
          )}
        </div>
        <div className="contentWriter">
          {writers && (
            <DynamicOptionsSelectPill
              rows={[data]}
              updateProperty={WRITER}
              action="users"
              data={writers}
              currentUserType={currentUserType}
              // make this rerender when data.writer is changed
              defaultSelected={selectedInParent}
              onSelectChange={handleOptionChange}
            />
          )}
        </div>
        <div className="contentProject">
          {projects && (
            <DynamicOptionsSelect
              isPopup={false}
              rows={[data]}
              updateProperty={PROJECT}
              action="project"
              data={projects}
              currentUserType={currentUserType}
              defaultSelected={getDefaultValue(data.project, projects)}
              onSelectChange={handleOptionChange}
            />
          )}
        </div>
        <div className="contentCreated">
          {!data.empty && <HumanReadableDate date={data.created} />}
        </div>
        <div className="contentDeadline">
          <DateDisplayWrapper
            currentRows={[data]}
            updateProperty={DEADLINE}
            handleDateChange={(updatedRows, updatedProperty) =>
              changeDateHandler(updatedRows, updatedProperty)
            }
            savedDate={data.deadline}
            readOnlyDisplay={
              userHasPermission(currentUserType, USER_PROOFREADER) ||
              userHasPermission(currentUserType, USER_WRITER)
            }
            isDeadline
          />
        </div>
        <div className="contentScore">
          <DynamicPill data={data} />
        </div>
        <div className="contentProof">
          {proofreaders && (
            <DynamicOptionsSelectPill
              rows={[data]}
              updateProperty={PROOFREADER}
              action="users"
              data={proofreaders}
              currentUserType={currentUserType}
              defaultSelected={getDefaultValuePill(
                data.proofreader,
                proofreaders
              )}
              onSelectChange={handleOptionChange}
            />
          )}
        </div>
        <div className="contentStatus">
          {statuses && (
            <DynamicOptionsSelect
              isPopup={false}
              rows={[data]}
              updateProperty={STATUS}
              action="status"
              data={statuses}
              currentUserType={currentUserType}
              defaultSelected={getDefaultValue(data.status, statuses)}
              onSelectChange={handleOptionChange}
            />
          )}
        </div>
        <div className="contentMenu">{displayCustomMenu()}</div>
      </div>
    </>
  );
};

export default ManagementCard;