import React from "react";
import { useQuery } from "react-query";
import { Queries } from "../../common/consts";
import CustomAsyncSelect from "../CustomAsyncSelect";
import TitleWithTag from "../TitleWithTag/TitleWithTag";
import { inputCustomStyles } from "./helpers";
import TopicResearchContent from "./TopicResearchContent/TopicResearchContent";
import { fetchTopicResults } from "../../services/manage-search-results";

import "./SearchResultsDashboardList.css";
import ResultsCompetitionBox from "./ResultsCompetitionBox";
import TableGridGenerator from "../TableGridGenerator/TableGridGenerator";
import TopicSerpContent from "./TopicSerpContent/TopicSerpContent";
import KeywordCluster from "./KeywordClusterContent/KeywordCluster";

const SearchResultsDasboardList = () => {
  const [searchString, setSearchString] = React.useState("");
  const { data, isLoading } = useQuery(
    [
      Queries.SEARCH_RESULTS, // unique query key
      searchString, // query params
    ],
    fetchTopicResults // query function
  );

  if (isLoading) return <div>Loading...</div>;
  const onSearchString = (value) => {
    setSearchString(value);
  };

  const rowData = [
    {
      rank: "2",
      image: "https://logo.clearbit.com/piatraonline.ro",
      domain: "Guturei.io/",
      keywords: "2",
      id: 1,
    },
    {
      rank: "5",
      image: "https://logo.clearbit.com/piatraonline.ro",
      domain: "somethingelse.io/",
      keywords: "223",
      id: 2,
    },
    {
      rank: "1",
      image: "https://logo.clearbit.com/piatraonline.ro",
      domain: "google",
      keywords: "1",
      id: 3,
    },
  ];

  const rowData2 = [
    {
      id: 0,
      topic: "amenajare living",
      keyword: "apartament",
      totalKeywords: 230,
      searchVol: 124,
      trafficPerc: 12,
      extraKeywords: [
        { key: "sufrageri amenajate", searches: 12.3 },
        { key: "sufragerii amenajate", searches: 12.3 },
        { key: "sufragerie mica amenajare", searches: 12.3 },
        { key: "sufragerii elegante", searches: 12.3 },
        { key: "sufragerii de apartament amenajate", searches: 12.3 },
      ],
    },
    {
      id: 1,
      topic: "amenajare living",
      keyword: "idei",
      totalKeywords: 10,
      searchVol: 11,
      trafficPerc: 232,
      extraKeywords: [
        { key: "sufrageri amenajate1", searches: 13.3 },
        { key: "sufragerii amenajate", searches: 14.3 },
        { key: "sufragerie mica amenajare", searches: 15.3 },
        { key: "sufragerii elegante", searches: 12.3 },
        { key: "sufragerii de apartament amenajate", searches: 12.3 },
      ],
    },
    {
      id: 2,
      topic: "amenajare living",
      keyword: "open space",
      totalKeywords: 30,
      searchVol: 14,
      trafficPerc: 81,
      extraKeywords: [
        { key: "sufrageri amenajate2", searches: 5.3 },
        { key: "sufragerii amenajate", searches: 8.3 },
        { key: "sufragerie mica amenajare", searches: 9.3 },
        { key: "sufragerii elegante", searches: 12.3 },
        { key: "sufragerii de apartament amenajate", searches: 12.3 },
      ],
    },
  ];

  const tableHeaders = [
    // name is the header column name, provide style as optional for each one if needed
    { name: "Rank" },
    { name: "Competitor" },
    {
      name: "Keywords",
      style: { display: "flex", justifyContent: "flex-end" },
    },
  ];

  return (
    <div className="searchResultsDashboardList">
      <div className="results-content">
        <div className="topic-research-head">
          <TitleWithTag title="Topic Research" tagColor="#c8baff" />
          <CustomAsyncSelect
            placeholder="Search another topic..."
            onStringSearch={onSearchString}
            customStyles={inputCustomStyles}
            initialValue={searchString}
          />
        </div>
        <TopicResearchContent data={data} />
      </div>
      <div className="results-content">
        <TitleWithTag title="Competition" tagColor="#b2e5ff" />

        <div className="results-competition-wrapper">
          {data.competition.map((data) => (
            <ResultsCompetitionBox
              key={data.name}
              name={data.name}
              logo={data.logo}
              data={data}
            />
          ))}
        </div>

        <TitleWithTag title="Headterm SERP" tagColor="#b6e4ca" />
        <div className="topic-serp-table-wrapper">
          <TableGridGenerator
            tableData={rowData}
            headers={tableHeaders}
            size="100px 4fr 1fr"
            RowComponent={TopicSerpContent}
            headerStyle={{
              lineHeight: "40px",
              borderBottom: "1px solid #e6e6e6",
              fontWeight: 900,
              color: "#1a1d1f",
              marginBottom: "10px",
              padding: "0 20px",
              fontSize: "18px",
            }}
            contentStyle={
              {
                // content style here, applies for all rows as a block
              }
            }
            rowGap={10}
          />
        </div>
      </div>
      <div className="results-content">
        <TitleWithTag title="Topic Clusters" tagColor="#b6e4ca" />
        <TableGridGenerator
          tableData={rowData2}
          headers={[]}
          size="2fr 1fr 1fr 1fr 200px"
          RowComponent={KeywordCluster}
          headerStyle={{}}
          contentStyle={{
            padding: "5px",
            marginTop: "40px",
          }}
          rowGap={10}
        />
      </div>
    </div>
  );
};

export default SearchResultsDasboardList;
