export const inputCustomStyles = {
  container: () => ({ width: "350px" }),
  control: (styles, state) => ({
    ...styles,
    backgroundColor: "white",
    borderRadius: "10px",
    borderColor: state.isFocused ? "#dbdbdb" : "#EFEFEF",
    borderWidth: "2px",
    boxShadow: 0,
    color: "#6F767E",
    "&:hover": {
      borderColor: "#dbdbdb",
    },
  }),

  option: (styles, { isSelected }) => {
    return {
      ...styles,
      fontWeight: 600,
      fontSize: "14px",
      borderRadius: "4px",

      "&:hover": {
        color: isSelected ? "#6C5DD3" : "#6C5DD3",
      },
    };
  },

  input: (styles) => ({
    ...styles,
    color: "#6F767E",
    innerHeight: "40px",
  }),
  placeholder: (styles) => ({ ...styles, color: "#6F767E" }),
  singleValue: (styles) => ({
    ...styles,
    color: "#6F767E",
    fontWeight: 600,
    fontSize: "14px",
  }),
};
