import React, { useState, useReducer, useCallback } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Input from "../Shared/Input";
import Button from "../Shared/Button";
import CustomCheckbox from "../Shared/CustomCheckbox";
import FormErrorMessage from "./FormErrorMessage";

import { VALIDATOR_EMAIL, VALIDATOR_MINLENGTH } from "../utils/validators";
import { formReducer } from "../utils/formReducer";

import "./RegisterForm.css";
import useUserHook from "../apiHooks/useUserHook";

const RegisterForm = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const registrationCode = queryParams.get("code");

  const { registerRequestHook } = useUserHook();
  const [errorMessage, setErrorMessage] = useState(null);
  const [formState, dispatch] = useReducer(formReducer, {
    inputs: {
      firstname: {
        value: "",
        isValid: false,
      },
      lastname: {
        value: "",
        isValid: false,
      },
      email: {
        value: "",
        isValid: false,
      },
      registrationCode: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
      terms: {
        value: "",
        isValid: false,
      },
    },
    isValid: false,
  });

  const handleCheckboxChange = (id, value) => {
    dispatch({
      type: "INPUT_CHANGE",
      value,
      isValid: value,
      inputId: id,
    });
  };

  const inputHandler = useCallback((id, value, isValid) => {
    dispatch({
      type: "INPUT_CHANGE",
      value: value,
      isValid: isValid,
      inputId: id,
    });
  }, []);

  const registerSubmitHandler = async (event) => {
    event.preventDefault();
    const userStatus = await registerRequestHook(formState.inputs);

    if (userStatus === 201) {
      history.push("/login");
    } else {
      setErrorMessage("Invalid registration code");
    }
  };

  const firstNameValid = formState.inputs.firstname.isValid;
  const lastNameValid = formState.inputs.lastname.isValid;
  const emailValid = formState.inputs.email.isValid;
  const passValid = formState.inputs.password.isValid;
  const codeValid = formState.inputs.registrationCode.isValid;

  const enableNextStep =
    firstNameValid && lastNameValid && emailValid && passValid && codeValid;

  return (
    <div>
      <form className="register-form" onSubmit={registerSubmitHandler}>
        <>
          <div className="email-box">
            <Input
              id="firstname"
              element="input"
              type="text"
              label="Firstname"
              validators={[VALIDATOR_MINLENGTH(3)]}
              errorText="Please enter a name longer than 3 characters."
              onInput={inputHandler}
              collapsed
            />
            <Input
              id="lastname"
              element="input"
              type="text"
              label="Lastname"
              validators={[VALIDATOR_MINLENGTH(3)]}
              errorText="Please enter a name longer than 3 characters."
              onInput={inputHandler}
              collapsed
            />
          </div>
          <Input
            id="email"
            element="input"
            type="text"
            label="Email"
            validators={[VALIDATOR_EMAIL()]}
            errorText="Please enter a valid email."
            onInput={inputHandler}
          />
          <Input
            id="registrationCode"
            element="input"
            type="text"
            label="Registration code"
            validators={[VALIDATOR_MINLENGTH(10)]}
            errorText="Registration code must have 10 characters."
            onInput={inputHandler}
            initialValue={registrationCode}
            disabled
          />
          <Input
            id="password"
            element="input"
            label="Password"
            type="password"
            validators={[VALIDATOR_MINLENGTH(5)]}
            errorText="Please enter more than 5 characters."
            onInput={inputHandler}
          />

          <CustomCheckbox
            id="terms"
            handleChange={(value) => handleCheckboxChange("terms", value)}
          />
          <Button type="submit" fullSize disabled={!formState.isValid}>
            Create Account
          </Button>
          {errorMessage && (
            <div className="error-message-info">
              <FormErrorMessage>{errorMessage}</FormErrorMessage>
            </div>
          )}
        </>
      </form>
      <div className="sign-up-link">
        Already a member? <Link to="/login"> Sign in</Link>
      </div>
    </div>
  );
};

export default RegisterForm;
