import axios from "axios";
import { BASE_URL, IMAGE_UPLOAD_PATH } from "../common/consts";

const baseEndpoint = "api/articles";
const newArticle = "new";
const saveArticleUrl = "edit";
const allArticles = "all";
const currentArticle = "get";
const stats = "stats";
const getStats = "get-stats";
const remove = "delete";
const exportArticle = "export-pdf";
const uploadCsv = "upload-csv";
const technicalFeedback = "technical-check";
const rating = "rating";
const plagiarism = "plagiarism";
const plagiarismCheck = "plagiarism-check";

// create a new article
export const setNewArticle = (article, token) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${newArticle}`, article, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// save content on existing article
export const saveArticleApi = (articleData, token) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${saveArticleUrl}`, articleData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// get a list of all articles per user
export const getArticles = (token) =>
  axios
    .get(`${BASE_URL}/${baseEndpoint}/${allArticles}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// get an article by ID
export const articleById = (id, token) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${currentArticle}`, id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// delete article by ID
export const deleteArticleFromList = (id, token) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${remove}`, id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// save article stats
export const saveArticleStats = (id, token) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${stats}`, id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// get article stats
export const getArticleStats = (id, token) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${getStats}`, id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// export to PDF
export const exportToPdf = (id, token) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${exportArticle}`, id, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data }) => data)
    .then((data) => {
      const fileURL = URL.createObjectURL(data);
      window.open(fileURL);
    })
    .catch((error) => Promise.reject(error.response));

// upload CSV file from dashboard
export const uploadCSV = (formData, token) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${uploadCsv}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.response));

// upload Image file from editor
export const uploadImage = (formData, setUploadProgress, token) =>
  axios
    .post(`${BASE_URL}/${IMAGE_UPLOAD_PATH}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        setUploadProgress(progress);
      },
    })
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.response));

export const updateTechnicalFeedback = (payload, token) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${technicalFeedback}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

export const rateArticle = (payload, token) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${rating}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

export const getArticlePlagiarismScore = (payload, token) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${plagiarism}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

export const updatePlagiarismScore = (payload, token) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${plagiarismCheck}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));
