import React from "react";

import "./SourceItem.css";

const SourceItem = ({ name, link, percent }) => {
  return (
    <div className="source-item">
      <div className="source-top">
        <div className="top-name">
          <a href={link} target="_blank" rel="noopener noreferrer">
            {name}
          </a>
        </div>
        <div className="top-percent">{`- ${percent}% similar`}</div>
        <div className="top-btn">
          <a href={link} target="_blank" rel="noopener noreferrer">
            Compare
          </a>
        </div>
      </div>
      <div className="source-bottom">
        <a href={link} target="_blank" rel="noopener noreferrer">
          {link}
        </a>
      </div>
    </div>
  );
};

export default SourceItem;
