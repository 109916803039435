import {
  getTopicData,
  getTopicCoverage,
  setWordAsDifferent,
  setWordAsRemoved,
} from "../api/topic-search";
import {
  transformKeywords,
  setTopicRules,
  transformData,
  getExpectedTitle,
  sortTopic,
  insertCountableKeyword,
} from "./topic-helpers";
import { getUserToken } from "../utils/userStatus";

export const requestTopicSearch = async (topic, language, token) => {
  const topicData = await getTopicData(
    { keyword: topic, lang: language },
    token
  );


  const shapeData = transformData(topicData);

  const topicResult = {
    topicData: shapeData,
    error: false,
  };

  return topicResult;
};

export const getTopicDetails = async (topic, language, type, token) => {
  const topicDetails = await getTopicCoverage(
    { keyword: topic, lang: language ?? "RO", type },
    token
  );

  // separate response based on type (if article or description)
  if (type === 0 || type === 2) {
    const {
      radarChart,
      topicDensityCount,
      topicCoverageHigh,
      topicCoverageLow,
    } = topicDetails;

    const modifiedRadarChart = {
      ...radarChart,
      topicDensity: radarChart.topicDensity > 1 ? radarChart.topicDensity : Math.round(radarChart.maxWords * 0.009)
    };

    const maxValues = {
      ...modifiedRadarChart,
    };

    const topicRules = setTopicRules(maxValues);
    const expectedTitle = getExpectedTitle(topicCoverageHigh);

    // sort the array to have the title always as first element
    const sortedTopicCoverage = sortTopic(topicCoverageHigh);

    // insert use_in_text value for keywords, this way we display how many times a keyword is used inside an article,
    // also this counts for topicDensity in radar chart
    const topicCoverageHighCountable = insertCountableKeyword(
      sortedTopicCoverage,
      topicDensityCount,
      modifiedRadarChart.topicDensity,
    );

    const shapedTopicDetails = {
      maxValues,
      rules: { ...topicRules },
      topicCoverageHigh: transformKeywords(topicCoverageHighCountable),
      topicCoverageLow: transformKeywords(topicCoverageLow),
      // topicCoverageHigh: [],
      // topicCoverageLow: [],
      topicDensityCount,
      expectedTitle,
      error: false,
    };

    return shapedTopicDetails;
  } else {
    const { topicCoverage } = topicDetails;
    const expectedTitle = getExpectedTitle(topicCoverage);
    const sortedTopicCoverage = sortTopic(topicCoverage);
    const shapedTopicDetails = {
      topicCoverageHigh: transformKeywords(sortedTopicCoverage),
      expectedTitle,
      error: false,
    };
    return shapedTopicDetails;
  }
};

export const keywordSetAsCompleted = async (keyword, articleId) => {
  const token = getUserToken();

  try {
    await setWordAsDifferent({ keyword, articleId }, token);
  } catch ({ data }) {
    return {
      ...data,
      error: true,
    };
  }
};

export const keywordRemovedByUser = async (keyword, articleId) => {
  const token = getUserToken();

  try {
    await setWordAsRemoved({ keyword, articleId }, token);
  } catch ({ data }) {
    return {
      ...data,
      error: true,
    };
  }
};
