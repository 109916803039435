import React from "react";

import "./TitleWithTag.css";

const TitleWithTag = ({ title, tagColor = "#dadada" }) => {
  return (
    <div className="title-with-tag">
      <span className="tag" style={{ background: tagColor }}></span>
      <div className="title">{title}</div>
    </div>
  );
};

export default TitleWithTag;
