import React from "react";

export const AudienceIcon = () => {
  return <img src="/images/_audience-icon.png" alt="icon" />;
};

export const ToneOfVoiceIcon = () => {
  return <img src="/images/_tov-icon.png" alt="icon" />;
};

export const PencilIcon = () => {
  return <img src="/images/_pencil-icon.png" alt="icon" />;
};

export const ThumbsIcon = () => {
  return <img src="/images/_thumbs-icon.png" alt="icon" />;
};

export const Thumbs2Icon = ({ width, height }) => {
  return (
    <img
      src="/images/_icon-thumb.png"
      alt="icon"
      width={width}
      height={height}
    />
  );
};

export const EyesIcon = ({ width, height }) => {
  return (
    <img
      src="/images/_icon-eyes.png"
      alt="icon"
      width={width}
      height={height}
    />
  );
};

export const AvoidIcon = () => {
  return <img src="/images/_avoid-icon.png" alt="icon" />;
};

export const FormattingIcon = () => {
  return <img src="/images/_formatting-icon.png" alt="icon" />;
};

export const PictureIcon = () => {
  return <img src="/images/_picture-ico.png" alt="icon" />;
};

export const CTAIcon = () => {
  return <img src="/images/_cta-icon.png" alt="icon" />;
};

export const FbIcon = () => {
  return <img src="/images/_fb-icon.png" alt="icon" />;
};
