import React, { useState } from "react";
import { requestIndustries } from "../services/industries";
import useMount from "../utils/useMount";

import "./Register.css";
import MultistepRegisterComponent from "../Components/MultistepRegister/MultistepRegisterComponent";

const MultistepRegister = () => {
  const [industries, setIndustries] = useState([]);
  useMount(() => {
    getIndustries();
  });

  const getIndustries = async () => {
    const industriesOptions = await requestIndustries();
    setIndustries(industriesOptions);
  };

  return (
    <div className="register-page">
      <MultistepRegisterComponent industriesOptions={industries} />
    </div>
  );
};

export default MultistepRegister;
