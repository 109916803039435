import React, { Fragment, useEffect, useMemo, useState } from "react";
import MenuItemTop from "./MenuItemTop";

import "./EditorMenuTop.css";

import ImageUploader from "./ImageUploader";
import Hyperlink from "./Hyperlink";
import Popup from "reactjs-popup";
import { useDispatch, useSelector } from "react-redux";
import { getImageLimitError, setLimitImagesError } from "../../reducers/images";

const EditorMenuTop = ({ editor }) => {
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState("");
  const imageLimitError = useSelector(getImageLimitError);

  useEffect(() => {
    if (imageLimitError) {
      setIsOpen(true);
      setError(imageLimitError);
    }
  }, [imageLimitError]);

  const items = useMemo(
    () => [
      {
        icon: "h-2",
        title: "Heading 2",
        action: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
        isActive: () => editor && editor.isActive("heading", { level: 2 }),
      },
      {
        icon: "h-3",
        title: "Heading 3",
        action: () => editor.chain().focus().toggleHeading({ level: 3 }).run(),
        isActive: () => editor && editor.isActive("heading", { level: 3 }),
      },
      {
        icon: "h-4",
        title: "Heading 4",
        action: () => editor.chain().focus().toggleHeading({ level: 4 }).run(),
        isActive: () => editor && editor.isActive("heading", { level: 4 }),
      },
      {
        icon: "h-5",
        title: "Heading 5",
        action: () => editor.chain().focus().toggleHeading({ level: 5 }).run(),
        isActive: () => editor && editor.isActive("heading", { level: 5 }),
      },
      {
        icon: "paragraph",
        title: "Paragraph",
        action: () => editor.chain().focus().setParagraph().run(),
        isActive: () => editor && editor.isActive("paragraph"),
      },
      {
        type: "divider",
      },
      {
        icon: "bold",
        title: "Bold",
        action: () => editor.chain().focus().toggleBold().run(),
        isActive: () => editor && editor.isActive("bold"),
      },
      {
        icon: "italic",
        title: "Italic",
        action: () => editor.chain().focus().toggleItalic().run(),
        isActive: () => editor && editor.isActive("italic"),
      },

      {
        icon: "image-fill",
        title: "Add Image",
        isComponent: true,
        Component: (
          <ImageUploader
            icon="image-fill"
            title="Add Image"
            onUploadProgres={setProgress}
            onUploadError={setError}
            onUploadStart={() => setIsOpen(true)}
            onUploadSuccess={() => setIsOpen(false)}
          />
        ),
      },
      {
        icon: "link",
        title: "Link",
        isComponent: true,
        Component: <Hyperlink icon="link" title="Link" />,
      },
      {
        type: "divider",
      },
      {
        icon: "align-left",
        title: "Align Left",
        action: () => editor.chain().focus().setTextAlign("left").run(),
        isActive: () => editor && editor.isActive({ textAlign: "left" }),
      },
      {
        icon: "align-center",
        title: "Align Center",
        action: () => editor.chain().focus().setTextAlign("center").run(),
        isActive: () => editor && editor.isActive({ textAlign: "center" }),
      },
      {
        icon: "align-right",
        title: "Align Right",
        action: () => editor.chain().focus().setTextAlign("right").run(),
        isActive: () => editor && editor.isActive({ textAlign: "right" }),
      },
      {
        icon: "align-justify",
        title: "Align Justified",
        action: () => editor.chain().focus().setTextAlign("justify").run(),
        isActive: () => editor && editor.isActive({ textAlign: "justify" }),
      },
      {
        type: "divider",
      },
      {
        icon: "list-unordered",
        title: "Bullet List",
        action: () => editor.chain().focus().toggleBulletList().run(),
        isActive: () => editor && editor.isActive("bulletList"),
      },
      {
        icon: "list-ordered",
        title: "Ordered List",
        action: () => editor.chain().focus().toggleOrderedList().run(),
        isActive: () => editor && editor.isActive("orderedList"),
      },
      {
        icon: "list-check-2",
        title: "Task List",
        action: () => editor.chain().focus().toggleTaskList().run(),
        isActive: () => editor && editor.isActive("taskList"),
      },
      {
        icon: "code-box-line",
        title: "Code Block",
        action: () => editor.chain().focus().toggleCodeBlock().run(),
        isActive: () => editor && editor.isActive("codeBlock"),
      },
      {
        type: "divider",
      },
      {
        icon: "double-quotes-l",
        title: "Blockquote",
        action: () => editor.chain().focus().toggleBlockquote().run(),
        isActive: () => editor && editor.isActive("blockquote"),
      },
      {
        icon: "separator",
        title: "Horizontal Rule",
        action: () =>
          editor && editor.chain().focus().setHorizontalRule().run(),
      },
      {
        type: "divider",
      },
      {
        icon: "arrow-go-back-line",
        title: "Undo",
        action: () => editor && editor.chain().focus().undo().run(),
      },
      {
        icon: "arrow-go-forward-line",
        title: "Redo",
        action: () => editor && editor.chain().focus().redo().run(),
      },
    ],
    [editor]
  );

  const onClosePopup = () => {
    dispatch(setLimitImagesError(null));
    setError("");
    setIsOpen(false);
  };

  return (
    <>
      <div className="editor__header">
        {items.map((item, index) => (
          <Fragment key={index}>
            {item.type === "divider" ? (
              <div className="divider" />
            ) : (
              <MenuItemTop {...item} />
            )}
          </Fragment>
        ))}
      </div>
      <Popup
        open={isOpen}
        onClose={() => onClosePopup()}
        closeOnDocumentClick={true}
      >
        <div className="upload-progress-wrapper">
          {!error && (
            <p className="upload-progress-message">UPLOADING IMAGE...</p>
          )}
          {error && <p className="upload-progress-error">{error}</p>}
          {!imageLimitError && (
            <div className="upload-progress-bar">
              <div
                style={{
                  width: `${progress}%`,
                  backgroundColor: `#afe9cb`,
                  height: "14px",
                  borderRadius: "9px",
                }}
              ></div>
            </div>
          )}
        </div>
      </Popup>
    </>
  );
};

export default EditorMenuTop;
