import React, { useCallback, useReducer, useState } from "react";
import Input from "../../Shared/Input";
import Button from "../../Shared/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  renameTopic,
  prefetchData,
  editLiveURL,
} from "../../services/dashboard";

import { formReducer } from "../../utils/formReducer";
import { VALIDATOR_MINLENGTH } from "../../utils/validators";

import "./RenameTopic.css";
import { EDIT_LIVE_URL, RENAME_TOPIC } from "../../common/consts";
import { formatDateForStore } from "../../utils/dateUtility";

const RenameTopic = ({
  onClose,
  currentRow,
  onRename,
  modalAction,
  onUpdateData,
}) => {
  const existingDate =
    currentRow.length > 0 && currentRow[0].publishedDate
      ? formatDateForStore(currentRow[0].publishedDate)
      : new Date();

  const [publishedDate, setPublishedDate] = useState(existingDate);

  const [formState, dispatch] = useReducer(formReducer, {
    inputs: {
      topic: {
        value: "",
        isValid: false,
      },
    },
    isValid: false,
  });
  const inputHandler = useCallback((id, value, isValid) => {
    dispatch({
      type: "INPUT_CHANGE",
      value: value,
      isValid: isValid,
      inputId: id,
    });
  }, []);

  const saveTopicInput = async (event) => {
    event.preventDefault();

    if (modalAction === EDIT_LIVE_URL) {
      console.log(currentRow);
      const result = await editLiveURL(
        currentRow[0].id,
        formState.inputs.topic.value,
        publishedDate
      );
      if (result) {
        onClose();
        onUpdateData();
      }
    } else {
      // currentRow will always be an array with a single object
      // so we can use the first element's id
      const newTopicName = await renameTopic(
        currentRow[0].id,
        formState.inputs.topic
      );
      if (newTopicName) {
        // close popup if we have new name from server
        onRename(newTopicName);
        onClose();
        // prefetch data for the created topic
        try {
          await prefetchData(formState.inputs.topic.value);
        } catch ({ data }) {
          return {
            ...data,
            error: true,
          };
        }
      }
    }
  };

  return (
    <form className={"rename-topic-form"} onSubmit={saveTopicInput}>
      <Input
        id="topic"
        element="input"
        type="text"
        label={`${modalAction === RENAME_TOPIC ? `Topic` : `URL`}`}
        validators={[VALIDATOR_MINLENGTH(3)]}
        errorText="Please enter a text longer than 3 characters."
        onInput={inputHandler}
        initialValue={
          modalAction === RENAME_TOPIC
            ? currentRow.length > 0 && currentRow[0].topic
            : currentRow.length > 0 && currentRow[0].liveUrl
        }
      />
      {modalAction === EDIT_LIVE_URL && (
        <div className="published-date">
          <p className="publish-label">Published Date: </p>
          <div className="publish-cal">
            <DatePicker
              closeOnScroll={true}
              selected={publishedDate}
              onChange={(date) => setPublishedDate(date)}
              dateFormat="dd/MM/yyyy"
              popperPlacement={"bottom"}
              required={true}
              calendarClassName="rasta-stripes"
              openToDate={new Date()}
              inline={false}
              className="url-date-picker"
            />
          </div>
        </div>
      )}

      <Button type="submit" fullSize disabled={!formState.isValid}>
        Submit
      </Button>
    </form>
  );
};

export default RenameTopic;
