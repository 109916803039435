import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentArticleId, saveArticle } from "../../reducers/articles";
import { getEditorInstance } from "../../reducers/textEditor";
import { useApplicationType } from "../../utils/useApplicationType";
import SearchKeywords from "../SearchKeywords";

import "./AISidebarContent.css";
import { useQuery } from "react-query";
import { Queries } from "../../common/consts";
import { requestAIData } from "../../services/ai-interactions";
import { parseAiFAQ, parseKeywords } from "./helpers";
import KeywordItem from "./KeywordItem";

const getSteps = () => {
  return [
    "Generate AI title",
    "Generate AI FAQ",
    "Generate AI outline",
    "Generate LSI AI keywords",
  ];
};

const AISidebarContent = () => {
  const applicationType = useApplicationType();
  const dispatch = useDispatch();
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [allKeywords, setAllKeywords] = useState(null);
  const [filterUsed, setFilterUsed] = useState(null);
  const [questionId, setQuestionId] = useState(-1);
  const [isFetching, setIsFetching] = useState(false);
  const editor = useSelector(getEditorInstance);
  const articleId = useSelector(getCurrentArticleId);
  const [idCollection, setIdCollection] = useState([]);

  const { data, refetch, isLoading, isFetching: fetchingData } = useQuery(
    [
      Queries.AI_RESULTS, // unique query key
      { id: articleId, qId: questionId }, // query params
    ],
    requestAIData, // query function,
    {
      enabled: isFetching, // only run when isFetching is true
      staleTime: 0, // don't use stale data
      cacheTime: 0, // don't use cache data
    }
  );

  const steps = getSteps();

  useEffect(() => {
    if (questionId === 3) {
      const filteredKeywords = filterUsed?.filter(({ keyword }) =>
        keyword?.includes(searchKeyword)
      );

      setAllKeywords(filteredKeywords);
    }
  }, [searchKeyword, questionId, filterUsed, data]);

  const filterKeywords = (filter) => {
    setSearchKeyword(filter);
  };

  const removeH1Tag = (html) => {
    return html.replace(/<h1>.*<\/h1>/, "");
  };

  const getMeTitle = () => {
    setQuestionId(0);
    setIsFetching(true);
    if (!idCollection.includes(0)) {
      setIdCollection([...idCollection, 0]);
    }

  };

  const getMeOutline = () => {
    setQuestionId(2);
    setIsFetching(true);
    if (!idCollection.includes(2)) {
      setIdCollection([...idCollection, 2]);
    }

  };
  const getMeFAQ = () => {
    setQuestionId(1);
    setIsFetching(true);
    if (!idCollection.includes(1)) {
      setIdCollection([...idCollection, 1]);
    }

  };

  const getMeKeywords = () => {
    setQuestionId(3);
    setIsFetching(true);
    if (!idCollection.includes(3)) {
      setIdCollection([...idCollection, 3]);
    }

  };

  useEffect(() => {
    if (isFetching) {
      refetch();
      setIsFetching(false);
    }
  }, [isFetching, questionId, refetch]);

  useEffect(() => {
    const editorData = editor?.getHTML();
    if (data) {
      if (questionId === 0) {
        const removed = removeH1Tag(editorData);
        const newTitle = `<h1>${data.data}</h1>`;
        editor?.commands.setContent(`${newTitle} ${removed}`);
      }

      if (questionId === 1) {
        const outlineContent = data.data;

        editor?.commands.setContent(
          `${editorData}
          <p></p><h2>${outlineContent ? `&#128073 AI Generated Outline` : `&#128680; The AI outline could not be generated. Please try again.`}</h2> ${outlineContent ? `<p><b>Based on topic keywords and ranking URLs for this topic, the AI module has generated the following possible structure.</b></p>` : ``} ${outlineContent}<hr>`
        );
      }
      if (questionId === 2) {
        const returned = parseAiFAQ(data.data);

        editor?.commands.setContent(
          `${editorData} <p></p><h2>${returned ? `&#128073 AI Generated FAQ` : `&#128680; The AI FAQ could not be generated. Please try again.`}</h2>${returned ? `<p><b>The article should answer as many of the following questions as possible. Some of these can be used as headings or marked with <a target="_blank" rel="canonical" href="https://schema.org/">Schema.org</a>  FAQ markup.</b></p>` : ``}<p></p>${returned}<hr>`
        );
      }
      if (questionId === 3) {
        const transformed = parseKeywords(data.data);
        setFilterUsed(transformed);
        setAllKeywords(transformed);
      }
      dispatch(saveArticle(applicationType));
    }
  }, [data, questionId, editor, dispatch, applicationType]);

  const getButtonText = useCallback((step) => {
    if (fetchingData && questionId === step) {
      return "Loading...";
    }

    if (idCollection.includes(step)) {
      return "Try Again";
    } else {
      return "Generate";
    }

  }, [fetchingData, questionId]);


  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div>
            <p className="step-desc">
              AI can suggest a title for your topic based on the keyword that
              generates the most organic traffic.
            </p>
            <button className="user-action-button" onClick={getMeTitle}>
              {getButtonText(0)}
            </button>
          </div>
        );
      case 1:
        return (
          <div>
            <p className="step-desc">
              By using AI to generate a list of the most commonly searched for
              questions related to a topic, creators can ensure that their
              content is addressing the questions that their target audience is
              asking. You can also implement the schema.org FAQ markup for 2-3
              questions in order for them to be displayed in the Google results
              pages.
            </p>
            <button className="user-action-button" onClick={getMeOutline}>
              {getButtonText(2)}
            </button>
          </div>
        );
      case 2:
        return (
          <div>
            <p className="step-desc">
              AI can suggest an article outline based on the clusters of
              keywords that competitors are ranking for this topic, as well as
              their on-page article content.

            </p>
            <button className="user-action-button" onClick={getMeFAQ}>
              {getButtonText(1)}
            </button>
          </div>
        );
      case 3:
        return (
          <div>
            <p className="step-desc">
              AI can suggest LSI (Latent Semantic Indexing) keywords that are
              related to the main keyword or the topic of this piece of content.
              Incorporate these keywords throughout your content to increase its
              relevance to the article's topic.
            </p>
            <button className="user-action-button" onClick={getMeKeywords}>
              {getButtonText(3)}
            </button>
          </div>
        );
      default:
        return "";
    }
  };

  return (
    <div>
      <div className="ai-sidebar">
        {steps.map((label, index) => (
          <div className="steps-wrapper" key={index}>
            <div className="step">
              <span className="step-bullet">{index + 1}</span>
              <div className="step-label">{label}</div>
            </div>
            <div className="step-content">{getStepContent(index)}</div>
          </div>
        ))}
      </div>

      {questionId === 3 && allKeywords && (
        <>
          <div className="side-topic-info">
            <h3>AI LSI Keywords</h3>
            <p>
              LSI (Latent Semantic Indexing) keywords are terms and phrases that
              are related to the main keyword or topic of a piece of content.
              These keywords help search engines to better understand the
              content and its relevance to the user's search query.
            </p>
          </div>
          <SearchKeywords onFilter={filterKeywords} />
          <KeywordItem activeKeywords={allKeywords} />
        </>
      )}
    </div>
  );
};

export default AISidebarContent;
