import React from 'react';
import { Link } from 'react-router-dom';
import './CustomCheckbox.css';

const CustomCheckbox = ({ checked, handleChange }) => {

  return (
    <div className="custom-checkbox">
      <input type="checkbox" id="myCheck" checked={checked} onChange={(event) => handleChange(event.target.checked)}/>
      <div className="checkbox-label">
        Creating an account means you’re okay with our <Link to="">Terms of Service.</Link>
      </div>
    </div>
  );
};

export default CustomCheckbox;