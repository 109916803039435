import React, { useState, useEffect } from "react";
import Select from "react-select";

import "./Options.css";

const Options = ({
  isPopup,
  data,
  handleOptionChange,
  preselectedValue,
  readOnlyDisplay,
  placeholder,
  restricted, // restricted flag will limit the width of the container to 100px
}) => {
  const [options, setOptions] = useState();
  const [selectedValue, setSelectedValue] = useState(preselectedValue);

  useEffect(() => {
    const formattedOptions =
      data &&
      data.map((option) => {
        return {
          value: option.id,
          label: option.name,
          color: option.color,
        };
      });

    const noOption = {
      value: "no-option-selected",
      label: "--",
    };

    const newOptions = [noOption, ...formattedOptions];

    setOptions(newOptions);
  }, [data]);

  useEffect(() => {
    setSelectedValue(preselectedValue);
  }, [preselectedValue]);

  const handleChangedValue = (values) => {
    const currentValue = values.value !== "no-option-selected" ? values : null;
    setSelectedValue(currentValue);

    // selectedValue from state will send the previous option selected
    // we need to know which user was unassigned (if that's the case)
    handleOptionChange(currentValue, selectedValue);
  };

  return (
    <>
      {!readOnlyDisplay ? (
        <div
          className={`${
            isPopup ? `table-select-options__popup` : "table-select-options"
          } ${restricted ? `table-select-options-restricted` : ""} ${
            // change text color to red if status is `Re-assigned to proof`
            preselectedValue && preselectedValue.color
              ? "table-select-options-red"
              : ""
          }`}
        >
          <Select
            style={{ width: "30px" }}
            options={options}
            placeholder={placeholder}
            className="react-select-container"
            classNamePrefix="react-select"
            onChange={handleChangedValue}
            value={selectedValue}
          />
        </div>
      ) : (
        <div className="read-only">
          {(preselectedValue && preselectedValue.label) || "N/A"}
        </div>
      )}
    </>
  );
};

export default Options;
