import React, { useState, useEffect } from "react";
import {
  getDeadlineInDays,
  compareDates,
  getDeadlineInYears,
} from "../../utils/dateUtility";
import DatePicker from "react-datepicker";
import "./DateDisplayWrapper.css";
import Popup from "reactjs-popup";
import { getYear } from "date-fns";

const MIN_YEAR = 2020;

const DateDisplayWrapper = ({
  savedDate,
  isDeadline,
  handleDateChange,
  readOnlyDisplay,
  currentRows,
  updateProperty,
  isCreateArticle,
}) => {
  const [startDate, setStartDate] = useState(
    new Date(savedDate) // MM-DD-YYYY
  );
  const [startDateYear] = useState(getYear(startDate));

  const [openCal, setOpenCal] = useState(false);

  const [deadlineStatus, setDeadlineStatus] = useState();

  useEffect(() => {
    if (isDeadline) {
      const status = compareDates(startDate);
      setDeadlineStatus(status);
    }
  }, [isDeadline, startDate]);

  const setChosenDate = (currentChosenDate) => {
    // don't update date here, will be updated from parent local state
    const updatedRow = isCreateArticle
      ? currentChosenDate
      : currentRows.map((row) => ({
          ...row,
          [updateProperty]: currentChosenDate,
        }));
    if (isCreateArticle) {
      setStartDate(currentChosenDate);
    }

    handleDateChange(updatedRow, updateProperty);
    setOpenCal(false);
  };

  const handleOpen = () => {
    setOpenCal(!openCal);
  };

  const messageDisplay = () => {
    if (startDateYear < MIN_YEAR) {
      return "No deadline";
    }

    const diffInYears = getDeadlineInYears(startDate);

    if (diffInYears !== 0) {
      if (diffInYears < 1) {
        const positiveNum = Math.abs(diffInYears);
        const padded = String(positiveNum).padStart(2, "0");
        const message = `${padded} year${positiveNum > 1 ? `s` : ``} ago`;

        return message;
      } else {
        const padded = String(diffInYears).padStart(2, "0");
        return `in ${padded} year${diffInYears > 1 ? `s` : ``}`;
      }
    } else {
      const calendarDays = getDeadlineInDays(startDate);
      if (calendarDays < 1) {
        const positiveNum = Math.abs(calendarDays);
        const padded = String(positiveNum).padStart(2, "0");
        const message =
          positiveNum !== 0
            ? `${padded} day${positiveNum > 1 ? `s` : ``} ago`
            : `today`;
        return message;
      } else {
        const padded = String(calendarDays).padStart(2, "0");
        return `in ${padded} day${calendarDays > 1 ? `s` : ``}`;
      }
    }
  };

  return (
    <>
      <Popup
        open={openCal}
        onClose={() => setOpenCal(false)}
        closeOnDocumentClick={true}
      >
        <div className="calendar-only-display">
          <DatePicker
            selected={startDateYear < MIN_YEAR ? new Date() : startDate} // if no date set this as today
            onChange={setChosenDate}
            inline
          />
        </div>
      </Popup>
      <div
        className={`${
          isDeadline
            ? `display-date-wrapper-${deadlineStatus}`
            : "display-date-wrapper"
        }`}
        onClick={!readOnlyDisplay ? handleOpen : undefined}
      >
        {messageDisplay()}
      </div>
    </>
  );
};

export default DateDisplayWrapper;
