import React from "react";

import Box from "@material-ui/core/Box";
import InfoTitle from "./InfoTitle";

import "./InfoCard.css";
import CardData from "./CardData";
import { intToString } from "../../../utils/formatNumbersToString";

const InfoCard = ({
  children,
  mainIcon,
  title,
  cardData,
  spanColor,
  inverted,
}) => {
  return (
    <Box className="info-card">
      <div className="info-card-content-wrapper">
        <div className="main-icon" style={{ background: spanColor }}>
          <span className="card-icon">{mainIcon}</span>
        </div>
        <div className="card-icon-title">
          <InfoTitle title={title} />
        </div>
        <div className="card-data-content">
          <CardData data={intToString(cardData)} />
        </div>
        {children}
      </div>
    </Box>
  );
};

export default InfoCard;
