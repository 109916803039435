import React from "react";

import Rating from "react-rating";
import Button from "../../Shared/Button";
import { useHistory } from "react-router-dom";
import { StarIconEmpty, StarIconFilled } from "./Icons";
import { submitRatingArticle } from "../../services/articles";
import FormErrorMessage from "../FormErrorMessage";
import { DASHBOARD_PATH } from "../../common/consts";

import "./RatingComponent.css";

const RatingComponent = ({ articleId, onClose, onRatingDone }) => {
  const history = useHistory();
  const [guidelines, setGuidelines] = React.useState(0);
  const [structure, setStructure] = React.useState(0);
  const [grammar, setGrammar] = React.useState(0);
  const [spelling, setSpelling] = React.useState(0);
  const [formatting, setFormatting] = React.useState(0);
  const [message, setMessage] = React.useState(null);
  const [buttonText, setButtonText] = React.useState("Rate");
  const [isSent, setIsSent] = React.useState(false);

  const onSubmitRating = async () => {
    const payload = {
      id: articleId,
      guidelines,
      structure,
      grammar,
      spelling,
      formatting,
    };

    try {
      const res = await submitRatingArticle(payload);
      if (res.error) {
        setMessage(res.data);
        setIsSent(false);
      }

      setIsSent(true);
      setMessage(
        "Thank you for your rating! You will be redirected to dashboard"
      );
      setTimeout(() => {
        onRedirect();
      }, 5000);

      setButtonText("Close");
    } catch (error) {
      console.log(error);
    }
  };

  const enableBtn =
    guidelines !== 0 &&
    structure !== 0 &&
    grammar !== 0 &&
    spelling !== 0 &&
    formatting !== 0;

  const buttonAction = () => {
    if (buttonText === "Rate") {
      onSubmitRating();
      onRatingDone();
    } else {
      onClose();
      history.push(DASHBOARD_PATH);
    }
  };

  const onRedirect = () => {
    onClose();
    history.push(DASHBOARD_PATH);
  };

  return (
    <div className="ratingComponent">
      <p className="submit-modal-title">
        Please rate the quality of this article
      </p>
      <div className="rating-group">
        <div className="rating-name">{`Project & Article Guidelines`}</div>
        <Rating
          initialRating={guidelines}
          emptySymbol={<StarIconEmpty width={20} height={20} fill="#d9e1e9" />}
          fullSymbol={<StarIconFilled width={20} height={20} fill="#f6cb00" />}
          onClick={(val) => setGuidelines(val)}
        />
      </div>
      <div className="rating-group">
        <div className="rating-name">{`Information & Logical Structure`}</div>
        <Rating
          initialRating={structure}
          emptySymbol={<StarIconEmpty width={20} height={20} fill="#d9e1e9" />}
          fullSymbol={<StarIconFilled width={20} height={20} fill="#f6cb00" />}
          onClick={(val) => setStructure(val)}
        />
      </div>
      <div className="rating-group">
        <div className="rating-name">{`Grammar`}</div>
        <Rating
          initialRating={grammar}
          emptySymbol={<StarIconEmpty width={20} height={20} fill="#d9e1e9" />}
          fullSymbol={<StarIconFilled width={20} height={20} fill="#f6cb00" />}
          onClick={(val) => setGrammar(val)}
        />
      </div>
      <div className="rating-group">
        <div className="rating-name">{`Spelling & Punctuation`}</div>
        <Rating
          initialRating={spelling}
          emptySymbol={<StarIconEmpty width={20} height={20} fill="#d9e1e9" />}
          fullSymbol={<StarIconFilled width={20} height={20} fill="#f6cb00" />}
          onClick={(val) => setSpelling(val)}
        />
      </div>
      <div className="rating-group">
        <div className="rating-name">{`Images & Formatting`}</div>
        <Rating
          initialRating={formatting}
          emptySymbol={<StarIconEmpty width={20} height={20} fill="#d9e1e9" />}
          fullSymbol={<StarIconFilled width={20} height={20} fill="#f6cb00" />}
          onClick={(val) => setFormatting(val)}
        />
      </div>
      <div className="rating-submit-btn-wrapper">
        <Button
          type="button"
          fullSize
          passive
          disabled={!enableBtn}
          onClick={buttonAction}
        >
          {buttonText}
        </Button>
        <p className="rating-footnote">
          *make sure you read the rating guidelines
        </p>
      </div>
      <FormErrorMessage success={isSent}>{message}</FormErrorMessage>
    </div>
  );
};

export default RatingComponent;
