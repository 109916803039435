export const getStarsNumber = (number) => {
  const fullNumber = Math.floor(number);
  const decimals = number - Math.floor(number);
  if (decimals > 0.5) {
    return fullNumber + 1;
  }

  return fullNumber;
};

export const getNumberWithOneDecimal = (number) => {
  return Number(number).toFixed(1);
};
