import React from "react";
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar } from "recharts";
import { intToString } from "../utils/formatNumbersToString";

import "./ResultsChart.css";

const ResultsChart = ({ data, hideYAxis }) => {
  return (
    <BarChart
      width={530}
      height={200}
      data={data}
      margin={{ top: 5, right: 35, left: 35, bottom: 5 }}
    >
      {!hideYAxis && <CartesianGrid strokeDasharray="1 1" vertical={false} />}
      <XAxis
        axisLine={false}
        tickSize={0}
        tickMargin={8}
        dataKey="name"
        tick={{ fill: "#7a8797", fontSize: 12 }}
      />
      {!hideYAxis && (
        <YAxis
          width={12}
          tickMargin={5}
          tickSize={0}
          axisLine={false}
          domain={[0, 1800]}
          tick={{ fill: "#7a8797", fontSize: 12 }}
        />
      )}

      <Tooltip
        cursor={false}
        isAnimationActive={false}
        offset={10}
        content={hideYAxis ? <SecondaryTooltip /> : <CustomTooltip />}
      />

      <Bar dataKey="value" />
    </BarChart>
  );
};

class CustomTooltip extends React.Component {
  render() {
    const { active } = this.props;

    if (active) {
      const { payload } = this.props;
      return (
        <div className="results-tooltip">
          <p className="tooltip-label">search volume</p>
          <p className="tooltip-value">
            {payload && payload[0].value.toLocaleString()}
          </p>
        </div>
      );
    }

    return null;
  }
}

class SecondaryTooltip extends React.Component {
  render() {
    const { active } = this.props;

    if (active) {
      const { payload } = this.props;
      return (
        <div className="secondary-tooltip">
          <p className="secondary-tooltip-label">Searches</p>
          <span className="info">
            <span className="square"></span>
            <p className="secondary-tooltip-value">
              {intToString(payload && payload[0].value)}
            </p>
          </span>
        </div>
      );
    }

    return null;
  }
}

export default ResultsChart;
