import React, { useCallback, useReducer, useState } from "react";
import Input from "../../Shared/Input";
import Button from "../../Shared/Button";
import Select, { components } from "react-select";
import {
  DEADLINE,
  WRITER,
  PROJECT,
  INDUSTRY,
  TYPE_ARTICLE,
} from "../../common/consts";

import { formReducer } from "../../utils/formReducer";
import { VALIDATOR_MINLENGTH } from "../../utils/validators";
import { createNewEntry, prefetchData } from "../../services/dashboard";

import { addDaysToCurrentDate } from "../../utils/dateUtility";

import "./CreateNewEntryForm.css";
import Scrollbars from "react-custom-scrollbars";
import DateDisplayWrapper from "../DateDisplayWrapper/DateDisplayWrapper";
import { useDispatch, useSelector } from "react-redux";
import { setApiError } from "../../reducers/apiErrors";
import { flagSelector, countries } from "../SearchTopicComponent";
import { getFeatureFlags } from "../../reducers/featureFlags";

const findDefaultValue = (data, currentId) => {
  if (currentId) {
    const current = data.find((element) => element.id === currentId);

    return {
      value: current.id,
      label: current.name,
    };
  } else {
    return null;
  }
};

export const convertOptionsData = (array) => {
  const formatted =
    array &&
    array.map((option) => {
      return {
        value: option.id,
        label: option.name,
      };
    });

  return formatted;
};

const CreateNewEntryForm = ({
  writers,
  projects,
  industries,
  editRowEntry,
  onClose,
  onCreated,
}) => {
  const stateDispatch = useDispatch();
  const { showTopicLanguages } = useSelector(getFeatureFlags);
  const isEditMode = editRowEntry.length > 0;
  const currentEntry = isEditMode ? editRowEntry[0] : {};

  const initialValues = {
    topic: currentEntry.topic,
    writer: currentEntry.writer,
    project: currentEntry.project,
    industry: currentEntry.industry,
    deadline: currentEntry.deadline,
    publisherUrl: currentEntry.publisherUrl,
    info: currentEntry.info,
  };
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const [formState, dispatch] = useReducer(formReducer, {
    inputs: {
      topic: {
        value: isEditMode ? initialValues.topic : "",
        // value: "",
        isValid: false,
      },
      writer: {
        value: "",
        isValid: true,
      },
      project: {
        value: isEditMode
          ? findDefaultValue(projects, initialValues.project).value
          : "",
        isValid: isEditMode,
      },
      industry: {
        value: "",
        isValid: true,
      },
      deadline: {
        value: addDaysToCurrentDate(2),
        isValid: true,
      },
      publisherUrl: {
        value: "",
        isValid: true,
      },
      info: {
        value: "",
        isValid: true,
      },
    },
    isValid: false,
  });

  const handleSelectChange = (id, values) => {
    dispatch({
      type: "INPUT_CHANGE",
      value: values.value || values,
      isValid: true,
      inputId: id,
    });
  };

  const inputHandler = useCallback((id, value, isValid) => {
    dispatch({
      type: "INPUT_CHANGE",
      value: value,
      isValid: isValid,
      inputId: id,
    });
  }, []);

  const saveFormInputs = async (event) => {
    event.preventDefault();

    const payload = isEditMode
      ? { ...formState.inputs, id: currentEntry.id }
      : { ...formState.inputs, type: TYPE_ARTICLE };

    try {
      const entry = await createNewEntry(payload, isEditMode);

      if (entry.id || (isEditMode && entry.includes(currentEntry.id))) {
        // callback to update entire table with the new article created
        onCreated();
        // close popup if we have an id from server response, or if the response
        // contains the id, in case of edit mode
        onClose();
        // prefetch data for the created topic

        await prefetchData(formState.inputs.topic.value, selectedCountry.value);
      }
    } catch (error) {
      stateDispatch(setApiError(error));
    }
  };

  const Option = (props) => (
    <components.Option {...props} className="country-option">
      <div className="country-option-select">
        <span className="country-option-icon">{props.data.icon}</span>
        <span>{props.data.label}</span>
      </div>
    </components.Option>
  );

  const handleChange = (value) => {
    setSelectedCountry(value);
  };

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      {selectedCountry.icon}
    </components.SingleValue>
  );

  return (
    <Scrollbars>
      <div className="form-wrapper">
        <form className={"new-entry-form"} onSubmit={saveFormInputs}>
          <div style={{ position: "relative" }}>
            <Input
              id="topic"
              element="input"
              type="text"
              label="Topic"
              validators={[VALIDATOR_MINLENGTH(3)]}
              errorText="Please enter a topic longer than 3 characters."
              onInput={inputHandler}
              initialValue={isEditMode ? initialValues.topic : ""}
            />
            {showTopicLanguages && (
              <div style={{ position: "absolute", top: 25, right: 0 }}>
                <Select
                  value={selectedCountry}
                  options={countries}
                  onChange={handleChange}
                  components={{
                    Option,
                    SingleValue,
                  }}
                  styles={flagSelector}
                />
              </div>
            )}
          </div>
          <div className={"select-inputs"}>
            <div className={"left-column"}>
              <Select
                options={writers ? convertOptionsData(writers) : []}
                placeholder="Select writer"
                className="react-select-container"
                classNamePrefix="react-select"
                onChange={(values) => handleSelectChange(WRITER, values)}
                defaultValue={
                  isEditMode
                    ? findDefaultValue(writers, initialValues.writer)
                    : null
                }
              />
              <Select
                options={industries ? convertOptionsData(industries) : []}
                placeholder="Select industry"
                className="react-select-container"
                classNamePrefix="react-select"
                onChange={(values) => handleSelectChange(INDUSTRY, values)}
                defaultValue={
                  isEditMode
                    ? findDefaultValue(industries, initialValues.industry)
                    : null
                }
              />
            </div>
            <div className={"right-column"}>
              <div className={"date-wrapper"}>
                <div className={"date-input-container"}>
                  <p className={"date-input-label"}>Set deadline</p>
                  <DateDisplayWrapper
                    handleDateChange={(date, property) =>
                      handleSelectChange(property, date)
                    }
                    savedDate={
                      isEditMode
                        ? initialValues.deadline
                        : addDaysToCurrentDate(2)
                    }
                    updateProperty={DEADLINE}
                    isCreateArticle={true}
                  />
                </div>
              </div>
              <Select
                options={projects ? convertOptionsData(projects) : []}
                placeholder="Select project"
                className="react-select-container"
                classNamePrefix="react-select required"
                onChange={(values) => handleSelectChange(PROJECT, values)}
                defaultValue={
                  isEditMode
                    ? findDefaultValue(projects, initialValues.project)
                    : null
                }
              />
            </div>
          </div>
          <Input
            id="publisherUrl"
            element="input"
            type="text"
            label="Publisher (Project's Blog)"
            validators={[]}
            onInput={inputHandler}
            initialValue={isEditMode ? initialValues.publisherUrl : ""}
            noValidation
          />
          <div className="additional-info-input">
            <Input
              id="info"
              type="text"
              label="Article Brief"
              rows={8}
              validators={[]}
              onInput={inputHandler}
              initialValue={isEditMode ? initialValues.info : ""}
              noValidation
            />
          </div>

          <Button
            type="submit"
            fullSize
            disabled={
              !formState.inputs.topic.isValid ||
              !formState.inputs.project.isValid
            }
          >
            Submit
          </Button>
        </form>
      </div>
    </Scrollbars>
  );
};

export default CreateNewEntryForm;
