import React, { useCallback, useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Button from "../../Shared/Button";
import Input from "../../Shared/Input";

import Select from "react-select";
import Popup from "reactjs-popup";
import { CLIENTS_DASHBOARD_PATH } from "../../common/consts";
import { dashboardIndustries } from "../../reducers/dashboard";
import {
  getProjectBriefData,
  sendProjectBriefForm,
} from "../../services/projects";
import { formReducer } from "../../utils/formReducer";
import { VALIDATOR_MINLENGTH } from "../../utils/validators";
import ProjectBriefSliders from "./ProjectBriefSliders";

import { setApiError } from "../../reducers/apiErrors";
import "./ProjectBriefForm.css";

const defaultInitialValues = {
  additionalInfo: null,
  audience: null,
  businessBlogUrl: null,
  businessFacebookUrl: null,
  businessName: null,
  businessUrl: null,
  contentSample: null,
  contentStyleSlider1: null,
  contentStyleSlider2: null,
  contentStyleSlider3: null,
  desiredCta: null,
  facebookCopy: null,
  formatting: null,
  primaryIndustryId: null,
  primaryIndustryName: null,
  sourcesAndImages: null,
  thingsToAvoid: null,
  thingsToConsider: null,
  toneOfVoice: null,
};

const convertOptionsData = (array) => {
  const formatted =
    array &&
    array.map((option) => {
      return {
        value: option.id,
        label: option.name,
      };
    });

  return formatted;
};

const findDefaultValue = (data, currentId) => {
  if (currentId && data) {
    const current = data.find((element) => element.id === currentId);

    const option = {
      value: current.id,
      label: current.name,
    };

    return option;
  } else {
    return null;
  }
};

const ProjectBriefForm = () => {
  const industries = useSelector(dashboardIndustries);
  const history = useHistory();
  const location = useLocation();
  const stateDispatch = useDispatch();
  const { id: projectId } = useParams();

  const [popupOpen, setPopupOpen] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState();

  useEffect(() => {
    if (projectId) {
      getInitialValues();
    }
  }, [projectId]);

  const getInitialValues = async () => {
    if (!location.state.new) {
      console.log("GETTING INITIAL VALUES");
      const initialValues = await getProjectBriefData(projectId);

      console.log({ initialValues });
      setFormInitialValues(initialValues);
    } else {
      setFormInitialValues(defaultInitialValues);
    }
  };

  const openPopup = () => setPopupOpen(true);
  const closePopup = () => setPopupOpen(false);
  const [formState, dispatch] = useReducer(formReducer, {
    inputs: {
      businessName: {
        value: "",
        isValid: false,
      },
      // businessUrl: {
      //   value: "",
      //   isValid: false,
      // },
      businessBlogUrl: {
        value: "",
        isValid: false,
      },
      businessFacebookUrl: {
        value: "",
        isValid: false,
      },
      primaryIndustry: {
        value: "",
        isValid: true,
      },
      // secondaryIndustry: {
      //   value: "",
      //   isValid: true,
      // },
      audience: {
        value: "",
        isValid: true,
      },
      contentStyleSlider1: {
        value: "" || null,
        isValid: true,
      },
      contentStyleSlider2: {
        value: "" || null,
        isValid: true,
      },
      contentStyleSlider3: {
        value: "" || null,
        isValid: true,
      },
      toneOfVoice: {
        value: "",
        isValid: true,
      },
      contentSample: {
        value: "",
        isValid: true,
      },
      thingsToAvoid: {
        value: "",
        isValid: true,
      },
      thingsToConsider: {
        value: "",
        isValid: true,
      },
      formatting: {
        value: "",
        isValid: true,
      },
      sourcesAndImages: {
        value: "",
        isValid: true,
      },
      desiredCta: {
        value: "",
        isValid: true,
      },
      additionalInfo: {
        value: "",
        isValid: true,
      },
      facebookCopy: {
        value: "",
        isValid: true,
      },
    },
    isValid: false,
  });

  const inputHandler = useCallback((id, value, isValid) => {
    dispatch({
      type: "INPUT_CHANGE",
      value: value,
      isValid: isValid,
      inputId: id,
    });
  }, []);

  const handleSelectChange = (id, values) => {
    dispatch({
      type: "INPUT_CHANGE",
      value: values.value || values,
      isValid: true,
      inputId: id,
    });
  };

  const handleSliderChange = (id, value) => {
    dispatch({
      type: "INPUT_CHANGE",
      value,
      isValid: true,
      inputId: id,
    });
  };

  const saveFormInputs = async (event) => {
    event.preventDefault();

    const payload = {
      project: projectId,
      businessName: formState.inputs.businessName.value,
      // businessUrl: formState.inputs.businessUrl.value,
      businessBlogUrl: formState.inputs.businessBlogUrl.value,
      businessFacebookUrl: formState.inputs.businessFacebookUrl.value,
      primaryIndustry: formState.inputs.primaryIndustry.value,
      // secondaryIndustry: formState.inputs.secondaryIndustry.value,
      audience: formState.inputs.audience.value,
      contentStyleSlider1: formState.inputs.contentStyleSlider1.value,
      contentStyleSlider2: formState.inputs.contentStyleSlider2.value,
      contentStyleSlider3: formState.inputs.contentStyleSlider3.value,
      toneOfVoice: formState.inputs.toneOfVoice.value,
      contentSample: formState.inputs.contentSample.value,
      thingsToConsider: formState.inputs.thingsToConsider.value,
      thingsToAvoid: formState.inputs.thingsToAvoid.value,
      formatting: formState.inputs.formatting.value,
      sourcesAndImages: formState.inputs.sourcesAndImages.value,
      desiredCta: formState.inputs.desiredCta.value,
      additionalInfo: formState.inputs.additionalInfo.value,
      facebookCopy: formState.inputs.facebookCopy.value,
    };

    try {
      const result = await sendProjectBriefForm(payload);
      console.log({ result });
      if (result) {
        openPopup();
      }
    } catch (error) {
      stateDispatch(setApiError(error));
    }
  };

  const confirmRedirect = () => {
    history.push(CLIENTS_DASHBOARD_PATH);
  };

  const setupLater = () => {
    history.push(CLIENTS_DASHBOARD_PATH);
  };

  const isFormValid = () => {
    const validName = formState.inputs.businessName.value !== "";
    // const validUrl = formState.inputs.businessUrl.value !== "";
    // const validBlog = formState.inputs.businessBlogUrl.value !== "";
    // const validFbUrl = formState.inputs.businessFacebookUrl.value !== "";

    return validName;
  };

  return (
    <>
      <Popup open={popupOpen} onClose={closePopup}>
        <div className="confirmation-brief-submit-modal">
          <>
            <p className="submit-modal-title">
              Your information was submitted successfully!
            </p>
            <p>You will be redirected to your dashboard page</p>
            <div className="submit-modal-controls">
              <Button type="button" fullSize onClick={confirmRedirect}>
                Ok
              </Button>
            </div>
          </>
        </div>
      </Popup>
      {formInitialValues && industries && (
        <div className="brief-form-wrapper">
          <form onSubmit={saveFormInputs}>
            <Input
              id="businessName"
              element="input"
              type="text"
              label="Project Name"
              validators={[VALIDATOR_MINLENGTH(3)]}
              errorText="This field cannot be blank."
              onInput={inputHandler}
              initialValue={formInitialValues && formInitialValues.businessName}
            />
            {/* <Input
            id="businessUrl"
            element="input"
            type="text"
            label="Business URL"
            validators={[VALIDATOR_MINLENGTH(3)]}
            errorText="This field cannot be blank."
            onInput={inputHandler}
            initialValue={formInitialValues && formInitialValues.businessUrl}
          /> */}
            <Input
              id="businessBlogUrl"
              element="input"
              type="text"
              label="Blog Path"
              validators={[VALIDATOR_MINLENGTH(3)]}
              errorText="This field cannot be blank."
              onInput={inputHandler}
              initialValue={
                formInitialValues && formInitialValues.businessBlogUrl
              }
            />
            <Input
              id="businessFacebookUrl"
              element="input"
              type="text"
              label="Social Profile"
              validators={[VALIDATOR_MINLENGTH(3)]}
              errorText="This field cannot be blank."
              onInput={inputHandler}
              initialValue={
                formInitialValues && formInitialValues.businessFacebookUrl
              }
            />
            <div className={"brief-select-inputs"}>
              <div className={"brief-left-column"}>
                <Select
                  options={convertOptionsData(industries)}
                  placeholder="Select Industry"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  onChange={(values) =>
                    handleSelectChange("primaryIndustry", values)
                  }
                  defaultValue={findDefaultValue(
                    industries,
                    formInitialValues.primaryIndustryId
                  )}
                />

                <Input
                  id="audience"
                  rows={5}
                  type="text"
                  placeholder="Ex: Publicul este alcătuit din consumatorii care iși doresc de un stil de viata sanatos, dar și din vegani și vegetariani, sportivi, persoane preocupate de starea lor de sanatate. Toti acestia cauta activ informatii despre viata sanatoasa si sustenabila. 
                "
                  // label={
                  //   <TitleWithIcon name="Audience" icon={<AudienceIcon />} />
                  // }
                  label="Audience"
                  // validators={[VALIDATOR_MINLENGTH(3)]}
                  noValidation
                  onInput={inputHandler}
                  initialValue={formInitialValues && formInitialValues.audience}
                />

                <Input
                  id="toneOfVoice"
                  rows={5}
                  type="text"
                  label="Tone of voice"
                  // label={
                  //   <TitleWithIcon
                  //     name="Tone of voice"
                  //     icon={<ToneOfVoiceIcon />}
                  //   />
                  // }
                  placeholder="Ex: Prietenos, autentic cald, uman, de incredere, la persoana a doua singular, din poziția specialistului care oferă informatii valoroase pe intelesul publicului la care vrea sa ajunga. "
                  // validators={[VALIDATOR_MINLENGTH(3)]}
                  noValidation
                  onInput={inputHandler}
                  initialValue={
                    formInitialValues && formInitialValues.toneOfVoice
                  }
                />
                <Input
                  id="thingsToConsider"
                  rows={5}
                  type="text"
                  label="Things to do"
                  // label={
                  //   <TitleWithIcon
                  //     name="Things to do"
                  //     icon={<ThumbsIcon />}
                  //     color="#66ca95"
                  //   />
                  // }
                  placeholder="Ex: Folosim un limbaj cat mai clar si cat mai simplu.
                Facem trimitere catre pagina de specialitate in fiecare articol despre o anumita afectiune
                "
                  // validators={[VALIDATOR_MINLENGTH(3)]}
                  noValidation
                  onInput={inputHandler}
                  initialValue={
                    formInitialValues && formInitialValues.thingsToConsider
                  }
                />
                <Input
                  id="formatting"
                  rows={5}
                  type="text"
                  label="Formatting"
                  // label={
                  //   <TitleWithIcon name="Formatting" icon={<FormattingIcon />} />
                  // }
                  placeholder="Ex: Foloseste fraze scurte - nu mai mult de 3 propozitii intr-o fraza, cu maximum 20 de cuvinte.
                Un paragraf trebuie sa trateze o singura idee.
                Utilizeaza bullets acolo unde este posibil.
                "
                  // validators={[VALIDATOR_MINLENGTH(3)]}
                  noValidation
                  onInput={inputHandler}
                  initialValue={
                    formInitialValues && formInitialValues.formatting
                  }
                />
              </div>
              <div className={"brief-right-column"}>
                {/* <Select
                options={convertOptionsData(industries)}
                placeholder="Select Industry"
                className="react-select-container"
                classNamePrefix="react-select"
                onChange={(values) =>
                  handleSelectChange("secondaryIndustry", values)
                }
                defaultValue={findDefaultValue(
                  industries,
                  formInitialValues.secondaryIndustryId
                )}
              /> */}

                <ProjectBriefSliders
                  isDisabled={false}
                  onUpdateSlider={(id, value) => handleSliderChange(id, value)}
                  defaultValue1={
                    formInitialValues && formInitialValues.contentStyleSlider1
                  }
                  defaultValue2={
                    formInitialValues && formInitialValues.contentStyleSlider2
                  }
                  defaultValue3={
                    formInitialValues && formInitialValues.contentStyleSlider3
                  }
                />

                <Input
                  id="contentSample"
                  name="project-brief-area"
                  type="text"
                  rows={5}
                  label="Content samples"
                  // label={
                  //   <TitleWithIcon name="Content samples" icon={<PencilIcon />} />
                  // }
                  placeholder="
                Ex: In urmatorul material informatia este foarte bine structurata, completa si usor de urmarit:
                https://brico.bricodepot.ro/idei-si-inspiratie/amenajare-interioara/ce-este-tinciul-si-cum-il-aplici/
                "
                  // validators={[VALIDATOR_MINLENGTH(3)]}
                  noValidation
                  onInput={inputHandler}
                  initialValue={
                    formInitialValues && formInitialValues.contentSample
                  }
                />

                <Input
                  id="thingsToAvoid"
                  rows={5}
                  type="text"
                  label="Things to avoid"
                  // label={
                  //   <TitleWithIcon
                  //     name="Things to avoid"
                  //     icon={<AvoidIcon />}
                  //     color="#ec5032"
                  //   />
                  // }
                  placeholder="Ex: Adaptarea unor termeni din limba engleza la limba romana, dar care nu sunt inca acceptati de majoritatea vorbitorilor. Ex: „a share-ui”, „a google-ui” etc
                regionalisme, arhaisme si elemente de jargon/argou
                "
                  // validators={[VALIDATOR_MINLENGTH(3)]}
                  noValidation
                  onInput={inputHandler}
                  initialValue={
                    formInitialValues && formInitialValues.thingsToAvoid
                  }
                />
                <Input
                  id="sourcesAndImages"
                  rows={5}
                  type="text"
                  label="Sources & Images"
                  // label={
                  //   <TitleWithIcon
                  //     name="Sources & Images"
                  //     icon={<PictureIcon />}
                  //   />
                  // }
                  placeholder="Ex: Imaginile vor fi cu oameni, in masura in care este posibil, mai naturale, nu putem folosi decat persone caucaziene, pentru ca publicul nostru cu ele se identifica. 
                Se va scrie sursa fotografiilor la fiecare final de articol
                "
                  // validators={[VALIDATOR_MINLENGTH(3)]}
                  noValidation
                  onInput={inputHandler}
                  initialValue={
                    formInitialValues && formInitialValues.sourcesAndImages
                  }
                />
              </div>
            </div>
            <Input
              id="desiredCta"
              rows={5}
              type="text"
              placeholder="Ex: Scopul final al conținutului pentru acest project este sa trimitem cititorii catre spatiile de cazare din diverse orase. Exemple de linkuri catre care se poate face trimitere (in functie de destinatiile despre care se scrie, se poate inlocui orasul/satul/regiunea): https://www.olx.ro/imobiliare/cazare-turism/ https://www.olx.ro/imobiliare/cazare-turism/sibiu/ https://www.olx.ro/imobiliare/cazare-turism/brasov/ 
            "
              label="Desired CTA"
              // label={<TitleWithIcon name="Desired CTA" icon={<CTAIcon />} />}
              // validators={[VALIDATOR_MINLENGTH(3)]}
              noValidation
              onInput={inputHandler}
              initialValue={formInitialValues && formInitialValues.desiredCta}
            />
            <Input
              id="additionalInfo"
              rows={5}
              type="text"
              placeholder="Ex: Pentru acest project nu se vor scrie texte cu legatura directa cu alcool, tutun si junk food. 
            Pozele vor fi cu mai mult rosu, culoare dupa care este recunoscut vizual projectul
            Linkurile din text vor fi doar catre categorii de produse, nu catre produse si catre articole de blog.
            "
              label="Additional info"
              // validators={[VALIDATOR_MINLENGTH(3)]}
              noValidation
              onInput={inputHandler}
              initialValue={
                formInitialValues && formInitialValues.additionalInfo
              }
            />
            <Input
              id="facebookCopy"
              rows={5}
              type="text"
              placeholder="Ex: Copy-ul va fi de cel mult trei randuri, ideal de doua. Acesta nu trebuie sa contina termeni care intra in conflict cu politicile facebook, spre exemplu: termeni medicali pe pagini generaliste. Pozele care vor insoti copy-ul de facebook nu trebuie sa fie cu refplexia in oglinda, cu text, cu o anumită parte a corpului și nici poze de tipul before and after.
            "
              label="Facebook Copy"
              // label={<TitleWithIcon name="Facebook Copy" icon={<FbIcon />} />}
              // validators={[VALIDATOR_MINLENGTH(3)]}
              noValidation
              onInput={inputHandler}
              initialValue={formInitialValues && formInitialValues.facebookCopy}
            />
            <div className="btn-wrapper">
              <div className="project-brief-submit">
                <Button fullSize passive onClick={setupLater}>
                  Set this up later
                </Button>
              </div>
              <div className="project-brief-submit">
                <Button type="submit" fullSize disabled={!isFormValid()}>
                  Save changes
                </Button>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default ProjectBriefForm;
