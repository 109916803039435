import React from "react";

import "./HumanReadableData.css";

const HumanReadableDate = ({ date }) => {
  const formatted = date.toLocaleString("default", {
    day: "numeric",
    month: "short",
  });

  return <p className="hr-data">{formatted}</p>;
};

export default HumanReadableDate;
