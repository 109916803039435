import React, { useCallback, useReducer, useState } from "react";

import "./MultistepRegisterComponent.css";

import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Typography from "@material-ui/core/Typography";
import { formReducer } from "../../utils/formReducer";
import { validateStep } from "./utils";
import Button from "../../Shared/Button";
import StepOneInputs from "./StepOneInputs";
import StepThreeInputs from "./StepThreeInputs";
import RegisterTitle from "./RegisterTitle";
import useUserHook from "../../apiHooks/useUserHook";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

function getSteps() {
  return ["Set up account data", "Set up team data"];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return `Provide the information required to create the master user for your account`;
    case 1:
      return `Add users to your team as content writers or additional management accounts.
      You can skip this step now, leave the fields blank and set this up later`;
    default:
      return "";
  }
}

const displayTitle = (activeStep) => {
  switch (activeStep) {
    case 0:
      return "Register new account";

    default:
      return "Add users";
  }
};

const displayDescription = (activeStep) => {
  switch (activeStep) {
    case 1:
      return "Content writers can write content for the projects they are assigned on while administrator accounts have unrestricted access to projects and dashboards";

    default:
      return "";
  }
};

const MultistepRegisterComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const { registerMultistepRequestHook } = useUserHook();
  const [activeStep, setActiveStep] = React.useState(0);
  const [teamList, setTeamList] = useState([]);
  const [formState, dispatch] = useReducer(formReducer, {
    inputs: {
      firstName: {
        value: "",
        isValid: false,
      },
      lastName: {
        value: "sop",
        isValid: false,
      },
      email: {
        value: "",
        isValid: false,
      },
      websiteUrl: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
      companyName: {
        value: "",
        isValid: false,
      },
      vatNumber: {
        value: "",
        isValid: false,
      },
      registrationNumber: {
        value: "",
        isValid: false,
      },
      address: {
        value: "",
        isValid: false,
      },
    },
    isValid: false,
  });
  const steps = getSteps();

  const handleNext = () => {
    if (activeStep < 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      submitFormData();
    }
  };

  const inputHandler = useCallback((id, value, isValid) => {
    dispatch({
      type: "INPUT_CHANGE",
      value: value,
      isValid: isValid,
      inputId: id,
    });
  }, []);

  const updateTeam = (teamData) => {
    setTeamList(teamData);
  };

  const registerSubmitHandler = async (event) => {
    event.preventDefault();
  };

  const submitFormData = async () => {
    const {
      inputs: { firstName, lastName, email, websiteUrl, password },
    } = formState;
    const multistepRegisterPayload = {
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      websiteUrl: websiteUrl.value,
      password: password.value,

      team: teamList,
    };

    const userStatus = await registerMultistepRequestHook(
      multistepRegisterPayload
    );

    if (userStatus === 201) {
      history.push("/login");
    }
  };

  return (
    <div className="multistep-register-component">
      <div className="multistep-register-left-content">
        <div className="multistep-register-app-logo">
          <img
            src="/images/logo-contero.svg"
            alt="logo"
            width="200px"
            height="65px"
          />
        </div>
        <div className={classes.root}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                  <Typography>{getStepContent(index)}</Typography>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </div>
      </div>
      <div className="multistep-register-right-content">
        <RegisterTitle title={displayTitle(activeStep)} />
        <div className="register-desc">{displayDescription(activeStep)}</div>
        <form
          className="multistep-register-form"
          onSubmit={registerSubmitHandler}
        >
          {activeStep === 0 && <StepOneInputs onInputChange={inputHandler} />}
          {/* {activeStep === 1 && <StepTwoInputs onInputChange={inputHandler} />} */}
          {activeStep === 1 && (
            <StepThreeInputs onUpdateTeamList={updateTeam} />
          )}

          <Button
            type="submit"
            fullSize
            onClick={handleNext}
            disabled={
              !validateStep(activeStep, formState.inputs) && activeStep !== 1
            }
          >
            {activeStep !== 1 ? "Next" : "Finish registration"}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default MultistepRegisterComponent;
