import React from "react";

import "./UsefulLinks.css";

const UsefulLinks = ({ dataBrief, article }) => {
  const hasLinks =
    (dataBrief && dataBrief.businessUrl) ||
    (dataBrief && dataBrief.businessFacebookUrl) ||
    (dataBrief && dataBrief.businessBlogUrl) ||
    (article && article.briefUrl) ||
    (article && article.publisherUrl);
  return (
    <>
      {hasLinks && (
        <>
          <p className="brief-titles">Useful Links:</p>
          <div className="links-collection">
            {dataBrief && (
              <>
                <a href={dataBrief.businessUrl} target="blank">
                  <div className="links-collection-stats">
                    Website
                    <img
                      src="/images/_external.svg"
                      alt="open"
                      width="14px"
                      height="14px"
                    />
                  </div>
                </a>
                <a href={dataBrief.businessFacebookUrl} target="blank">
                  <div className="links-collection-stats">
                    Social profile
                    <img
                      src="/images/_external.svg"
                      alt="open"
                      width="14px"
                      height="14px"
                    />
                  </div>
                </a>
                <a href={dataBrief.businessBlogUrl} target="blank">
                  <div className="links-collection-stats">
                    Blog Path
                    <img
                      src="/images/_external.svg"
                      alt="open"
                      width="14px"
                      height="14px"
                    />
                  </div>
                </a>
              </>
            )}

            {article && article.briefUrl && (
              <a href={article.briefUrl} target="blank">
                <div className="links-collection-stats">
                  Brief Url
                  <img
                    src="/images/_external.svg"
                    alt="open"
                    width="14px"
                    height="14px"
                  />
                </div>
              </a>
            )}
            {article && article.publisherUrl && (
              <a href={article.publisherUrl} target="blank">
                <div className="links-collection-stats">
                  Publisher Url
                  <img
                    src="/images/_external.svg"
                    alt="open"
                    width="14px"
                    height="14px"
                  />
                </div>
              </a>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default UsefulLinks;
