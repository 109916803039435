import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DEFAULT_ARTICLE_ID } from "../consts";

const useArticleId = () => {
  const urlParams = useParams();
  const [articleId, setArticleId] = useState();
  const [isDefault, setIsDefault] = useState(false);

  useEffect(() => {
    const isDefaultId = urlParams.id && urlParams.id === DEFAULT_ARTICLE_ID;
    setArticleId(urlParams.id);
    setIsDefault(isDefaultId);
  }, [urlParams]);

  return {
    isDefaultArticleId: isDefault,
    articleId,
  };
};

export default useArticleId;
