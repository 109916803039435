import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import SearchResultsDashboard from "../Components/SearchResultsDashboard/SearchResultsDashboard";

const SearchResultsPage = () => {
  const [isUserAuth] = useState(!!localStorage.getItem("userToken"));

  return isUserAuth ? <SearchResultsDashboard /> : <Redirect to="/login" />;
};

export default SearchResultsPage;
