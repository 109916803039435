import React from "react";
import ChangePassComponent from "../Components/ChangePassComponent";

import "./ResetPassword.css";

const ChangePassowrd = () => {
  return (
    <div className="reset-pass-page">
      <ChangePassComponent />
    </div>
  );
};

export default ChangePassowrd;
