import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEditorInstance } from "../../reducers/textEditor";
import CustomSvgIcon from "./CustomSvgIcon";
import { Menu, MenuItem } from "@material-ui/core";
import CustomFileUploader from "../CustomFileUploader";

import "./ImageUploader.css";
import { getCurrentImages, setLimitImagesError } from "../../reducers/images";
import { IMG_NOT_ALLOWED, MAX_IMAGES_ALLOWED } from "../../common/consts";

const ImageUploader = ({
  icon,
  title,
  isActive = null,
  onUploadProgres,
  onUploadError,
  onUploadStart,
  onUploadSuccess,
}) => {
  const editor = useSelector(getEditorInstance);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [updateProgress, setUpdateProgress] = useState(0);

  const totalImages = useSelector(getCurrentImages);

  const handleClick = (event) => {
    if (totalImages < MAX_IMAGES_ALLOWED) {
      setAnchorEl(event.currentTarget);
    }
    if (totalImages >= MAX_IMAGES_ALLOWED) {
      dispatch(setLimitImagesError(IMG_NOT_ALLOWED));
    }
  };

  const handleClose = (e) => {
    setAnchorEl(null);
  };

  const sendImageUrl = () => {
    if (imageUrl !== "") {
      editor.chain().focus().setImage({ src: imageUrl }).run();
    }
    setImageUrl("");
    handleClose();
  };

  useEffect(() => {
    onUploadProgres(updateProgress);
  }, [onUploadProgres, updateProgress]);

  const onImageUploaded = (image) => {
    editor.commands.setImage({
      src: image.url,
    });
    onUploadSuccess();
  };

  const onInputChange = (e) => {
    setImageUrl(e.target.value);

    // onUploadStart();
  };
  return (
    <>
      <button
        className={`menu-item${isActive && isActive() ? " is-active" : ""}`}
        onClick={handleClick}
        title={title}
      >
        <CustomSvgIcon icon={icon} />
      </button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="image-opener"
      >
        <MenuItem id="url-insert">
          <div className="image-input-url">
            <input
              type="text"
              value={imageUrl}
              placeholder="Image Url..."
              onChange={onInputChange}
            />
            <button className="insert-image-btn" onClick={sendImageUrl}>
              Ok
            </button>
          </div>
        </MenuItem>
        <MenuItem id="uploader" onClick={handleClose}>
          <CustomFileUploader
            onSuccess={onImageUploaded}
            onStart={onUploadStart}
            onError={onUploadError}
            title="Upload Image"
            onUpdatedProgress={setUpdateProgress}
          />
        </MenuItem>
      </Menu>
    </>
  );
};

export default ImageUploader;
