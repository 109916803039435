import React, { useState, useEffect } from "react";

import Popup from "reactjs-popup";
import CountDownTimer from "./CountDownTimer";

import "./InfoTips.css";

import { getShuffledArray } from "./infoTipsHelpers";

const data = [
  {
    showThisIn: 120000,
    keepThisFor: 5000,
    title: `Hey! Ai inceput sa scrii <img src="/images/_pencil-ico.png"/>?`,
    imageUrl: "/images/_icon_card1.png",
    paragraphs: [
      {
        text: `Folosește fraze scurte - nu mai mult de 3 propoziții într-o frază, cu maximum 20 de cuvinte`,
      },
      {
        text: `Construiește paragrafe scurte - încadrează-te între 3 și 10 propoziții/fraze, dar care să nu aibă mai mult de 150 de cuvinte împreună`,
      },
      {
        text: `Un paragraf trebuie să trateze o singură idee`,
      },
      {
        text: `Folosește white space în cadrul paragrafelor`,
      },
      {
        text: `Utilizează bullets acolo unde este posibil (enumerări, liste de orice fel, avantaje, optiuni, argumente)`,
      },
    ],
  },
  {
    showThisIn: 1380000,
    keepThisFor: 8000,
    title: `Pssst.. Mai am avem o rugaminte <img src="/images/_hug-ico.png"/>.`,
    imageUrl: "/images/_icon_card2.png",
    paragraphs: [
      {
        text: `Să nu ai repetiții sau dezacorduri`,
      },
      {
        text: `Să fii constant cu persoana la care scrii`,
      },
      {
        text: `Să nu ai greșeli de ortografie și punctuație`,
      },
      {
        text: `Să nu faci abuz de epitete, metafore sau neologisme`,
      },
      {
        text: `Verifică să nu ai spații duble`,
      },
    ],
  },
  {
    showThisIn: 900000,
    keepThisFor: 5000,
    title: `Nu uita! Imaginile sunt si ele importante <img src="/images/_picture-ico.png"/>.`,
    imageUrl: "/images/_icon_card3.png",
    paragraphs: [
      {
        text: `Respectă numărul de imagini recomandat de tool`,
      },
      {
        text: `Imaginile completează textul, alege-le ținând cont de asta, nu doar ca să bifezi numărul indicat de tool`,
      },
      {
        text: `Ferește-te de imagini care par de stock (regizate), alege unele cu ipostaze firești`,
      },
      {
        text: `Redenumește imaginile înainte de a le pune în folder, fie folosind cuvântul principal și numerotare individuală (lavandă1, lavanda2),  fie cu denumiri care descriu sugestiv fotografia (amenajare dormitor copil)`,
      },
      {
        text: `Folosește imagini cu puține elemente și cu oameni, atunci când este posibil`,
      },
      {
        text: `Alege imagini cu care cititorul să se identifice (nu cu negri, asiatici, hispanici)`,
      },
    ],
  },
  {
    showThisIn: 1260000,
    keepThisFor: 56000,
    title: `Foloseste inteligent ancorele <img src="/images/_anchor-ico.png"/>.`,
    imageUrl: "/images/_icon_card4.png",
    paragraphs: [
      {
        text: `Ai grijă mereu ca omul să știe unde ajunge dacă alege să dea click pe o ancoră (dacă landing page-ul unde ajunge este despre parfumuri, amenajarea dormitorului, idei de deserturi etc., anchor textul trebuie să dea clar această informație)`,
      },
      {
        text: `Nu pune linkul pe cuvinte de genul “aici”, “vezi aici”, “găsești aici” etc., ci mai degrabă pe obiectul acțiunii (“vezi aici categoria noastră de mobilă de dormitor” - ancora pe care va fi pus linkul este “mobilă de dormitor”, nu “vezi aici”)`,
      },
      {
        text: `Alternează CTA-urile (call to action) cu introducerea linkurilor pe ancore care se integrează firesc în text`,
      },
      {
        text: `Ancorele se scriu MEREU fără diacritice, chiar dacă textul este cu diacritice  fie cu denumiri care descriu sugestiv fotografia (amenajare dormitor copil)`,
      },
    ],
  },
  {
    showThisIn: 138000,
    keepThisFor: 6000,
    title: `Reguli de stil <img src="/images/_hat-ico.png"/> si vocabular:`,
    imageUrl: "/images/_icon_card5.png",
    paragraphs: [
      {
        text: `Evită cuvintele de umplutură și expresiile pompoase`,
      },
      {
        text: `Folosește termeni pe care să îi înțeleagă orice cititor`,
      },
      {
        text: `Folosește diateza activă, nu pe cea pasivă, care poate distruge dinamismul textului`,
      },
      {
        text: `Evită să folosești gerunziul, pe cât posibil`,
      },
      {
        text: `Folosește adverbe și conjuncții pentru a introduce argumentele și pentru a ghida cititorul (totuși, în concluzie, așadar, și, dar, însă, cu toate că, dincolo de, mai mult etc.). `,
      },
      {
        text: `Evită folosirea argoului, jargonului și a regionalismelor`,
      },
    ],
  },
];

const InfoTips = () => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [currentCard, setCurrentCard] = useState(null);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [totalCardsNumber, setTotalCardsNumber] = useState(0);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [currentTime, setCurrentTime] = useState();

  useEffect(() => {
    const shuffledData = getShuffledArray(data);

    setCurrentCard(shuffledData[currentCardIndex]);
    setTotalCardsNumber(shuffledData.length);
  }, [data]);

  useEffect(() => {
    // this is the timer to set the interval between showing cards
    // var rand = Math.round(Math.random() * (3000 - 500)) + 500;
    setShowCloseButton(false);
    if (currentCard) {
      const genericTimer = setTimeout(() => {
        openPopup();
      }, currentCard.showThisIn);

      return () => {
        clearInterval(genericTimer);
      };
    }
  }, [currentCard]);

  useEffect(() => {
    if (currentCard) {
      const minutes = Math.floor(currentCard.keepThisFor / 60000);
      const seconds = ((currentCard.keepThisFor % 60000) / 1000).toFixed(0);
      const hoursMinSecs = { minutes, seconds };
      setCurrentTime(hoursMinSecs);
    }
  }, [currentCard]);

  const openPopup = () => {
    setPopupOpen(true);
    allowCloseCard();
  };
  const closePopup = () => {
    setPopupOpen(false);
    setShowCloseButton(false);
  };

  const closeCurrentCard = () => {
    setCurrentCard(null);
    closePopup();
    const nextCard =
      currentCardIndex < totalCardsNumber - 1 ? currentCardIndex + 1 : 0;

    setCurrentCardIndex(nextCard);
    setCurrentCard(data[nextCard]);
  };

  const allowCloseCard = () => {
    if (currentCard) {
      const closeBtnTimer = setTimeout(() => {
        setShowCloseButton(true);
      }, currentCard.keepThisFor);

      return () => {
        clearInterval(closeBtnTimer);
      };
    }
  };

  return (
    <Popup open={popupOpen} onClose={closePopup} closeOnDocumentClick={false}>
      <div className="main-tips-wrapper">
        <div className="top-bar-control">
          {!showCloseButton ? (
            <div className="close-info-window">
              <p className="close-tips-text">You can close this window in</p>
              <CountDownTimer minSecs={currentTime} />
            </div>
          ) : (
            <div className="close-tips-button" onClick={closeCurrentCard}>
              &#10005;
            </div>
          )}
        </div>
        <div className="tips-content">
          <div className="left-side-info">
            <h2
              className="title-tips"
              dangerouslySetInnerHTML={{
                __html: currentCard && currentCard.title,
              }}
            ></h2>

            {currentCard &&
              currentCard.paragraphs &&
              currentCard.paragraphs.map((paragraph, index) => (
                <div key={index} className="info-bullets">
                  <img
                    className="info-bullet-img"
                    src="/images/_bullet-icon.png"
                    width="20px"
                    height="20px"
                    alt="check-icon"
                  />

                  <p>{paragraph.text}</p>
                </div>
              ))}
          </div>
          {currentCard && (
            <div className="right-side-img">
              <img src={currentCard.imageUrl} alt="Tips" />
            </div>
          )}
        </div>
      </div>
    </Popup>
  );
};

export default InfoTips;
