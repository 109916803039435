import { connectionWords, specialCharsCollection } from "./specialChars";

export const removeSpecialChars = (data) => {
  const cleaned = data.replace(
    /[ăĂâÂșşȘŞțţȚŢî]/g,
    (m) => specialCharsCollection[m]
  );
  return cleaned;
};

export const stripHtmlTags = (string) => {
  const cleanString = string.replace(/(<([^>]+)>)/gi, "");
  return cleanString;
};

export const transformConnectionWords = () => {
  // convert connectionWords to string and remove special chars from it
  const stringOfConnectionWords = removeSpecialChars(
    connectionWords.toString()
  );
  //  split string into array of words
  return stringOfConnectionWords.split(",");
};

export const removeWords = (string, words) => {
  // Match whole words with punctuation and non-word characters
  const regex = new RegExp(`\\b(${words.map(word => escapeRegExp(word)).join("|")})\\b`, "gi");
  // Replace matched words with spaces
  const withSpaces = string.replace(regex, " ");
  // Remove extra spaces
  const allTrimmed = withSpaces.replace(/\s+/g, " ");
  return allTrimmed.trim();
};

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}
