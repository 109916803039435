export const transformKeywords = (keywords) => {
  const topicForTags = keywords.map(({ heading, use_in_title, ...rest }) => {
    if (use_in_title) {
      if (heading) {
        return {
          titleAndHeading: true,
          heading,
          use_in_title,
          isMulti: true,
          headingMatch: true,
          markedByUser: false,
          ignoredByUser: false,
          repeat: 0,
          points: 2,
          ...rest,
        };
      }

      return {
        titleAndArticle: true,
        heading,
        use_in_title,
        isMulti: true,
        paragraphMatch: true,
        headingMatch: true,
        markedByUser: false,
        ignoredByUser: false,
        repeat: 0,
        points: 2,
        ...rest,
      };
    }

    if (heading) {
      return {
        headingAndArticle: true,
        heading,
        use_in_title,
        isMulti: true,
        paragraphMatch: true,
        markedByUser: false,
        ignoredByUser: false,
        headingMatch: true,
        repeat: 0,
        points: 2,
        ...rest,
      };
    }

    return {
      heading,
      use_in_title,
      isMulti: false,
      paragraphMatch: true,
      headingMatch: false,
      markedByUser: false,
      ignoredByUser: false,
      repeat: 0,
      points: 1,
      ...rest,
    };
  });

  return topicForTags;
};

// rules of calculation for the completion bar
export const setTopicRules = ({ maxWords, maxImages }) => {
  if (maxWords < 2000 && maxImages < 50) {
    return {
      type: "normal",
      topicDensity: 10,
      images: 5,
      wordcount: 15,
      headingsOptimization: 25,
      topicCoverage: 25,
      titleOptimization: 15,
    };
  }

  if (maxWords > 2000 && maxImages > 0) {
    return {
      type: "words",
      topicDensity: 10,
      images: 5,
      wordcount: 20,
      headingsOptimization: 25,
      topicCoverage: 25,
      titleOptimization: 10,
    };
  }

  if (maxWords > 0 && maxImages > 50) {
    return {
      type: "images",
      topicDensity: 5,
      images: 35,
      wordcount: 10,
      headingsOptimization: 20,
      topicCoverage: 20,
      titleOptimization: 10,
    };
  }
};

export const transformData = (data) => {
  const date = new Date();
  const currentMonth = date.getMonth();

  const months = data.monthlyValues.reduce(
    (acc, curr) =>
      curr.id === currentMonth
        ? [...acc, { ...curr, fill: "#A9ACD4", currentMonth: true }]
        : [...acc, { ...curr, fill: "#F2F4F9", currentMonth: false }],
    []
  );

  const shapedData = {
    ...data,
    monthlyValues: months,
  };

  return shapedData;
};

export const getExpectedTitle = (array) => {
  const expectedTitle = array.find(({ use_in_title }) => use_in_title);
  const title = expectedTitle ? expectedTitle.keyword : "";

  return title;
};

export const sortTopic = (topic) => {
  const sorted = topic.sort((a, b) => b.use_in_title - a.use_in_title);

  return sorted;
};

export const insertCountableKeyword = (keywords, topics, topicDensity) => {
  // match the keywords with the words from topic density and add
  // `use_in_text, this will help us display how many times the keyword is isued inside the article
  const countable = keywords.map((item) => {
    const matches = topics.find((keyword) => keyword === item.keyword);
    if (matches && matches === item.keyword) {
      return {
        ...item,
        // assign topic density value to use_in_text if default value is less than 1
        use_in_text: item.use_in_text > 1 ? item.use_in_text : topicDensity
      };
    }

    return item;
  });

  return countable;
};
