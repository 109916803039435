import React from "react";
import Options from "./Options";
import { userHasPermission } from "./dashboardComponentHelpers";
import { USER_PROOFREADER, USER_WRITER } from "../common/consts";

const DynamicOptionsSelect = ({
  isPopup,
  rows,
  updateProperty,
  data,
  defaultSelected,
  onSelectChange,
  currentUserType,
  action,
  placeholder,
  restricted,
  isReadOnly,
}) => {
  const updateRowWithSelectedOption = (currentValue, previousValue) => {
    const currentSelectedValue = currentValue ? currentValue.value : null;
    const previousSelectedValue = previousValue ? previousValue.value : null;
    // build new updated row
    const updatedRow = rows.map((row) => ({
      ...row,
      [updateProperty]: currentSelectedValue,
      [`prev${updateProperty}`]: previousSelectedValue,
    }));
    // send updated row as array to parent
    onSelectChange(updatedRow, action, updateProperty);
  };

  return (
    <Options
      handleOptionChange={(currentValue, previousValue) =>
        updateRowWithSelectedOption(currentValue, previousValue)
      }
      // restricted flag will limit the width of the container to 100px
      // restricted={restricted}
      data={data}
      placeholder={placeholder}
      preselectedValue={defaultSelected}
      isPopup={isPopup}
      readOnlyDisplay={
        isReadOnly ||
        userHasPermission(currentUserType, USER_PROOFREADER) ||
        userHasPermission(currentUserType, USER_WRITER)
      }
    />
  );
};

export default DynamicOptionsSelect;
