import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPerformanceList } from "../../services/performance-dashboard";
import PerformanceCard from "./PerformanceCard";
import Checkbox from "@material-ui/core/Checkbox";
import "./PerformanceDashboardList.css";
import { dashboardProjects } from "../../reducers/dashboard";

import TablePagination from "@material-ui/core/TablePagination";
import { useParams } from "react-router-dom";
import { BlackTooltip } from "../UITooltips/UITooltip";
import { setArticleId } from "../../reducers/articles";
import { setKeywordsSearch, setTopicData } from "../../reducers/searchTopic";
import { setTopicCoverageData } from "../../reducers/topicCoverage";
import { resetEditorData, setEditorData } from "../../reducers/textEditor";
import { FolderIcon, LogoutIcon, ViewIcon } from "../../UI_utils/SVGIcons";
import {
  getStatsHealth,
  getStatsImpressions,
  getStatsOpportunity,
  getStatsPublished,
  getStatsVisits,
  initPerformanceStats,
} from "../../reducers/performanceDashboard";
import PerformanceStatsBigCard from "../PerformanceStatsBigCard/PerformanceStatsBigCard";
import PerformanceStatsSmallCard from "../PerformanceStatsSmallCard/PerformanceStatsSmallCard";
import TextWithInfoIcon from "../PerformanceStatsSmallCard/TextWithInfoIcon";

const PerformanceDashboardList = () => {
  const dispatch = useDispatch();
  const urlParams = useParams();
  const opportunityStats = useSelector(getStatsOpportunity);
  const healthStats = useSelector(getStatsHealth);
  const visitsStats = useSelector(getStatsVisits);
  const impressionsStats = useSelector(getStatsImpressions);
  const publishedStats = useSelector(getStatsPublished);
  const projects = useSelector(dashboardProjects);
  const [listData, setListData] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [period, setPeriod] = useState("m");
  const [page, setPage] = React.useState(0);
  const [totalRows, setTotalRows] = useState();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // clean up all article related data when loading this page
  useEffect(() => {
    dispatch(setArticleId(null));
    dispatch(setTopicData(null));
    //   // reset topic coverage data when coming from eshop
    dispatch(setTopicCoverageData([]));
    //   // reset editor data to empty
    // dispatch(resetEditorData(true));
    dispatch(setKeywordsSearch(null));
  }, []);

  const currentProject =
    projects && projects.find(({ id }) => id === Number(urlParams.clientId));

  useEffect(() => {
    dispatch(initPerformanceStats(urlParams.clientId, period));
  }, [urlParams.clientId, period]);

  useEffect(() => {
    fetchPerformanceData(page, rowsPerPage, period);
  }, [page, rowsPerPage, period]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const updateListWithSelected =
      listData &&
      listData.map((item) => ({
        ...item,
        selected: selectAll,
      }));
    setListData(updateListWithSelected);
  }, [selectAll]);

  const fetchPerformanceData = async (page, rowsPerPage, period) => {
    try {
      const result = await getPerformanceList(
        page,
        rowsPerPage,
        period,
        urlParams.clientId
      );

      console.log({ result });
      setListData(result.rows);
      setTotalRows(result.total);
    } catch (error) {
      console.log({ error });
    }
  };

  if (!listData) {
    return <div>Loading</div>;
  }

  const selectAllCards = () => {
    setSelectAll(!selectAll);
  };

  const updateSelected = (id, value) => {
    const updateListById =
      listData &&
      listData.map((item) =>
        item.id === id ? { ...item, selected: value } : { ...item }
      );

    setListData(updateListById);
  };

  const selectDateRange = (interval) => {
    if (interval.value === 7) {
      setPeriod("w");
    } else {
      setPeriod("m");
    }
  };

  const logOutHandler = () => {
    localStorage.removeItem("userToken");
    window.location.replace("./login");
  };

  const label = { inputProps: { "aria-label": "Checkbox" } };

  return (
    <div className="performanceDashboard">
      <div className="stats-wrapper">
        <div className="performance-stats">
          <PerformanceStatsBigCard
            title={currentProject && currentProject.name}
            onSelectDateRange={selectDateRange}
            opportunity={opportunityStats && opportunityStats.opportunity}
            health={healthStats && healthStats}
            publishedArticles={publishedStats && publishedStats}
          />
          <div className="performance-small-cards">
            <PerformanceStatsSmallCard
              title="Visits"
              currentData={visitsStats && visitsStats.new}
              data={visitsStats && visitsStats}
              icon={<FolderIcon />}
              isView={false}
            />
            <PerformanceStatsSmallCard
              title="Impressions"
              currentData={impressionsStats && impressionsStats.new}
              data={impressionsStats && impressionsStats}
              icon={<ViewIcon />}
              isView={true}
            />
          </div>
        </div>
      </div>
      <div className="performanceListWrapper">
        <div className="mainList">
          <div className="listHead">
            <div className="selectCard">
              <Checkbox
                {...label}
                checked={selectAll}
                onChange={selectAllCards}
                color="default"
              />
            </div>
            <div className="selectAllTitle">Select All</div>

            <div className="healthTitle">
              <TextWithInfoIcon
                title="Health"
                tooltipText="The percentage of organic visits for the selected timeframe compared to the total number of visits this topic can obtain."
                fontSize={13}
                weight={600}
                color="#6F767E"
              />
            </div>

            <div className="opportunityTitle">
              <TextWithInfoIcon
                title="Opportunity"
                tooltipText="The total number of visits that this topic can obtain."
                fontSize={13}
                weight={600}
                color="#6F767E"
              />
            </div>

            <div className="visitsTitle">
              <TextWithInfoIcon
                title="Visits"
                tooltipText="The number of organic visits for the selected timeframe."
                fontSize={13}
                weight={600}
                color="#6F767E"
              />
            </div>

            <div className="keywordsTitle">
              <TextWithInfoIcon
                title="Keywords"
                tooltipText="This article is ranking for this number of keywords in the selected timeframe."
                fontSize={13}
                weight={600}
                color="#6F767E"
              />
            </div>

            <div className="rankTitle">
              <TextWithInfoIcon
                title="Avg. rank"
                tooltipText="The average rank for all the keywords this article is ranking for."
                fontSize={13}
                weight={600}
                color="#6F767E"
              />
            </div>

            <div className="scoreTitle">
              <TextWithInfoIcon
                title="Score"
                tooltipText="The Contero score metric takes into account the percentage of article brief guidelines implementation by the writer."
                fontSize={13}
                weight={600}
                color="#6F767E"
              />
            </div>

            <div className="ratingTitle">
              <TextWithInfoIcon
                title="Rating"
                tooltipText="The article rating manually set by the proofreader based on grammar, spelling, punctuation and formatting guidelines."
                fontSize={13}
                weight={600}
                color="#6F767E"
              />
            </div>

            <div className="writerTitle">
              <TextWithInfoIcon
                title="Writer"
                tooltipText="Writer's full name"
                fontSize={13}
                weight={600}
                color="#6F767E"
              />
            </div>
          </div>
          <div className="listCard">
            {listData.map((article) => (
              <PerformanceCard
                key={article.id}
                data={article}
                onUpdateSelected={updateSelected}
              />
            ))}
          </div>
          {totalRows && (
            <TablePagination
              component="div"
              rowsPerPageOptions={[5, 10, 15, 20]}
              count={totalRows}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </div>
      </div>
      <div className="bottom-info">
        <div className="copyright-info">
          &#169; Contero. All rights reserved.
        </div>
      </div>
    </div>
  );
};

export default PerformanceDashboardList;
