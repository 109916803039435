import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { formatDateForServer } from "../../utils/dateUtility";
import FormErrorMessage from "../FormErrorMessage";

import "./CustomDateRangeFilter.css";

const CustomDateRangePicker = ({
  onFilter,
  currentColumn,
  initialValue,
  isClear,
}) => {
  // put default values directly in the state
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [rangeDate, setRangeDate] = useState([null, null]);
  const [errorStart, setErrorStart] = useState(null);
  const [errorEnd, setErrorEnd] = useState(null);

  useEffect(() => {
    if (initialValue && initialValue.length > 0) {
      getInitialValues();
    }
  }, []);

  useEffect(() => {
    buildFilterOptions();
  }, [rangeDate]);

  useEffect(() => {
    if (isClear) {
      setStartDate(null);
      setEndDate(null);
      setRangeDate(null, null);
      const filter = {
        [currentColumn]: undefined,
      };
      onFilter(filter);
    }
  }, [isClear]);

  const getInitialValues = () => {
    setStartDate(initialValue[0]);
    setEndDate(initialValue[1]);
    setRangeDate([initialValue[0], initialValue[1]]);
  };

  const buildFilterOptions = () => {
    const dates =
      rangeDate && rangeDate.map((date) => date && formatDateForServer(date));
    const filter = {
      [currentColumn]: dates,
    };
    if (dates && dates.every((date) => date)) {
      // don't send the dates if they are null
      onFilter(filter);
    }
  };

  const handleStartDate = (newDate) => {
    setStartDate(newDate);
    const range = [newDate, endDate];
    setRangeDate(range);
  };
  const handleEndDate = (newDate) => {
    setEndDate(newDate);
    const range = [startDate, newDate];
    setRangeDate(range);
  };

  const validateRangeDate = () => {
    if (startDate && !endDate) {
      setErrorEnd(true);
    } else if (!startDate && endDate) {
      setErrorStart(true);
    } else if (!startDate && !endDate) {
      setErrorStart(true);
      setErrorEnd(true);
    } else {
      setErrorStart(false);
      setErrorEnd(false);
    }
  };

  return (
    <>
      <div className="range-dates-input">
        <div className="date-picker-start">
          <DatePicker
            selected={startDate}
            onChange={handleStartDate}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            placeholderText="Start Date"
            autoFocus={false}
            startOpen={false}
            onCalendarClose={validateRangeDate}
            popperPlacement="top-start"
          />
          {errorStart && (
            <FormErrorMessage>*please select a start range</FormErrorMessage>
          )}
        </div>

        {/* <p className="date-range-text">to</p> */}

        <div className="date-picker-end">
          <DatePicker
            selected={endDate}
            onChange={handleEndDate}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            placeholderText="End Date"
            startOpen={false}
            onCalendarClose={validateRangeDate}
            popperPlacement="top-end"
          />
          {errorEnd && (
            <FormErrorMessage>*please select an end range</FormErrorMessage>
          )}
        </div>
      </div>
    </>
  );
};

export default CustomDateRangePicker;
