import React from "react";

import "./HeadingsInArticle.css";

const HeadingsInArticle = ({ headings }) => {
  return (
    <div>
      <p className="table-of-contents">TABLE OF CONTENTS</p>
      {headings &&
        headings.map((heading, index) => {
          const key = Object.keys(heading).join();
          const value = Object.values(heading).join();

          return (
            <p
              key={index}
              className={`${key}-article-heading`}
            >{`<${key}> ${value}`}</p>
          );
        })}
    </div>
  );
};

export default HeadingsInArticle;
