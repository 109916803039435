import React from "react";

import "./TitleWithIcon.css";

const TitleWithIcon = ({ icon, name, color, small, iconLeft, customFont }) => {
  return (
    <div className="title-with-icon">
      {iconLeft ? (
        <>
          {icon}
          <div
            className="title-name"
            style={{ color, fontSize: small ? "14px" : "15px", ...customFont }}
          >
            {name}
          </div>
        </>
      ) : (
        <>
          <div
            className="title-name"
            style={{ color, fontSize: small ? "14px" : "15px", ...customFont }}
          >
            {name}
          </div>
          {icon}
        </>
      )}
    </div>
  );
};

export default TitleWithIcon;
