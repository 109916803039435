import { getUserToken } from "../utils/userStatus";
import {
  setNewArticle,
  saveArticleApi,
  getArticles,
  articleById,
  saveArticleStats,
  getArticleStats,
  uploadCSV,
  exportToPdf,
  updateTechnicalFeedback,
  rateArticle,
  getArticlePlagiarismScore,
  updatePlagiarismScore,
  uploadImage,
} from "../api/articles";

import { formatDateForStore, getTimeFromDate } from "../utils/dateUtility";

export const createNewArticle = async (keyword, articleType, projectId) => {
  const token = getUserToken();

  const { id } = await setNewArticle(
    {
      name: keyword,
      content: "Article content",
      type: articleType,
      project: projectId || 1,
    },
    token
  );

  return id;
};

export const saveArticleData = async (payload) => {
  const token = getUserToken();

  const result = await saveArticleApi({ ...payload }, token);
  return result;
};

export const getAllArticles = async () => {
  const token = getUserToken();

  try {
    const articles = await getArticles(token);
    const articlesWithHRDate = articles.map(({ stats, ...rest }) => {
      const newStats = {
        ...stats,
      };
      // failproof for old articles which don't have dateObject saved in the stats
      if (stats && stats.dateObject) {
        const saveTime = getTimeFromDate(stats.dateObject);
        const saveDate = formatDateForStore(stats.dateObject);

        return {
          stats: {
            ...newStats,
            saveTime,
            saveDate,
          },
          ...rest,
        };
      }

      return {
        stats,
        ...rest,
      };
    });

    // failproof
    // get only articles with stats and dateObject so we can sort them in order
    const onlyArticlesWithStats = articlesWithHRDate.filter(
      ({ stats }) => stats
    );
    const filtered = onlyArticlesWithStats.filter(
      (article) => article.stats.dateObject
    );

    const sorted = filtered.sort(
      (a, b) =>
        new Date(b.stats.dateObject).getTime() -
        new Date(a.stats.dateObject).getTime()
    );

    return sorted;
  } catch ({ data }) {
    return {
      ...data,
      error: true,
    };
  }
};

export const getArticleById = async (id) => {
  const token = getUserToken();

  try {
    const currentArticle = await articleById({ id }, token);
    return currentArticle;
  } catch ({ data }) {
    return {
      ...data,
      error: true,
    };
  }
};

export const saveStats = async (id, stats) => {
  const token = getUserToken();

  const saved = await saveArticleStats({ id, stats }, token);
  return saved;
};

export const getStats = async (id) => {
  const token = getUserToken();
  try {
    const articleStats = await getArticleStats({ id }, token);

    return articleStats;
  } catch (error) {
    return [];
  }
};

export const uploadCSVArticles = async (file) => {
  const token = getUserToken();

  let formData = new FormData();
  formData.append("file", file);

  try {
    const uploaded = await uploadCSV(formData, token);
    return uploaded;
  } catch (data) {
    return {
      ...data,
      error: true,
    };
  }
};

export const uploadImageToEditor = async (file, setUploadProgress) => {
  const token = getUserToken();

  let formData = new FormData();
  formData.append("file", file);

  try {
    const uploaded = await uploadImage(formData, setUploadProgress, token);
    return uploaded;
  } catch (data) {
    return {
      ...data,
      error: true,
    };
  }
};

export const exportArticle = async (id) => {
  const token = getUserToken();
  await exportToPdf({ id }, token);
};

export const saveTechnicalFeedback = async (payload) => {
  const token = getUserToken();

  try {
    const result = await updateTechnicalFeedback(payload, token);
    return result;
  } catch ({ data }) {
    return {
      ...data,
      error: true,
    };
  }
};

export const submitRatingArticle = async (payload) => {
  const token = getUserToken();

  try {
    const result = await rateArticle(payload, token);
    return result;
  } catch ({ data }) {
    return {
      ...data,
      error: true,
    };
  }
};

export const checkPlagiarismScore = async (id) => {
  const token = getUserToken();
  const payload = {
    id,
  };

  const result = await getArticlePlagiarismScore(payload, token);
  return result;
};

export const checkPlagiarismAndUpdate = async (id) => {
  const token = getUserToken();
  const payload = {
    id,
  };

  const result = await updatePlagiarismScore(payload, token);
  return result;
};
