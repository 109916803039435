import React, { useEffect } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import Document from "@tiptap/extension-document";
import StarterKit from "@tiptap/starter-kit";
import CharacterCount from "@tiptap/extension-character-count";
import Placeholder from "@tiptap/extension-placeholder";
import Image from "@tiptap/extension-image";
// we use our custom extension from extensions folder
import { Link } from "./extensions/link/CustomLink";
import TextAlign from "@tiptap/extension-text-align";
import { debounce } from "lodash";
import "./TipTapEditor.css";
import {
  saveEditorDataToStore,
  setEditorInstance,
} from "../../reducers/textEditor";
import {
  setHighTopicCoverage,
  setLowTopicCoverage,
  setTopicCoverageData,
  updateMatchingWords,
} from "../../reducers/topicCoverage";
import { useDispatch, useSelector } from "react-redux";
import { setWordCount } from "../../reducers/counters";
import { DEFAULT_ARTICLE_ID } from "../../common/consts";
import {
  getCurrentArticle,
  getCurrentArticleId,
  saveArticle,
  setArticle,
  setArticleId,
  setArticlesCollection,
} from "../../reducers/articles";
import { isDemoApplication } from "../../reducers/applicationMode";
import { checkTitleOptimization } from "../../reducers/titleOptimization";
import { checkHeadingsOptimization } from "../../reducers/headingsOptimization";
import { checkTopicDensity } from "../../reducers/topicDensity";
import {
  checkCurrentImages,
  transformContentOnPaste,
} from "../../reducers/images";

import useUnmount from "../../utils/useUnmount";
import { useApplicationType } from "../../utils/useApplicationType";
import EditorMenuTop from "./EditorMenuTop";
import { loadingStep2Data } from "../../reducers/searchTopic";

const CustomDocument = Document.extend({
  content: "heading block*",
});

const parseEditorData = debounce((dispatch, data) => {
  // console.log("SAVING DATA DEBOUNCED ", data);
  dispatch(saveEditorDataToStore(data));
}, 500);

const trackTopicCoverage = debounce((dispatch) => {
  dispatch(updateMatchingWords());
}, 600);

const sendDataToServer = debounce((dispatch, articleType) => {
  console.log("SAVING TO SERVER", articleType);
  dispatch(saveArticle(articleType));
}, 5000);

const trackTitle = debounce((dispatch) => {
  dispatch(checkTitleOptimization());
}, 900);

const trackHeadings = debounce((dispatch) => {
  dispatch(checkHeadingsOptimization());
}, 2800);

const trackTopicDensity = debounce((dispatch) => {
  dispatch(checkTopicDensity());
}, 2900);

const trackImages = debounce((dispatch) => {
  dispatch(checkCurrentImages());
}, 3000);

const TipTapEditor = () => {
  const dispatch = useDispatch();
  const isDemoApp = useSelector(isDemoApplication);
  const currentArticleId = useSelector(getCurrentArticleId);

  const currentArticle = useSelector(getCurrentArticle);
  const applicationType = useApplicationType();
  const isTopicLoading = useSelector(loadingStep2Data);

  const addContentToEditor = (editor) => {
    editor.commands.setContent(
      currentArticle.content === "Article content" ? "" : currentArticle.content
    );

    trackDataDetails(editor);
  };

  useEffect(() => {
    if (currentArticle && editor) {
      addContentToEditor(editor);
      trackDataDetails(editor);
    }
  }, [currentArticle]);

  useUnmount(() => {
    dispatch(setArticle(null));
    dispatch(setArticleId(null));
    dispatch(setArticlesCollection([]));
    dispatch(setEditorInstance(null));
    dispatch(setTopicCoverageData([]));
    dispatch(setHighTopicCoverage([]));
    dispatch(setLowTopicCoverage([]));
    if (editor) {
      editor.destroy();
    }
  });

  const trackDataDetails = (editor) => {
    const data = editor.getHTML();
    // console.log({ data });
    const wordCount = editor.storage.characterCount.words();
    dispatch(setWordCount(wordCount));
    // parsed all data - debounced
    parseEditorData(dispatch, data);
    // track words from the content editor - debounced
    trackTopicCoverage(dispatch);
    // track title from content editor - debounced
    trackTitle(dispatch);
    // track headings from content editor - debounced
    trackHeadings(dispatch);
    // track topic density from content editor
    // we use raw data for this one - debounced
    trackTopicDensity(dispatch);
    // track images from content editor - debounce
    trackImages(dispatch);
  };

  const editor = useEditor({
    editorProps: {
      transformPastedHTML: (html) => dispatch(transformContentOnPaste(html)),
    },
    extensions: [
      CustomDocument,
      StarterKit.configure({
        document: false,
      }),
      CharacterCount,
      Image,
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
      Link.configure({
        openOnClick: true,
        linkOnPaste: true,
        HTMLAttributes: {
          target: "_blank",
          rel: "canonical",
          class: null,
        },
      }),
      Placeholder.configure({
        placeholder: ({ node }) => {
          if (node.type.name === "heading") {
            return "Insert title here...";
          }

          return "";
        },
      }),
    ],
    // content: currentArticle.content,
    onCreate: ({ editor }) => {
      // setting editor content with current article content
      addContentToEditor(editor);
      // set editor instance globally so it can be accessed throught the app
      dispatch(setEditorInstance(editor)); // REVIEW: is this needed?
    },
    onUpdate: ({ editor }) => {
      trackDataDetails(editor);
      // send data to API, autosave debounced
      if (!isDemoApp && currentArticleId !== DEFAULT_ARTICLE_ID) {
        sendDataToServer(dispatch, applicationType);
      }
    },
  });

  if (!editor) {
    return null;
  }

  return (
    <div className="editor-wrapper">
      <EditorMenuTop editor={editor} />
      {!isTopicLoading && <EditorContent editor={editor} />}
    </div>
  );
};

export default TipTapEditor;
