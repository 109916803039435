import React, { useState } from "react";

import "./CustomTooltip.css";

const CustomTooltip = ({ noHide, text, posTop, posLeft }) => {
  const [tooltip, showTooltip] = useState(false);

  const InfoIcon = () => (
    <img
      src="/images/_info-icon.svg"
      alt="info"
      onMouseOver={() => text && showTooltip(true)}
      onMouseOut={() => text && showTooltip(false)}
    />
  );

  // noHide used for dev mode only (easier to position)
  const displayTooltip = (text && tooltip) || noHide;

  return (
    <div className="custom-tooltip">
      <InfoIcon />
      {displayTooltip && (
        <>
          <div className="tooltip-box" style={{ top: posTop, left: posLeft }}>
            <p className="tooltip-text">{text}</p>
          </div>
          <span className="tooltip-arrow"></span>
        </>
      )}
    </div>
  );
};

export default CustomTooltip;
