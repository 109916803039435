import React from "react";
import Input from "../Shared/Input";

import "./SearchKeywords.css";

const SearchKeywords = ({ onFilter }) => {
  const onSearch = (id, value) => {
    onFilter(value);
  };

  return (
    <div className="search-keywords">
      <Input
        id="search"
        element="input"
        type="text"
        validators={[]}
        onInput={onSearch}
        placeholder="Search a keyword..."
      />
    </div>
  );
};

export default SearchKeywords;
