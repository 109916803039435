import React from "react";
import ResetForm from "./ResetForm";

import "./ResetComponent.css";

const ResetComponent = () => {
  return (
    <div className="reset-component">
      <div className="reset-left-content">
        <img src="/images/_reset.png" width="220px" alt="Forgot Password" />
        <div className="reset-copy-area">
          <p className="reset-title-copy">We'll help you out!</p>
          <p className="reset-subcopy">
            All you need to do is tell us your e-mail and we'll send you reset
            instructions.
          </p>
        </div>
      </div>
      <div className="login-divider"></div>
      <div className="reset-right-content">
        <div className="reset-app-logo">
          <img
            src="/images/logo-contero.svg"
            alt="logo"
            width="200px"
            height="65px"
          />
        </div>
        <ResetForm />
      </div>
    </div>
  );
};

export default ResetComponent;
