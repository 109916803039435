import React, { useCallback, useState } from "react";
import "./ProjectsPerformanceDashboardList.css";
import Checkbox from "@material-ui/core/Checkbox";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { intToString } from "../../utils/formatNumbersToString";
import { useHistory } from "react-router-dom";
import { PERFORMANCE_DASHBOARD_PATH } from "../../common/consts";
import {
  LinkIcon,
  TrendArrowDown,
  TrendArrowUp,
} from "../../UI_utils/SVGIcons";

import { BlackTooltip } from "../UITooltips/UITooltip";

const ProjectsPerformanceCard = ({ data, onUpdateSelected }) => {
  const [activeId, setActiveId] = useState(null);
  const history = useHistory();

  const updateSelectedCard = (event) => {
    onUpdateSelected(data.id, event.target.checked);
  };

  const isHover = useCallback(() => {
    setActiveId(data.id);
  }, [data.id]);

  const isHighlight = activeId === data.id;

  const connectedStyle = {
    background: "#EAFAE4",
    color: "#83BF6E",
  };

  const openProject = () => {
    history.push(`${PERFORMANCE_DASHBOARD_PATH}/${data.id}`);
  };

  return (
    <>
      <div
        className="performanceCard"
        onMouseOver={isHover}
        onMouseLeave={() => setActiveId(null)}
      >
        <div className="cardCheck">
          <Checkbox
            checked={data.selected}
            onChange={updateSelectedCard}
            color="default"
          />
        </div>

        <div className="clientName">
          <BlackTooltip
            arrow
            placement="top"
            title={
              data.articles !== 0 ? "Open" : "No articles matched for project"
            }
          >
            <div
              onClick={data.articles !== 0 ? () => openProject() : () => {}}
              style={{ cursor: data.articles !== 0 ? "pointer" : "default" }}
            >
              {data.clientName}
            </div>
          </BlackTooltip>
        </div>

        <div className="articleInfo">
          <BlackTooltip
            arrow
            placement="top"
            title="The number of articles that have been published and matched with a live URL for a client."
          >
            <div>{data.articles}</div>
          </BlackTooltip>
        </div>

        <div className="healthInfo">
          <BlackTooltip
            arrow
            placement="top"
            title="The percentage of organic visits for the selected timeframe compared to the total number of visits this topic can obtain."
          >
            <div className="circleWrapper">
              <CircularProgressbarWithChildren
                value={data.health}
                strokeWidth="7"
                styles={buildStyles({
                  rotation: 0.25,
                  strokeLinecap: "round",
                  pathTransitionDuration: 0.5,
                  pathColor: "#6870f6",
                  trailColor: isHighlight ? "white" : "#F4F4F4",
                })}
              >
                <div className="healthScore">
                  {data.health ? `${data.health}%` : "N/A"}
                </div>
              </CircularProgressbarWithChildren>
            </div>
          </BlackTooltip>
        </div>
        <div className="scoreInfo">
          <BlackTooltip
            arrow
            placement="top"
            title="The Contero score metric takes into account the percentage of article brief guidelines implementation by the writer."
          >
            <div className="scoreValue">
              {data.score !== "0" ? data.score : "N/A"}
            </div>
          </BlackTooltip>
        </div>
        <div className="opportunityInfo">
          <BlackTooltip
            arrow
            placement="top"
            title="The total number of visits that this topic can obtain."
          >
            <div className="centerLabels">
              <div
                className="infoTag"
                style={{
                  background: `${isHighlight ? "white" : "#EFEFEF"}`,
                  // color: "red",
                }}
              >
                {data.trafficOpportunity.total !== 0
                  ? intToString(data.trafficOpportunity.total)
                  : "N/A"}
              </div>
            </div>
          </BlackTooltip>
        </div>
        <div className="visitsInfoPerf">
          <BlackTooltip
            arrow
            placement="top"
            title="The number of organic visits for the selected timeframe."
          >
            <div className="centerLabelsOffsetPerf">
              {data.clientStatus === "Connected" &&
                data.visitsTrend.value !== 0 && (
                  <div className="visitsTrend">
                    {data.visitsTrend.status === "ascending" ? (
                      <TrendArrowUp />
                    ) : (
                      <TrendArrowDown />
                    )}

                    <div
                      className="visitsTrendValue"
                      style={{
                        color:
                          data.visitsTrend.status === "ascending"
                            ? "#83BF6E"
                            : "#FF6A55",
                      }}
                    >{`${data.visitsTrend.value}%`}</div>
                  </div>
                )}
              <div
                className="infoTag"
                style={{
                  background: `${isHighlight ? "white" : "#EFEFEF"}`,
                  // color: "#1A1D1F",
                }}
              >
                {data.visits !== 0 ? intToString(data.visits) : "N/A"}
              </div>
            </div>
          </BlackTooltip>
        </div>

        <div className="rankInfo">
          <div className="centerLabels">
            <div
              className="statusTag"
              style={
                data.clientStatus === "Connected"
                  ? connectedStyle
                  : {
                      background: `${isHighlight ? "white" : "#EFEFEF"}`,
                      // color: "#1A1D1F",
                    }
              }
            >
              {data.clientStatus}
            </div>
          </div>
        </div>
        {data.articles !== 0 && (
          <div className="menuItemsInfo">
            <div className="menuIcons">
              <div
                className="iconWrapper"
                style={{ background: `${isHighlight ? "white" : "#EFEFEF"}` }}
                onClick={openProject}
              >
                <LinkIcon />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ProjectsPerformanceCard;
