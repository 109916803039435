import React, { Fragment, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Header from "../Components/Header";
import Modal from "../Shared/Modal";
import ModalContentSwitcher from "../Components/ModalContentSwitcher";
import SidebarLeft from "../Components/SidebarLeft";
import ArticleMode from "../ApplicationMode/ArticleMode";
import useMount from "../utils/useMount";
import {
  DASHBOARD_PATH,
  DEFAULT_ARTICLE_ID,
  OPEN_MODAL_INTERVAL,
} from "../common/consts";
import { getCurrentArticleId, requestArticleById } from "../reducers/articles";
import { isDemoApplication } from "../reducers/applicationMode";

import "./HomeContent.css";

const HomeContent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const articleId = useSelector(getCurrentArticleId);
  const isDemoApp = useSelector(isDemoApplication);

  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);

  const closeModalHandler = () => setShowModal(false);
  const openModalHandler = () => setShowModal(true);

  const hideModal = id && id !== DEFAULT_ARTICLE_ID;

  useMount(() => {
    // fetch article by id from url when we open the link or refresh the page
    // we do this only if id is not the default id, meaning 1
    if (!articleId && hideModal) {
      dispatch(requestArticleById(id));
    }

    const timer = setTimeout(() => {
      if (!hideModal && !isDemoApp) {
        setShowModal(true);
      }
    }, OPEN_MODAL_INTERVAL);
    return () => clearTimeout(timer);
  });

  const forceCloseModal = () => {
    closeModalHandler();
    history.push(DASHBOARD_PATH);
  };

  return (
    <Fragment>
      <Modal
        show={showModal}
        onCancel={closeModalHandler}
        header={
          <div className="modal__close-btn" onClick={forceCloseModal}>
            &#10005;
          </div>
        }
        contentClass="search-topic__modal-content"
      >
        <ModalContentSwitcher onCancel={closeModalHandler} />
      </Modal>
      <div className="homepage">
        <SidebarLeft onCreateNew={openModalHandler} />
        <div className="homepage-wrapper">
          <Header />
          <ArticleMode />
        </div>
      </div>
    </Fragment>
  );
};

export default HomeContent;
