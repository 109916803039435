import React from "react";
import TitleWithIcon from "../ProjectBriefComponents/TitleWithIcon";

import "./TitleWithDescription.css";

const TitleWithDescription = ({ name, desc, icon, color }) => {
  return (
    <div>
      <TitleWithIcon small name={name} icon={icon} color={color} />
      <p className="brief-desc">{desc}</p>
    </div>
  );
};

export default TitleWithDescription;
