import { getUserToken } from "../utils/userStatus";
import { sendFeedbackByUser } from "../api/feedbacks";

export const sendFeedbackToServer = async (id, userAction) => {
  const token = getUserToken();

  // console.log(id, userAction);

  const patchObject = {
    id,
  };

  const result = await sendFeedbackByUser(token, userAction, patchObject);
  return result;
};
