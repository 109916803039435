import React from "react";
import "./BriefCompletionBar.css";

const BriefCompletionBar = ({ completion }) => {
  const completionColor =
    completion < 30 ? "#E898A2" : completion > 70 ? "#ADE8C9" : "#EECD72";
  return (
    <>
      <div className="brief-completion-bar">
        <div
          style={{
            width: `${completion}%`,
            backgroundColor: completionColor,
            height: "10px",
            borderTopLeftRadius: "3px",
            borderTopRightRadius: "3px",
            borderBottomLeftRadius: "3px",
            borderBottomRightRadius: "3px",
          }}
        ></div>
      </div>
      <div className="url-column">{`${completion}%`}</div>
    </>
  );
};

export default BriefCompletionBar;
